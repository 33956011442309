import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { ApiVersion, baseURL } from '@/config';
import { LocalStorageItems } from '@/constants';

enum StatusCode {
  Invalid = 400,
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

export type ErrorMsg = {
  status: number;
  msg: string;
};

interface AxiosConfig extends InternalAxiosRequestConfig {
  retry?: boolean;
}

export const formDataHeader = { 'content-type': 'multipart/form-data' };

const headers: Readonly<Record<string, string | boolean | null>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Credentials': true,
  'X-Requested-With': 'XMLHttpRequest',
};

class Http {
  private isRefreshing = false;

  private failedQueue: any[] = [];

  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL,
      headers,
      withCredentials: true,
    });

    http.interceptors.request.use((config) => {
      if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }
      config.headers['X-HELPTAPP-AGENCY'] = localStorage.getItem(
        LocalStorageItems.CurrentAgencyId,
      );
      return config;
    });

    http.interceptors.response.use(
      (response) => response,
      async (error) => {
        return this.handleError(error);
      },
    );

    this.instance = http;
    return http;
  }

  async request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.request(config);
  }

  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  async patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  private processQueue = (error: any, token: string | null) => {
    this.failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    this.failedQueue = [];
  };

  private async handleError(error: AxiosError<any>) {
    const { response, config } = error;

    const status = response?.status || 500;
    let message = response?.data.message || 'Internal server error';

    switch (status) {
      case StatusCode.Invalid: {
        const details = response?.data.details || [];
        details.map((detail: any) => {
          message = message + `, ${detail.path}: ${detail.msg}`;
        });
        break;
      }
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle fobbiden error
        break;
      }
      case StatusCode.Unauthorized: {
        if (response?.data?.message === 'Please verify account first.') {
          message = response.data.message;
          break;
        }
        return this.refreshToken(config as InternalAxiosRequestConfig);
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
    }

    return Promise.reject({ status, message });
  }

  async refreshToken(config: AxiosConfig) {
    const originalRequest = config;
    if (this.isRefreshing) {
      return new Promise((resolve, reject) => {
        this.failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalRequest.retry = true;
    this.isRefreshing = true;

    const accessToken = localStorage.getItem(LocalStorageItems.AccessToken);
    const refreshToken = localStorage.getItem(LocalStorageItems.RefreshToken);

    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}api/${ApiVersion.V1}/auth/refresh-token/`, {
          accessToken,
          refreshToken,
        })
        .then(({ data }) => {
          localStorage.setItem(LocalStorageItems.AccessToken, data.accessToken);
          localStorage.setItem(
            LocalStorageItems.RefreshToken,
            data.refreshToken,
          );
          this.setHeader('Authorization', `Bearer ${data.accessToken}`);
          originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
          this.processQueue(null, data.accessToken);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          localStorage.setItem(LocalStorageItems.AccessToken, '');
          localStorage.setItem(LocalStorageItems.RefreshToken, '');
          this.setHeader('Authorization', undefined);
          originalRequest.headers.Authorization = undefined;
          this.processQueue(err, null);
          window.dispatchEvent(
            new StorageEvent('storage', { key: 'access-token', newValue: '' }),
          );
          reject(err);
        })
        .finally(() => {
          this.isRefreshing = false;
        });
    });
  }

  setHeader(type: string, value?: string | null) {
    if (this.instance == null) {
      this.initHttp();
    }
    if (this.instance != null) {
      const currentHeaders = this.instance.defaults.headers.common;
      if (value !== currentHeaders[type]) {
        this.instance.defaults.headers.common = {
          ...this.instance.defaults.headers.common,
          [type]: value,
        };
      }
    }
  }
}

export const http = new Http();
