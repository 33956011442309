import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import { IMileMarker } from '@/models/milemarker';

import { http } from './http';
import { IGetParams } from './types';

export const MileMarkerApi = {
  list: async (params: IGetParams): Promise<AxiosResponse<IMileMarker[]>> => {
    return http.get(`api/${ApiVersion.V1}/mile-markers`, { params });
  },
};
