import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';

import { IcCircleFlowerShape } from '@/assets/images';

import { AssociatedItemRoot } from '../styles';

export type AssociatedItemType = 'address' | 'reporter';

interface AssociatedItemProps {
  itemType: AssociatedItemType;
  label?: string;
  tooltip?: string;
  isUsed?: boolean;
  onClick: () => void;
}

export const AssociatedItem = ({
  itemType,
  label,
  tooltip,
  isUsed,
  onClick,
}: AssociatedItemProps) => {
  return (
    <AssociatedItemRoot xs={12}>
      <Stack flex={1} flexDirection="row" alignItems="center">
        {!!tooltip && (
          <Tooltip title={tooltip}>
            <Box sx={{ mt: 0.75, mr: 1.25 }}>
              <IcCircleFlowerShape />
            </Box>
          </Tooltip>
        )}
        <Typography className="name" variant="button">
          {label}
        </Typography>
      </Stack>
      <Button
        color="error"
        variant="text"
        onClick={onClick}
        sx={{ paddingRight: 0 }}
      >
        {isUsed ? `Remove ${itemType}` : `Use ${itemType}`}
      </Button>
    </AssociatedItemRoot>
  );
};
