import { colors } from './variables';

export default {
  fontFamily: 'Roboto, sans-serif',
  inherit: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '500',
  },
  subtitle1: {
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    fontWeight: '600',
  },
  subtitle2: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: '500',
    color: colors.grey[100],
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '400',
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: '400',
  },
  button: {
    fontSize: '0.9375rem',
    fontWeight: '500',
    lineHeight: '1.25rem',
  },
};
