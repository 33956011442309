import { useState, useCallback } from 'react';

import { LocalStorageItems } from '@/constants';

function useExpandedItem(id: string) {
  const loadedIds = JSON.parse(
    localStorage.getItem(LocalStorageItems.ExpandedUnitItems) || '[]',
  ) as string[];

  const [isExpanded, setIsExpanded] = useState(loadedIds.includes(id));

  // Function to toggle expansion state
  const setExpanded = useCallback(
    (expanded: boolean) => {
      const storedIds = JSON.parse(
        localStorage.getItem(LocalStorageItems.ExpandedUnitItems) ?? '[]',
      ) as string[];

      // pop from list if item is false, push if item is true.
      const updatedIds = expanded
        ? [...storedIds, id]
        : storedIds.filter((storeId) => storeId !== id);

      localStorage.setItem(
        LocalStorageItems.ExpandedUnitItems,
        JSON.stringify(updatedIds),
      );

      setIsExpanded(expanded);
    },
    [id],
  );

  return { isExpanded, setExpanded };
}

export default useExpandedItem;
