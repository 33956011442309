import { AxiosResponse } from 'axios';

import { http } from '@/api/http';
import { IGetParams, IListResponse } from '@/api/types';
import { ApiVersion } from '@/config';
import { IPointLayer, IPolygonLayer } from '@/models';

export const MapLayerApi = {
  getPolygonLayers: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IPolygonLayer>>> => {
    return http.get(`api/${ApiVersion.V1}/map-layers/polygons`, { params });
  },
  getPointLayers: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IPointLayer>>> => {
    return http.get(`api/${ApiVersion.V1}/map-layers/points`, { params });
  },
};
