import { Button, styled } from '@mui/material';

import { AppDialog, BaseDialogProps } from '@/components';

interface ConfirmationDialogProps extends BaseDialogProps {
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm?: () => void;
}

const Root = styled(AppDialog)(() => ({
  '& .MuiDialog-paper': { minWidth: 420, maxWidth: 600 },
  '& .MuiDialogActions-root': {
    borderTop: '1px solid #0000001f',
  },
}));

export const ConfirmationDialog = ({
  title,
  description,
  cancelText = 'Cancel',
  confirmText = 'Ok',
  onCancel,
  onConfirm,
  ...rest
}: Omit<ConfirmationDialogProps, 'onClose'>) => {
  const Body = (
    <div
      className="btnDescription"
      dangerouslySetInnerHTML={{ __html: description ?? '' }}
    ></div>
  );

  const Footer = () => {
    return (
      <>
        <Button
          variant="outlined"
          onClick={onCancel}
          size="large"
          color="inherit"
        >
          {cancelText}
        </Button>

        <Button
          variant="contained"
          color="error"
          className="btnConfirm"
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </>
    );
  };

  return (
    <Root
      {...rest}
      title={title}
      onClose={onCancel}
      body={Body}
      footer={<Footer />}
    />
  );
};
