import { AxiosResponse } from 'axios';

import { IGetParams, IListResponse, IPaginatedListResponse } from '@/api/types';
import { ApiVersion } from '@/config';
import {
  IAssignedUnitShiftStatusReq,
  ICFSCloseReason,
  ICFSLog,
} from '@/models';
import {
  AssignmentStatus,
  IAdditionalInfo,
  ICFS,
  ICFSForm,
  ICFSMasterName,
  ICFSMasterVehicle,
  ICFSInvolvement,
  IRequiredUnitForm,
  TSeverity,
} from '@/models/cfs';
import { INote } from '@/models/note';

import { http } from './http';

export type TActiveCFSFilter = {
  severity?: TSeverity;
  assignmentStatus?: AssignmentStatus;
  readBy?: boolean;
  isReadyToDispatch?: boolean;
};

export type ICFSFromIncomingCallReq = {
  address?: string;
  primaryReporter?: string;
  reporter: {
    callerPhoneNumber: string;
  };
};

export const CfsApi = {
  create: async (): Promise<AxiosResponse<ICFS>> => {
    return http.post(`api/${ApiVersion.V1}/cfs`);
  },
  createFromIncomingCall: async (
    data: ICFSFromIncomingCallReq,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.post(`api/${ApiVersion.V1}/cfs/incoming-call`, data);
  },
  getOne: async (id: string): Promise<AxiosResponse<ICFS>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/${id}`);
  },
  list: async (
    params: IGetParams,
  ): Promise<AxiosResponse<IPaginatedListResponse<ICFS>>> => {
    return http.get(`api/${ApiVersion.V1}/cfs`, { params });
  },
  activeList: async (params?: IGetParams): Promise<AxiosResponse<ICFS[]>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/active`, {
      params: {
        ...params,
        limit: 1000,
      },
    });
  },
  update: async (
    id: string,
    data: Partial<ICFSForm>,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.put(`api/${ApiVersion.V1}/cfs/${id}`, data);
  },
  delete: async (id: string): Promise<AxiosResponse> => {
    return http.delete(`api/${ApiVersion.V1}/cfs/${id}`);
  },
  updateAddressInfo: async (
    cfsId: string,
    addressId?: string,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.put(`api/${ApiVersion.V1}/cfs/${cfsId}/address-info`, {
      address: addressId,
    });
  },
  addAdditionalInfo: async (
    cfsId: string,
    data: Partial<IAdditionalInfo>,
  ): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/cfs/${cfsId}/additional-info`, data);
  },
  assignUnitShift: async (
    cfsId: string,
    unitShift: string,
    isDispatchedToHQ?: boolean,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.post(`api/${ApiVersion.V1}/cfs/${cfsId}/assigned-unit-shifts`, {
      unitShift,
      isDispatchedToHQ,
    });
  },
  addUnitShiftToQueue: async (
    cfsId: string,
    unitShiftId: string,
  ): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/cfs/${cfsId}/queue/${unitShiftId}`);
  },
  updateIncidentCodes: async (
    cfsId: string,
    incidentCodes: string[],
  ): Promise<AxiosResponse<ICFS>> => {
    return http.patch(`api/${ApiVersion.V1}/cfs/${cfsId}/incident-codes`, {
      incidentCodes,
    });
  },
  updateRequiredUnits: async (
    cfsId: string,
    requiredUnits: IRequiredUnitForm[],
  ): Promise<AxiosResponse<ICFS>> => {
    return http.put(`api/${ApiVersion.V1}/cfs/${cfsId}/required-units`, {
      requiredUnits,
    });
  },
  updateAssignedUnitShiftStatus: async (
    cfsId: string,
    assignedUnitShiftId: string,
    data: IAssignedUnitShiftStatusReq,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.patch(
      `api/${ApiVersion.V1}/cfs/${cfsId}/assigned-unit-shifts/${assignedUnitShiftId}/status`,
      data,
    );
  },
  updateAssignedUnitShiftDispatchedToHQStatus: async (
    cfsId: string,
    unitShiftId: string,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.patch(
      `api/${ApiVersion.V1}/cfs/${cfsId}/assigned-unit-shifts/${unitShiftId}/dispatch-to-hq`,
    );
  },
  getCloseReasons: async (): Promise<AxiosResponse<ICFSCloseReason[]>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/close-reasons`);
  },
  closeCFS: async (
    id: string,
    closeReason: string,
    assignedOfficer?: string,
  ): Promise<AxiosResponse> => {
    return http.put(`api/${ApiVersion.V1}/cfs/${id}/close`, {
      closeReason,
      assignedOfficer,
    });
  },
  reopenCFS: async (id: string): Promise<AxiosResponse> => {
    return http.put(`api/${ApiVersion.V1}/cfs/${id}/reopen`);
  },
  addCfsMasterVehicle: async (
    cfsId: string,
    vehicleId: string,
  ): Promise<AxiosResponse<ICFSMasterVehicle>> => {
    return http.post(
      `api/${ApiVersion.V1}/cfs/${cfsId}/master-vehicles/${vehicleId}`,
    );
  },
  getCfsMasterVehicles: async (
    cfsId: string,
  ): Promise<AxiosResponse<ICFSMasterVehicle[]>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/${cfsId}/master-vehicles`);
  },
  getCfsMasterVehicle: async (
    cfsId: string,
    vehicleId: string,
  ): Promise<AxiosResponse<ICFSMasterVehicle[]>> => {
    return http.get(
      `api/${ApiVersion.V1}/cfs/${cfsId}/master-vehicles/${vehicleId}`,
    );
  },
  deleteCfsMasterVehicle: async (
    cfsId: string,
    vehicleId: string,
  ): Promise<AxiosResponse<ICFSMasterVehicle>> => {
    return http.delete(
      `api/${ApiVersion.V1}/cfs/${cfsId}/master-vehicles/${vehicleId}`,
    );
  },
  addCfsMasterName: async (
    cfsId: string,
    nameId: string,
    data: { relationship: ICFSInvolvement },
  ): Promise<AxiosResponse<ICFSMasterName>> => {
    return http.post(
      `api/${ApiVersion.V1}/cfs/${cfsId}/master-names/${nameId}`,
      data,
    );
  },

  updateCfsMasterName: async (
    cfsId: string,
    nameId: string,
    data: { relationship: ICFSInvolvement },
  ): Promise<AxiosResponse<ICFSMasterName>> => {
    return http.put(
      `api/${ApiVersion.V1}/cfs/${cfsId}/master-names/${nameId}`,
      data,
    );
  },
  getCfsMasterNames: async (
    cfsId: string,
  ): Promise<AxiosResponse<ICFSMasterName[]>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/${cfsId}/master-names`);
  },
  getCfsMasterName: async (
    cfsId: string,
    nameId: string,
  ): Promise<AxiosResponse<ICFSMasterName>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/${cfsId}/master-names/${nameId}`);
  },
  deleteCfsMasterName: async (
    cfsId: string,
    nameId: string,
  ): Promise<AxiosResponse<ICFSMasterName>> => {
    return http.delete(
      `api/${ApiVersion.V1}/cfs/${cfsId}/master-names/${nameId}`,
    );
  },
  createMasterNameNote: (
    cfsId: string,
    nameId: string,
    description: string,
  ): Promise<AxiosResponse<INote>> => {
    return http.post(
      `api/${ApiVersion.V1}/cfs/${cfsId}/master-names/${nameId}/notes`,
      {
        description,
      },
    );
  },
  getCfsHistory: async (
    cfsId: string,
    params: IGetParams,
  ): Promise<AxiosResponse<IListResponse<ICFSLog>>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/${cfsId}/logs`, { params });
  },
  // Set isReadyToDispatch to true in cfs
  setCFSRTD: async (cfsId: string): Promise<AxiosResponse<ICFS>> => {
    return http.patch(`api/${ApiVersion.V1}/cfs/${cfsId}/ready-to-dispatch`);
  },
  refreshUnitShiftDistances: async (
    cfsId: string,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.patch(
      `api/${ApiVersion.V1}/cfs/${cfsId}/unit-shift-distances`,
      {},
    );
  },
  sendToExternalService: async (
    cfsId: string,
  ): Promise<AxiosResponse<IListResponse<ICFSLog>>> => {
    return http.patch(
      `api/${ApiVersion.V1}/cfs/${cfsId}/send-to-external-service`,
    );
  },
  makeFireUnderControl: async (cfsId: string): Promise<AxiosResponse<ICFS>> => {
    return http.patch(
      `api/${ApiVersion.V1}/cfs/${cfsId}/make-fire-under-control`,
    );
  },
  listByUser: async (
    userId: string,
  ): Promise<AxiosResponse<{ assigned: ICFS[]; queued: ICFS[] }>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/user/${userId}`);
  },
  requestBackup: async (
    cfsId: string,
    unitShiftId: string,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.post(`api/${ApiVersion.V1}/cfs/${cfsId}/backup-request`, {
      unitShiftId,
    });
  },
  responseBackup: async (
    cfsId: string,
    accepted: boolean,
  ): Promise<AxiosResponse<{ assigned: ICFS[]; queued: ICFS[] }>> => {
    return http.post(`api/${ApiVersion.V1}/cfs/${cfsId}/backup-response`, {
      accepted,
    });
  },
  assignMe: async (
    cfsId: string,
    isDispatchedToHQ = false,
  ): Promise<AxiosResponse<ICFS>> => {
    return http.post(`api/${ApiVersion.V1}/cfs/${cfsId}/assign-me`, {
      isDispatchedToHQ,
    });
  },
  generateAISummary: async (
    id: string,
  ): Promise<AxiosResponse<{ shortDescription: string }>> => {
    return http.get(`api/${ApiVersion.V1}/cfs/${id}/ai-summary`);
  },
};
