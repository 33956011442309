export default {
  sidebarClosed: 88,
  mainPadding: {
    lg: 20,
    md: 10,
  },
  bottomTabBar: {
    height: 70,
    padding: 15,
    margin: 20,
  },
};
