import { FC, ReactNode } from 'react';

import { MotionProps, motion } from 'framer-motion';

interface AnimationWrapperProps extends MotionProps {
  children: ReactNode;
}

export const AnimationWrapper: FC<AnimationWrapperProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <motion.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        position: 'relative',
        zIndex: 3,
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

AnimationWrapper.defaultProps = {
  initial: { x: '100%' },
  animate: { x: '0' },
  exit: { x: '-100%', opacity: 0 },
  transition: { ease: 'easeIn', duration: 0.2 },
};
