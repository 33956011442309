import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';

import { Chip } from '@/components';
import { ASSIGNMENT_STATUS } from '@/constants';
import { ICFS } from '@/models';
import { getAddressLocation } from '@/services';
import { colors } from '@/theme/variables';
import { openNewWindow } from '@/utils';

import { RecentCFSCardRoot } from '../styles';

export const RecentCFSCard = ({
  _id,
  number,
  shortDescription,
  assignmentStatus,
  addressInfo,
  onClose,
}: ICFS & {
  onClose: () => void;
}) => {
  const address = addressInfo?.address;

  const handleOpenClick = async (id?: string) => {
    if (id) await openNewWindow(`/cfs/${id}`, id);
    onClose();
  };

  return (
    <RecentCFSCardRoot key={number}>
      <Stack className="call-chips">
        <Chip
          label={number}
          sx={{
            color: colors.white,
            backgroundColor: colors.primary.main,
          }}
        />

        <Chip
          label={ASSIGNMENT_STATUS[assignmentStatus].label}
          sx={{
            color: colors.white,
            backgroundColor: ASSIGNMENT_STATUS[assignmentStatus].color,
          }}
        />
      </Stack>
      <Stack className="call-info-wrapper">
        <Box>
          <Typography variant="subtitle2">
            {getAddressLocation(address)}
          </Typography>

          <Typography>
            <Tooltip title={shortDescription} className="cfs-description">
              <Box>{shortDescription}</Box>
            </Tooltip>
          </Typography>
        </Box>
        <Stack className="call-action">
          <Button
            variant="contained"
            color="inherit"
            onClick={() => handleOpenClick(_id)}
          >
            Open
          </Button>
          <Button variant="contained" color="error" onClick={onClose}>
            Duplicate
          </Button>
        </Stack>
      </Stack>
    </RecentCFSCardRoot>
  );
};
