import { IMultiPolygon, IPoint, IPolygon } from '@/models';

export const formatDistance = (fitValue?: number) => {
  if (!fitValue) return '0 ft';
  if (fitValue < 400) return `${Math.round(fitValue)} ft`;

  const miles = Math.round((fitValue * 10) / 5280) / 10;
  if (miles > 1) return `${miles} miles`;
  return `${miles} mile`;
};

export const metersToMilesAndFeet = (meters: number) => {
  const metersInAMile = 1609.34;
  const metersInAFoot = 0.3048;

  const miles = meters / metersInAMile;

  if (miles < 0.1) {
    const feet = meters / metersInAFoot;
    return `${feet.toFixed(0)} ft`;
  } else if (miles >= 10) {
    return `${miles.toFixed(0)} miles`;
  } else {
    return `${miles.toFixed(1)} miles`;
  }
};

export const isInsidePolygon = (
  point?: IPoint | null,
  polygon?: IPolygon | null,
) => {
  if (!point || !polygon) return false;
  const pointX = point.coordinates[0];
  const pointY = point.coordinates[1];
  let isInside = false;

  const calculate = (polygonCoordinates: number[][]) => {
    if (isInside) return;

    for (
      let i = 0, j = polygonCoordinates.length - 1;
      i < polygonCoordinates.length;
      j = i++
    ) {
      const xi = polygonCoordinates[i][0],
        yi = polygonCoordinates[i][1];
      const xj = polygonCoordinates[j][0],
        yj = polygonCoordinates[j][1];

      const intersect =
        yi > pointY != yj > pointY &&
        pointX < ((xj - xi) * (pointY - yi)) / (yj - yi) + xi;
      if (intersect) isInside = !isInside;
    }
  };

  polygon.coordinates.forEach((coordinates) => calculate(coordinates));

  return isInside;
};

export const isInsideMultiPolygon = (
  point?: IPoint | null,
  multiPolygon?: IMultiPolygon | null,
) => {
  if (!point || !multiPolygon) return false;

  let isInside = false;

  for (let i = 0; i < multiPolygon.coordinates.length; i++) {
    const polygon: IPolygon = {
      type: 'Polygon',
      coordinates: multiPolygon.coordinates[i],
    };
    isInside = isInsidePolygon(point, polygon);
    if (isInside) {
      break;
    }
  }

  return isInside;
};
