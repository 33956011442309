import { FC } from 'react';

import { Button, InputLabel, Stack, styled } from '@mui/material';

import colors from '@/theme/variables/colors';

export type ButtonTabValue = string | number;

export interface ButtonTab {
  label: string;
  value: ButtonTabValue;
  description?: string;
}

export interface ButtonTabsProps {
  label?: string;
  buttons?: ButtonTab[];
  value: ButtonTabValue;
  onChange: (value: ButtonTabValue) => void;
}

const ButtonGroup = styled(Stack)(() => ({
  borderRadius: 6,
  border: `1px solid ${colors.grey['20']}`,
  '.tabItem': {
    flexGrow: 1,
    borderRadius: 0,
  },
  '.active': {
    color: 'white',
    backgroundColor: colors.error.main,
    '&:hover': {
      backgroundColor: colors.error.main,
    },
  },
  '.btnFirst': {
    borderBottomLeftRadius: 6,
    borderTopLeftRadius: 6,
  },
  '.btnLast': {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  '.borderRight': {
    borderRight: `1px solid ${colors.grey['20']}`,
  },
}));

const ButtonTabs: FC<ButtonTabsProps> = (props) => {
  const { label, buttons = [], value, onChange } = props;

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}

      <ButtonGroup flexDirection="row">
        {buttons.map((button, index) => {
          const isActive = button.value === value;
          const isLastBtn = index === buttons.length - 1;
          const isFirstBtn = index === 0;

          return (
            <Button
              key={button.value}
              className={`tabItem
               ${isActive && 'active'}
               ${isLastBtn && 'btnLast'}
               ${isFirstBtn && 'btnFirst'}
               ${!isActive && !isLastBtn && 'borderRight'}
               `}
              color="inherit"
              onClick={() => onChange(button.value)}
              sx={{
                height: '36px',
              }}
            >
              {button.label}
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};

export { ButtonTabs };
