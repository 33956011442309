import { createContext, FC, ReactNode, useState } from 'react';

import { KaseApi } from '@/api';
import { Loader } from '@/components';
import { KaseRoleBasedAction } from '@/constants';
import { IKase } from '@/models';
import { isReadOnlyKaseRole } from '@/services';
import { useToastStore, useUserStore } from '@/store';

interface KaseContextValue {
  kase: IKase | null;
  fetchKase: (kaseId: string) => Promise<IKase | null>;
  updateKase: (data: IKase | null) => void;
  isReadOnlyNames: boolean;
  isReadOnlyVehicles: boolean;
  isReadOnlyOffenses: boolean;
  isReadOnlyProperties: boolean;
  editingSection?: string;
  setEditingSection: (sectionName: string) => void;
}

interface KaseProviderProps {
  children: ReactNode;
}

export const KaseContext = createContext<KaseContextValue>({
  kase: null,
  fetchKase: async () => null,
  updateKase: () => {},
  isReadOnlyNames: false,
  isReadOnlyVehicles: false,
  isReadOnlyOffenses: false,
  isReadOnlyProperties: false,
  setEditingSection: () => {},
});

export const KaseProvider: FC<KaseProviderProps> = (props) => {
  const { children } = props;
  const { updateToast } = useToastStore();
  const { user } = useUserStore();
  const [kase, setKase] = useState<IKase | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [editingSection, setEditingSection] = useState<string>();
  const isReadOnlyNames = isReadOnlyKaseRole(
    KaseRoleBasedAction.NAMES,
    user,
    kase,
  );
  const isReadOnlyVehicles = isReadOnlyKaseRole(
    KaseRoleBasedAction.VEHICLES,
    user,
    kase,
  );
  const isReadOnlyOffenses = isReadOnlyKaseRole(
    KaseRoleBasedAction.CRIMES,
    user,
    kase,
  );
  const isReadOnlyProperties = isReadOnlyKaseRole(
    KaseRoleBasedAction.PROPERTIES,
    user,
    kase,
  );

  const fetchKase = async (kaseId: string) => {
    setIsLoading(true);
    try {
      const res = await KaseApi.getOne(kaseId);
      setKase(res.data);
      return res.data;
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const updateKase = (data: IKase | null) => {
    setKase(data);
  };

  return (
    <KaseContext.Provider
      value={{
        kase,
        fetchKase,
        updateKase,
        isReadOnlyNames,
        isReadOnlyVehicles,
        isReadOnlyOffenses,
        isReadOnlyProperties,
        editingSection,
        setEditingSection,
      }}
    >
      {children}
      <Loader open={isLoading} />
    </KaseContext.Provider>
  );
};
