import { ObjectId } from './base';

export type GeoJsonTypes = GeoJSON['type'];

export type BBox =
  | [number, number, number, number]
  | [number, number, number, number, number, number];

export type Position = number[]; // [number, number] | [number, number, number];

export interface GeoJsonObject {
  type: GeoJsonTypes;
  bbox?: BBox | undefined;
}

export type GeoJSON =
  | IPoint
  | IMultiPoint
  | ILineString
  | IMultiLineString
  | IPolygon
  | IMultiPolygon;

export interface IPoint extends GeoJsonObject {
  type: 'Point';
  coordinates: Position;
}

export interface IMultiPoint extends GeoJsonObject {
  type: 'MultiPoint';
  coordinates: Position[];
}

export interface ILineString extends GeoJsonObject {
  type: 'LineString';
  coordinates: Position[];
}

export interface IMultiLineString extends GeoJsonObject {
  type: 'MultiLineString';
  coordinates: Position[][];
}

export interface IPolygon extends GeoJsonObject {
  type: 'Polygon';
  coordinates: Position[][];
}

export interface IMultiPolygon extends GeoJsonObject {
  type: 'MultiPolygon';
  coordinates: Position[][][];
}

export enum MapType {
  SECTOR = 'SECTOR',
  MUNICIPALITY = 'MUNICIPALITY',
}

export enum PolygonLayerType {
  BEAT = 'BEAT',
  MUNICIPALITY = 'MUNICIPALITY',
}

export enum LineLayerType {
  ROAD = 'ROAD',
  RAILWAY = 'RAILWAY',
  RIVER = 'RIVER',
}

export enum PointLayerType {
  TRAIN_STATION = 'TRAIN_STATION',
  BUS_STATION = 'BUS_STATION',
}

export interface IPolygonLayer extends ObjectId {
  name: string;
  type: PolygonLayerType;
  area: IPolygon;
  agency: string;
}

export interface ILineLayer extends ObjectId {
  name: string;
  type: LineLayerType;
  line: ILineString;
}

export interface IPointLayer extends ObjectId {
  name: string;
  type: PointLayerType;
  point: IPoint;
}

export interface IMap extends ObjectId {
  name: string;
  type: MapType;
  area: IPolygon;
}

export interface ILocation extends ObjectId {
  device: string;
  user: string;
  // UnitShift information will exist when track location for active unit shifts.
  unitShift?: string | null;
  // CFS information will exist if unit shift is assigned to CFS.
  cfs?: string | null;
  // address corresponding to the point
  address?: string | null;

  // https://github.com/transistorsoft/react-native-background-geolocation-android/wiki/Location-Data-Schema
  // https://transistorsoft.github.io/react-native-background-geolocation/interfaces/location.html
  uuid: string;
  timestamp: Date; // The time when arrived at the location
  age: number; // Age of the location in milliseconds
  is_moving: boolean; // The motion-state when location was recorded
  is_heartbeat?: boolean; // If this location was recorded during heartbeat-mode
  odometer: number;
  mock?: boolean;
  point: IPoint;
  accuracy: number;
  speed: number;
  speed_accuracy: number;
  heading: number;
  heading_accuracy: number;
  altitude: number;
  ellipsoidal_altitude: number;
  altitude_accuracy: number;

  measuredAt: Date; // The time when location is measured, it's calculated as timestamp + age
  readonly createdAt: Date; // The time when location is synced with our backend
}
