import { Grid, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const ViewItemRoot = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: '10px 0px',

  '& .label': {
    width: '100%',
    flex: 1,
    fontSize: '0.75rem',
    fontWeight: 400,
  },

  '& .value': {
    flex: 1,
    fontSize: '0.875rem',
    fontWeight: 500,
    color: colors.grey[80],

    ...(theme.palette.mode !== 'light' && {
      color: colors.grey[30],
    }),
  },
  '& .value.is--email': {
    textTransform: 'none',
  },
}));
