import { SnackbarOrigin, SnackbarProps } from '@mui/material';
import { create } from 'zustand';

export enum ConfirmTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface ToastProps extends SnackbarOrigin, SnackbarProps {
  open?: boolean;
  type?: ConfirmTypes;
  message?: string;
  onClose?: () => void;
}

const initialState: ToastProps = {
  open: false,
  type: ConfirmTypes.ERROR,
  message: '',
  vertical: 'bottom',
  horizontal: 'right',
  autoHideDuration: 6000,
};

interface ToastState extends ToastProps {
  updateToast: (data: Partial<ToastProps>) => void;
}

export const useToastStore = create<ToastState>((set) => ({
  ...initialState,
  updateToast: (data: Partial<ToastProps>) =>
    set(() => ({
      ...initialState,
      ...data,
    })),
}));
