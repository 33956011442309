import { forwardRef } from 'react';

import { InputBaseProps } from '@mui/material';

import { IAddressForm } from '@/models/address';

import { AddressItem } from './AddressItem';
import { AddressFieldRoot } from './styles';
import { SubAddressItem } from './SubAddressItem';
import { BaseFieldProps } from '../../FormElements';

export interface AddressFormProps
  extends Omit<InputBaseProps, 'value' | 'error' | 'onChange'>,
    BaseFieldProps {
  value?: IAddressForm;
  locationRestriction?: google.maps.LatLngBoundsLiteral;
  onChange: (newAddress?: IAddressForm | null) => void;
}

export const AddressForm = forwardRef<HTMLDivElement, AddressFormProps>(
  ({ name, value, onChange, ...rest }, ref) => {
    const updateCurrentAddress = (newAddress?: IAddressForm | null) => {
      onChange(newAddress);
    };

    return (
      <AddressFieldRoot ref={ref}>
        <AddressItem
          updateCurrentAddress={updateCurrentAddress}
          name={name}
          value={value ?? undefined}
          {...rest}
        />
        <SubAddressItem
          updateCurrentAddress={updateCurrentAddress}
          name={name}
          value={value ?? undefined}
        />
      </AddressFieldRoot>
    );
  },
);

AddressForm.displayName = 'custom address form';
