export const fileSizeConverter = (bytes = 0, decimals = 0, onlyMB = false) => {
  const K_UNIT = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (!bytes) return '0B';

  if (onlyMB) return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + 'MB';

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const resp =
    parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + sizes[i];

  return resp;
};

export const createFileFromUrl = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const filename = url.replace(/\?.+/, '').split('/').pop() || '';
  return new File([data], filename, metadata);
};

export function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (event) => {
      return resolve(event?.target?.result);
    };
    reader.onerror = (event) => {
      reader.abort();
      return reject(event);
    };
  });
}

export const getFileType = (
  mimeType?: string,
): 'video' | 'document' | 'image' => {
  if (!mimeType) return 'document';

  const docTypeFiles = ['application', 'text'];
  const fileType = mimeType.split('/')[0].toLowerCase() as
    | 'video'
    | 'document'
    | 'image';
  if (docTypeFiles.includes(fileType)) return 'document';
  return fileType;
};

export const generateFileFromBase64 = (
  base64String: string,
  fileName = 'image.png',
  fileType = 'image/png',
) => {
  const base64Data = base64String.replace(/^data:image\/png;base64,/, '');
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });
  return new File([blob], fileName, { type: fileType });
};

export const getFileExtension = (fileName?: string) => {
  if (!fileName) return '';

  const parts = fileName.split('.');
  return parts.length > 1 ? parts.pop() : '';
};

export const generateRandomFileName = (originFileName?: string) => {
  const fileExtension = getFileExtension(originFileName);
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(3, 10);
  let fileName = `${timestamp}-${randomString}`;

  if (fileExtension) {
    fileName += `.${fileExtension}`;
  }
  return fileName;
};

const errorMessages: Record<string, string> = {
  'file-too-large': 'File is too large (maximum 15GB)',
  'file-invalid-type': 'This file format is not supported.',
};

export const getErrorMessage = (
  errorCode?: string,
  defaultMessage?: string,
) => {
  return errorCode
    ? errorMessages[errorCode] || defaultMessage
    : defaultMessage;
};
