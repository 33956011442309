import { ReactNode } from 'react';

import { Button, CardMedia, Stack } from '@mui/material';

import { AppModal } from '@/components';
import { useFetchSasToken } from '@/hooks';
import { IFile } from '@/models';
import { downloadFile } from '@/services';
import { colors } from '@/theme/variables';
import { getFileType } from '@/utils';

interface FilePreviewModalProps {
  open: boolean;
  file?: IFile;
  onClose: () => void;
}

const FilePreviewModal = ({ open, file, onClose }: FilePreviewModalProps) => {
  const sasToken = useFetchSasToken([file]);

  if (!file || !sasToken) return null;

  const fileType = getFileType(file.mimeType);

  const previewContent: Record<string, ReactNode> = {
    video: (
      <CardMedia
        component="video"
        sx={{ maxHeight: '600px' }}
        src={`${file.url}?${sasToken}`}
        controls
      />
    ),
    image: (
      <Stack>
        <img
          src={`${file.url}?${sasToken}`}
          alt={file.name}
          loading="lazy"
          style={{
            maxHeight: '590px',
            objectFit: 'contain',
          }}
        />
      </Stack>
    ),
    document: (
      <Stack
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '176px',
          backgroundColor: colors.grey[10],
          width: '100%',
        }}
      >
        No preview available
      </Stack>
    ),
  };

  const onDownload = () => downloadFile(file);

  return (
    <AppModal open={open} title={file.name} onClose={onClose}>
      <Stack sx={{ display: 'flex', alignItems: 'center' }}>
        {previewContent[fileType]}
        <Button
          sx={{ mt: 2 }}
          size="medium"
          variant="contained"
          color="error"
          onClick={onDownload}
        >
          Download
        </Button>
      </Stack>
    </AppModal>
  );
};

export default FilePreviewModal;
