import { PhoneNumberType } from '@/models';

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
  UNKNOWN = 'N',
  // For MasterName only
  NOT_REPORTED = 'R',
  NOT_APPLICABLE = 'X',
}

export const GenderList = {
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female',
  [Gender.UNKNOWN]: 'Unknown',
} as const;

export enum Race {
  WHITE = 'W',
  BLACK_OR_AFRICAN_AMERICAN = 'B',
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'I',
  ASIAN = 'A',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'P',
  UNKNOWN = 'U', // Unknown
  NOT_REPORTED = 'R',
  NOT_APPLICABLE = 'X',
}

export const RaceList = {
  [Race.WHITE]: 'White',
  [Race.BLACK_OR_AFRICAN_AMERICAN]: 'Black or African American',
  [Race.AMERICAN_INDIAN_OR_ALASKA_NATIVE]: 'American Indian or Alaska Native',
  [Race.ASIAN]: 'Asian',
  [Race.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER]:
    'Native Hawaiian or Other Pacific Islander',
  [Race.NOT_REPORTED]: 'Not Reported',
  [Race.UNKNOWN]: 'Unknown',
} as const;

export enum EthnicOrigin {
  HISPANIC_LATINO = 'H',
  NOT_HISPANIC_LATINO = 'N',
  NOT_REPORTED = 'R',
  UNKNOWN = 'U',
  NOT_APPLICABLE = 'X',
}

export const EthnicOriginList = {
  [EthnicOrigin.HISPANIC_LATINO]: 'Hispanic or Latino',
  [EthnicOrigin.NOT_HISPANIC_LATINO]: 'Not Hispanic or Latino',
  [EthnicOrigin.NOT_REPORTED]: 'Not reported',
  [EthnicOrigin.UNKNOWN]: 'Unknown',
} as const;

export const EyeColor = {
  BROWN: 'Brown',
  BLUE: 'Blue',
  HAZEL: 'Hazel',
  AMBER: 'Amber',
  GRAY: 'Gray',
  GREEN: 'Green',
} as const;

export const eyeColorInput = {
  name: 'eyeColor',
  type: 'autocomplete',
  label: 'Eye color',
  items: [
    ...Object.entries(EyeColor).map(([value, label]) => ({
      label,
      value,
    })),
  ],
};

export const genderInput = {
  name: 'sex',
  type: 'autocomplete',
  label: 'Sex',
  items: [
    {
      label: GenderList[Gender.MALE],
      value: Gender.MALE,
    },
    {
      label: GenderList[Gender.FEMALE],
      value: Gender.FEMALE,
    },
    {
      label: GenderList[Gender.UNKNOWN],
      value: Gender.UNKNOWN,
    },
  ],
  rules: {
    required: 'This field is required.',
  },
};

export const phoneNumberInput = {
  name: 'phoneNumber',
  type: 'pattern',
  label: 'Phone number',
  format: '(###) ###-####',
  rules: {
    pattern: {
      value: /[2-9][0-9]{9}$/im,
      // value: /[2-9]{1}[0-9]{9}$/im,
      message: 'Invalid phone number.',
    },
  },
};

export const phoneTypeInput = {
  type: 'autocomplete',
  name: 'type',
  label: 'Number type',
  items: [
    {
      label: 'Home',
      value: PhoneNumberType.HOME,
    },
    {
      label: 'Mobile',
      value: PhoneNumberType.MOBILE,
    },
    {
      label: 'Work',
      value: PhoneNumberType.WORK,
    },
    {
      label: 'Caller',
      value: PhoneNumberType.CALLER,
    },
    {
      label: 'Other',
      value: PhoneNumberType.OTHER,
    },
  ],
  grid: {
    md: 4,
  },
};

export const raceInput = {
  name: 'race',
  type: 'autocomplete',
  label: 'Race',
  items: [
    ...Object.entries(RaceList).map(([value, label]) => ({
      label,
      value,
    })),
  ],
};

export const ethnicRaceInput = {
  name: 'ethnicOrigin',
  type: 'autocomplete',
  label: 'Ethnic origin',
  items: [
    ...Object.entries(EthnicOriginList).map(([value, label]) => ({
      label,
      value,
    })),
  ],
};
