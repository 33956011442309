import { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface TabItem {
  path: string;
  label: string;
}

interface TabLayoutContextValues {
  tabLinks: TabItem[];
  basePath: string;
  currentNavTab?: TabItem;
  nextPath?: string;
  currentScreen?: string;
}

interface TabLayoutProviderValues extends TabLayoutContextValues {
  updateTabLayoutContextValues: (
    newValues: Partial<TabLayoutContextValues>,
  ) => void;
  updateCurrentScreen: (screenName: string) => void;
}

const TabLayoutContext = createContext<TabLayoutProviderValues | undefined>(
  undefined,
);

export function useTabLayoutContext() {
  const context = useContext(TabLayoutContext);
  if (!context) {
    throw new Error(
      'useTabLayoutContext must be used within a TabLayoutContextProvider',
    );
  }
  return context;
}

export function TabLayoutContextProvider({ children }: PropsWithChildren) {
  const [values, setValues] = useState<TabLayoutContextValues>({
    tabLinks: [],
    basePath: '/',
  });

  const updateTabLayoutContextValues = (
    newValues: Partial<TabLayoutContextValues>,
  ) => {
    setValues((v) => ({ ...v, ...newValues }));
  };

  const updateCurrentScreen = (newScreenName: string) => {
    setValues((v) => ({
      ...v,
      currentScreen: newScreenName,
      nextPath: v.currentScreen,
    }));
  };

  return (
    <TabLayoutContext.Provider
      value={{ ...values, updateTabLayoutContextValues, updateCurrentScreen }}
    >
      {children}
    </TabLayoutContext.Provider>
  );
}
