import { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Divider,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useChannel } from 'ably/react';

import { CfsApi } from '@/api';
import { AppModal, BaseModalProps, Select, SelectItem } from '@/components';
import { LocalStorageItems } from '@/constants';
import { IUser } from '@/models';
import {
  ConfirmTypes,
  useDataStore,
  useToastStore,
  useUserStore,
} from '@/store';

interface IClosureReasonModal extends BaseModalProps {
  cfsId: string;
  updateCfsClosedState?: (isClosed: boolean, reason: string) => void;
}

const ClosureReasonModal = ({
  open,
  cfsId,
  onClose,
  updateCfsClosedState,
}: IClosureReasonModal) => {
  const { updateToast } = useToastStore();
  const { cfsCloseReasons } = useDataStore();
  const { account } = useUserStore();

  const [reason, setReason] = useState('');
  const [assignedOfficer, setAssignedOfficer] = useState('n/a');
  const [respondingOfficers, setRespondingOfficers] = useState<SelectItem[]>([
    { value: 'n/a', label: 'N/A' },
  ]);

  const { userAgencyId } = useUserStore();
  const reasonOptions = cfsCloseReasons.map((item) => ({
    value: String(item._id),
    label: item.reason,
  }));
  const { channel } = useChannel(`account:${account?._id}:cfs:${cfsId}`);

  useEffect(() => {
    if (cfsId) {
      fetchAllRespondingOfficers();
    }
  }, []);

  const handleClose = () => {
    setReason('');
    onClose();
  };

  const handleAassignedOfficerChange = (e: SelectChangeEvent<unknown>) => {
    setAssignedOfficer(e.target.value as string);
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    setReason(e.target.value as string);
  };

  useEffect(() => {
    if (respondingOfficers.length === 2) {
      setAssignedOfficer(respondingOfficers[1].value as string);
    }
  }, [respondingOfficers]);

  const onConfirm = () => {
    if (
      !reason ||
      (account?.settings.features?.isEnabledAutoTaskCreation &&
        !assignedOfficer)
    ) {
      return;
    }

    if (userAgencyId) {
      localStorage.setItem(LocalStorageItems.CurrentAgencyId, userAgencyId);
    }

    CfsApi.closeCFS(cfsId, reason, assignedOfficer)
      .then(() => {
        handleClose();
        if (updateCfsClosedState) {
          updateCfsClosedState(true, reason);
        }

        channel?.publish(`closed:cfs:${cfsId}`, {
          id: cfsId,
          event: 'cfsClosed',
        });
        updateToast({
          type: ConfirmTypes.SUCCESS,
          open: true,
          message: 'CFS record closed successfully',
        });
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  // this can be extracter as shared function if needed
  const fetchAllRespondingOfficers = async () => {
    try {
      const officers: any[] = [];
      const response = await CfsApi.getOne(cfsId);
      response.data.assignedUnitShifts.forEach((shift) => {
        if (shift.unitShift.users) {
          officers.push(...shift.unitShift.users);
        }
      });

      const uniqueOfficers = officers.reduce((acc, officer) => {
        if (!acc.some((o: IUser) => o._id === officer._id)) {
          acc.push(officer);
        }
        return acc;
      }, []);
      setRespondingOfficers([
        { value: 'n/a', label: 'N/A' },
        ...uniqueOfficers.map((officer: IUser) => ({
          value: officer._id,
          label: officer.fullName,
        })),
      ]);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  return (
    <AppModal open={open} onClose={handleClose} title="Set disposition">
      <Stack>
        <Select
          label="Disposition"
          value={reason}
          items={reasonOptions}
          onChange={handleChange}
        />
        {account?.settings.features?.isEnabledAutoTaskCreation && (
          <>
            <Select
              label="Assigned officer"
              value={assignedOfficer}
              required
              items={respondingOfficers}
              onChange={handleAassignedOfficerChange}
            />
            <Alert severity="info">
              Assigning an officer will trigger automatic task creation for that
              officer.
            </Alert>
          </>
        )}
        <Divider sx={{ my: 2 }} />
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button color="inherit" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={onConfirm}
            disabled={
              !reason ||
              (account?.settings.features?.isEnabledAutoTaskCreation &&
                !assignedOfficer)
            }
            sx={{ ml: 1 }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </AppModal>
  );
};

export default ClosureReasonModal;
