import {
  Box,
  Button,
  Chip,
  colors,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import PersonPlaceholder from '@/assets/images/pngs/person-placeholder.png';
import { useFetchSasToken } from '@/hooks';
import { IMasterVehicle } from '@/models';
import { getAddressCommonString } from '@/services';

import { TabType } from '../tabs/CarStopCfsTab';

type CarDetailsCardProps = {
  data: IMasterVehicle;
  setSelectedTab: (tab: TabType) => void;
};

const DetailsItem = ({ label, value }: { label: string; value?: string }) => (
  <Stack display="flex" flexDirection="row" alignItems="center">
    <Typography fontWeight={600} fontSize={18} flex={1}>
      {label}
    </Typography>
    <Typography flex={1}>{value}</Typography>
  </Stack>
);

const RelationsItems = ({ setSelectedTab }: CarDetailsCardProps) => {
  const linked = {
    CFS: null,
    Cases: null,
  };

  return (
    <Stack gap={1} flex={1}>
      {/* TODO: linked cases is missing */}
      {(['CFS', 'Cases'] as Array<keyof typeof linked>).map((item) => (
        <Stack
          key={item}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography fontWeight={600} fontSize={18} flex={1}>
            {item} {linked[item] && <Chip color="error" label={linked[item]} />}
          </Typography>
          <Box flex={1}>
            {linked[item] && (
              <Button
                variant="text"
                onClick={() =>
                  item === 'CFS'
                    ? setSelectedTab('relations-cfs')
                    : setSelectedTab('relations-cases')
                }
              >
                View more
              </Button>
            )}
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};

const CarDetailsCard = ({ data, setSelectedTab }: CarDetailsCardProps) => {
  const sasToken = useFetchSasToken(data.files);

  const personDetailsItems = [
    {
      label: 'Vehicle owner',
      value: data?.owner
        ? data?.owner?.firstName + ' ' + data?.owner?.lastName
        : '/',
    },
    {
      label: 'Owner address',
      value: getAddressCommonString(data.owner?.address) || '/',
    },
    { label: 'Vehicle color', value: data?.color || '/' },
    { label: 'Vehicle year', value: data?.year || '/' },
    {
      label: 'Registration status',
      value: data?.isVerified ? 'Verified' : 'Not Verified',
    },
    { label: 'VIN', value: data?.vin || '/' },
  ];

  return (
    <Stack
      padding={2}
      border={1}
      borderRadius={1}
      borderColor={colors.grey[300]}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <img
          src={
            (data?.files?.length ?? 0) > 0
              ? `${data?.files?.[0]?.url ?? ''}?${sasToken}`
              : PersonPlaceholder
          }
          height={84}
          width={84}
          style={{ objectFit: 'cover', borderRadius: 8 }}
        />
        <Stack gap={1}>
          <Typography fontWeight={600} fontSize={32}>
            {data?.make} {data?.model}
          </Typography>
          <Typography fontWeight={400} fontSize={21}>
            {data?.licensePlate?.number}
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <Stack gap={1}>
        {personDetailsItems.map((item, index) => (
          <DetailsItem
            key={index}
            label={item.label}
            value={item.value as string}
          />
        ))}
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <RelationsItems data={data} setSelectedTab={setSelectedTab} />
    </Stack>
  );
};

export default CarDetailsCard;
