import { FC, useState, useEffect } from 'react';

import { SxProps, styled } from '@mui/material/styles';

import { IcClose, IcSearch } from '@/assets/images';
import { colors } from '@/theme/variables';

import { Input, InputProps } from '../FormElements/Input';

const Root = styled('div')(({ theme, style }) => ({
  height: 36,
  width: 300,
  maxWidth: style?.maxWidth ? style.maxWidth : 350,
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  marginRight: 10,
  borderRadius: 6,

  '& .MuiInputBase-root': {
    width: '100%',
    padding: '6px 10px',
    color: colors.grey[80],
    ...(theme.palette.mode !== 'light' && {
      borderColor: colors.grey[60],
      color: colors.grey[20],
    }),
  },

  ...(theme.palette.mode !== 'light' && {
    backgroundColor: colors.grey[80],
  }),
}));

interface SearchBarProps extends Omit<InputProps, 'onChange'> {
  className?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  cancelCallback?: () => void;
  shouldCancel?: string;
  sx?: SxProps;
}

export const SearchBar: FC<SearchBarProps> = ({
  name,
  label,
  placeholder,
  disabled,
  defaultValue = '',
  onChange,
  className,
  fullWidth,
  cancelCallback,
  shouldCancel,
  sx,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (shouldCancel) {
      handleCancel();
    }
  }, [shouldCancel]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleCancel = () => {
    setInputValue('');
    onChange('');
    if (typeof cancelCallback === 'function') {
      cancelCallback();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleCancel();
    }
  };

  return (
    <Root className={className ?? ''} sx={sx}>
      <Input
        name={name}
        label={label}
        value={inputValue}
        placeholder={placeholder ?? 'Search'}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        disabled={disabled}
        startAdornment={<IcSearch style={{ marginRight: 4 }} />}
        endAdornment={
          inputValue.length > 0 && (
            <IcClose
              onClick={handleCancel}
              style={{
                cursor: 'pointer',
              }}
            />
          )
        }
        fullWidth={fullWidth}
      />
    </Root>
  );
};
