import { FilePickerDirection, TableColumn } from '@/components';

export enum KaseFileScreens {
  KaseFileList = 'KaseFilesList',
  KaseFileAdd = 'KaseFileAdd',
  KaseFileEdit = 'KaseFileEdit',
}

export const KaseFileNextRoutes = ['naratives', 'property'];

export const KaseFileDefaultValues = {
  files: [],
};

export const kaseFilesListTableColumns: TableColumn[] = [
  { key: 'fileName', label: 'File name' },
  { key: 'description', label: 'Description' },
  { key: 'dateUploaded', label: 'Date uploaded' },
  { key: 'uploadedBy', label: 'Uploaded by' },
  { key: 'fileType', label: 'File type' },
  { key: 'origin', label: 'Origin' },
  { key: 'action', label: '' },
];

export const fileTypeExtensions: { [key: string]: string[] } = {
  documents: [
    '.pdf',
    '.docx',
    '.doc',
    '.rtf',
    '.txt',
    '.xlsx',
    '.xls',
    '.odt',
    '.csv',
  ],
  photos: ['.jpg', '.jpeg', '.png'],
  videos: ['.mp4', '.mkv', '.mov', '.avi', '.wmv', '.mpg', '.mpeg'],
};

export const FileAcceptedTypes = {
  'image/jpeg': [],
  'image/png': [],
  'video/mp4': [],
  'video/mkv': [],
  'video/webm': [],
  'video/ogg': [],
  'video/mov': [],
  'video/mpg': [],
  'video/mpeg': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // .docx
  'text/csv': [],
  'application/pdf': [],
  'application/rtf': [],
  'text/plain': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // .xlsx
  'application/vnd.oasis.opendocument.text': [], // .odt
};

export const filterMenuFormFields = [
  {
    name: 'fileType',
    type: 'autocomplete',
    label: '',
    items: [
      {
        label: 'All',
        value: 'All',
      },
      {
        label: 'Documents',
        value: 'documents',
      },
      {
        label: 'Photos',
        value: 'photos',
      },
      {
        label: 'Videos',
        value: 'videos',
      },
    ],
  },
];

export const KaseFileFormFields = [
  {
    key: 'photos',
    title: 'Photos',
    items: [
      {
        name: 'files',
        type: 'file',
        label: '',
        enableEditDescription: true,
        direction: 'vertical' as FilePickerDirection,
        grid: {
          md: 12,
          lg: 12,
        },
      },
    ],
  },
];
