import { Grid, Stack, Typography, styled, IconButton } from '@mui/material';

import { IcDeleteTrash, IcPencil } from '@/assets/images';
import { colors } from '@/theme/variables';

const ReporterItemRoot = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10px 15px',
  borderRadius: 6,
  border: `1px solid ${colors.grey[20]}`,

  '& .name': {
    textTransform: 'capitalize',
  },

  '& .address': {
    color: colors.grey[80],
  },
}));

interface ReporterItemProps {
  name: string;
  address: string;
  onEdit?: () => void;
  onRemove: () => void;
}

export const ReporterItem = ({
  name,
  address,
  onEdit,
  onRemove,
}: ReporterItemProps) => {
  return (
    <ReporterItemRoot xs={12}>
      <Stack flex={1}>
        <Typography className="name" variant="button">
          {name}
        </Typography>
        <Typography className="address" variant="body2">
          {address}
        </Typography>
      </Stack>
      <Stack direction="row">
        {!!onEdit && (
          <IconButton onClick={onEdit}>
            <IcPencil />
          </IconButton>
        )}
        <IconButton color="error" onClick={onRemove}>
          <IcDeleteTrash style={{ color: colors.error.main }} />
        </IconButton>
      </Stack>
    </ReporterItemRoot>
  );
};
