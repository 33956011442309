import { Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment';

import { IcClock, IcPencil } from '@/assets/images';
import { DateTimePicker } from '@/components';
import { DATE_TIME_FORMAT } from '@/config';

interface CFSReporterTimeItemProps {
  reporterTime?: string;
  onAccept?: (newDateTime: string) => void;
}

export const CFSReporterTimeItem = ({
  reporterTime,
  onAccept,
}: CFSReporterTimeItemProps) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" columnGap={1}>
      <IcClock color={theme.palette.text.primary} height={20} />
      <Typography color={theme.palette.text.primary} variant="body2">
        {moment(reporterTime).format(DATE_TIME_FORMAT)}
      </Typography>
      {!!onAccept && (
        <DateTimePicker
          pickerIcon={<IcPencil />}
          onAccept={(v) => onAccept(moment(v).toISOString())}
          hideInput
        />
      )}
    </Stack>
  );
};
