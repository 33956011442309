import { useNavigate } from 'react-router-dom';

import { MasterAddNameDetails } from '@/layouts/TabLayout/MasterNames/components/MasterAddNameDetails';

import MasterIndexPersonBase from './MasterIndexPersonBase';

const MasterIndexPersonForm = () => {
  const navigate = useNavigate();

  return (
    <MasterIndexPersonBase>
      <MasterAddNameDetails
        onGoBack={() => navigate('/master-index/persons')}
        hideHeader={true}
        isMasterIndex={true}
      />
    </MasterIndexPersonBase>
  );
};

export default MasterIndexPersonForm;
