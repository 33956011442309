import { IconButton } from '@mui/material';
import { MapControl, ControlPosition } from '@vis.gl/react-google-maps';

import { IcMapCondense, IcMapExpand } from '@/assets/images';

export type ExpandControlProps = {
  isExpanded?: boolean;
  position?: ControlPosition;
  onExpand?: () => void;
  onCondense?: () => void;
};

export const ExpandControl = ({
  position = ControlPosition.RIGHT_BOTTOM,
  isExpanded,
  onCondense,
  onExpand,
}: ExpandControlProps) => {
  const renderButton = () => {
    if (isExpanded) {
      return (
        <IconButton onClick={onCondense} style={{ cursor: 'pointer' }}>
          <IcMapCondense />
        </IconButton>
      );
    }

    return (
      <IconButton onClick={onExpand} style={{ cursor: 'pointer' }}>
        <IcMapExpand />
      </IconButton>
    );
  };

  return (
    <MapControl position={position}>
      <div>{renderButton()}</div>
    </MapControl>
  );
};
