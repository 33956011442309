import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
} from '@mui/material';

import { UnitApi } from '@/api';
import { FormInputField, FormSelectField, SelectItem } from '@/components';
import { AgencyType, IUnitFilter, IUnitFilterForm } from '@/models';
import {
  ConfirmTypes,
  useAgencyStore,
  useDataStore,
  useToastStore,
} from '@/store';

import { agencyTypeItems, initFilterValues } from '../data';

export interface FilterValues {
  agencies?: string[] | null;
  beat?: string | null;
  agencyType?: AgencyType | null;
  unitTypes?: string[] | null;
}

interface IUnitFilterFormProps {
  onSave: (newFilter: IUnitFilter) => void;
  onChange: (name?: string, value?: string | number) => void;
}

export const UnitFilterForm = ({ onChange, onSave }: IUnitFilterFormProps) => {
  const [queryParams] = useSearchParams();
  const { beats } = useDataStore();
  const { agencies, fetchAgencies } = useAgencyStore();
  const { unitTypes } = useDataStore();
  const { updateToast } = useToastStore();
  const [shouldSave, setShouldSave] = useState(false);

  const {
    watch,
    reset,
    handleSubmit,
    getFieldState,
    formState: { isDirty },
  } = useFormContext<IUnitFilterForm>();

  const agencyId = queryParams.get('agencyId');

  // In single agency accounts, we use agencyId instead of selected agencies.
  const singleAgency = agencies.length === 1 ? agencies[0] : null;

  const selectedAgencies = watch('agencies');
  const agencyType = watch('agencyType');
  const currentFilterId = watch('_id');
  const filterNameField = getFieldState('name');

  const agencyFilterItems = useMemo(() => {
    return agencies.map(({ name, _id }) => ({
      label: name,
      value: _id || '',
    }));
  }, [agencies]);

  const beatFilterItems = useMemo(() => {
    let filteredBeats = [];
    if (singleAgency) {
      filteredBeats = beats.filter(({ agency }) => agency === singleAgency._id);
    } else {
      filteredBeats = beats.filter(
        ({ agency }) =>
          !selectedAgencies?.length || selectedAgencies.indexOf(agency) > -1,
      );
    }

    return filteredBeats.map(({ name, _id }) => ({
      label: name,
      value: _id || '',
    }));
  }, [beats, selectedAgencies, singleAgency?._id]);

  // const agencyTypeFilterItems = useMemo(() => {
  //   const selectedAgency = agencies.find(({ _id }) => _id === selectedAgencies);
  //   return selectedAgencies
  //     ? [
  //         {
  //           label: selectedAgency?.type || '',
  //           value: selectedAgency?.type || '',
  //         },
  //       ]
  //     : agencyTypeItems;
  // }, [selectedAgencies, agencies]);

  const unitTypeFilterItems = useMemo(() => {
    return unitTypes.reduce((filteredList: SelectItem[], item) => {
      if (
        (singleAgency && singleAgency.type === item.agencyType) ||
        item.agencyType === agencyType
      ) {
        filteredList.push({
          label: item.name,
          value: item._id || '',
        });
      }
      return filteredList;
    }, []);
  }, [unitTypes, agencyType, singleAgency?._id]);

  useEffect(() => {
    fetchAgencies();
  }, []);

  useEffect(() => {
    if (currentFilterId) {
      setShouldSave(true);
    } else {
      setShouldSave(false);
    }
  }, [currentFilterId]);

  const handleSave = async (filterValues: IUnitFilterForm) => {
    try {
      let newSavedFilter;
      if (filterValues._id) {
        await UnitApi.updateUnitFilter(filterValues);
        newSavedFilter = filterValues as IUnitFilter;
        updateToast({
          open: true,
          type: ConfirmTypes.SUCCESS,
          message: 'Updated unit filters successfully.',
        });
      } else {
        const res = await UnitApi.createUnitFilter(filterValues);
        newSavedFilter = res.data;
        updateToast({
          open: true,
          type: ConfirmTypes.SUCCESS,
          message: 'Saved unit filters successfully.',
        });
      }
      reset(newSavedFilter);
      onSave(newSavedFilter);
    } catch (err: any) {
      updateToast({
        open: true,
        message: err.message,
      });
    }
  };

  const handleClear = () => {
    reset(initFilterValues);
    onChange();
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      {!agencyId && agencies.length > 1 && (
        <FormSelectField
          name="agencies"
          label="Agency"
          items={agencyFilterItems}
          handleChange={onChange}
          multiple
          fullWidth
          sx={{ maxWidth: '280px' }}
        />
      )}
      <FormSelectField
        name="beat"
        label="Beat"
        items={beatFilterItems}
        handleChange={onChange}
        fullWidth
      />
      {agencies.length > 1 && (
        <FormSelectField
          name="agencyType"
          label="Agency type"
          items={agencyTypeItems}
          handleChange={onChange}
          fullWidth
        />
      )}
      <FormSelectField
        name="unitTypes"
        label="Unit type"
        items={unitTypeFilterItems}
        handleChange={onChange}
        fullWidth
        multiple
      />
      <FormControlLabel
        control={
          <Checkbox
            color="error"
            checked={shouldSave}
            onChange={(_, checked) => setShouldSave(checked)}
          />
        }
        label="Add to saved filters"
        sx={{ '.MuiTypography-root': { fontWeight: 700 } }}
        className="checkbox"
      />
      {shouldSave && (
        <Box sx={{ mt: 2 }}>
          <FormInputField
            name="name"
            label="Filter name"
            placeholder="Filter name"
            //When typing "F" as first letter, input field is blurred. https://github.com/mui/material-ui/issues/36133
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            handleChange={onChange}
            rules={{
              required: 'This field is required.',
            }}
          />
        </Box>
      )}

      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Stack flexDirection="row" justifyContent="space-between" flex={1}>
          <Button
            color="inherit"
            variant="contained"
            sx={{ padding: '8px 22px' }}
            onClick={handleClear}
          >
            Clear
          </Button>
          {shouldSave && (
            <Button
              type="submit"
              color="error"
              variant="contained"
              sx={{ padding: '8px 22px' }}
              disabled={!isDirty || filterNameField.invalid}
            >
              Save
            </Button>
          )}
        </Stack>
      </>
    </form>
  );
};
