import { Box } from '@mui/material';

import { MasterNameOrganizationView } from '@/layouts/TabLayout/MasterNames/components/MasterNameOrganizationView';

import MasterIndexOrganizationBase from './MasterIndexOrganizationBase';

const MasterIndexOrganizationView = () => {
  return (
    <MasterIndexOrganizationBase>
      <Box sx={{ padding: 2 }}>
        <MasterNameOrganizationView isMasterIndex={true} hideHeader={true} />
      </Box>
    </MasterIndexOrganizationBase>
  );
};

export default MasterIndexOrganizationView;
