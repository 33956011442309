import { PropsWithChildren } from 'react';

import { Stack, StackProps, Typography } from '@mui/material';

export const NoResultItem = ({
  children,
  ...rest
}: PropsWithChildren & StackProps) => {
  return (
    <Stack justifyContent="space-between" width="100%" {...rest}>
      <Typography variant="body2" sx={{ mx: 2 }}>
        No results found
      </Typography>
      {children}
    </Stack>
  );
};
