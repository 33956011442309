import { Button, Divider, Stack, styled, Typography } from '@mui/material';

import { CfsApi } from '@/api';
import { AppModal, BaseModalProps } from '@/components';
import { useCFSContext } from '@/hooks';
import { ConfirmTypes, useDataStore, useToastStore } from '@/store';

const CFSAddressJurisdictionModalRoot = styled(AppModal)(({ theme }) => ({
  '& .modal-content': {
    position: 'absolute',
    minWidth: 500,
    padding: 20,
    borderRadius: 8,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 600,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 600,
    },

    '& .btn-close': {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.error.main,
    },
    '& .separator': {
      marginTop: 20,
      marginBottom: 20,
    },
  },
}));

interface CFSAddressJurisdictionModalProps
  extends Omit<BaseModalProps, 'onClose'> {
  onClearAddress: () => void;
  onKeepAddress: () => void;
  onClose: (closeReason?: string) => void;
}

const CFSAddressJurisdictionModal = ({
  open,
  onClose,
  onClearAddress,
  onKeepAddress,
}: CFSAddressJurisdictionModalProps) => {
  const { updateToast } = useToastStore();
  const { cfsCloseReasons } = useDataStore();
  const { cfs } = useCFSContext();

  const onCloseCFS = () => {
    const closeReason = cfsCloseReasons.find(
      ({ reason }) => reason === 'Wrong jurisdiction',
    );
    if (cfs?._id && closeReason?._id) {
      CfsApi.closeCFS(cfs._id, closeReason._id)
        .then(() => {
          updateToast({
            type: ConfirmTypes.SUCCESS,
            open: true,
            message: 'CFS record closed successfully',
          });
          onClose(closeReason._id);
        })
        .catch((err: any) => {
          updateToast({ open: true, message: err.message });
          onClose();
        });
    } else {
      onClose();
    }
  };

  return (
    <CFSAddressJurisdictionModalRoot
      onClose={onKeepAddress}
      open={open}
      title="Jurisdiction Warning!"
      hideCloseButton
    >
      <Stack rowGap={2} divider={<Divider />}>
        <Typography>
          The selected address is outside our jurisdiction. You can either close
          this CFS, select a different address within our jurisdiction, or
          choose to keep the current address and proceed.
        </Typography>

        <Stack flexDirection="row" justifyContent="space-between">
          <Button variant="contained" color="inherit" onClick={onCloseCFS}>
            Close CFS
          </Button>
          <Stack flexDirection="row" justifyContent="flex-end">
            <Stack flexDirection="row" columnGap={4}>
              <Button
                variant="contained"
                color="error"
                onClick={onClearAddress}
              >
                Change Address
              </Button>
              <Button variant="contained" color="error" onClick={onKeepAddress}>
                Keep Address
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </CFSAddressJurisdictionModalRoot>
  );
};

export default CFSAddressJurisdictionModal;
