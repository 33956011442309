import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';

import { http } from './http';

export interface LoginFormData {
  email: string;
  password: string;
  code?: string;
}

export interface EmailVerifyRequest {
  key: string;
}

export interface ForgotPasswordFormData {
  email: string;
}

export interface ResetPasswordFormData {
  key: string;
  password: string;
}

export interface RefreshTokenRequest {
  accessToken: string;
  refreshToken: string;
}

export interface LogoutRequest {
  refreshToken: string;
}

export const AuthApi = {
  login: async (data: LoginFormData): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/auth/login/`, data);
  },
  forgotPassword: async (
    data: ForgotPasswordFormData,
  ): Promise<AxiosResponse> => {
    return http.post(
      `api/${ApiVersion.V1}/auth/send-reset-password-email/`,
      data,
    );
  },
  resetPassword: async (
    data: ResetPasswordFormData,
  ): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/auth/reset-password/`, data);
  },
  verifyEmail: async (data: EmailVerifyRequest): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/auth/verify-email`, data);
  },
  sendVerificationEmail: async (
    data: EmailVerifyRequest,
  ): Promise<AxiosResponse> => {
    return http.post(
      `api/${ApiVersion.V1}/auth/send-verification-email/`,
      data,
    );
  },
  refreshToken: async (data: RefreshTokenRequest): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/auth/refresh-token/`, data);
  },
  logout: async (data: LogoutRequest): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/auth/logout/`, data);
  },
};
