import { FC, PropsWithChildren } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { StackProps } from '@mui/material';

import { AuthPageRoot, PageRoot } from './styles';

interface PageProps extends PropsWithChildren, StackProps {
  title?: string;
}

export const Page: FC<PageProps> = ({ title, children, style }) => {
  return (
    <PageRoot style={style}>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      {children}
    </PageRoot>
  );
};

export const AuthPage: FC<PageProps> = ({ title, children, style }) => {
  return (
    <AuthPageRoot style={style}>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      {children}
    </AuthPageRoot>
  );
};
