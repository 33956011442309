import { IUser } from '@/models';

export const shouldDisplayNewCfsModal = (message: any, user: IUser) => {
  if (message.name !== 'UNIT_SHIFT_ASSIGNED') return false;
  if (message.data.assignmentStatus !== 'NEW') return false;
  if (
    !message ||
    !message.data ||
    !message.data.assignedUnitShifts ||
    !message.data.assignedUnitShifts.unitShift ||
    !message.data.assignedUnitShifts.unitShift.users
  ) {
    return false;
  }

  const users = message.data.assignedUnitShifts.unitShift.users;

  for (const u of users) {
    if (u._id === user?._id) {
      return true;
    }
  }

  return false;
};
