import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import { CfsApi } from '@/api';
import { useCFSContext } from '@/hooks';
import {
  CFSMasterNameInvolvement,
  ICFSMasterName,
  IFormOnChangeProps,
  IMasterNamePerson,
} from '@/models';
import { useToastStore } from '@/store';

import { ReporterFormFields } from './data';
import { PrimaryReporterForm } from './PrimaryReporterForm';
import { FormCard } from '../../Cards/FormCard';
import { RenderFormField } from '../../FormElements';

export const ReporterForm = ({ onChange, ...props }: IFormOnChangeProps) => {
  const urlParams = useParams();
  const cfsId = props?.cfsId || String(urlParams.cfsId);
  const { updateToast } = useToastStore();
  const { cfs, ablyCfsChannel, updateCFS } = useCFSContext();
  const [linkedMasterNames, setLinkedMasterNames] = useState<ICFSMasterName[]>(
    [],
  );
  const masterName = cfs?.primaryReporter;

  useEffect(() => {
    fetchLinkedMasternames();
  }, [cfs?.names]);

  const fetchLinkedMasternames = async () => {
    if (!cfsId) return;
    try {
      const res = await CfsApi.getCfsMasterNames(cfsId);
      const primaryReporter = res.data.find((mName) =>
        mName.relationship?.involvement?.includes(
          CFSMasterNameInvolvement.PRIMARY_REPORTER,
        ),
      );
      if (primaryReporter) {
        setLinkedMasterNames([primaryReporter]);
      }
    } catch (err: any) {
      updateToast({
        open: true,
        message: err.message,
      });
    }
  };

  const handleUpdatePrimaryReporter = async (
    newPrimaryReporter?: IMasterNamePerson,
  ) => {
    try {
      let primaryReporter;
      if (cfsId && cfs?.primaryReporter?._id && !newPrimaryReporter) {
        const cfsMasterName = await CfsApi.getCfsMasterName(
          cfsId,
          cfs.primaryReporter._id,
        );
        const updatedInvolvement =
          (
            cfsMasterName.data.relationship
              ?.involvement as CFSMasterNameInvolvement[]
          )?.filter(
            (inv: CFSMasterNameInvolvement) =>
              inv !== CFSMasterNameInvolvement.PRIMARY_REPORTER,
          ) || [];

        await CfsApi.updateCfsMasterName(cfsId, cfs.primaryReporter._id, {
          relationship: {
            involvement: updatedInvolvement,
          },
        });
      } else if (newPrimaryReporter?._id && cfsId) {
        if (cfs?.primaryReporter) {
          primaryReporter = { ...cfs.primaryReporter, ...newPrimaryReporter };
        } else {
          const res = await CfsApi.addCfsMasterName(
            cfsId,
            newPrimaryReporter?._id,
            {
              relationship: {
                behavior: 'N/A',
                involvement: [CFSMasterNameInvolvement.PRIMARY_REPORTER],
              },
            },
          );
          primaryReporter = res.data.masterName;
        }
      }

      if (cfs) {
        updateCFS({ ...cfs, primaryReporter });
      }
      ablyCfsChannel?.publish('cfsForm', { primaryReporter });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  return (
    <FormCard title="Reporter information">
      <Grid item xs={12}>
        <PrimaryReporterForm
          masterName={masterName}
          excludedMasterNames={linkedMasterNames}
          onChange={handleUpdatePrimaryReporter}
        />
      </Grid>

      {ReporterFormFields.map(({ styles, ...rest }) => (
        <Grid key={rest.name} item xs={6} {...styles}>
          <RenderFormField {...rest} handleChange={onChange} />
        </Grid>
      ))}
    </FormCard>
  );
};
