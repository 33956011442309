import { useEffect, useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { Button, Stack } from '@mui/material';
import { AxiosResponse } from 'axios';

import { Input, PageHeader } from '@/components';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { ICFSMasterName } from '@/models';
import { useToastStore } from '@/store/store.toast';

import { useMasterNamesContext } from '..';
import {
  MasterLookupTableColumns,
  TabNamesNextPaths,
  TabNamesScreens,
} from '../data';
import { NameLookupTable } from './tables/NameLookupTables';

export const MasterLookupName = () => {
  const { updateCurrentScreen } = useTabLayoutContext();

  const [search, setSearch] = useState<string>('');
  const { updateToast } = useToastStore();

  const response = useLoaderData() as AxiosResponse<ICFSMasterName[]>;

  const { linkedMasterNames, updateLinkedMasterNames } =
    useMasterNamesContext();

  useEffect(() => {
    if (response && response.status !== 200) {
      updateToast({ open: true, message: response.statusText });
    } else {
      updateLinkedMasterNames(
        linkedMasterNames && linkedMasterNames.length
          ? linkedMasterNames
          : response.data,
      );
    }
  }, []);

  const filteredMasterNames = useMemo(() => {
    return search
      ? linkedMasterNames.filter(({ masterName }) => {
          return masterName.name?.toLowerCase().includes(search.toLowerCase());
        })
      : linkedMasterNames;
  }, [linkedMasterNames, search]);

  const onAddName = () => {
    updateCurrentScreen(TabNamesScreens.MasterAddName);
  };

  const onGoBack = () => {
    updateCurrentScreen(TabNamesScreens.MasterAddNameDetails);
  };

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.nameList}>
      <PageBackHeader title="Lookup Names" goBack={onGoBack} />
      <PageHeader className="overlayHeader">
        <Input
          className="filter"
          placeholder="Search names..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: '40%' }}
        />
        <Stack columnGap={2} flexDirection="row">
          <Button
            variant="contained"
            color="error"
            size="medium"
            onClick={onAddName}
          >
            Add name
          </Button>
        </Stack>
      </PageHeader>
      <NameLookupTable
        columns={MasterLookupTableColumns}
        rows={filteredMasterNames}
      />
    </TabAnimationWrapper>
  );
};
