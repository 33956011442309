import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IUser, Role } from '@/models';
import { isTargetRole } from '@/services';

const useShowIncomingCalls = (user: IUser | null) => {
  const { pathname } = useLocation();
  const excludedPaths = /^\/(cases|master-index|tasks|agencies)(\/[^/]+)*$/;

  const [showIncomingCalls, setShowIncomingCalls] = useState(false);

  useEffect(() => {
    if (!user) return;

    const isExcludedPage = excludedPaths.test(pathname);
    const shouldShow =
      !isExcludedPage &&
      (isTargetRole(user, Role.ACCOUNT_ADMIN) ||
        isTargetRole(user, Role.DISPATCHER));

    setShowIncomingCalls(shouldShow);
  }, [pathname, user]);

  return showIncomingCalls;
};

export default useShowIncomingCalls;
