import { FC } from 'react';

import {
  Backdrop,
  BackdropProps,
  CircularProgress,
  styled,
} from '@mui/material';

const LoadingContainer = styled(Backdrop)(() => ({
  zIndex: 10000,
}));

export const Loader: FC<BackdropProps> = (props) => {
  return (
    <LoadingContainer {...props}>
      <CircularProgress />
    </LoadingContainer>
  );
};
