import { Stack, alpha, styled } from '@mui/material';

import { colors, sizes } from '@/theme/variables';
export const TabLayoutRoot = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  width: 'calc(100% - 90px)',
  height: sizes.bottomTabBar.height,
  bottom: 0,
  margin: `${sizes.bottomTabBar.margin}px 0px`,
  zIndex: 3,
  transition: 'all 0s ease-in',
  overflow: 'hidden',

  '&.active': {
    height: 'calc(100vh - 40px)',

    '& .tablayout-container': {
      marginBottom: 18,
    },
  },

  '& .tablayout-container': {
    flex: 1,
    marginBottom: 0,
    borderRadius: 8,
    overflow: 'hidden',
  },

  '& .header': {
    minHeight: 64,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.default,
    },
    zIndex: 2,
  },

  '& .tablayout-content': {
    flex: 1,
    height: '100%',
    padding: '0 20px',
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    zIndex: 2,

    '&.renderOnPage': {
      background: 'none',
      padding: 0,
    },
  },

  '& .overlay': {
    backgroundColor: alpha(colors.black, 0.1),
  },
}));
