import { Button, Divider, Stack, Typography } from '@mui/material';

import { IcWarning } from '@/assets/images';
import { ICFS } from '@/models';
import { CautionChips } from '@/pages/FirstResponder/components/CautionChips';
import { HeaderSection } from '@/pages/FirstResponder/components/HeaderSection';
import { ModalState } from '@/pages/FirstResponder/shared/modalState';
import { getAddressLocation } from '@/services';

type CfsDetailsModalContentProps = {
  toggleModal: (modalName: keyof ModalState) => void;
  action: () => void;
  cfs?: ICFS;
};

const CfsDetailsModalContent = ({
  toggleModal,
  action,
  cfs,
}: CfsDetailsModalContentProps) => {
  const getPrimaryReporterFullName = () => {
    if (cfs?.primaryReporter?.firstName && cfs?.primaryReporter?.lastName) {
      return (
        cfs?.primaryReporter?.firstName + ' ' + cfs?.primaryReporter?.lastName
      );
    } else {
      return 'No reporter';
    }
  };

  return (
    <>
      <Stack flex={1} padding={2}>
        {/* What do i need to show here? */}
        <HeaderSection
          title={cfs?.shortDescription || 'No Emergency detail for this CFS'}
          subtitle={getAddressLocation(cfs?.addressInfo?.address)}
          phoneNumber={cfs?.primaryReporter?.phoneNumbers?.[0]?.phoneNumber.toString()}
          primaryReporter={getPrimaryReporterFullName()}
          icon={<IcWarning />}
          severity={cfs?.severity}
          hideNavigationButton={true}
        />
        <Divider />
        <Stack direction="row" marginY={2} justifyContent="space-between">
          <CautionChips addressId={cfs?.addressInfo?.address?._id || ''} />
        </Stack>
        <Divider />
        <Typography
          fontSize={18}
          fontWeight={400}
          marginY={2}
          color="textSecondary"
        >
          {cfs?.addressInfo?.instructions || 'No instructions'}
        </Typography>
        <Divider />
      </Stack>
      <Stack padding={2}>
        <Divider />
        <Button
          color="error"
          variant="contained"
          size="large"
          sx={{ padding: 2 }}
          fullWidth
          onClick={() => {
            action();
            toggleModal('cfsDetails');
          }}
        >
          Assign CFS
        </Button>
      </Stack>
    </>
  );
};

export default CfsDetailsModalContent;
