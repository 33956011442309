import { MouseEvent, useState } from 'react';

import { Alert, Popover } from '@mui/material';

import { IcInfo } from '@/assets/images';

export const MasterNameKaseInvolvementPopover = () => {
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const handleClick = (event: MouseEvent<SVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <IcInfo
        onClick={handleClick}
        style={{ position: 'absolute', bottom: 15, right: -30 }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Alert severity="error">
          Changing Name Involvement is restricted to avoid inconsistencies.{' '}
          <br />
          Please remove the name and re-initiate involvement if adjustments are
          needed..
        </Alert>
      </Popover>
    </>
  );
};
