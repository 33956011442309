import { Control, FieldValues } from 'react-hook-form';

import { InputCollection } from './InputCollection';
import { InformationType, infoTypeMap } from '../data';
interface IAdditionalInfoRelated {
  currentSelection: InformationType;
  control: Control<FieldValues, any>;
  index: number;
}
const AdditionalInfoRelated = ({
  currentSelection,
  control,
  index,
}: IAdditionalInfoRelated) => {
  const currentInfotype =
    infoTypeMap[currentSelection as keyof typeof infoTypeMap];
  return (
    currentSelection &&
    currentInfotype && (
      <InputCollection
        control={control}
        {...currentInfotype}
        name={`informationType.${index}.items`}
        populateByDefault={true}
      />
    )
  );
};
export default AdditionalInfoRelated;
