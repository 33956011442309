import { KaseMasterNameInvolvement } from './kase';
import { IMasterNameRelationBehavior } from './master.names';

export interface IKaseMasterNameRelationship {
  behavior?: IMasterNameRelationBehavior | null;
  involvement: KaseMasterNameInvolvement;
}
export type IKaseInvolvement = {
  behavior?: IMasterNameRelationBehavior;
  involvement?: KaseMasterNameInvolvement;
};

export enum AgeGroup {
  EXACT_AGE = 'EXACT_AGE',
  AGE_RANGE = 'AGE_RANGE',
  OVER_NINETY_EIGHT_YEARS_OLD = 'OVER_NINETY_EIGHT_YEARS_OLD',
  UNKNOWN = 'UNKNOWN',
}

export enum OffenderType {
  SUSPECT = 'SUSPECT',
  ACCOMPLICE = 'ACCOMPLICE',
  CLEARED = 'CLEARED',
  CHARGED = 'CHARGED',
}

export enum OffenderCondition {
  APPEARS_TO_BE_IMPAIRED_WITH_DRUGS = '01',
  APPEARS_TO_BE_IMPAIRED_WITH_ALCOHOL = '02',
  APPEARS_TO_BE_MENTALLY_DISORDERED = '03',
  APPEARS_TO_BE_INJURED_OR_ILL = '04',
  APPARENTLY_NORMAL = '05',
  NOT_REPORTED = '88',
  UNKNOWN = '99',
}

export enum ResidenceStatus {
  RESIDENT = 'R',
  NONRESIDENT = 'N',
  FOREIGN_NONRESIDENT = 'F',
  UNKNOWN = 'U',
}

export enum VictimType {
  FIRE_FIGHTER = 'A',
  INDIVIDUAL = 'I',
  LAW_ENFORCEMENT_OFFICER = 'L',
  BUSINESS = 'B',
  FINANCIAL_INSTITUTION = 'F',
  GOVERNMENT = 'G',
  RELIGIOUS_ORGANIZATION = 'R',
  SOCIETY_PUBLIC = 'S',
  OTHER = 'O',
  UNKNOWN = 'U',
}

export enum LevelOfInjury {
  DEAD = '1',
  APPEARED_TO_BE_SERIOUSLY_INJURED = '2',
  APPEARED_TO_BE_PHYSICALLY_INJURED = '3',
  NOT_INJURED = '4',
  NOT_APPLICABLE = '7',
  NOT_REPORTED = '8',
  UNKNOWN = '9',
}

export enum InjuryType {
  NONE = '00',
  APPARENT_BROKEN_BONES = '01',
  POSSIBLE_INTERNAL_INJURY = '02',
  SEVERE_LACERATION = '03',
  LOSS_OF_TEETH = '04',
  UNCONSCIOUSNESS = '05',
  OTHER_MAJOR_INJURY = '06',
  APPARENT_MINOR_INJURY = '07',
  NOT_APPLICABLE = '77',
  NOT_REPORTED = '88',
  UNKNOWN = '99',
}

export enum MedicalTreatment {
  HOSPITALIZED = '1',
  TREATED_AND_RELEASED = '2',
  REFUSED_TREATMENT = '3',
  NOT_TREATED = '4',
  TREATED_AT_SCENE = '5',
  NOT_APPLICABLE = '7',
  NOT_INDICATED_IN_REPORT = '8',
  UNKNOWN = '9',
}

export enum VictimResidenceStatus {
  RESIDENT = '01',
  COMMUTER = '02',
  TOURIST = '03',
  MILITARY = '04',
  STUDENT = '05',
  TEMPORARY_RESIDENT = '06',
  OTHER_STATUS = '07',
  HOMELESS = '08',
  NOT_APPLICABLE = '77',
  NOT_REPORTED = '88',
  UNKNOWN = '99',
}

export enum OfficerActivityType {
  RESPONDING_TO_DISTURBANCE_CALL = '01',
  BURGLARIES_IN_PROGRESS = '02',
  ROBBERIES_IN_PROGRESS = '03',
  ATTEMPTING_OTHER_ARRESTS = '04',
  CIVIL_DISORDER = '05',
  HANDLING_TRANSPORTING_CUSTODY_OF_PRISONERS = '06',
  INVESTIGATING_SUSPICIOUS_PERSONS = '07',
  AMBUSH_NO_WARNING = '08',
  HANDLING_PERSONS_WITH_MENTAL_ILLNESS = '09',
  TRAFFIC_PURSUITS_AND_STOPS = '10',
  ALL_OTHER = '11',
  NOT_APPLICABLE = '77',
}

export enum OfficerAssignmentType {
  TWO_OFFICER_VEHICLE = 'F',
  ONE_OFFICER_VEHICLE_ALONE = 'G',
  ONE_OFFICER_VEHICLE_ASSISTED = 'H',
  DETECTIVE_OR_SPECIAL_ASSIGNMENT_ALONE = 'I',
  DETECTIVE_OR_SPECIAL_ASSIGNMENT_ASSISTED = 'J',
  OTHER_ALONE = 'K',
  OTHER_ASSISTED = 'L',
  NOT_APPLICABLE = 'X',
}

export enum JurisdictionType {
  NOT_APPLICABLE = '777777777',
}

export type IAgeFields = {
  ageGroup: AgeGroup;
  age?: number;
  ageRange?: [number, number];
};

export type kaseWitnessForm = IAgeFields & {
  wasInterviewed: boolean;
  residenceStatus: VictimResidenceStatus;
};

export type kaseOffenderForm = IAgeFields & {
  offenderType: OffenderType;
  wasArrested?: boolean;
  offenderCondition: OffenderCondition;
  residenceStatus: ResidenceStatus;
};

export type kaseVictimForm = kaseWitnessForm & {
  victimType: VictimType;
  interviewedBy: string;
  levelOfInjury: LevelOfInjury;
  injuryType: InjuryType;
  medicalTreatment: MedicalTreatment;
  officerActivityType: OfficerActivityType;
  officerAssignmentType: OfficerAssignmentType;
  jurisdiction: JurisdictionType;
};

export type IKaseInvolvementForm = IKaseInvolvement & { _id: string | null } & (
    | kaseOffenderForm
    | kaseVictimForm
    | kaseWitnessForm
  );
