import {
  Box,
  Button,
  Chip,
  colors,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import PersonPlaceholder from '@/assets/images/pngs/person-placeholder.png';
import { useFetchSasToken } from '@/hooks';
import { IMasterNamePerson } from '@/models';
import { getAddressCommonString } from '@/services';

import { TabType } from '../tabs/CarStopCfsTab';

type PersonDetailsCardProps = {
  data: IMasterNamePerson;
  setSelectedTab: (tab: TabType) => void;
};

const DetailsItem = ({ label, value }: { label: string; value?: string }) => (
  <Stack display="flex" flexDirection="row" alignItems="center">
    <Typography fontWeight={600} fontSize={18} flex={1}>
      {label}
    </Typography>
    <Typography flex={1}>{value}</Typography>
  </Stack>
);

const RelationsItems = ({ data, setSelectedTab }: PersonDetailsCardProps) => {
  const linked = {
    CFS: data?.linkedCFSs ?? null,
    Cases: null,
  };

  return (
    <Stack gap={1} flex={1}>
      {/* TODO: linked cases is missing */}
      {(['CFS', 'Cases'] as Array<keyof typeof linked>).map((item) => (
        <Stack
          key={item}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography fontWeight={600} fontSize={18} flex={1}>
            {item} {linked[item] && <Chip color="error" label={linked[item]} />}
          </Typography>
          <Box flex={1}>
            {linked[item] && (
              <Button
                variant="text"
                onClick={() =>
                  item === 'CFS'
                    ? setSelectedTab('relations-cfs')
                    : setSelectedTab('relations-cases')
                }
              >
                View more
              </Button>
            )}
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};

const PersonDetailsCard = ({
  data,
  setSelectedTab,
}: PersonDetailsCardProps) => {
  const sasToken = useFetchSasToken(data.files);

  const personDetailsItems = [
    { label: 'ID number', value: data?.ssn || '/' },
    { label: 'Date of birth', value: data?.birthday || '/' },
    { label: 'Issued date', value: data?.driverLicense?.number || '/' },
    { label: 'Expired date', value: data?.driverLicense?.expireDate || '/' },
    { label: 'Sex', value: data?.sex || '/' },
    { label: 'Height', value: data?.physicalCharacteristics?.height || '/' },
    { label: 'Eyes', value: data?.physicalCharacteristics?.eyeColor || '/' },
    {
      label: "Driver's license number",
      value: data?.driverLicense?.number || '/',
    },
  ];

  return (
    <Stack
      padding={2}
      border={1}
      borderRadius={1}
      borderColor={colors.grey[300]}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <img
          src={
            (data?.files?.length ?? 0) > 0
              ? `${data?.files?.[0]?.url ?? ''}?${sasToken}`
              : PersonPlaceholder
          }
          height={84}
          width={84}
          style={{ objectFit: 'cover', borderRadius: 8 }}
        />
        <Stack gap={1}>
          <Typography fontWeight={600} fontSize={32}>
            {data?.firstName} {data?.lastName}
          </Typography>
          <Typography fontWeight={400} fontSize={21}>
            {getAddressCommonString(data?.address)}
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <Stack gap={1}>
        {personDetailsItems.map((item, index) => (
          <DetailsItem
            key={index}
            label={item.label}
            value={item.value as string}
          />
        ))}
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <RelationsItems data={data} setSelectedTab={setSelectedTab} />
    </Stack>
  );
};

export default PersonDetailsCard;
