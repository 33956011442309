import { useEffect, useState } from 'react';

import { ButtonBase, debounce, Stack, Typography } from '@mui/material';
import * as Ably from 'ably';
import { useChannel } from 'ably/react';

import { CfsApi } from '@/api';
import { IGetParams } from '@/api/types';
import externalReadyToDispatchAudio from '@/assets/audios/external-ready-for-dispatch.mp3';
import newReadyToDispatchAudio from '@/assets/audios/new-ready-to-dispatch.mp3';
import { withLoader, WithLoaderProps } from '@/hocs';
import { CFSEvent, ICFS } from '@/models';
import { playAudio } from '@/services';
import { useToastStore, useUserStore } from '@/store';

import { CFSListItem } from '../components/CFSListItem';
import { ModalState } from '../shared/modalState';

const CfsListTab = ({
  showLoader,
  hideLoader,
  setActiveCfs,
  toggleModal,
}: WithLoaderProps & {
  setActiveCfs: (cfs: ICFS) => void;
  toggleModal: (modalName: keyof ModalState) => void;
}) => {
  const { updateToast } = useToastStore();
  const { account } = useUserStore();

  const [activeCFSList, setActiveCFSList] = useState<ICFS[] | null>(null);

  useChannel(
    `account:${account?._id}:cfs:all`,
    debounce((message: Ably.Types.Message) => {
      if (message.name === CFSEvent.READY_TO_DISPATCH) {
        if (message.data.isExternal) {
          playAudio(externalReadyToDispatchAudio);
        } else {
          playAudio(newReadyToDispatchAudio);
        }
      }
      fetchActiveCFSList();
    }, 500),
  );

  useEffect(() => {
    fetchActiveCFSList();
  }, []);

  const handleClickedCFS = (cfs: ICFS) => {
    showLoader();
    if (!cfs._id) {
      return;
    }
    CfsApi.getOne(cfs._id)
      .then((response) => {
        setActiveCfs(response.data);
        toggleModal('cfsDetails');
        hideLoader();
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
        hideLoader();
      });
  };

  const fetchActiveCFSList = () => {
    showLoader();
    const filters = {};

    const params: IGetParams = {
      filter: JSON.stringify(filters),
      sort: JSON.stringify({ createdAt: -1 }),
    };

    CfsApi.activeList(params)
      .then((response) => {
        const results = response.data;
        setActiveCFSList(results);
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      })
      .finally(() => {
        hideLoader();
      });
  };

  if (activeCFSList === null) {
    return (
      <Stack padding={3} gap={2}>
        <Typography>Loading...</Typography>;
      </Stack>
    );
  }

  return (
    <Stack padding={3} gap={2}>
      {activeCFSList.length === 0 && <Typography>No active CFS</Typography>}
      {activeCFSList.map((cfs) => (
        <ButtonBase
          onClick={() => handleClickedCFS(cfs)}
          key={cfs._id}
          sx={{ display: 'flex' }}
        >
          <CFSListItem cfs={cfs}></CFSListItem>
        </ButtonBase>
      ))}
    </Stack>
  );
};

export default withLoader(CfsListTab);
