import { useEffect, useMemo, useState } from 'react';

import { VehicleApi } from '@/api';
import { IFormSelectItem, VehicleType } from '@/models';
import { useDataStore, useToastStore } from '@/store';

const vehicleMinYear = 1990;

export const useMasterVehicleFormOptions = (
  vehicleType?: string,
  makeName?: string,
  modelName?: string,
  selectedYear?: number | string,
) => {
  const { updateToast } = useToastStore();
  const { makes } = useDataStore();
  const [modelOptions, setModelOptions] = useState<IFormSelectItem[]>([]);
  const [yearOptions, setYearOptions] = useState<IFormSelectItem[]>([]);
  const [colorOptions, setColorOptions] = useState<IFormSelectItem[]>([]);

  const makeOptions = useMemo(
    () =>
      makes.map(({ _id, name }) => ({
        label: name,
        value: name,
        _id,
      })),
    [makes],
  );

  useEffect(() => {
    generateYearOptions();
  }, []);

  useEffect(() => {
    if (selectedYear && vehicleType === VehicleType.AUTOMOBILE && makeName) {
      fetchModels();
    } else {
      setModelOptions([]);
      setColorOptions([]);
    }
  }, [selectedYear, makeName, vehicleType]);

  useEffect(() => {
    if (modelName && modelOptions.length > 0 && selectedYear && makeName) {
      fetchColors();
    }
  }, [modelOptions, modelName, selectedYear, makeName]);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const diffYears = currentYear - vehicleMinYear;
    const _yearOptions: IFormSelectItem[] = [];

    for (let i = 0; i <= diffYears; i++) {
      _yearOptions.unshift({
        label: `${vehicleMinYear + i}`,
        value: vehicleMinYear + i,
      });
    }
    setYearOptions(_yearOptions);
  };

  const getMakeIdByName = (makeNameValue?: string) => {
    if (!makeNameValue) {
      return null;
    }
    const make = makes.find((option) => option.name === makeNameValue);
    return make ? make._id : null;
  };

  const fetchModels = async () => {
    try {
      const makeItemId = getMakeIdByName(makeName);
      if (!makeItemId) {
        throw new Error('Invalid makeId');
      }
      const res = await VehicleApi.getVehicleModels(
        String(makeItemId),
        Number(selectedYear),
      );
      setModelOptions(
        res.data.map(({ name, _id }) => ({
          label: name,
          value: name,
          _id,
        })),
      );
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const getModelIdByName = (modelNameValue?: string) => {
    if (!modelNameValue) {
      return null;
    }
    const model = modelOptions?.find(
      (option) => option.value === modelNameValue,
    );
    return model ? (model as { _id: string })._id : null;
  };

  const fetchColors = async () => {
    try {
      const makeItemId = getMakeIdByName(makeName);
      if (!makeItemId) {
        throw new Error('Invalid makeId');
      }
      const modelItemId = getModelIdByName(modelName);
      if (!modelItemId) {
        throw new Error('Invalid modelId');
      }
      let filter = {};
      if (makeItemId && modelItemId && selectedYear) {
        filter = {
          make: makeItemId,
          model: modelItemId,
          year: selectedYear,
        };
        const res = await VehicleApi.getVehicleColors({
          limit: 1000,
          filter: JSON.stringify(filter),
        });
        setColorOptions(
          res.data.results.map(({ name }) => ({
            label: name,
            value: name,
          })),
        );
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  return { makeOptions, modelOptions, yearOptions, colorOptions };
};
