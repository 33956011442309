import { Alert, AlertProps } from '@mui/material';

export interface AlertInfoProps extends AlertProps {
  label?: string;
}

const AlertInfo = ({ label, sx }: AlertInfoProps) => {
  return (
    <Alert severity="warning" sx={{ margin: '10px 0', ...sx }}>
      {label}
    </Alert>
  );
};

export { AlertInfo };
