import { IAuditModel } from '@/models/base';
import { IBaseLog } from '@/models/log';

export enum MasterIndexEntity {
  ADDRESS = 'address',
  PERSON = 'person',
  VEHICLE = 'vehicle',
  ORGANIZATION = 'organization',
  PROPERTY = 'property',
}

export enum MasterIndexEvent {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export interface IMasterIndexLog
  extends Omit<IAuditModel, 'createdAt'>,
    IBaseLog {
  entity: MasterIndexEntity;
  event: MasterIndexEvent;
  entityId: string;
}
