import { OrganizationType, PhoneNumberTypeMap } from '@/models';

export const parsePhoneNumber = (phoneNumber?: string, countryCode = '+1') => {
  if (!phoneNumber) return '';
  if (phoneNumber.includes(countryCode)) {
    return phoneNumber.replace(countryCode, '');
  }
  return countryCode + phoneNumber;
};

export const formatPhoneNumber = (str?: string) => {
  if (!str) return '';
  const match = str.match(/(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : str;
};

export const formatPhoneNumberType = (str?: string) => {
  if (!str) {
    return 'Unknown phone number type';
  }

  const formattedType =
    PhoneNumberTypeMap[str as keyof typeof PhoneNumberTypeMap];

  return formattedType ? formattedType : 'Invalid phone number type';
};

export const formatOrganisationName = (str?: string) => {
  if (!str) return '';
  const match = str.match(/(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : str;
};

export const formatOrganisationType = (str?: string) => {
  if (!str) {
    return 'Unknown organization type';
  }

  const formattedType = OrganizationType[str as keyof typeof OrganizationType];

  return formattedType ? formattedType : 'Invalid organization type';
};

export const convertToAsteriskSSN = (lastDigitsString: string) => {
  return lastDigitsString ? `*****${lastDigitsString}` : '';
};
