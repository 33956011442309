import { createContext, FC, ReactNode, useEffect, useState } from 'react';

import { debounce } from '@mui/material';
import * as Ably from 'ably';
import { useChannel } from 'ably/react';

import { CfsApi, UserApi } from '@/api';
import { IcMarkerUser } from '@/assets/images';
import { Loader } from '@/components';
import { IMarker, UserMarkerInfoItem } from '@/components/GoogleMap';
import { ICFS } from '@/models';
import { useToastStore, useUserStore } from '@/store';

type UnitShiftDistances = Record<string, number>;

interface CFSContextValue {
  cfs: ICFS | null;
  unitShiftDistances: UnitShiftDistances;
  mapBounds?: google.maps.LatLngBoundsLiteral;
  cfsMapMarkers?: IMarker[];
  fetchCFS: () => Promise<ICFS | null>;
  updateCFS: (data: ICFS | null) => void;
  setMapBounds: (newBounds?: google.maps.LatLngBoundsLiteral) => void;
  handleRefresh: () => void;
  ablyCfsChannel?: Ably.Types.RealtimeChannelPromise;
  isFetching?: boolean;
}

interface CFSProviderProps {
  cfsId?: string;
  children: ReactNode;
}

export const CFSContext = createContext<CFSContextValue>({
  cfs: null,
  unitShiftDistances: {},
  fetchCFS: async () => null,
  updateCFS: () => {},
  setMapBounds: () => {},
  handleRefresh: () => {},
  isFetching: false,
});

export const CFSProvider: FC<CFSProviderProps> = (props) => {
  const { cfsId, children } = props;
  const { updateToast } = useToastStore();
  const { account } = useUserStore();
  const [cfs, setCfs] = useState<ICFS | null>(null);
  const [unitShiftDistances, setUnitShiftDistances] =
    useState<UnitShiftDistances>({});
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBoundsLiteral>();
  const [cfsMapMarkers, setCfsMapMarkers] = useState<IMarker[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { channel: ablyCfsChannel } = useChannel(
    `account:${account?._id}:cfs:${cfsId}`,
    async (message: Ably.Types.Message) => {
      const data = JSON.parse(JSON.stringify(message.data));
      if (cfs) {
        if (data.reportTime) {
          data.reporter = {
            ...cfs.reporter,
            reportTime: data.reportTime,
          };
          delete data.reportTime;
        }
        if (data.reporter) {
          data.reporter = {
            ...cfs.reporter,
            ...data.reporter,
          };
        }
        if (data.assignedUnitShifts) {
          const currentAssignedUnitShifts =
            cfs.assignedUnitShifts?.slice() || [];
          const indexOfExist = currentAssignedUnitShifts.findIndex(
            ({ _id }) => _id === data.assignedUnitShifts._id,
          );

          if (indexOfExist > -1) {
            currentAssignedUnitShifts[indexOfExist] = {
              ...currentAssignedUnitShifts[indexOfExist],
              ...data.assignedUnitShifts,
            };
          } else {
            currentAssignedUnitShifts.push(data.assignedUnitShifts);
          }
          data.assignedUnitShifts = currentAssignedUnitShifts;
        }
        if (data.isReadyToDispatch) {
          fetchNearbyHQUsers(false);
        }

        setCfs((prevCfs) => {
          if (!prevCfs) {
            return prevCfs;
          }

          return {
            ...prevCfs,
            ...data,
          };
        });
      }
    },
  );

  useChannel(
    `account:${account?._id}:cfs:all`,
    debounce((message: Ably.Types.Message) => {
      if (message?.name === 'GENERATED_AI_DESCRIPTION') {
        if (message?.data?.updatedCFS?.additionalInfo?.length) {
          setCfs((prevCfs) => {
            if (!prevCfs) {
              return prevCfs;
            }

            return {
              ...prevCfs,
              additionalInfo: message?.data?.updatedCFS?.additionalInfo,
            };
          });
        }
      }
    }, 500),
  );

  useEffect(() => {
    if (cfs?.unitShiftDistances) {
      const distanceObj = cfs.unitShiftDistances.reduce(
        (obj: UnitShiftDistances, current) => {
          obj[current.unitShift] = current.distance;
          return obj;
        },
        {},
      );
      setUnitShiftDistances(distanceObj);
    }
  }, [cfs?.unitShiftDistances]);

  useEffect(() => {
    if (cfsId) {
      fetchCFS();
    }
  }, [cfsId]);

  useEffect(() => {
    fetchNearbyHQUsers();
  }, [cfs?.wasReadyToDispatchAt]);

  const fetchCFS = async () => {
    setIsLoading(true);
    try {
      const res = await CfsApi.getOne(String(cfsId));
      setCfs(res.data);
      return res.data;
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const updateCFS = (data: ICFS | null) => {
    setCfs(data);
  };

  const fetchNearbyHQUsers = async (checkTime = true) => {
    // When get isReadyToDispatch: true message, fetch nearby users even if wasReadyToDispatchAt value is not updated.
    try {
      if (!cfs?.wasReadyToDispatchAt && checkTime) return;

      // Only fetch nearby users within 5 minutes since cfs became readyToDispatch.
      const timeDifferenceFromNow =
        checkTime && !!cfs?.wasReadyToDispatchAt
          ? (new Date().getTime() -
              new Date(cfs?.wasReadyToDispatchAt).getTime()) /
            1000
          : 0;

      if (timeDifferenceFromNow < 300) {
        const res = await UserApi.getNearByUsersFromHQ();
        const userMarkers = res.data.map((user) => {
          return {
            key: String(user._id),
            title: user.fullName ?? user.email,
            position: {
              lng: user.location?.point?.coordinates[0] || 0,
              lat: user.location?.point?.coordinates[1] || 0,
            },
            info: (
              <UserMarkerInfoItem
                userName={user.fullName || user.email}
                badgeNumber={user.profile?.employmentInformation?.badgeNumber}
              />
            ),
            icon: <IcMarkerUser />,
          };
        });

        setCfsMapMarkers(userMarkers);
      } else {
        setCfsMapMarkers(undefined);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return null;
    }
  };

  return (
    <CFSContext.Provider
      value={{
        cfs,
        unitShiftDistances,
        mapBounds,
        ablyCfsChannel,
        cfsMapMarkers,
        fetchCFS,
        updateCFS,
        setMapBounds,
        handleRefresh: fetchNearbyHQUsers,
        isFetching: isLoading,
      }}
    >
      {children}
      <Loader open={isLoading} />
    </CFSContext.Provider>
  );
};
