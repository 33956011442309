import { useEffect, useState } from 'react';

import { Button, Divider, Stack, Typography } from '@mui/material';

import { DriverLicenseApi } from '@/api/api.driver.license';
import { ViewCard } from '@/components/Cards/ViewCard/ViewCard';
import { ViewItem } from '@/components/Cards/ViewItemList/ViewItem';
import { Loader } from '@/components/Loader/Loader';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { ConfirmTypes, useToastStore } from '@/store/store.toast';
import { colors } from '@/theme/variables';
import { flattenObject } from '@/utils';

import { useMasterNamesContext } from '..';
import {
  DriverDetailsViewFields,
  TabNamesNextPaths,
  TabNamesScreens,
} from '../data';
import { UserAvatar } from '../styles';

export const MasterNameDriverLicenseView = () => {
  const { updateCurrentScreen } = useTabLayoutContext();
  const { setCurrentDriverLicenseId, currentDriverLicenseId } =
    useMasterNamesContext();
  const { updateToast } = useToastStore();
  const [currentDriverLicense, setCurretDriverLicense] =
    useState<Record<string, any>>();

  useEffect(() => {
    if (currentDriverLicenseId) fetchCurrentDriverLicense();
  }, [currentDriverLicenseId]);

  const fetchCurrentDriverLicense = async () => {
    try {
      const res = await DriverLicenseApi.getDriverLicense(
        String(currentDriverLicenseId),
      );
      setCurretDriverLicense(flattenObject(res.data));
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };
  const onGoBack = () => {
    updateCurrentScreen(TabNamesScreens.MasterAddNameDetails);
  };
  const onGoEdit = () => {
    if (!currentDriverLicense) return;
    setCurrentDriverLicenseId(currentDriverLicense._id);
    updateCurrentScreen(TabNamesScreens.MasterAddDriverLicense);
  };
  const onDelete = async () => {
    if (!currentDriverLicenseId) return;
    if (
      !confirm(
        'warning this will PERMANENTLY delete the driver license, are you sure?',
      )
    ) {
      return;
    }
    try {
      const res = await DriverLicenseApi.deleteDriverLicense(
        currentDriverLicenseId,
      );

      if (res.status === 204) {
        updateToast({
          open: true,
          message: 'driver license delete',
          type: ConfirmTypes.SUCCESS,
        });
        onGoBack();
      }
    } catch (e: unknown) {
      updateToast({
        open: true,
        message: 'ERROR, driver license deletion failed',
      });
    }
  };

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.MasterAddNameDetails}>
      <PageBackHeader
        title="Driver's license / ID card information"
        goBack={onGoBack}
      />
      {!currentDriverLicense ? (
        <Loader open={true} />
      ) : (
        <>
          <Stack flexDirection="row" justifyContent="left" alignItems="center">
            <UserAvatar sx={{ color: colors.error.dark, fontSize: 28 }}>
              {currentDriverLicense.firstName?.charAt(0)}
              {currentDriverLicense.lastName?.charAt(0)}
            </UserAvatar>
          </Stack>
          <Divider sx={{ mb: 3 }} />
          <Stack
            columnGap={2}
            flexDirection="row"
            height="100%"
            overflow="auto"
          >
            <ViewCard title="">
              {currentDriverLicense &&
                DriverDetailsViewFields.map((item) => (
                  <ViewItem
                    key={item.key}
                    flexDirection="row"
                    flexWrap="wrap"
                    p={2}
                    md={2}
                  >
                    <Typography sx={{ fontSize: '12px' }}>
                      {item.label}
                    </Typography>
                    <Typography variant="inherit">
                      {currentDriverLicense[item.key]}
                    </Typography>
                  </ViewItem>
                ))}
            </ViewCard>
            <Divider sx={{ mx: 3 }} />
          </Stack>
          <Divider sx={{ my: 2, width: '100%' }} />
          <Stack columnGap={2} flexDirection="row" justifyContent="end" m={3}>
            <Button variant="contained" color="inherit" size="medium">
              Rescan
            </Button>
            <Button
              variant="contained"
              color="inherit"
              size="medium"
              onClick={onGoEdit}
            >
              Edit
            </Button>
            <Button
              onClick={onDelete}
              color="error"
              variant="contained"
              size="medium"
            >
              Delete
            </Button>
          </Stack>
        </>
      )}
    </TabAnimationWrapper>
  );
};
