import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Badge, Button, Divider, Grid } from '@mui/material';

import { CautionApi } from '@/api';
import { AppModal, RenderFormField } from '@/components';
import { WithLoaderProps, withLoader } from '@/hocs';
import { ICaution, ICautionForm, ICautionType } from '@/models';
import { useToastStore } from '@/store';

import { CautionTable } from './components/CautionTable';
import { cautionFormFields } from './data';
import { CautionFormRoot, CautionRoot } from './styles';

interface CautionModalProps extends WithLoaderProps {
  addressId?: string;
}

interface CautionForm extends ICautionForm {
  customCautionType: string;
}

const CautionModal: FC<CautionModalProps> = ({
  addressId,
  showLoader,
  hideLoader,
}) => {
  const { updateToast } = useToastStore();
  const [cautions, setCautions] = useState<ICaution[]>([]);
  const [cautionTypes, setCautionTypes] = useState<ICautionType[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const methods = useForm<CautionForm>({
    mode: 'all',
  });
  const { handleSubmit, getValues, reset, watch } = methods;
  const currentType = watch('type');

  const cautionTypeItems = cautionTypes.map((cautionType) => ({
    label: cautionType.name,
    value: cautionType.name,
  }));

  useEffect(() => {
    fetchData();
  }, [addressId]);

  const fetchData = () => {
    fetchCautions();
    fetchCautionTypes();
  };

  const fetchCautionTypes = async () => {
    try {
      const res = await CautionApi.getCautionTypes();
      setCautionTypes([...res.data, { name: 'Other' }]);
    } catch (err: any) {
      updateToast({ open: err, message: err.message });
    }
  };

  const fetchCautions = async () => {
    try {
      const params = {
        sort: JSON.stringify({ createdAt: -1 }),
        filter: JSON.stringify({ address: addressId }),
      };
      const res = await CautionApi.getCautions(params);
      setCautions(res.data.results);
    } catch (err: any) {
      updateToast({ open: err, message: err.message });
    }
  };

  const onClose = () => {
    setOpenModal(false);
    reset();
  };

  const handleDeleteCaution = async (id?: string) => {
    showLoader();
    try {
      await CautionApi.deleteCaution(id || '');
      const newCautions = cautions
        .slice()
        .filter((caution) => caution._id !== id);
      setCautions(newCautions);
    } catch (err: any) {
      updateToast({ open: err, message: err.message });
    } finally {
      hideLoader();
    }
  };

  const onSubmit = async () => {
    showLoader();
    const values = getValues();
    try {
      await CautionApi.createCaution({
        comment: values.comment,
        type: values.type !== 'Other' ? values.type : values.customCautionType,
        address: addressId || '',
      });
      fetchData();
      (document.activeElement as HTMLElement)?.blur();
      reset();
    } catch (err: any) {
      updateToast({ open: err, message: err.message });
    } finally {
      hideLoader();
    }
  };

  return (
    <CautionRoot>
      <Button color="error" onClick={() => setOpenModal(true)}>
        View cautions
        {!!cautions.length && (
          <Badge badgeContent={cautions.length} sx={{ ml: 2, mr: 1 }} />
        )}
      </Button>
      <AppModal open={openModal} title="View cautions" onClose={onClose}>
        <FormProvider {...methods}>
          <CautionFormRoot onSubmit={handleSubmit(onSubmit)}>
            <Grid columnSpacing={2} flex={1} container>
              {cautionFormFields.map((field) => {
                if (
                  currentType !== 'Other' &&
                  field.name === 'customCautionType'
                )
                  return null;

                return (
                  <Grid
                    key={field.name}
                    xs={
                      currentType === 'Other' && field.name === 'comment'
                        ? 12
                        : field.styles.xs
                    }
                    item
                  >
                    <RenderFormField {...field} items={cautionTypeItems} />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              variant="contained"
              type="submit"
              color="error"
              size="large"
              fullWidth
              sx={{ mt: 2.5, ml: 2, flex: 0 }}
            >
              Add
            </Button>
          </CautionFormRoot>
          <Divider />
          <CautionTable
            cautions={cautions}
            handleDelete={handleDeleteCaution}
          />
        </FormProvider>
      </AppModal>
    </CautionRoot>
  );
};

export default withLoader(CautionModal);
