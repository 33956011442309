import { useEffect, useState } from 'react';

import { IconButton } from '@mui/material';
import { ControlPosition, MapControl, useMap } from '@vis.gl/react-google-maps';

import { IcStreetView } from '@/assets/images';

import { IMarker } from './MapMarker';

export type StreetViewControlProps = {
  position?: ControlPosition;
  marker: IMarker | undefined;
};

export const StreetViewControl = ({
  position = ControlPosition.RIGHT_BOTTOM,
  marker,
}: StreetViewControlProps) => {
  const map = useMap();
  const [isStreetViewAvailable, setIsStreetViewAvailable] = useState(false);

  if (!map) return null;
  const streetView = map.getStreetView();

  useEffect(() => {
    if (!marker) return;

    const streetViewService = new google.maps.StreetViewService();
    streetViewService.getPanorama(
      { location: marker.position, radius: 50 },
      (data, status) => {
        if (data && status === google.maps.StreetViewStatus.OK) {
          setIsStreetViewAvailable(true);
        } else {
          setIsStreetViewAvailable(false);
          streetView.setVisible(false);
        }
      },
    );
    streetView.setPosition({
      lat: marker.position.lat,
      lng: marker.position.lng,
    });
  }, [marker]);

  const toggleStreetView = () => {
    streetView.setVisible(true);
  };

  if (!marker || !isStreetViewAvailable) return null;

  return (
    <MapControl position={position}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <IconButton
          onClick={() => toggleStreetView()}
          sx={{ cursor: 'pointer', py: 0 }}
        >
          <IcStreetView />
        </IconButton>
      </div>
    </MapControl>
  );
};
