import { Stack, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const AuthPageRoot = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0px 30px',
  alignItems: 'center',
  overflow: 'hidden',
  background: theme.palette.background.paper,

  [theme.breakpoints.up('lg')]: {
    padding: '0px 60px',
  },

  [theme.breakpoints.up('xl')]: {
    padding: '0px 100px',
  },
}));

export const PageRoot = styled(Stack)(() => ({
  flex: 1,
  height: '100vh',
  flexDirection: 'column',
  overflow: 'hidden',

  '& .sticky-header-content': {
    flexDirection: 'row',
    position: 'absolute',
    top: '15px',
    right: 20,
  },
}));

export const PageHeaderRoot = styled(Stack)(({ className }) => ({
  width: '100%',
  height: 67,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '15px 0',

  ...(className?.includes('overlayHeader') && {
    borderBottom: '1px solid #E7EAEC',
    padding: '16px 0',
    width: 'auto',
  }),
  borderBottom: `1px solid ${colors.grey[20]}`,

  '& .header-breadcrumb': {
    flexDirection: 'row',
    alignItems: 'center',

    '& .btnBack': {
      marginRight: 10,
    },

    '& .page-title': {
      fontSize: '1.3125rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
  },
}));
