import { useNavigate } from 'react-router-dom';

import { MasterNameKaseOrganizationForm } from '@/layouts/TabLayout/MasterNames/components/MasterNameKaseOrganizationForm';

import MasterIndexOrganizationBase from './MasterIndexOrganizationBase';

const MasterIndexOrganizationForm = () => {
  const navigate = useNavigate();

  return (
    <MasterIndexOrganizationBase>
      <MasterNameKaseOrganizationForm
        onGoBack={() => navigate('/master-index/organizations')}
        hideHeader={true}
        isMasterIndex={true}
      />
    </MasterIndexOrganizationBase>
  );
};

export default MasterIndexOrganizationForm;
