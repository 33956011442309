export default {
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: '0.875rem',
        padding: '0px 5px',
        borderBottomWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        '&.MuiTableCell-head': {
          padding: '12px 5px',
        },
        '&.action-cell': {
          maxWidth: 50,
          minWidth: 50,
          width: '1%',
          zIndex: 100,
        },
      },
    },
  },
};
