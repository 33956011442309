export enum KaseRoleBasedAction {
  OVERVIEW = 'Overview',
  NAMES = 'Names',
  VEHICLES = 'Vehicles',
  CRIMES = 'Crimes',
  NARRATIVE_MAIN = 'NarrativeMain',
  NARRATIVE_PERSONAL = 'NarrativePersonal',
  FILES = 'Files',
  PROPERTIES = 'Properties',
}
