import { styled } from '@mui/material';

export const CautionRoot = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '12px 20px',
}));

export const CautionFormRoot = styled('form')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  overflowY: 'auto',
  paddingBottom: 20,
  paddingTop: 10,
  marginTop: -10,
  '&::-webkit-scrollbar': {
    width: 0,
  },

  [theme.breakpoints.up('md')]: {
    width: 700,
  },

  [theme.breakpoints.up('lg')]: {
    width: 900,
  },
}));
