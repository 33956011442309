import { Stack, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const FormSignatureFieldRoot = styled(Stack)(() => ({
  '& .signature-box': {
    border: `1px solid ${colors.grey[20]}`,
    borderRadius: 6,
  },

  '& .btn-clear': {
    marginTop: 10,
    flex: 0,
  },
}));
