import { useLocation } from 'react-router-dom';

import { SidebarItems1 } from '@/layouts/data';
import { MasterIndexRoutes } from '@/routes/RoutePaths';

const masterIndexSubroutes = (): string[] => {
  return Object.values(MasterIndexRoutes).map(
    (type) => `/master-index/${type}`,
  );
};

const extractLinks = (items: typeof SidebarItems1): string[] => {
  return items.reduce((acc, item) => {
    if (item.link) acc.push(item.link);
    if (item.items) acc.push(...extractLinks(item.items));
    return acc;
  }, [] as string[]);
};

const useShouldHideButton = (): boolean => {
  const location = useLocation();
  const links = extractLinks(SidebarItems1);

  const currentPath = location.pathname;
  const masterIndexRoutes = masterIndexSubroutes();

  const allRoutes = [...links, ...masterIndexRoutes];
  return allRoutes.includes(currentPath);
};

export default useShouldHideButton;
