import { create } from 'zustand';

import { AgencyApi } from '@/api';
import { IAgency } from '@/models';

interface AgencyState {
  agencies: IAgency[];
  currentAgency: IAgency | null;
  fetchAgencies: () => Promise<IAgency[] | undefined>;
  fetchAgency: (id: string) => Promise<IAgency | undefined>;
}

export const useAgencyStore = create<AgencyState>((set) => ({
  agencies: [],
  currentAgency: null,
  fetchAgencies: async () => {
    const res = await AgencyApi.getAgencies();
    set({ agencies: res.data.results });
    return res.data.results;
  },

  fetchAgency: async (id: string) => {
    const res = await AgencyApi.getAgency(id);
    set({ currentAgency: res.data });
    return res.data;
  },
}));
