import { create } from 'zustand';

import { FileToken, StorageApi } from '@/api';

const uploadTokenExpirationDelta = 15 * 60 * 1000; // Give 15 minutes for uploading files by default.

interface FileStoreState {
  fileTokens?: Record<string, FileToken>;
  fetchFileTokens: () => Promise<Record<string, FileToken>>;
  checkUploadTokenExpiration: (tokenKey: string) => boolean;
  checkViewTokenExpiration: (tokenKey: string) => boolean;
  fetching: boolean;
  error?: string;
}

export const useFileStore = create<FileStoreState>((set, get) => ({
  fetching: false,
  checkUploadTokenExpiration: (tokenKey: string) => {
    const fileTokens = get().fileTokens;
    const fileToken = fileTokens ? fileTokens[tokenKey] : undefined;
    if (!fileToken) return true;
    return (
      new Date(fileToken.expiresOn).getTime() <
      new Date().getTime() + uploadTokenExpirationDelta
    );
  },
  checkViewTokenExpiration: (tokenKey: string) => {
    const fileTokens = get().fileTokens;
    const fileToken = fileTokens ? fileTokens[tokenKey] : undefined;
    if (!fileToken) return true;
    return (
      new Date(fileToken.expiresOn).getTime() < new Date().getTime() + 60000
    );
  },
  fetchFileTokens: async () => {
    const { fetching } = get();

    if (!fetching) {
      try {
        set({ fetching: true });
        const res = await StorageApi.getSasTokens();
        const fileTokens = Object.fromEntries(
          res.data.map((token) => [token.folder, token]),
        );
        set({ fetching: false, fileTokens });
        return fileTokens;
      } catch (err: any) {
        set({ fetching: false, error: err.message });
        return Promise.reject(new Error('Failed to fetch file'));
      }
    }

    return {};
  },
}));
