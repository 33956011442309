export interface IKaseReport {
  _id: string;
  type: string;
  createdAt: string;
  createdBy: string;
}

export const KaseReportsTableColumns = [
  { key: 'type', label: 'Type' },
  { key: 'createdAt', label: 'Date' },
  { key: 'createdBy', label: 'Created By' },
];

export const DummyKaseReports: IKaseReport[] = [
  {
    _id: '1',
    type: 'Domestic incident report',
    createdAt: new Date().toLocaleDateString('en-US'),
    createdBy: 'John Doe',
  },
  {
    _id: '2',
    type: 'Use of force',
    createdAt: new Date().toLocaleDateString('en-US'),
    createdBy: 'Jane Doe',
  },
  {
    _id: '3',
    type: 'Arrest Report',
    createdAt: new Date().toLocaleDateString('en-US'),
    createdBy: 'John Doe',
  },
  {
    _id: '4',
    type: 'Domestic incident report',
    createdAt: new Date().toLocaleDateString('en-US'),
    createdBy: 'Jane Doe',
  },
  {
    _id: '5',
    type: 'Arrest Report',
    createdAt: new Date().toLocaleDateString('en-US'),
    createdBy: 'John Doe',
  },
  {
    _id: '6',
    type: 'Use of force',
    createdAt: new Date().toLocaleDateString('en-US'),
    createdBy: 'Jane Doe',
  },
];
