import { Chip as MuiChip, styled } from '@mui/material';

export const Chip = styled(MuiChip)(() => ({
  borderRadius: 6,
  color: 'white',

  '& .MuiChip-label': {
    fontSize: '0.75rem',
    fontWeight: 500,
  },

  '& svg': {
    width: '0.875rem',
    height: '0.875rem',
  },

  '& .MuiChip-deleteIcon': {
    color: 'white',
  },
}));
