import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { AxiosResponse } from 'axios';

import { useCFSContext } from '@/hooks';
import { useKaseContext } from '@/hooks/useKaseContext';
import {
  KaseMasterNameInvolvement,
  CFSMasterNameInvolvement,
  IKaseInvolvement,
  IKaseMasterName,
  ICFSMasterName,
  ICFSInvolvement,
} from '@/models';
import { IMasterName, IMasterNamePerson } from '@/models/master.names';
import { INote } from '@/models/note';

export interface TabItem {
  path: string;
  label: string;
}

interface MasterNamesContextProviderProps extends PropsWithChildren {
  nextRoutes: string[];
  handleSubmit: (data: IMasterName) => void;
  handleSearchCase?: () => void;
  handleSearchOwners?: () => void;
  involvementOptions:
    | typeof KaseMasterNameInvolvement
    | typeof CFSMasterNameInvolvement;
  addLinkedName?: (
    nameId: string,
    data?: { relationship: ICFSInvolvement },
  ) => Promise<AxiosResponse<ICFSMasterName | IKaseMasterName>>;
  updateLinkedName?: (
    nameId: string,
    data: { relationship: ICFSInvolvement | IKaseInvolvement },
  ) => Promise<AxiosResponse<ICFSMasterName | IKaseMasterName>>;

  updateMasterName?: (
    nameId: string,
    data: any,
  ) => Promise<AxiosResponse<IMasterName>>;

  getLinkedMasterName?: (
    nameId: string,
  ) => Promise<AxiosResponse<ICFSMasterName | IKaseMasterName>>;
  getLinkedMasterNames?: () => Promise<
    AxiosResponse<(ICFSMasterName | IKaseMasterName)[]>
  >;
  saveNote?: (
    nameId: string,
    description: string,
    noteId?: string,
  ) => Promise<AxiosResponse<INote | undefined, any>>;
  deleteLinkedName?: (nameId: string) => Promise<AxiosResponse>;
  getMasterName?: (nameId: string) => Promise<AxiosResponse<IMasterName>>;
}

interface MasterNamesContextValues
  extends Omit<MasterNamesContextProviderProps, 'children'> {
  linkedMasterNames: (ICFSMasterName | IKaseMasterName)[];
  updateLinkedMasterNames: (
    newNames: (ICFSMasterName | IKaseMasterName)[],
  ) => void;
  currentMastername?: ICFSMasterName | IKaseMasterName;
  currentDriverLicenseId?: string;
  setCurrentMastername: (
    masterName: ICFSMasterName | IKaseMasterName | undefined,
  ) => void;
  setCurrentDriverLicenseId: (licenseId: string) => void;
  handleDeleteLinkedName: (nameId: string) => Promise<void>;
  handleAddLinkedName: (
    nameId: string,
    data?: {
      relationship: ICFSInvolvement;
    },
  ) => Promise<void>;
  handleUpdateMasterName: (
    nameId: string,
    data: {
      firstName: string;
      lastName: string;
      driverLicense: string;
    },
  ) => Promise<AxiosResponse<IMasterName, any> | undefined>;
  handleUpdateLinkedName: (
    nameId: string,
    data: {
      relationship: ICFSInvolvement | IKaseInvolvement;
    },
  ) => Promise<void>;
  handleSaveNote: (
    nameId: string,
    description: string,
    noteId?: string,
  ) => Promise<AxiosResponse<INote | undefined, any> | undefined>;
  handleGetLinkedMasterName: (
    nameId: string,
  ) => Promise<ICFSMasterName | IKaseMasterName | undefined>;
  getLinkedMasterNames?: () => Promise<
    AxiosResponse<(ICFSMasterName | IKaseMasterName)[]>
  >;
}

const MasterNamesContext = createContext<MasterNamesContextValues | undefined>(
  undefined,
);

export function useMasterNamesContext() {
  const context = useContext(MasterNamesContext);
  if (!context) {
    throw new Error(
      'useMasterNamesContext must be used within a MasterNamesContextProvider',
    );
  }
  return context;
}

export function MasterNamesContextProvider({
  children,
  addLinkedName,
  updateLinkedName,
  updateMasterName,
  saveNote,
  deleteLinkedName,
  getLinkedMasterName,
  getLinkedMasterNames,
  involvementOptions,
  getMasterName,
  ...rest
}: MasterNamesContextProviderProps) {
  const [currentMastername, setCurrentMastername] = useState<
    (ICFSMasterName | IKaseMasterName) | undefined
  >();
  const [linkedMasterNames, setLinkedMasterNames] = useState<
    (ICFSMasterName | IKaseMasterName)[]
  >([]);
  const [currentDriverLicenseId, setCurrentDriverLicenseId] =
    useState<string>();
  const { cfs, updateCFS } = useCFSContext();
  const { kase, updateKase } = useKaseContext();

  const handleAddLinkedName = async (
    id: string,
    data?: { relationship: ICFSInvolvement },
  ) => {
    if (addLinkedName) {
      const res = await addLinkedName(id, data);
      setLinkedMasterNames((v) => [...v, res.data]);
      if (
        data?.relationship.involvement?.includes(
          CFSMasterNameInvolvement.PRIMARY_REPORTER,
        )
      ) {
        if (cfs) {
          updateCFS({
            ...cfs,
            primaryReporter: res.data.masterName as IMasterNamePerson,
          });
        }
        if (kase) {
          updateKase({ ...kase });
        }
      }
    }
  };

  const handleUpdateLinkedName = async (
    id: string,
    data: { relationship: ICFSInvolvement | IKaseInvolvement },
  ) => {
    if (updateLinkedName) {
      const res = await updateLinkedName(id, data);
      if (res) {
        const updateLinkNames = linkedMasterNames?.map((item) => {
          if (item.masterName?._id === id) {
            return { ...item, relationship: data.relationship };
          }
          return item;
        });
        if (!updateLinkNames) {
          return;
        }
        setLinkedMasterNames(updateLinkNames as ICFSMasterName[]);
        if (
          cfs &&
          data.relationship.involvement?.includes(
            CFSMasterNameInvolvement.PRIMARY_REPORTER,
          )
        ) {
          updateCFS({
            ...cfs,
            primaryReporter: res.data.masterName as IMasterNamePerson,
          });
        } else if (
          cfs?.primaryReporter?._id === id &&
          !data.relationship.involvement?.includes(
            CFSMasterNameInvolvement.PRIMARY_REPORTER,
          )
        ) {
          updateCFS({ ...cfs, primaryReporter: undefined });
        }
      }
    }
  };

  const handleUpdateMasterName = async (id: string, data: any) => {
    if (updateMasterName) {
      const res = await updateMasterName(id, data);
      if (res && getMasterName) {
        const final = await getMasterName(id);
        if (final) {
          setCurrentMastername((prev) => {
            if (!prev) return undefined;
            return {
              ...prev,
              masterName: final.data as IMasterNamePerson,
            };
          });
        }

        return final;
      }
    }
  };
  const handleSaveNote = async (
    nameId: string,
    description: string,
    noteId?: string,
  ) => (saveNote ? saveNote(nameId, description, noteId) : undefined);

  const handleDeleteLinkedName = async (nameId: string) => {
    if (deleteLinkedName) {
      await deleteLinkedName(nameId);
      const updatedLinkedNames = linkedMasterNames.filter(
        ({ masterName }) => masterName?._id !== nameId,
      );
      setLinkedMasterNames(updatedLinkedNames);
      if (cfs?.primaryReporter?._id === nameId) {
        updateCFS({ ...cfs, primaryReporter: undefined });
      }
    }
  };

  const handleGetLinkedMasterName = async (nameId: string) => {
    if (getLinkedMasterName) {
      const res = await getLinkedMasterName(nameId);
      return res ? res.data : undefined;
    }
  };

  return (
    <MasterNamesContext.Provider
      value={{
        linkedMasterNames,
        updateLinkedMasterNames: setLinkedMasterNames,
        currentMastername,
        setCurrentMastername,
        currentDriverLicenseId,
        setCurrentDriverLicenseId,
        handleAddLinkedName,
        handleSaveNote,
        handleUpdateLinkedName,
        handleUpdateMasterName,
        handleDeleteLinkedName,
        handleGetLinkedMasterName,
        getLinkedMasterNames,
        involvementOptions,
        ...rest,
      }}
    >
      {children}
    </MasterNamesContext.Provider>
  );
}
