import { useEffect, useState } from 'react';

import { Button, Divider, Menu, MenuItem, Stack } from '@mui/material';

import { IcArrowDown, IcArrowUp } from '@/assets/images';
import { MenuState } from '@/pages/CFS/Records/types/types';

import KaseLogsTable from './KaseReportsTable';
import { SearchBar } from '../../../../components/SearchBar';
import { TabAnimationWrapper } from '../../../../layouts/TabLayout';
import { DummyKaseReports, IKaseReport } from '../data';

const KaseReportsList = () => {
  const [kaseReports, setKaseReports] = useState<IKaseReport[]>([]);

  const [menuState, setMenuState] = useState<MenuState>({
    menuEl: null,
  });

  const { menuEl } = menuState;
  const openMenu = Boolean(menuEl);

  const handleMenuClose = () => {
    setMenuState({ menuEl: null });
  };

  useEffect(() => {
    fetchKaseReports();
  }, []);

  const fetchKaseReports = async () => {
    setTimeout(() => {
      const res = DummyKaseReports;
      setKaseReports(res);
    }, 1000);
  };

  const handleSearch = (value: string) => {
    const res = DummyKaseReports.filter((report) => {
      return (
        report.type.toLowerCase().includes(value.toLowerCase()) ||
        report.createdBy.toLowerCase().includes(value.toLowerCase())
      );
    });
    setKaseReports(res);
  };

  return (
    <TabAnimationWrapper>
      <Stack flexDirection="row" justifyContent="space-between">
        <SearchBar
          placeholder="Search..."
          className="filter"
          sx={{ width: 500, maxWidth: 500, mt: 2 }}
          onChange={handleSearch}
        />
        <Button
          onClick={(event) => setMenuState({ menuEl: event.currentTarget })}
          variant="contained"
          color="error"
          sx={{ whiteSpace: 'nowrap', mt: 2 }}
        >
          Add new
          {openMenu ? (
            <IcArrowUp style={{ marginLeft: 10 }} />
          ) : (
            <IcArrowDown style={{ marginLeft: 10 }} />
          )}
        </Button>
      </Stack>
      <Divider sx={{ mt: 2 }} />

      <KaseLogsTable rows={kaseReports} />
      <Menu
        id="basic-menu"
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleMenuClose}>Domestic incident report</MenuItem>
        <MenuItem onClick={handleMenuClose}>Arrest Report</MenuItem>
        <MenuItem onClick={handleMenuClose}>Use of force</MenuItem>
      </Menu>
    </TabAnimationWrapper>
  );
};

export default KaseReportsList;
