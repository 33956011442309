import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Page, PageHeader } from '@/components';
import NavigationButtonGroup from '@/components/NavigationButtonGroup/NavigationButtonGroup';
import { MasterIndexTabRoutes } from '@/routes/RoutePaths';
import { Button, Stack } from '@mui/material';

// Define the types for the MasterIndexDetailsPageProps here
export type MasterIndexType =
  | 'persons'
  | 'organizations'
  | 'vehicles'
  | 'address';
export type MasterIndexAction = 'new' | 'edit' | 'view';

type MasterIndexDetailsPageProps = {
  action: MasterIndexAction;
  masterIndexType: MasterIndexType;
};

// setup tabs for each master index type, return empty array if details page has no tabs
const getTabs = (
  type: MasterIndexType,
  action: MasterIndexAction,
): { label: string; path: string; disabled?: boolean }[] => {
  switch (type) {
    case 'persons':
      return [
        {
          label: 'Personal information',
          path: MasterIndexTabRoutes.Information,
        },
        {
          label: 'Related records',
          path: MasterIndexTabRoutes.Related,
          disabled: action === 'new',
        },
        {
          label: 'Notes',
          path: MasterIndexTabRoutes.Notes,
          disabled: action === 'new',
        },
      ];
    case 'organizations':
      return [
        {
          label: 'Organization information',
          path: MasterIndexTabRoutes.Information,
        },
        {
          label: 'Related records',
          path: MasterIndexTabRoutes.Related,
          disabled: action === 'new',
        },
        {
          label: 'Notes',
          path: MasterIndexTabRoutes.Notes,
          disabled: action === 'new',
        },
      ];
    case 'vehicles':
      return [
        {
          label: 'Vehicle information',
          path: MasterIndexTabRoutes.Information,
        },
        {
          label: 'Related records',
          path: MasterIndexTabRoutes.Related,
          disabled: action === 'new',
        },
      ];
    case 'address': {
      const commonTabs = [
        {
          label: 'Address information',
          path: MasterIndexTabRoutes.Information,
        },
        {
          label: 'Related records',
          path: MasterIndexTabRoutes.Related,
          disabled: action === 'new',
        },
      ];

      if (action === 'view') {
        commonTabs.push({
          label: 'Cautions',
          path: MasterIndexTabRoutes.Cautions,
        });
      }

      return commonTabs;
    }
    default:
      return [];
  }
};
const getBackRoute = (type: MasterIndexType): string => {
  return `/master-index/${type}`;
};

const getTitle = (type: MasterIndexType, action: MasterIndexAction): string => {
  if (type === 'address') {
    if (action === 'new') return 'Add Address';
    if (action === 'view') return 'View Address';
    if (action === 'edit') return 'Edit Address';
    return '';
  }

  switch (action) {
    case 'new':
      return `New ${type.slice(0, -1)}`;
    case 'edit':
      return `Edit ${type.slice(0, -1)}`;
    case 'view':
      return `View ${type.slice(0, -1)}`;
    default:
      return '';
  }
};
export const MasterIndexDetailsPage = ({
  action,
  masterIndexType,
}: MasterIndexDetailsPageProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const paths = pathname.split('/');

  const activeTab = paths[paths.length - 1];
  const tabs = getTabs(masterIndexType, action);
  const title = getTitle(masterIndexType, action);
  const backRoute = getBackRoute(masterIndexType);

  const handleCancel = () => {
    navigate(backRoute);
  };

  const renderActionButtons = () => {
    if (action === 'view') return null;
    
    return (
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="inherit"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          form="master-index-form"
          type="submit"
        >
          Save
        </Button>
      </Stack>
    );
  };

  return (
    <Page title={title}>
      <PageHeader 
        title={title} 
        backRoute={backRoute}
        action={renderActionButtons()}
      />
      {tabs && <NavigationButtonGroup tabs={tabs} activeTab={activeTab} />}
      <Outlet />
    </Page>
  );
};
