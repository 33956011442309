export const flattenObject = (
  obj?: Record<string, any>,
  parentKey?: string,
) => {
  let result: Record<string, any> = {};

  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      const value = obj[key];
      const _key = parentKey ? `${parentKey}.${key}` : key;
      if (typeof value === 'object' && !Array.isArray(value)) {
        result = { ...result, ...flattenObject(value, _key) };
      } else {
        result[_key] = value;
      }
    });
  }

  return result;
};

export const compositeObject = (key: string, value: any) => {
  let obj: any = value;
  const keyArr = key.split('.');
  for (let i = keyArr.length - 1; i > -1; i--) {
    if (!keyArr[i]) continue;
    obj = { [keyArr[i]]: obj };
  }
  return obj;
};

export const getItemValueFromObject: any = (
  obj: Record<string, any>,
  key?: string,
) => {
  if (!key) return obj;
  let itemValue = { ...obj };
  const keyArr = key.split('.');
  keyArr.forEach((_key) => {
    if (_key) {
      itemValue = itemValue ? itemValue[_key] : undefined;
    }
  });
  return itemValue;
};

type FlattenedObject = { [key: string]: any };
type NestedObject = { [key: string]: any };

function setNestedValue(obj: NestedObject, path: string, value: any): void {
  const keys = path.split('.');
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key]) current[key] = {};
    current = current[key];
  }

  current[keys[keys.length - 1]] = value;
}

export function deflatten<T extends FlattenedObject>(
  obj: T,
  keyToDeflatten: string,
): T {
  const deflattened: NestedObject = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (key.startsWith(`${keyToDeflatten}.`)) {
        const subKey = key.slice(keyToDeflatten.length + 1); // Remove the parent key and dot
        if (subKey) {
          setNestedValue(deflattened, subKey, obj[key]);
        } else {
          deflattened[keyToDeflatten] = obj[key];
        }
      } else {
        deflattened[key] = obj[key];
      }
    }
  }

  return deflattened as T;
}
