import { FC, useEffect, useState } from 'react';

import { Button, Stack, styled } from '@mui/material';
import { useChannel } from 'ably/react';

import { UnitApi } from '@/api';
import { withLoader } from '@/hocs';
import { useCFSContext, useUnitListFilter } from '@/hooks';
import { IUnit } from '@/models';
import { useToastStore, useUserStore } from '@/store';

import { UnitItem } from './UnitItem';
import {
  default as UnitShiftAssignModal,
  UnitShiftAssignModalProps,
} from './UnitShiftAssignModal';
import { CommonUnitsProps } from './UnitShiftList';

const Root = styled(Stack)(() => ({
  flex: 1,
  padding: 15,
  overflow: 'auto',
}));

const OffDutyUnits: FC<CommonUnitsProps> = ({ filterValues, searchText }) => {
  const { updateToast } = useToastStore();
  const { cfs } = useCFSContext();
  const { account } = useUserStore();

  const [resourceModal, setResourceModal] = useState<
    Omit<UnitShiftAssignModalProps, 'onClose' | 'showLoader' | 'hideLoader'>
  >({
    open: false,
  });
  const [offDutyUnits, setOffDutyUnits] = useState<IUnit[]>([]);

  const offDutyUnitsData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: offDutyUnits,
    searchText,
  }) as IUnit[];

  useChannel(`account:${account?._id}:unit`, () => {
    fetchOffDutyUnits();
  });

  useEffect(() => {
    fetchOffDutyUnits();
  }, []);

  const fetchOffDutyUnits = async () => {
    try {
      const filter = JSON.stringify({
        status: 'OFF_DUTY',
        isStandByWhenOffDuty: false,
      });
      const res = await UnitApi.getUnits({ filter, limit: 1000 });
      setOffDutyUnits(res.data.results);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const onOpenModal = (unit: IUnit) => {
    setResourceModal({
      open: true,
      unit,
    });
  };

  const onCloseModal = () => {
    setResourceModal({
      open: false,
    });
  };

  return (
    <Root flex={1}>
      {offDutyUnitsData.map((unit, index) => (
        <UnitItem key={index} {...unit}>
          <Button
            color="error"
            variant="contained"
            size="large"
            onClick={() => onOpenModal(unit)}
          >
            On-duty
          </Button>
        </UnitItem>
      ))}
      <UnitShiftAssignModal
        showScheduleOption
        {...resourceModal}
        onClose={onCloseModal}
      />
    </Root>
  );
};

export default withLoader(OffDutyUnits);
