import { FC, ReactElement, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthContext } from '@/hooks';
import { AuthRoutes } from '@/routes/RoutePaths';
import { getAllowedSidebarItems, validateRoute } from '@/services';
import { useUserStore, useAgencyStore } from '@/store';

interface RouteGuardProps {
  children?: ReactElement | null;
}

export const GuestGuard: FC<RouteGuardProps> = ({ children }) => {
  const { accessToken } = useAuthContext();
  const { state, pathname } = useLocation();

  let nextPath: string =
    state?.path == null || state.path === '/auth/login' ? '/' : state.path;

  if (accessToken && pathname !== AuthRoutes.ForgotPassword) {
    if (state?.search) {
      nextPath += state.search;
    }
    return <Navigate to={nextPath} />;
  }

  return <>{children}</>;
};

export const DemoGuard: FC<RouteGuardProps> = ({ children }) => {
  const { account } = useUserStore();
  if (!account?.settings.demoMode) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export const AuthGuard: FC<RouteGuardProps> = ({ children }) => {
  const { accessToken } = useAuthContext();
  const { account, user } = useUserStore();
  const { agencies } = useAgencyStore();
  const { pathname, search } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>();

  if (!accessToken) {
    return (
      <Navigate
        to="/auth/login"
        replace
        state={{
          path: pathname,
          search,
        }}
      />
    );
  }

  if (!account?._id || !user || !agencies.length) {
    return null;
  }
  if (!validateRoute(pathname, user, account)) {
    const initialItem = getAllowedSidebarItems(user, account)[0];
    return <Navigate to={initialItem?.link || '404'} />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};
