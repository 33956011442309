import { PaletteMode } from '@mui/material';

import { colors } from './variables';
// define custom colors: https://material-ui.com/customization/palette/
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    sideBar: Palette['primary'];
  }
  interface PaletteOptions {
    sideBar: PaletteOptions['primary'];
  }
}

export const getDesignTokens = (mode: PaletteMode) => ({
  mode,
  ...(mode === 'light'
    ? {
        ...colors,
        background: {
          paper: '#FFFFFF',
          default: '#F5F7F7',
        },
        text: {
          primary: colors.grey[100],
          secondary: colors.grey[80],
        },
        sideBar: {
          main: '#0E3145',
        },
      }
    : {
        ...colors,
        background: {
          default: '#0B1922',
          paper: '#17252D',
        },
        text: {
          primary: colors.white,
          secondary: colors.grey[40],
        },
        sideBar: {
          main: '#17252d',
        },
      }),
});
