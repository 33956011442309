import { styled, Stack, Chip, Avatar } from '@mui/material';

import { colors } from '@/theme/variables';

export const BorderedStack = styled(Stack)(({ theme }) => ({
  border: '1px solid #E7EAEC',
  borderRadius: theme.spacing(),
  padding: '4px 12px',
  marginBottom: 10,
  justifyContent: 'space-between',
  flexFlow: 'row',
  alignItems: 'center',
  '& .MuiButton-text': {
    textDecoration: 'underline',
  },
  '& .MuiButtonBase-root': {
    color: colors.error.main,
  },
}));

export const KaseStatusChip = styled(Chip)(({ theme }) => ({
  borderRadius: '6px',
  background: theme.palette.background.default,
  height: 'auto',
  fontWeight: 500,
  marginRight: '6px',
}));

export const UserAvatar = styled(Avatar)(() => ({
  width: 85,
  height: 85,
  border: '5px solid white',
  outline: `1px solid ${colors.grey[10]}`,
  background: colors.grey[10],
  borderRadius: '100%',
  margin: 20,
}));
