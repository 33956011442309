import { FC, MouseEvent, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Button, Divider, Menu, Stack, styled } from '@mui/material';

import { TActiveCFSFilter } from '@/api';
import { IcFilter } from '@/assets/images';
import {
  NarrowButton,
  RenderFormField,
  ActiveFilterIndicator,
} from '@/components';

import { filterMenuFormFields } from '../data';

interface IFilterMenuProps {
  onChangeFilter: (value: TActiveCFSFilter) => void;
}

const FormRoot = styled('form')(() => ({
  padding: 16,
  '& .divider': {
    marginBottom: 16,
  },
}));

const initialFilterValue = {
  severity: '',
  assignmentStatus: '',
  readBy: false,
};

export const FilterMenu: FC<IFilterMenuProps> = ({ onChangeFilter }) => {
  const methods = useForm<TActiveCFSFilter>({
    defaultValues: initialFilterValue as TActiveCFSFilter,
    mode: 'all',
  });

  const { handleSubmit, reset } = methods;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFilterDot, setShowFilterDot] = useState(false);

  const openActionMenu = Boolean(anchorEl);

  const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onCancel = () => {
    handleCloseMenu();
    reset();
  };

  const onClear = () => {
    reset();
    onChangeFilter({});
    setShowFilterDot(false);
  };

  const onSubmit: SubmitHandler<TActiveCFSFilter> = (data) => {
    onChangeFilter(data);

    const isFilterDirty = Object.values(data).find((value) => !!value);
    if (isFilterDirty) {
      setShowFilterDot(true);
    }
    handleCloseMenu();
  };

  return (
    <>
      <NarrowButton
        variant="contained"
        color="inherit"
        onClick={handleOpenMenu}
        sx={{ position: 'relative' }}
      >
        <IcFilter />
        {showFilterDot && <ActiveFilterIndicator />}
      </NarrowButton>

      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={openActionMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <FormProvider {...methods}>
          <FormRoot onSubmit={handleSubmit(onSubmit)}>
            {filterMenuFormFields.map((field) => (
              <RenderFormField key={field.name} {...field} />
            ))}

            <Divider className="divider" />

            <Stack flexDirection="row" columnGap={2}>
              <Button
                type="button"
                variant="contained"
                color="inherit"
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button
                type="button"
                variant="text"
                color="inherit"
                onClick={onClear}
              >
                Clear
              </Button>

              <Button type="submit" variant="contained" color="error">
                Apply
              </Button>
            </Stack>
          </FormRoot>
        </FormProvider>
      </Menu>
    </>
  );
};
