import { FC, useEffect, useState, useRef } from 'react';

import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  StackProps,
  Tab,
  Tabs,
  Typography,
  Paper,
  MenuItem,
} from '@mui/material';

import { CfsApi, MapLayerApi, UnitApi } from '@/api';
import { IcRefresh } from '@/assets/images';
import { EditableRequiredUnits, SearchBar } from '@/components';
import { LocalStorageItems } from '@/constants';
import { WithLoaderProps } from '@/hocs';
import { useCFSContext, useUnitShiftContext } from '@/hooks';
import {
  IUnitShift,
  UnitShiftStatus,
  IAssignedUnitShift,
  AssignedUnitShiftStatus,
  IUnit,
  PolygonLayerType,
  IUser,
} from '@/models';
import { ConfirmTypes, useDataStore, useToastStore } from '@/store';

import AssignedUnits from './AssignedUnits';
import AvailableUnits from './AvailableUnits';
import OffDutyUnits from './OffDutyUnits';
import { TabPanel } from './TabPanel';
import { UnitFilter } from './UnitFilter';
import { FilterValues } from './UnitFilterForm';
import { UnitShiftListRoot } from '../styles';

interface UnitShiftListProps extends StackProps {
  cfsId?: string;
  fetchActiveCFSList?: () => void;
  isUnitShiftsPage?: boolean;
}

export interface CommonUnitsProps extends WithLoaderProps {
  cfsId?: string;
  filterValues: FilterValues;
  searchText?: string;
  handleAssignUnit?: () => void;
}

interface AssignedUnitWithCFS {
  unit: IUnit;
  assignments: Array<{
    cfsId: string;
    cfsNumber: string;
  }>;
}

interface GroupedSearchResults {
  available: {
    units: IUnitShift[];
    count: number;
  };
  scheduled: {
    units: IUnitShift[];
    count: number;
  };
  assigned: {
    units: AssignedUnitWithCFS[];
    count: number;
  };
  offDuty: {
    units: IUnit[];
    count: number;
  };
  standby: {
    units: IUnit[];
    count: number;
  };
}

// Helper function to check if a unit is currently assigned
const isCurrentlyAssigned = (assigned: IAssignedUnitShift) => {
  return (
    !assigned.unAssignedAt &&
    assigned.status !== AssignedUnitShiftStatus.CANCELED &&
    assigned.status !== AssignedUnitShiftStatus.COMPLETED
  );
};

export const UnitShiftList: FC<UnitShiftListProps> = ({
  cfsId,
  fetchActiveCFSList,
  isUnitShiftsPage,
  ...rest
}) => {
  const { cfs, handleRefresh } = useCFSContext();
  const { filterValues } = useUnitShiftContext();
  const { updateData } = useDataStore();
  const { updateToast } = useToastStore();
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const [currentTab, setCurrentTab] = useState<number>(
    Number(localStorage.getItem(LocalStorageItems.UnitShiftListCurrentTab)) ??
      1,
  );
  const [shouldCancel, setShouldCancel] = useState<string>('');
  const [searchUnitText, setSearchUnitText] = useState<string>('');
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [offDutyUnits, setOffDutyUnits] = useState<IUnit[]>([]);
  const [groupedResults, setGroupedResults] = useState<GroupedSearchResults>({
    available: { units: [], count: 0 },
    assigned: { units: [], count: 0 },
    offDuty: { units: [], count: 0 },
    standby: { units: [], count: 0 },
    scheduled: { units: [], count: 0 },
  });

  const {
    onDutyUnitShifts,
    scheduledUnitShifts,
    assignedUnitShifts,
    setSearchText,
  } = useUnitShiftContext();

  const cfsBeatName = cfs?.addressInfo?.additionalInfo?.beat?.name;

  const handleAgencyId = () => {
    const params = new URLSearchParams(window.location.search);
    const agencyId = params.get('agencyId');
    
    if (agencyId) {
      localStorage.setItem(LocalStorageItems.CurrentAgencyId, agencyId);
    } else {
      localStorage.setItem(LocalStorageItems.CurrentAgencyId, '');
    }
  };

  const fetchOffDutyUnits = async () => {
    try {
      const filter = JSON.stringify({
        status: 'OFF_DUTY',
      });
      const res = await UnitApi.getUnits({ filter, limit: 1000 });

      setOffDutyUnits(res.data.results);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  useEffect(() => {
    handleAgencyId();
    fetchBeatPolygonLayers();
    fetchOffDutyUnits();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target as Node)
      ) {
        setShowSearchResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = (searchValue: string) => {
    setSearchUnitText(searchValue);
    setSearchText(searchValue);

    if (!searchValue.trim()) {
      setShowSearchResults(false);
      return;
    }

    const searchTerm = searchValue.toLowerCase();

    const searchUnitShifts = (unit: IUnit) => {
      return (
        unit.name?.toLowerCase().includes(searchTerm) ||
        unit.callSign?.toLowerCase().includes(searchTerm) ||
        unit.users?.some((u) =>
          (u as IUser)?.profile?.employmentInformation?.badgeNumber?.includes(
            searchTerm,
          ),
        )
      );
    };

    // First get all currently assigned unit IDs
    const currentlyAssignedUnitIds = new Set(
      assignedUnitShifts
        .filter(isCurrentlyAssigned)
        .map((assigned) => assigned.unitShift.unit._id),
    );

    // Filter available units (excluding assigned ones)
    const availableResults = onDutyUnitShifts.filter(
      (unit) =>
        searchUnitShifts(unit.unit) &&
        !currentlyAssignedUnitIds.has(unit.unit._id) &&
        unit.status !== UnitShiftStatus.CLOSED,
    );

    const scheduledResults = scheduledUnitShifts.filter(
      (unit) =>
        searchUnitShifts(unit.unit) &&
        !currentlyAssignedUnitIds.has(unit.unit._id) &&
        unit.status !== UnitShiftStatus.CLOSED,
    );

    // Get assigned units
    const assignedUnitsMap = new Map();
    assignedUnitShifts.filter(isCurrentlyAssigned).forEach((assigned) => {
      const unit = assigned.unitShift.unit;
      if (searchUnitShifts(unit)) {
        assignedUnitsMap.set(unit._id, {
          unit,
          assignments: [
            {
              cfsId,
              cfsNumber: assigned.cfsNumber,
            },
          ],
        });
      }
    });

    const assignedResults = Array.from(assignedUnitsMap.values());

    // Filter standby units first
    const standbyResults = offDutyUnits.filter(
      (unit) => searchUnitShifts(unit) && unit.isStandByWhenOffDuty === true,
    );

    // Then filter off-duty units, excluding standby ones
    const offDutyResults = !cfsId 
    ? offDutyUnits.filter(
        (unit) => searchUnitShifts(unit) && unit.isStandByWhenOffDuty !== true,
      )
    : [];

    setGroupedResults({
      available: {
        units: availableResults,
        count: availableResults.length,
      },
      scheduled: {
        units: scheduledResults,
        count: scheduledResults.length,
      },
      assigned: {
        units: assignedResults,
        count: assignedResults.length,
      },
      offDuty: {
        units: offDutyResults,
        count: offDutyResults.length,
      },
      standby: {
        units: standbyResults,
        count: standbyResults.length,
      },
    });

    setShowSearchResults(true);
  };

  const handleResultClick = (unit: IUnit, section: string) => {
    setSearchUnitText(unit.name || unit.callSign || '');
    switch (section) {
      case 'available':
        handleTabChange(0);
        break;
      case 'standby':
        handleTabChange(0);
        break;
      case 'assigned':
        handleTabChange(1);
        break;
      case 'offDuty':
        handleTabChange(2);
        break;
    }
    setShowSearchResults(false);
  };

  const fetchBeatPolygonLayers = () => {
    const beatParam = { type: PolygonLayerType.BEAT };
    MapLayerApi.getPolygonLayers({
      filter: JSON.stringify(beatParam),
      limit: 1000,
    })
      .then(({ data: { results } }) => {
        updateData({ beats: results });
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  const handleTabChange = (tab: number) => {
    localStorage.setItem(
      LocalStorageItems.UnitShiftListCurrentTab,
      tab.toString(),
    );
    setCurrentTab(tab);
  };

  const handleRefreshUnitShiftDistances = () => {
    CfsApi.refreshUnitShiftDistances(cfsId as string)
      .then(() => {
        updateToast({
          open: true,
          message: 'Refreshed successfully',
          type: ConfirmTypes.SUCCESS,
        });
      })
      .catch((err: any) => {
        updateToast({
          open: true,
          message: err.message,
          type: ConfirmTypes.WARNING,
        });
      });
    handleRefresh();
  };

  const handleAssignUnit = () => {
    setSearchUnitText('');
    setShouldCancel(Date.now().toString());
  };

  return (
    <UnitShiftListRoot {...rest} className={cfsId && 'hasCfsId'}>
      {!!cfsId && (
        <Stack className="header">
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {cfsBeatName && (
              <Chip label={cfsBeatName} className="sector-chip" />
            )}
            <div
              style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}
            >
              <EditableRequiredUnits />
            </div>
          </Stack>
          <Divider sx={{ mt: 2 }} />
        </Stack>
      )}

      <Box className="tab-box">
        <Stack
          sx={{ gap: '15px', width: '100%' }}
          flexDirection="row"
          margin="8px 0px"
        >
          <div style={{ position: 'relative', flex: 1 }}>
            <SearchBar
              onChange={handleSearch}
              shouldCancel={shouldCancel}
              sx={{
                marginRight: '0',
                flex: 1,
                maxWidth: '100%',
              }}
              placeholder="Search units..."
            />

            {showSearchResults && (
              <Paper
                ref={searchResultsRef}
                sx={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                  mt: 1,
                  maxHeight: 400,
                  overflow: 'auto',
                }}
              >
                {groupedResults.available.count > 0 && (
                  <div>
                    <Typography color="error" sx={{ px: 2, py: 1 }}>
                      Available ({groupedResults.available.count})
                    </Typography>
                    {groupedResults.available.units.map((unit) => (
                      <MenuItem
                        key={unit._id}
                        onClick={() =>
                          handleResultClick(unit.unit, 'available')
                        }
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        {unit.unit.name}
                      </MenuItem>
                    ))}
                  </div>
                )}
                {groupedResults.scheduled.count > 0 && (
                  <div>
                    <Typography color="error" sx={{ px: 2, py: 1 }}>
                      Available ({groupedResults.scheduled.count})
                    </Typography>
                    {groupedResults.scheduled.units.map((unit) => (
                      <MenuItem
                        key={unit._id}
                        onClick={() =>
                          handleResultClick(unit.unit, 'available')
                        }
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        {unit.unit.name}
                      </MenuItem>
                    ))}
                  </div>
                )}

                {groupedResults.assigned.count > 0 && (
                  <div>
                    <Typography color="error" sx={{ px: 2, py: 1 }}>
                      Assigned ({groupedResults.assigned.count})
                    </Typography>
                    {groupedResults.assigned.units.map((unitData) => (
                      <MenuItem
                        key={unitData.unit._id}
                        onClick={() =>
                          handleResultClick(unitData.unit, 'assigned')
                        }
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Typography>{unitData.unit.name}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          Assigned to:{' '}
                          {unitData.assignments
                            .map((a) => a.cfsNumber)
                            .join(', ')}
                        </Typography>
                      </MenuItem>
                    ))}
                  </div>
                )}

                {groupedResults.standby.count > 0 && (
                  <div>
                    <Typography color="error" sx={{ px: 2, py: 1 }}>
                      Standby ({groupedResults.standby.count})
                    </Typography>
                    {groupedResults.standby.units.map((unit) => (
                      <MenuItem
                        key={unit._id}
                        onClick={() => handleResultClick(unit, 'standby')}
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        {unit.name}
                      </MenuItem>
                    ))}
                  </div>
                )}

                {!cfsId && groupedResults.offDuty.count > 0 && (
                  <div>
                      <Typography color="error" sx={{ px: 2, py: 1 }}>
                        Off Duty ({groupedResults.offDuty.count})
                      </Typography>
                    {groupedResults.offDuty.units.map((unit) => (
                      <MenuItem
                        key={unit._id}
                        onClick={() => handleResultClick(unit, 'offDuty')}
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        {unit.name}
                      </MenuItem>
                    ))}
                  </div>
                )}

                {!groupedResults.available.count &&
                  !groupedResults.assigned.count &&
                  !groupedResults.standby.count &&
                  !groupedResults.offDuty.count && (
                    <Typography
                      sx={{
                        p: 2,
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      No units found
                    </Typography>
                  )}
              </Paper>
            )}
          </div>
          <UnitFilter />
        </Stack>
      </Box>

      <Box className="tab-box">
        <Tabs
          value={currentTab}
          onChange={(_, value) => handleTabChange(value)}
        >
          <Tab value={0} label="Available" />
          <Tab value={1} label="Assigned" />
          {!cfsId && <Tab value={2} label="Off-duty" />}
        </Tabs>
        {!!cfsId && (
          <IconButton onClick={handleRefreshUnitShiftDistances}>
            <IcRefresh />
          </IconButton>
        )}
      </Box>

      <Divider style={{ margin: '0px 8px' }} />

      <TabPanel value={0} index={currentTab}>
        <AvailableUnits
          cfsId={cfsId}
          filterValues={filterValues}
          searchText={searchUnitText}
          setCurrentTab={handleTabChange}
          fetchActiveCFSList={fetchActiveCFSList}
          handleAssignUnit={handleAssignUnit}
          showMenu={isUnitShiftsPage}
        />
      </TabPanel>
      <TabPanel value={1} index={currentTab}>
        <AssignedUnits
          cfsId={cfsId}
          filterValues={filterValues}
          searchText={searchUnitText}
          handleAssignUnit={handleAssignUnit}
        />
      </TabPanel>
      {!cfsId && (
        <TabPanel value={2} index={currentTab}>
          <OffDutyUnits
            filterValues={filterValues}
            searchText={searchUnitText}
          />
        </TabPanel>
      )}
    </UnitShiftListRoot>
  );
};
