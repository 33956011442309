import {
  KeyboardEvent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';

import { Autocomplete, Box, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { MasterNameApi } from '@/api';
import {
  IMasterName,
  IMasterNameOrganization,
  IMasterNamePerson,
  MasterNameType,
} from '@/models';
import { getAddressLocation } from '@/services';
import { useToastStore } from '@/store';
import { colors } from '@/theme/variables';

interface IMasterNameAutocompleteProps {
  excludedMasterNames?: Array<IMasterNamePerson | IMasterNameOrganization>;
  noResults?: ReactNode;
  disabled?: boolean;
  onChange: (value: IMasterName) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  filterByType?: MasterNameType | null;
}

export const MasterNameAutocomplete = ({
  excludedMasterNames = [],
  noResults,
  onChange,
  onKeyUp,
  disabled = false,
  filterByType,
}: IMasterNameAutocompleteProps) => {
  const [searchText, setSearchText] = useState('');
  const [masterNames, setMasterNames] = useState<
    Array<IMasterNamePerson | IMasterNameOrganization>
  >([]);

  const { updateToast } = useToastStore();

  useEffect(() => {
    fetchMasterNames();
  }, []);

  const fetchMasterNames = async (search?: string) => {
    try {
      const res = await MasterNameApi.getMasterNames({
        search,
        type: filterByType as string,
      });
      setMasterNames(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err });
    }
  };

  const handleInputChange = useDebouncedCallback(
    (_: SyntheticEvent, v: string) => {
      setSearchText(v);
      fetchMasterNames(v);
    },
    500,
  );

  return (
    <Autocomplete
      autoHighlight
      fullWidth
      disabled={disabled}
      onInputChange={handleInputChange}
      options={masterNames}
      noOptionsText={noResults}
      filterOptions={(allMasterNames) => {
        return allMasterNames.reduce((acc, item) => {
          const found = (excludedMasterNames || []).find((excludedItem) => {
            return excludedItem?._id === item._id;
          });
          return found ||
            (item as IMasterNameOrganization).name?.includes('Unknown') ||
            (item as IMasterNamePerson).firstName?.includes('Unknown')
            ? acc
            : [...acc, item];
        }, [] as Array<IMasterNamePerson | IMasterNameOrganization>);
      }}
      onChange={(_, v) => {
        if (v) onChange(v);
      }}
      getOptionLabel={() => ''}
      renderOption={(props, option: IMasterName) => {
        return (
          <Box
            component="li"
            {...props}
            key={option._id}
            flexDirection="column"
            flexWrap="wrap"
            sx={{
              flexDirection: 'row',
              borderBottom: `1px solid ${colors.grey[10]}`,
              flexWrap: 'wrap',
              m: 1,
              height: '60px',
            }}
          >
            <p style={{ width: '100%', margin: 0 }}>
              <strong style={{ margin: 0 }}>{option.name}</strong>
            </p>
            <p style={{ margin: 0 }}>{getAddressLocation(option.address)}</p>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={searchText}
          placeholder="Search names..."
          onKeyUp={(e) => {
            if (onKeyUp) {
              onKeyUp(e);
            }
          }}
          fullWidth
        />
      )}
      clearOnBlur={false}
      disableClearable={true}
    />
  );
};
