import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ButtonBase, Chip, Stack, Typography } from '@mui/material';
import { useMap } from '@vis.gl/react-google-maps';

import { useUnitShiftContext } from '@/hooks';
import { IAssignedUnitShift, IUnitShift } from '@/models';
import { getUnitShiftAddressLabel } from '@/services';

import { UnitItem } from '../components/UnitItem';

const AllUnitsTab = ({
  setMapCenter,
}: {
  setMapCenter: Dispatch<SetStateAction<google.maps.LatLngLiteral | undefined>>;
}) => {
  const [assignedUnits, setAssignedUnits] = useState<IAssignedUnitShift[]>([]);
  const [availableUnits, setAvailableUnits] = useState<IUnitShift[]>([]);
  const map = useMap();
  const {
    assignedUnitShifts,
    onDutyUnitShifts,
    unitShiftAddresses,
    fetchOnDutyUnitShifts,
  } = useUnitShiftContext();

  useEffect(() => {
    fetchOnDutyUnitShifts();
  }, []);

  useEffect(() => {
    if (assignedUnitShifts) {
      const units = assignedUnitShifts.filter((unit) => {
        return unit.status === 'ASSIGNED';
      });

      setAssignedUnits(units);
    }
  }, [assignedUnitShifts]);

  useEffect(() => {
    if (onDutyUnitShifts) {
      setAvailableUnits(onDutyUnitShifts);
    }
  }, [onDutyUnitShifts]);

  return (
    <Stack padding={3} gap={2}>
      {assignedUnits.map(({ unitShift }, index) => (
        <ButtonBase
          key={index}
          onClick={() => {
            if (unitShift?.location?.point?.coordinates) {
              const latLang: google.maps.LatLngLiteral = {
                lat: unitShift.location.point.coordinates[1],
                lng: unitShift.location.point.coordinates[0],
              };

              setMapCenter(latLang);
              map?.setZoom(15);
            }
          }}
        >
          <UnitItem
            name={unitShift.unit?.name || ''}
            type={unitShift.unit?.type || ''}
            action={
              <Chip
                color="error"
                sx={{ paddingY: 2.5, paddingX: 1.5, borderRadius: 1.5 }}
                label="Assigned"
              ></Chip>
            }
            description={
              <>
                <Stack paddingY={2}>
                  <Typography fontSize={18} fontWeight={500} display="flex">
                    {getUnitShiftAddressLabel(unitShift, unitShiftAddresses)}
                  </Typography>
                  {/* <Typography fontSize={18} fontWeight={400}>
                          Manhattan South Sector
                        </Typography> */}
                </Stack>
              </>
            }
          />
        </ButtonBase>
      ))}
      {availableUnits.map((unitShift, index) => (
        <ButtonBase
          key={index}
          onClick={() => {
            if (unitShift?.location?.point?.coordinates) {
              const latLang: google.maps.LatLngLiteral = {
                lat: unitShift.location.point.coordinates[1],
                lng: unitShift.location.point.coordinates[0],
              };

              setMapCenter(latLang);
              map?.setZoom(15);
            }
          }}
        >
          <UnitItem
            name={unitShift.unit?.name || ''}
            type={unitShift.unit?.type || ''}
            action={
              <Chip
                color="error"
                sx={{
                  paddingY: 2.5,
                  paddingX: 1.5,
                  borderRadius: 1.5,
                  backgroundColor: '#46DBB1',
                }}
                label="Available"
              ></Chip>
            }
            description={
              <>
                <Stack paddingY={2}>
                  <Typography fontSize={18} fontWeight={500} display="flex">
                    {getUnitShiftAddressLabel(unitShift, unitShiftAddresses)}
                  </Typography>
                  {/* <Typography fontSize={18} fontWeight={400}>
                          Manhattan South Sector
                        </Typography> */}
                </Stack>
              </>
            }
          />
        </ButtonBase>
      ))}
    </Stack>
  );
};

export default AllUnitsTab;
