import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { TableColumn, TableContainer } from '@/components';
import { DATE_FORMAT } from '@/config';
import { useTabLayoutContext } from '@/layouts/TabLayout';
import {
  ICFSMasterName,
  IKaseInvolvement,
  IKaseMasterName,
  ICFSInvolvement,
} from '@/models';
import { IMasterName, IMasterNamePerson } from '@/models/master.names';
import { formatPhoneNumber } from '@/utils/pattern';

import { useMasterNamesContext } from '../..';
import { TabNamesScreens } from '../../data';

interface NameLookupTableProps {
  columns: TableColumn[];
  rows?: (ICFSMasterName | IKaseMasterName)[];
}
export const NameLookupTable = ({ columns, rows }: NameLookupTableProps) => {
  const { setCurrentMastername } = useMasterNamesContext();
  const { updateCurrentScreen } = useTabLayoutContext();

  const renderColumn = (
    row: IMasterName & { relationship?: ICFSInvolvement | IKaseInvolvement },
    field: { key: string; label?: string },
  ) => {
    switch (field.key) {
      case 'phone':
        return row.phoneNumbers
          ? formatPhoneNumber(row.phoneNumbers[0]?.phoneNumber)
          : '';
      case 'dateOfBirth': {
        const birthday = (row as IMasterNamePerson)?.birthday;
        return birthday ? moment(birthday).format(DATE_FORMAT) : '';
      }
      case 'race':
        return (row as IMasterNamePerson).race?.toString();
      default: {
        const data = row[field.key as keyof typeof row];
        return data?.toString();
      }
    }
  };

  return (
    <TableContainer sx={{ p: 0 }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((field) => (
              <TableCell key={field.key}>{field.label}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row) => (
              <TableRow key={row._id}>
                {columns.map((field: TableColumn) => {
                  return (
                    <TableCell key={field.key}>
                      {renderColumn(
                        {
                          ...row.masterName,
                          relationship: row.relationship,
                        },
                        field,
                      )}
                    </TableCell>
                  );
                })}
                <TableCell key={row._id} sx={{ width: '150px' }}>
                  {row._id && (
                    <Button
                      variant="text"
                      size="medium"
                      color="error"
                      onClick={() => {
                        setCurrentMastername(row);
                        updateCurrentScreen(
                          TabNamesScreens.MasterAddNameDetails,
                        );
                      }}
                    >
                      Use information
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
