import { ChangeEvent, FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { IconButton, InputAdornment } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers-pro';

import { Input, InputProps } from '../Input';
import { FormElementProps } from '../types';

export interface FormInputFieldProps
  extends Omit<InputProps, 'name' | 'onChange'>,
    FormElementProps {
  handleChange?: (name: string, value: string | number) => void;
  clearable?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const FormInputField: FC<FormInputFieldProps> = (props) => {
  const {
    name,
    rules,
    defaultValue,
    handleChange,
    inputRef,
    clearable,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ''}
      name={name}
      render={({ field }: any) => (
        <Input
          {...rest}
          {...field}
          {...(inputRef ? { ref: inputRef } : {})}
          error={error?.message}
          name={name}
          endAdornment={
            clearable && field.value ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    field.onChange('');
                    if (handleChange) handleChange(name, '');
                  }}
                  size="small"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : null
          }
          onChange={(
            event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => {
            if (handleChange != null)
              handleChange(event.target.name, event.target.value);
            field.onChange(event);
          }}
        />
      )}
      rules={rules}
    />
  );
};
