import { tooltipClasses } from '@mui/material';

export default {
  MuiTooltip: {
    styleOverrides: {
      popper: {
        '&.no-max-width': {
          [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
            [`&.${tooltipClasses.tooltipPlacementBottom}`]: {
              marginTop: '-27px',
            },
          },
        },
      },
    },
  },
};
