import { FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { FormHelperText, styled } from '@mui/material';

import { IFile } from '@/models';

import { FilePicker, FilePickerProps } from '../../FilePicker';
import { FormElementProps } from '../types';

export interface FormFilePickerProps
  extends Omit<FilePickerProps, 'onChange'>,
    FormElementProps {
  handleChange?: (name: string, value: IFile | IFile[]) => void;
}

const Root = styled('div')(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
}));

export const FormFilePicker: FC<FormFilePickerProps> = (props) => {
  const { name, rules, handleChange, initialFileItems, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Root>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }: any) => (
          <FilePicker
            {...rest}
            initialFileItems={
              initialFileItems?.length ? initialFileItems : value
            }
            onChange={(files: IFile[]) => {
              if (handleChange != null) handleChange(name, files);
              onChange(files);
            }}
          />
        )}
        rules={rules}
      />
      <FormHelperText error>{error?.message}</FormHelperText>
    </Root>
  );
};
