import { ReactNode, useState, Ref } from 'react';

import { Autocomplete, AutocompleteProps, Typography } from '@mui/material';

import { NoResultItem } from '@/components/FormElements';
import { IAddressForm } from '@/models';

import { AddressOptionItemRoot, OptionListPaper, TextField } from './styles';

export interface AddressOptionType extends IAddressForm {
  placeId?: string;
  mainText?: string;
  secondaryText?: string;
}

interface AddressSearchProps
  extends Omit<
    AutocompleteProps<AddressOptionType | string, false, boolean, false>,
    'renderInput' | 'onInputChange' | 'renderOption'
  > {
  renderOption: (option: AddressOptionType) => ReactNode;
  handleInputChange?: (newInputValue: string) => void;
  extraOptionItem?: ReactNode;
  inputRef?: Ref<any>;
}

export const AddressSearch = (props: AddressSearchProps) => {
  const {
    renderOption,
    handleInputChange,
    extraOptionItem,
    inputRef,
    ...restProps
  } = props;
  const [inputValue, setInputValue] = useState<string>('');

  const renderOptionItem = (
    renderProps: React.HTMLAttributes<HTMLLIElement>,
    option: AddressOptionType | string,
  ) => {
    if (!option) return null;
    return (
      <AddressOptionItemRoot {...renderProps}>
        {typeof option === 'string' ? (
          <Typography variant="subtitle2">{option}</Typography>
        ) : (
          renderOption(option)
        )}
      </AddressOptionItemRoot>
    );
  };

  return (
    <Autocomplete
      disableClearable={!inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        if (handleInputChange) handleInputChange(newInputValue);
      }}
      noOptionsText={
        <NoResultItem sx={{ display: !inputValue ? 'none' : 'flex' }} />
      }
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          autoComplete="off"
          autoCorrect="off"
          value={inputValue}
          {...params}
        />
      )}
      PaperComponent={({ children, ...rest }) => (
        <OptionListPaper {...rest}>
          {children}
          {extraOptionItem}
        </OptionListPaper>
      )}
      renderOption={renderOptionItem}
      clearOnBlur={false}
      disablePortal
      {...restProps}
    />
  );
};
