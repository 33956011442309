import { FC } from 'react';

import { MasterVehicleView } from '@/layouts/TabLayout/MasterVehicles/components/MasterVehicleView';
import MasterIndexVehicleBase from '@/pages/MasterIndex/Vehicles/components/MasterIndexVehicleBase';

const MasterIndexVehicleView: FC = () => {
  return (
    <MasterIndexVehicleBase>
      <MasterVehicleView isMasterIndex hideHeader></MasterVehicleView>
    </MasterIndexVehicleBase>
  );
};

export default MasterIndexVehicleView;
