import { PaletteMode } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material/styles';

import components from './components';
import { getDesignTokens } from './palette';
import typography from './typography';

export const createCustomTheme = (mode: PaletteMode) =>
  createMuiTheme({
    palette: getDesignTokens(mode),
    components,
    typography,
  });
