import { DataLakeServiceClient } from '@azure/storage-file-datalake';

import { azureStorageAccountName } from '@/config';
import { IFile } from '@/models';
import { useFileStore, useToastStore, useUserStore } from '@/store';
import { generateFileFromBase64, generateRandomFileName } from '@/utils';

const uploadFolder = 'tmp';

const getFolderNameFromUrl = (fileUrl?: string): string | undefined => {
  // Get folder name from azure blob storage URL.
  if (!fileUrl) return undefined;

  const parsedUrl = new URL(fileUrl);
  const pathname = parsedUrl.pathname;

  if (!pathname) return undefined;
  return pathname.split('/')[2];
};

export const getSasTokenFromFileUrl = async (
  fileUrl?: string,
): Promise<string | undefined> => {
  // Get SAS token matched with folder name from file URL.
  const folderName = getFolderNameFromUrl(fileUrl);
  if (!folderName) return undefined;
  const { fileTokens, checkUploadTokenExpiration, fetchFileTokens } =
    useFileStore.getState();

  const isTokenExpired = !fileTokens || checkUploadTokenExpiration(folderName);

  if (isTokenExpired) {
    const newTokens = await fetchFileTokens();
    return newTokens[folderName]?.sasToken;
  } else {
    return fileTokens[folderName]?.sasToken;
  }
};

export const uploadFiles = async (
  files: IFile[],
  fileNamePrefix?: string,
): Promise<IFile[]> => {
  const { fileTokens, checkUploadTokenExpiration, fetchFileTokens } =
    useFileStore.getState();
  const { account, user } = useUserStore.getState();

  if (!account?._id || !user?._id) {
    throw new Error(
      'User or account information is undefined, please log in again.',
    );
  }

  let uploadToken: string;
  const azureStorageUrl = `https://${azureStorageAccountName}.blob.core.windows.net`;
  const containerName = `acct-${account._id}`;
  const directoryName = `${uploadFolder}/${user._id}`;

  const isUploadTokenExpired = !fileTokens || checkUploadTokenExpiration('tmp');
  if (isUploadTokenExpired) {
    const newTokens = await fetchFileTokens();
    uploadToken = newTokens.tmp?.sasToken;
  } else {
    uploadToken = fileTokens.tmp?.sasToken;
  }

  const datalakeServiceClient = new DataLakeServiceClient(
    `https://${azureStorageAccountName}.dfs.core.windows.net?${uploadToken}`,
  );
  const fileSystemClient =
    datalakeServiceClient.getFileSystemClient(containerName);
  const directoryClient = fileSystemClient.getDirectoryClient(directoryName);
  await directoryClient.createIfNotExists();

  // Function to upload the file in chunks
  // Function to upload the file in chunks with parallelism and error handling
  // Function to upload the file in chunks with batching of 10 parallel operations
  const uploadInChunks = async (
    fileClient: ReturnType<typeof directoryClient.getFileClient>,
    file: File,
    chunkSize = 15 * 1024 * 1024, // 15 MB
  ): Promise<void> => {
    const fileSize = file.size;
    const reader = file.stream().getReader();

    // Create the file on Azure Blob Storage
    await fileClient.create();

    let offset = 0;
    const appendPromises: Promise<void>[] = [];

    // Function to handle the execution of append operations in batches
    const executeBatch = async () => {
      // Wait for the current batch of append operations to complete
      await Promise.all(appendPromises);
      // Clear the batch for the next set of operations
      appendPromises.length = 0;
    };

    try {
      while (offset < fileSize) {
        const { done, value } = await reader.read();
        if (done || !value) break;

        // Adjust the chunk size to prevent slicing beyond available data
        const chunk = value.slice(0, Math.min(chunkSize, value.length));

        // Push the append operation to the promises array for parallel execution
        appendPromises.push(
          fileClient.append(chunk, offset, chunk.length).then(() => {}),
        );
        offset += chunk.length;

        // If the batch reaches 10 operations, execute the batch
        if (appendPromises.length === 10) {
          await executeBatch();
        }
      }

      // Execute any remaining append operations
      if (appendPromises.length > 0) {
        await executeBatch();
      }

      // Finalize the upload by flushing the entire file size
      await fileClient.flush(fileSize);
    } catch (error) {
      throw new Error('Failed to upload file in chunks');
    }
  };

  // Iterate over each file and upload
  const uploadPromises = files.map(async (fileItem) => {
    const file = fileItem.file;
    if (!file) return fileItem;

    let fileName = generateRandomFileName(file.name);
    if (fileNamePrefix) {
      fileName += `${fileNamePrefix}-`;
    }
    const fileClient = directoryClient.getFileClient(fileName);

    // Upload the file in chunks
    await uploadInChunks(fileClient, file);
    fileItem.url = `${azureStorageUrl}/${containerName}/${directoryName}/${fileName}`;
    delete fileItem.file;
    return fileItem;
  });

  await Promise.all(uploadPromises);
  return files;
};

export const uploadSignatureFile = async (
  base64String: string,
  fileName = 'signature.png',
): Promise<IFile[]> => {
  const signatureFile = generateFileFromBase64(base64String, fileName);
  const payload: IFile = {
    name: signatureFile.name,
    size: signatureFile.size,
    mimeType: signatureFile.type,
    file: signatureFile,
  };
  const uploadedSignatureFile = await uploadFiles([payload]);
  return uploadedSignatureFile;
};

export const downloadFile = async (
  file: IFile,
  shouldAttachSasToken = true,
): Promise<void> => {
  const { updateToast } = useToastStore.getState();
  try {
    let fileUrl = file.url;
    if (!fileUrl) throw new Error('File URL is undefined.');

    if (shouldAttachSasToken) {
      const sasToken = await getSasTokenFromFileUrl(file.url);

      if (!sasToken) throw new Error('SAS token is undefined.');

      fileUrl += `?${sasToken}`;
    }

    const res = await fetch(fileUrl);

    if (!res.ok) {
      throw new Error('File download failed.');
    }

    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (err: any) {
    updateToast({ open: true, message: err.message });
  }
};
