import { FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { InputProps } from '../Input';
import { PatternInput } from '../PatternInput';
import { FormElementProps } from '../types';

export interface FormPatternFieldProps
  extends Omit<InputProps, 'name' | 'onChange'>,
    FormElementProps {
  format?: string;
  mask?: string | string[];
  allowEmptyFormatting?: boolean;
  patternChar?: string;
  handleChange?: (name: string, value: string | number) => void;
}

export const FormPatternField: FC<FormPatternFieldProps> = (props) => {
  const {
    name,
    rules,
    format = '',
    defaultValue,
    handleChange,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ''}
      name={name}
      render={({ field: { onChange, ...fieldRest } }: any) => (
        <PatternInput
          {...rest}
          {...fieldRest}
          type="text"
          displayType="input"
          allowEmptyFormatting
          format={format}
          error={error?.message}
          name={name}
          onValueChange={(v) => {
            onChange(v.value);
            if (handleChange != null) handleChange(name, v.value ?? '');
          }}
        />
      )}
      rules={rules}
    />
  );
};
