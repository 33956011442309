import { IPoint, IPolygonLayer, IUnitShift } from '@/models';
import { isInsidePolygon } from '@/utils';

import { getAddressLocation } from './service.address';

export const getUnitShiftAddressLabel = (
  unitShift: IUnitShift,
  uniShiftAddressObj: Record<string, string>,
) => {
  return unitShift.location
    ? uniShiftAddressObj[unitShift?._id as string] ||
        getAddressLocation(unitShift.location.address)
    : 'Offline';
};

export const getSectorNameInfo = (
  beats: IPolygonLayer[],
  point?: IPoint,
  polygons: string[] = [],
  isOnDuty = false,
) => {
  let isCoverCFS = false;
  const sectorNames = polygons.reduce((sectors: string[], current) => {
    const matchedBeat = beats.find(({ _id }) => _id === current);
    if (matchedBeat) {
      sectors.push(matchedBeat.name);
      if (isOnDuty && point && isInsidePolygon(point, matchedBeat.area)) {
        isCoverCFS = true;
      }
    }
    return sectors;
  }, []);

  return { isCoverCFS, sectorNames };
};
