import {
  getGridNumericOperators,
  GridFilterInputValue,
  GridFilterInputValueProps,
} from '@mui/x-data-grid-pro';

const NumberInput = (props: GridFilterInputValueProps) => {
  return (
    <GridFilterInputValue
      {...props}
      type="number"
      onChange={(event) => {
        const value = event.target.value;
        props.applyValue({ ...props.item, value: value === '' ? '' : value });
      }}
    />
  );
};

export const customNumericOperators = getGridNumericOperators().map(
  (operator) => ({
    ...operator,
    InputComponent: NumberInput,
  }),
);
