import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Typography, Stack, Tab } from '@mui/material';

import { UnitApi } from '@/api';
import { IcFilter } from '@/assets/images';
import { ActiveFilterIndicator, BaseAutocomplete, Tabs } from '@/components';
import { LocalStoragePrefix } from '@/constants';
import { useUnitShiftContext } from '@/hooks';
import { IUnitFilter, IUnitFilterForm } from '@/models';
import { useToastStore, useUserStore } from '@/store';
import { getLocalStorageItem, setLocalStorageItem } from '@/utils';

import { UnitFilterForm } from './UnitFilterForm';
import { UnitFilterList } from './UnitFilterList';
import { unitFilterMenuTabs, initFilterValues } from '../data';
import { Menu } from '../styles';

export const UnitFilter: FC = () => {
  const { user } = useUserStore();
  const { updateToast } = useToastStore();
  const { setFilterValues } = useUnitShiftContext();
  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);
  const [currentTab, setCurrentTab] = useState('currentFilter');

  const [savedUnitFilters, setSavedUnitFilters] = useState<IUnitFilter[]>([]);
  const [selectedUnitFilter, setSelectedUnitFilter] = useState('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);

  const methods = useForm<IUnitFilterForm>({
    defaultValues: initFilterValues,
  });

  const { reset, getValues, setError, clearErrors } = methods;

  const openMenu = Boolean(menuEl);
  const savedUnitFilterOptions = savedUnitFilters.map(({ _id, name }) => ({
    label: name,
    value: _id ?? '',
  }));

  useEffect(() => {
    if (openMenu) {
      applyStorageFilters();
      fetchSavedUnitFilters();
    }
  }, [openMenu]);

  const fetchSavedUnitFilters = async () => {
    try {
      const res = await UnitApi.getUnitFilters();
      setSavedUnitFilters(res.data);
    } catch (err) {
      updateToast({
        open: true,
        message: 'Failed to fetch unit filters',
      });
    }
  };

  const applyStorageFilters = () => {
    if (!user || !user._id) return;
    const savedFilter = getLocalStorageItem({
      key: user._id,
      prefix: LocalStoragePrefix.UnitFilters,
      shouldParse: true,
    });
    if (!savedFilter) return;
    reset(savedFilter);
    handleFilter();
  };

  const handleFilter = (newFilterValues?: IUnitFilterForm) => {
    const _filterValues = newFilterValues || getValues();
    const filterParams = {
      agencies: _filterValues.agencies,
      agencyType: _filterValues.agencyType,
      beat: _filterValues.beat,
      unitTypes: _filterValues.unitTypes,
    };
    setFilterValues(filterParams);
    setLocalStorageItem({
      key: String(user?._id),
      prefix: LocalStoragePrefix.UnitFilters,
      value: filterParams,
    });
    setHasAppliedFilters(
      !!filterParams.agencies?.length ||
        !!filterParams.agencyType ||
        !!filterParams.beat ||
        !!filterParams.unitTypes?.length,
    );
  };

  const handleChangeFilters = (name?: string, value?: string | number) => {
    if (name === 'name') {
      const existingName = savedUnitFilters.find(
        (savedFilter) => value && savedFilter.name === (value as string),
      );
      if (existingName) {
        setError('name', { message: 'The filter name must be unique' });
      } else {
        clearErrors();
      }
    } else if (name) {
      handleFilter({ ...getValues(), [name]: value });
    } else {
      handleFilter();
    }
  };

  const handleDeleteSavedFilter = async (id: string) => {
    try {
      const currentFilterValues = getValues();
      await UnitApi.deleteUnitFilter(id);
      const newSavedUnitFilters = savedUnitFilters.filter(
        ({ _id }) => _id !== id,
      );
      setSavedUnitFilters(newSavedUnitFilters);
      if (currentFilterValues._id === id) {
        reset(initFilterValues);
        handleFilter();
      }
    } catch (err: any) {
      updateToast({
        open: true,
        message: err.message,
      });
    }
  };

  const handleEditSavedFilter = (selectedFilter: IUnitFilter) => {
    reset(selectedFilter);
    setCurrentTab('currentFilter');
    handleFilter();
  };

  const handleSaveFilter = (newFilter: IUnitFilter) => {
    const indexOfExist = savedUnitFilters.findIndex(
      ({ _id }) => _id === newFilter._id,
    );
    if (indexOfExist < 0) {
      setSavedUnitFilters((v) => [...v, newFilter]);
    } else {
      const newSavedFilters = savedUnitFilters.slice();
      newSavedFilters[indexOfExist] = newFilter;
      setSavedUnitFilters(newSavedFilters);
    }
    handleMenuClose();
  };

  const handleChangeSelectedUnitFilter = (newValue: string) => {
    setSelectedUnitFilter(newValue);
    const matchedUnitFilter = savedUnitFilters.find(
      ({ _id }) => _id === newValue,
    );
    reset(matchedUnitFilter || initFilterValues);
    handleFilter();
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setCurrentTab('currentFilter');
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  return (
    <div style={{ width: 250 }}>
      <Stack flexDirection="row">
        <BaseAutocomplete
          placeholder="Select filter..."
          value={selectedUnitFilter}
          items={savedUnitFilterOptions}
          onChange={handleChangeSelectedUnitFilter}
          sx={{ minWidth: 0, marginBottom: -2 }}
          showPopupIcon
        />
        <Button
          color="inherit"
          variant="outlined"
          style={{
            padding: '0px 10px',
            height: 36,
            minWidth: 40,
            marginLeft: 10,
          }}
          onClick={(e) => handleMenuOpen(e)}
          sx={{ position: 'relative' }}
        >
          <IcFilter />
          {hasAppliedFilters && <ActiveFilterIndicator />}
        </Button>
      </Stack>

      <Menu
        id="unit-filter-menu"
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'unit-filter-menu',
        }}
        style={{ minWidth: '300px!important' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        sx={{ marginTop: '30px' }}
      >
        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          sx={{ mb: 2 }}
        >
          {unitFilterMenuTabs.map((tab) => (
            <Tab
              key={tab.value}
              {...tab}
              sx={{ flex: 1, textTransform: 'none!important' }}
            />
          ))}
        </Tabs>
        <Stack>
          <FormProvider {...methods}>
            {currentTab === 'currentFilter' ? (
              <UnitFilterForm
                onSave={handleSaveFilter}
                onChange={handleChangeFilters}
              />
            ) : savedUnitFilters.length ? (
              <UnitFilterList
                unitFilters={savedUnitFilters}
                onDelete={handleDeleteSavedFilter}
                onEdit={handleEditSavedFilter}
              />
            ) : (
              <Typography variant="body2" align="center">
                There are no saved filters.
              </Typography>
            )}
          </FormProvider>
        </Stack>
      </Menu>
    </div>
  );
};
