import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const SIDEBAR_WIDTH = 88;

export const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'transparent',
    padding: '12px',
    zIndex: 1000,
    transition: 'transform 0.3s ease-in-out',
    flex: 1,
  },
  sidebarFull: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'transparent',
    padding: '12px',
    zIndex: 1000,
    transition: 'transform 0.3s ease-in-out',
    flex: 1,
  },
  sidebarContent: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    flex: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '24px',
    marginLeft: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  toggleButton: {
    boxShadow: '10px 10px 50px 0px #0000001A',
    position: 'absolute',
    top: '50%',
    left: -48,
    width: '44px',
    height: '44px',
    borderRadius: '8px',
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
  contollButtons: {
    position: 'absolute',
    bottom: 210,
    left: -48,
    width: '40px',
    height: '40px',
    borderRadius: '8px',
  },
  controllButton: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    width: '44px !important',
    height: '44px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '10px 10px 50px 0px #0000001A',
  },
  topButtonGroup: {
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  bottomButtonGroup: {
    borderBottomLeftRadius: '50%',
    borderBottomRightRadius: '50%',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  locate: {
    borderRadius: '50%',
    marginBottom: 10,
    marginTop: 10,
  },
}));

export const customRequestBackupStyles = {
  backgroundColor: '#46DBB1',
  paddingY: 1.5,
  fontWeight: 500,
  fontSize: 16,
  color: 'white',
  '&:hover': {
    backgroundColor: '#33C299',
  },
};
