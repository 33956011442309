import { Box } from '@mui/material';

export const ActiveFilterIndicator = () => {
  return (
    <Box
      sx={{
        width: '14px',
        height: '14px',
        backgroundColor: '#EE3E5C',
        borderRadius: '100%',
        position: 'absolute',
        top: -6,
        right: -6,
      }}
    />
  );
};
