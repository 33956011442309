import { FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { AddressForm, AddressFormProps, FormElementProps } from '@/components';

export interface FormAddressFieldProps
  extends Omit<AddressFormProps, 'name' | 'onChange'>,
    FormElementProps {
  handleChange?: (name: string, value: string) => void;
}

export const FormAddressField: FC<FormAddressFieldProps> = (props) => {
  const { name, rules, defaultValue = '', handleChange, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => (
        <AddressForm
          {...rest}
          {...field}
          error={error?.message}
          onChange={(e) => {
            field.onChange(e);
            if (handleChange && (!e || e._id)) {
              handleChange(name, e?._id || '');
            }
          }}
        />
      )}
      rules={rules}
    />
  );
};
