import { colors } from '../variables';

export default {
  MuiButtonGroup: {
    styleOverrides: {
      firstButton: {
        border: 0,
        borderRight: `1px solid ${colors.grey[20]}`,
      },
      middleButton: {
        border: 0,
        borderRight: `1px solid ${colors.grey[20]}`,
      },
      lastButton: {
        border: 0,
      },
    },
  },
};
