import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { MasterIndexApi } from '@/api';
import { withLoader, WithLoaderProps } from '@/hocs';
import { MasterVehicleFormData } from '@/layouts/TabLayout/MasterVehicles/components/MasterVehicleForm';
import { IMasterVehicle } from '@/models';
import MasterVehicleForm from '@/pages/MasterIndex/Vehicles/components/MasterVehicleForm';
import { useToastStore } from '@/store';

const MasterVehicleEdit: FC<WithLoaderProps> = () => {
  const { updateToast } = useToastStore();
  const urlParams = useParams();

  const methods = useForm<MasterVehicleFormData>({
    defaultValues: {
      make: '',
      model: '',
      isVerified: false,
    },
    mode: 'all',
  });

  const { reset } = methods;

  const vehicleId =
    urlParams.vehicleId !== 'new' ? urlParams.vehicleId : undefined;

  useEffect(() => {
    if (vehicleId) fetchVehicleData();
    else reset();
  }, [vehicleId]);

  const fetchVehicleData = async () => {
    try {
      const promises = [MasterIndexApi.getMasterVehicle(vehicleId || '')];
      const [res] = await Promise.all(promises);
      const vehicle = res.data as IMasterVehicle;

      reset({
        ...vehicle,
        year: vehicle.year?.toString(),
        make: res.data.make?.toString(),
        model: res.data.model?.toString(),
        color: res.data.color?._id || res.data.color?.toString(),
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  return (
    <FormProvider {...methods}>
      <MasterVehicleForm />
    </FormProvider>
  );
};

export default withLoader(MasterVehicleEdit);
