import { Stack } from '@mui/material';

import { Page, PageHeader } from '@/components';
import KasesForm from '@/pages/Kases/Components/KasesForm';

type KasesTabProps = {
  handleBack: () => void;
};

const KasesTab = ({ handleBack }: KasesTabProps) => {
  return (
    <Page title="Create Incident Report">
      <PageHeader
        title="Create Incident Report"
        onBack={handleBack}
        sx={{
          paddingLeft: 2,
        }}
      ></PageHeader>
      <Stack spacing={2} flex={1} padding={3}>
        <KasesForm onClose={() => {}} />
      </Stack>
    </Page>
  );
};

export default KasesTab;
