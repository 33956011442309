import { Ref, forwardRef } from 'react';

import { FormHelperText, InputLabel, styled } from '@mui/material';
import {
  LocalizationProvider,
  DateRangePicker as MuiDateRangePicker,
  DateRangePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

import { BaseFieldProps } from '../types';

export const DateRangePickerRoot = styled('div')(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
  '& .MuiFormControl-root': {
    width: '100%',
  },
}));

export interface BaseDateRangePickerProps
  extends DateRangePickerProps<string>,
    BaseFieldProps {
  hideError?: boolean;
}

export const DateRangePicker = forwardRef<Ref<any>, BaseDateRangePickerProps>(
  (props, ref) => {
    const { label, error, hideError = false, ...rest } = props;

    return (
      <DateRangePickerRoot>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          {label && <InputLabel>{label}</InputLabel>}
          <MuiDateRangePicker
            {...rest}
            inputRef={ref as Ref<HTMLInputElement>}
          />
          {!hideError && <FormHelperText error>{error}</FormHelperText>}
        </LocalizationProvider>
      </DateRangePickerRoot>
    );
  },
);

DateRangePicker.displayName = 'Custom Date Range Picker';
