import { IViewCard, TableColumn } from '@/components';
import { USStates } from '@/constants/address';
import {
  ethnicRaceInput,
  eyeColorInput,
  genderInput,
  phoneNumberInput,
  phoneTypeInput,
  raceInput,
} from '@/constants/form_inputs';
import {
  AgeGroup,
  KaseMasterNameInvolvement,
  kaseOffenderForm,
  OffenderType,
  OffenderCondition,
  ResidenceStatus,
  VictimType,
  LevelOfInjury,
  InjuryType,
  MedicalTreatment,
  VictimResidenceStatus,
  OfficerActivityType,
  OfficerAssignmentType,
  JurisdictionType,
  kaseVictimForm,
} from '@/models';
import {
  HeightMeasurementType,
  OrganizationType,
  OtherPhysicalCharacteristics,
  WeightMeasurementType,
} from '@/models/master.names';
import {
  formatOrganisationName,
  formatOrganisationType,
  formatPhoneNumber,
  formatPhoneNumberType,
  parsePhoneNumber,
} from '@/utils';

import { FileAcceptedTypes } from '../../../pages/Kases/KaseFiles/components/data';

export type IFieldItem = {
  name: string;
  type: string;
  label: string;
  freeSolo?: boolean;
  clearOnBlur?: boolean;
  addNew?: boolean;
  items?:
    | Array<{ value: string; label: string }>
    | string
    | typeof booleanTypesItems;
  shouldDisplayCallback?: string;
  rules?: any;
  multiple?: boolean;
};

export const AgeGroupLabel = {
  [AgeGroup.EXACT_AGE]: 'Exact age',
  [AgeGroup.AGE_RANGE]: 'Age range',
  [AgeGroup.OVER_NINETY_EIGHT_YEARS_OLD]: 'Over 98 Years Old',
  [AgeGroup.UNKNOWN]: 'Unknown',
};

export const OffenderTypeLabel = {
  [OffenderType.SUSPECT]: 'Suspect',
  [OffenderType.ACCOMPLICE]: 'Accomplice',
  [OffenderType.CLEARED]: 'Cleared',
  [OffenderType.CHARGED]: 'Charged',
};

export const OffenderConditionLabel = {
  [OffenderCondition.APPEARS_TO_BE_IMPAIRED_WITH_DRUGS]:
    'Appears to be Impaired with Drugs',
  [OffenderCondition.APPEARS_TO_BE_IMPAIRED_WITH_ALCOHOL]:
    'Appears to be Impaired with Alcohol',
  [OffenderCondition.APPEARS_TO_BE_MENTALLY_DISORDERED]:
    'Appears to be Mentally Disordered',
  [OffenderCondition.APPEARS_TO_BE_INJURED_OR_ILL]:
    'Appears to be Injured or Ill',
  [OffenderCondition.APPARENTLY_NORMAL]: 'Apparently Normal',
  [OffenderCondition.NOT_REPORTED]: 'Not Reported',
  [OffenderCondition.UNKNOWN]: 'Unknown',
};

export const ResidenceStatusLabel = {
  [ResidenceStatus.RESIDENT]: 'Resident',
  [ResidenceStatus.NONRESIDENT]: 'Nonresident',
  [ResidenceStatus.FOREIGN_NONRESIDENT]: 'Foreign Nonresident',
  [ResidenceStatus.UNKNOWN]: 'Unknown',
};

export const VictimTypeLabel = {
  [VictimType.FIRE_FIGHTER]: 'Firefighter',
  [VictimType.INDIVIDUAL]: 'Individual',
  [VictimType.LAW_ENFORCEMENT_OFFICER]: 'Law Enforcement Officer (LEOKA)',
  [VictimType.BUSINESS]: 'Business',
  [VictimType.FINANCIAL_INSTITUTION]: 'Financial Institution',
  [VictimType.GOVERNMENT]: 'Government',
  [VictimType.RELIGIOUS_ORGANIZATION]: 'Religious Organization',
  [VictimType.SOCIETY_PUBLIC]: 'Society/Public',
  [VictimType.OTHER]: 'Other',
  [VictimType.UNKNOWN]: 'Unknown',
};

export const LevelOfInjuryLabel = {
  [LevelOfInjury.DEAD]: 'Dead',
  [LevelOfInjury.APPEARED_TO_BE_SERIOUSLY_INJURED]:
    'Appeared to Be Seriously Injured',
  [LevelOfInjury.APPEARED_TO_BE_PHYSICALLY_INJURED]:
    'Appeared to Be Physically Injured, But Not Seriously',
  [LevelOfInjury.NOT_INJURED]: 'Not Injured',
  [LevelOfInjury.NOT_APPLICABLE]: 'Not Applicable',
  [LevelOfInjury.NOT_REPORTED]: 'Not Reported',
  [LevelOfInjury.UNKNOWN]: 'Unknown ',
};

export const InjuryTypeLabel = {
  [InjuryType.NONE]: 'None',
  [InjuryType.APPARENT_BROKEN_BONES]: 'Apparent Broken Bones',
  [InjuryType.POSSIBLE_INTERNAL_INJURY]: 'Possible Internal Injury',
  [InjuryType.SEVERE_LACERATION]: 'Severe Laceration',
  [InjuryType.LOSS_OF_TEETH]: 'Loss of Teeth',
  [InjuryType.UNCONSCIOUSNESS]: 'Unconsciousness',
  [InjuryType.OTHER_MAJOR_INJURY]: 'Other Major Injury',
  [InjuryType.APPARENT_MINOR_INJURY]: 'Apparent Minor Injury',
  [InjuryType.NOT_APPLICABLE]: 'Not Applicable',
  [InjuryType.NOT_REPORTED]: 'Not Reported',
  [InjuryType.UNKNOWN]: 'Unknown',
};

export const MedicalTreatmentLabel = {
  [MedicalTreatment.HOSPITALIZED]: 'Hospitalized',
  [MedicalTreatment.TREATED_AND_RELEASED]: 'Treated and Released',
  [MedicalTreatment.REFUSED_TREATMENT]: 'Refused Treatment',
  [MedicalTreatment.NOT_TREATED]: 'Not Treated',
  [MedicalTreatment.TREATED_AT_SCENE]: 'Treated at Scene',
  [MedicalTreatment.NOT_APPLICABLE]: 'Not Applicable',
  [MedicalTreatment.NOT_INDICATED_IN_REPORT]: 'Not Indicated in Report',
  [MedicalTreatment.UNKNOWN]: 'Unknown',
};

export const VictimResidenceStatusLabel = {
  [VictimResidenceStatus.RESIDENT]: 'Resident',
  [VictimResidenceStatus.COMMUTER]: 'Commuter',
  [VictimResidenceStatus.TOURIST]: 'Tourist',
  [VictimResidenceStatus.MILITARY]: 'Military',
  [VictimResidenceStatus.STUDENT]: 'Student',
  [VictimResidenceStatus.TEMPORARY_RESIDENT]:
    'Temporary Resident/Foreign National',
  [VictimResidenceStatus.OTHER_STATUS]: 'Other Status',
  [VictimResidenceStatus.HOMELESS]: 'Homeless',
  [VictimResidenceStatus.NOT_APPLICABLE]: 'Not Applicable',
  [VictimResidenceStatus.NOT_REPORTED]: 'Not Reported',
  [VictimResidenceStatus.UNKNOWN]: 'Unknown',
};

export const OfficerActivityTypeLabel = {
  [OfficerActivityType.RESPONDING_TO_DISTURBANCE_CALL]:
    'Responding to Disturbance Call',
  [OfficerActivityType.BURGLARIES_IN_PROGRESS]:
    'Burglaries in Progress or Pursuing Burglary Suspects',
  [OfficerActivityType.ROBBERIES_IN_PROGRESS]:
    'Robberies in Progress or Pursuing Robbery Suspect',
  [OfficerActivityType.ATTEMPTING_OTHER_ARRESTS]: 'Attempting Other Arrests',
  [OfficerActivityType.CIVIL_DISORDER]: 'Civil Disorder',
  [OfficerActivityType.HANDLING_TRANSPORTING_CUSTODY_OF_PRISONERS]:
    'Handling, Transporting, Custody of Prisoners',
  [OfficerActivityType.INVESTIGATING_SUSPICIOUS_PERSONS]:
    'Investigating Suspicious Persons or Circumstances',
  [OfficerActivityType.AMBUSH_NO_WARNING]: 'Ambush, No Warning',
  [OfficerActivityType.HANDLING_PERSONS_WITH_MENTAL_ILLNESS]:
    'Handling Persons with Mental Illness',
  [OfficerActivityType.TRAFFIC_PURSUITS_AND_STOPS]:
    'Traffic Pursuits and Stops',
  [OfficerActivityType.ALL_OTHER]: 'All Other',
  [OfficerActivityType.NOT_APPLICABLE]: 'Not Applicable',
};

export const OfficerAssignmentTypeLabel = {
  [OfficerAssignmentType.TWO_OFFICER_VEHICLE]: 'Two-Officer Vehicle',
  [OfficerAssignmentType.ONE_OFFICER_VEHICLE_ALONE]:
    'One-Officer Vehicle (Alone)',
  [OfficerAssignmentType.ONE_OFFICER_VEHICLE_ASSISTED]:
    'One-Officer Vehicle (Assisted)',
  [OfficerAssignmentType.DETECTIVE_OR_SPECIAL_ASSIGNMENT_ALONE]:
    'Detective or Special Assignment (Alone)',
  [OfficerAssignmentType.DETECTIVE_OR_SPECIAL_ASSIGNMENT_ASSISTED]:
    'Detective or Special Assignment (Assisted) ',
  [OfficerAssignmentType.OTHER_ALONE]: 'Other (Alone)',
  [OfficerAssignmentType.OTHER_ASSISTED]: 'Other (Assisted)',
  [OfficerAssignmentType.NOT_APPLICABLE]: 'Not Applicable',
};

export const JurisdictionTypeLabel = {
  [JurisdictionType.NOT_APPLICABLE]: 'Not Applicable',
};

export const AgeGroupSelectItems = [
  {
    label: AgeGroupLabel[AgeGroup.EXACT_AGE],
    value: AgeGroup.EXACT_AGE,
  },
  {
    label: AgeGroupLabel[AgeGroup.AGE_RANGE],
    value: AgeGroup.AGE_RANGE,
  },
  {
    label: AgeGroupLabel[AgeGroup.OVER_NINETY_EIGHT_YEARS_OLD],
    value: AgeGroup.OVER_NINETY_EIGHT_YEARS_OLD,
  },
  {
    label: AgeGroupLabel[AgeGroup.UNKNOWN],
    value: AgeGroup.UNKNOWN,
  },
];

export const OffenderTypeSelectItems = [
  {
    label: OffenderTypeLabel[OffenderType.SUSPECT],
    value: OffenderType.SUSPECT,
  },
  {
    label: OffenderTypeLabel[OffenderType.ACCOMPLICE],
    value: OffenderType.ACCOMPLICE,
  },
  {
    label: OffenderTypeLabel[OffenderType.CLEARED],
    value: OffenderType.CLEARED,
  },
  {
    label: OffenderTypeLabel[OffenderType.CHARGED],
    value: OffenderType.CHARGED,
  },
];

export const OffenderConditionSelectItems = [
  {
    label: `${OffenderCondition.APPEARS_TO_BE_IMPAIRED_WITH_DRUGS} = ${
      OffenderConditionLabel[
        OffenderCondition.APPEARS_TO_BE_IMPAIRED_WITH_DRUGS
      ]
    }`,
    value: OffenderCondition.APPEARS_TO_BE_IMPAIRED_WITH_DRUGS,
  },
  {
    label: `${OffenderCondition.APPEARS_TO_BE_IMPAIRED_WITH_ALCOHOL} = ${
      OffenderConditionLabel[
        OffenderCondition.APPEARS_TO_BE_IMPAIRED_WITH_ALCOHOL
      ]
    }`,
    value: OffenderCondition.APPEARS_TO_BE_IMPAIRED_WITH_ALCOHOL,
  },
  {
    label: `${OffenderCondition.APPEARS_TO_BE_MENTALLY_DISORDERED} = ${
      OffenderConditionLabel[
        OffenderCondition.APPEARS_TO_BE_MENTALLY_DISORDERED
      ]
    }`,
    value: OffenderCondition.APPEARS_TO_BE_MENTALLY_DISORDERED,
  },
  {
    label: `${OffenderCondition.APPEARS_TO_BE_INJURED_OR_ILL} = ${
      OffenderConditionLabel[OffenderCondition.APPEARS_TO_BE_INJURED_OR_ILL]
    }`,
    value: OffenderCondition.APPEARS_TO_BE_INJURED_OR_ILL,
  },
  {
    label: `${OffenderCondition.APPARENTLY_NORMAL} = ${
      OffenderConditionLabel[OffenderCondition.APPARENTLY_NORMAL]
    }`,
    value: OffenderCondition.APPARENTLY_NORMAL,
  },
  {
    label: `${OffenderCondition.NOT_REPORTED} = ${
      OffenderConditionLabel[OffenderCondition.NOT_REPORTED]
    }`,
    value: OffenderCondition.NOT_REPORTED,
  },
  {
    label: `${OffenderCondition.UNKNOWN} = ${
      OffenderConditionLabel[OffenderCondition.UNKNOWN]
    }`,
    value: OffenderCondition.UNKNOWN,
  },
];

export const ResidenceStatusSelectItems = [
  {
    label: ResidenceStatusLabel[ResidenceStatus.RESIDENT],
    value: ResidenceStatus.RESIDENT,
  },
  {
    label: ResidenceStatusLabel[ResidenceStatus.NONRESIDENT],
    value: ResidenceStatus.NONRESIDENT,
  },
  {
    label: ResidenceStatusLabel[ResidenceStatus.FOREIGN_NONRESIDENT],
    value: ResidenceStatus.FOREIGN_NONRESIDENT,
  },
  {
    label: ResidenceStatusLabel[ResidenceStatus.UNKNOWN],
    value: ResidenceStatus.UNKNOWN,
  },
];
export const VictimTypeSelectItems = [
  {
    label: `${VictimType.FIRE_FIGHTER} = ${
      VictimTypeLabel[VictimType.FIRE_FIGHTER]
    }`,
    value: VictimType.FIRE_FIGHTER,
  },
  {
    label: `${VictimType.INDIVIDUAL} = ${
      VictimTypeLabel[VictimType.INDIVIDUAL]
    }`,
    value: VictimType.INDIVIDUAL,
  },
  {
    label: `${VictimType.LAW_ENFORCEMENT_OFFICER} = ${
      VictimTypeLabel[VictimType.LAW_ENFORCEMENT_OFFICER]
    }`,
    value: VictimType.LAW_ENFORCEMENT_OFFICER,
  },
  {
    label: `${VictimType.BUSINESS} = ${VictimTypeLabel[VictimType.BUSINESS]}`,
    value: VictimType.BUSINESS,
  },
  {
    label: `${VictimType.FINANCIAL_INSTITUTION} = ${
      VictimTypeLabel[VictimType.FINANCIAL_INSTITUTION]
    }`,
    value: VictimType.FINANCIAL_INSTITUTION,
  },
  {
    label: `${VictimType.GOVERNMENT} = ${
      VictimTypeLabel[VictimType.GOVERNMENT]
    }`,
    value: VictimType.GOVERNMENT,
  },
  {
    label: `${VictimType.RELIGIOUS_ORGANIZATION} = ${
      VictimTypeLabel[VictimType.RELIGIOUS_ORGANIZATION]
    }`,
    value: VictimType.RELIGIOUS_ORGANIZATION,
  },
  {
    label: `${VictimType.SOCIETY_PUBLIC} = ${
      VictimTypeLabel[VictimType.SOCIETY_PUBLIC]
    }`,
    value: VictimType.SOCIETY_PUBLIC,
  },
  {
    label: `${VictimType.OTHER} = ${VictimTypeLabel[VictimType.OTHER]}`,
    value: VictimType.OTHER,
  },
  {
    label: `${VictimType.UNKNOWN} = ${VictimTypeLabel[VictimType.UNKNOWN]}`,
    value: VictimType.UNKNOWN,
  },
];

export const LevelOfInjurySelectItems = [
  {
    label: `${LevelOfInjury.DEAD} = ${LevelOfInjuryLabel[LevelOfInjury.DEAD]}`,
    value: LevelOfInjury.DEAD,
  },
  {
    label: `${LevelOfInjury.APPEARED_TO_BE_SERIOUSLY_INJURED} = ${
      LevelOfInjuryLabel[LevelOfInjury.APPEARED_TO_BE_SERIOUSLY_INJURED]
    }`,
    value: LevelOfInjury.APPEARED_TO_BE_SERIOUSLY_INJURED,
  },
  {
    label: `${LevelOfInjury.APPEARED_TO_BE_PHYSICALLY_INJURED} = ${
      LevelOfInjuryLabel[LevelOfInjury.APPEARED_TO_BE_PHYSICALLY_INJURED]
    }`,
    value: LevelOfInjury.APPEARED_TO_BE_PHYSICALLY_INJURED,
  },
  {
    label: `${LevelOfInjury.NOT_INJURED} = ${
      LevelOfInjuryLabel[LevelOfInjury.NOT_INJURED]
    }`,
    value: LevelOfInjury.NOT_INJURED,
  },
  {
    label: `${LevelOfInjury.NOT_APPLICABLE} = ${
      LevelOfInjuryLabel[LevelOfInjury.NOT_APPLICABLE]
    }`,
    value: LevelOfInjury.NOT_APPLICABLE,
  },
  {
    label: `${LevelOfInjury.NOT_REPORTED} = ${
      LevelOfInjuryLabel[LevelOfInjury.NOT_REPORTED]
    }`,
    value: LevelOfInjury.NOT_REPORTED,
  },
  {
    label: `${LevelOfInjury.UNKNOWN} = ${
      LevelOfInjuryLabel[LevelOfInjury.UNKNOWN]
    }`,
    value: LevelOfInjury.UNKNOWN,
  },
];

export const InjuryTypeSelectItems = [
  {
    label: `${InjuryType.NONE} = ${InjuryTypeLabel[InjuryType.NONE]}`,
    value: InjuryType.NONE,
  },
  {
    label: `${InjuryType.APPARENT_BROKEN_BONES} = ${
      InjuryTypeLabel[InjuryType.APPARENT_BROKEN_BONES]
    }`,
    value: InjuryType.APPARENT_BROKEN_BONES,
  },
  {
    label: `${InjuryType.POSSIBLE_INTERNAL_INJURY} = ${
      InjuryTypeLabel[InjuryType.POSSIBLE_INTERNAL_INJURY]
    }`,
    value: InjuryType.POSSIBLE_INTERNAL_INJURY,
  },
  {
    label: `${InjuryType.SEVERE_LACERATION} = ${
      InjuryTypeLabel[InjuryType.SEVERE_LACERATION]
    }`,
    value: InjuryType.SEVERE_LACERATION,
  },
  {
    label: `${InjuryType.LOSS_OF_TEETH} = ${
      InjuryTypeLabel[InjuryType.LOSS_OF_TEETH]
    }`,
    value: InjuryType.LOSS_OF_TEETH,
  },
  {
    label: `${InjuryType.UNCONSCIOUSNESS} = ${
      InjuryTypeLabel[InjuryType.UNCONSCIOUSNESS]
    }`,
    value: InjuryType.UNCONSCIOUSNESS,
  },
  {
    label: `${InjuryType.OTHER_MAJOR_INJURY} = ${
      InjuryTypeLabel[InjuryType.OTHER_MAJOR_INJURY]
    }`,
    value: InjuryType.OTHER_MAJOR_INJURY,
  },
  {
    label: `${InjuryType.APPARENT_MINOR_INJURY} = ${
      InjuryTypeLabel[InjuryType.APPARENT_MINOR_INJURY]
    }`,
    value: InjuryType.APPARENT_MINOR_INJURY,
  },
  {
    label: `${InjuryType.NOT_APPLICABLE} = ${
      InjuryTypeLabel[InjuryType.NOT_APPLICABLE]
    }`,
    value: InjuryType.NOT_APPLICABLE,
  },
  {
    label: `${InjuryType.NOT_REPORTED} = ${
      InjuryTypeLabel[InjuryType.NOT_REPORTED]
    }`,
    value: InjuryType.NOT_REPORTED,
  },
  {
    label: `${InjuryType.UNKNOWN} = ${InjuryTypeLabel[InjuryType.UNKNOWN]}`,
    value: InjuryType.UNKNOWN,
  },
];

export const MedicalTreatmentSelectItems = [
  {
    label: `${MedicalTreatment.HOSPITALIZED} = ${
      MedicalTreatmentLabel[MedicalTreatment.HOSPITALIZED]
    }`,
    value: MedicalTreatment.HOSPITALIZED,
  },
  {
    label: `${MedicalTreatment.TREATED_AND_RELEASED} = ${
      MedicalTreatmentLabel[MedicalTreatment.TREATED_AND_RELEASED]
    }`,
    value: MedicalTreatment.TREATED_AND_RELEASED,
  },
  {
    label: `${MedicalTreatment.REFUSED_TREATMENT} = ${
      MedicalTreatmentLabel[MedicalTreatment.REFUSED_TREATMENT]
    }`,
    value: MedicalTreatment.REFUSED_TREATMENT,
  },
  {
    label: `${MedicalTreatment.NOT_TREATED} = ${
      MedicalTreatmentLabel[MedicalTreatment.NOT_TREATED]
    }`,
    value: MedicalTreatment.NOT_TREATED,
  },
  {
    label: `${MedicalTreatment.TREATED_AT_SCENE} = ${
      MedicalTreatmentLabel[MedicalTreatment.TREATED_AT_SCENE]
    }`,
    value: MedicalTreatment.TREATED_AT_SCENE,
  },
  {
    label: `${MedicalTreatment.NOT_APPLICABLE} = ${
      MedicalTreatmentLabel[MedicalTreatment.NOT_APPLICABLE]
    }`,
    value: MedicalTreatment.NOT_APPLICABLE,
  },
  {
    label: `${MedicalTreatment.NOT_INDICATED_IN_REPORT} = ${
      MedicalTreatmentLabel[MedicalTreatment.NOT_INDICATED_IN_REPORT]
    }`,
    value: MedicalTreatment.NOT_INDICATED_IN_REPORT,
  },
  {
    label: `${MedicalTreatment.UNKNOWN} = ${
      MedicalTreatmentLabel[MedicalTreatment.UNKNOWN]
    }`,
    value: MedicalTreatment.UNKNOWN,
  },
];

export const VictimResidenceStatusSelectItems = [
  {
    label: `${VictimResidenceStatus.RESIDENT} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.RESIDENT]
    }`,
    value: VictimResidenceStatus.RESIDENT,
  },
  {
    label: `${VictimResidenceStatus.COMMUTER} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.COMMUTER]
    }`,
    value: VictimResidenceStatus.COMMUTER,
  },
  {
    label: `${VictimResidenceStatus.TOURIST} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.TOURIST]
    }`,
    value: VictimResidenceStatus.TOURIST,
  },
  {
    label: `${VictimResidenceStatus.MILITARY} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.MILITARY]
    }`,
    value: VictimResidenceStatus.MILITARY,
  },
  {
    label: `${VictimResidenceStatus.STUDENT} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.STUDENT]
    }`,
    value: VictimResidenceStatus.STUDENT,
  },
  {
    label: `${VictimResidenceStatus.TEMPORARY_RESIDENT} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.TEMPORARY_RESIDENT]
    }`,
    value: VictimResidenceStatus.TEMPORARY_RESIDENT,
  },
  {
    label: `${VictimResidenceStatus.OTHER_STATUS} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.OTHER_STATUS]
    }`,
    value: VictimResidenceStatus.OTHER_STATUS,
  },
  {
    label: `${VictimResidenceStatus.HOMELESS} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.HOMELESS]
    }`,
    value: VictimResidenceStatus.HOMELESS,
  },
  {
    label: `${VictimResidenceStatus.NOT_APPLICABLE} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.NOT_APPLICABLE]
    }`,
    value: VictimResidenceStatus.NOT_APPLICABLE,
  },
  {
    label: `${VictimResidenceStatus.NOT_REPORTED} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.NOT_REPORTED]
    }`,
    value: VictimResidenceStatus.NOT_REPORTED,
  },
  {
    label: `${VictimResidenceStatus.UNKNOWN} = ${
      VictimResidenceStatusLabel[VictimResidenceStatus.UNKNOWN]
    }`,
    value: VictimResidenceStatus.UNKNOWN,
  },
];

export const OfficerActivityTypeSelectItems = [
  {
    label: `${OfficerActivityType.RESPONDING_TO_DISTURBANCE_CALL} = ${
      OfficerActivityTypeLabel[
        OfficerActivityType.RESPONDING_TO_DISTURBANCE_CALL
      ]
    }`,
    value: OfficerActivityType.RESPONDING_TO_DISTURBANCE_CALL,
  },
  {
    label: `${OfficerActivityType.BURGLARIES_IN_PROGRESS} =
      ${OfficerActivityTypeLabel[OfficerActivityType.BURGLARIES_IN_PROGRESS]}`,
    value: OfficerActivityType.BURGLARIES_IN_PROGRESS,
  },
  {
    label: `${OfficerActivityType.ROBBERIES_IN_PROGRESS} = ${
      OfficerActivityTypeLabel[OfficerActivityType.ROBBERIES_IN_PROGRESS]
    }`,
    value: OfficerActivityType.ROBBERIES_IN_PROGRESS,
  },
  {
    label: `${OfficerActivityType.ATTEMPTING_OTHER_ARRESTS} = ${
      OfficerActivityTypeLabel[OfficerActivityType.ATTEMPTING_OTHER_ARRESTS]
    }`,
    value: OfficerActivityType.ATTEMPTING_OTHER_ARRESTS,
  },
  {
    label: `${OfficerActivityType.CIVIL_DISORDER} = ${
      OfficerActivityTypeLabel[OfficerActivityType.CIVIL_DISORDER]
    }`,
    value: OfficerActivityType.CIVIL_DISORDER,
  },
  {
    label: `${
      OfficerActivityType.HANDLING_TRANSPORTING_CUSTODY_OF_PRISONERS
    } = ${
      OfficerActivityTypeLabel[
        OfficerActivityType.HANDLING_TRANSPORTING_CUSTODY_OF_PRISONERS
      ]
    }`,
    value: OfficerActivityType.HANDLING_TRANSPORTING_CUSTODY_OF_PRISONERS,
  },
  {
    label: `${OfficerActivityType.INVESTIGATING_SUSPICIOUS_PERSONS} = ${
      OfficerActivityTypeLabel[
        OfficerActivityType.INVESTIGATING_SUSPICIOUS_PERSONS
      ]
    }`,
    value: OfficerActivityType.INVESTIGATING_SUSPICIOUS_PERSONS,
  },
  {
    label: `${OfficerActivityType.AMBUSH_NO_WARNING} = ${
      OfficerActivityTypeLabel[OfficerActivityType.AMBUSH_NO_WARNING]
    }`,
    value: OfficerActivityType.AMBUSH_NO_WARNING,
  },
  {
    label: `${OfficerActivityType.HANDLING_PERSONS_WITH_MENTAL_ILLNESS} = ${
      OfficerActivityTypeLabel[
        OfficerActivityType.HANDLING_PERSONS_WITH_MENTAL_ILLNESS
      ]
    }`,
    value: OfficerActivityType.HANDLING_PERSONS_WITH_MENTAL_ILLNESS,
  },
  {
    label: `${OfficerActivityType.TRAFFIC_PURSUITS_AND_STOPS} = ${
      OfficerActivityTypeLabel[OfficerActivityType.TRAFFIC_PURSUITS_AND_STOPS]
    }`,
    value: OfficerActivityType.TRAFFIC_PURSUITS_AND_STOPS,
  },
  {
    label: `${OfficerActivityType.ALL_OTHER} = ${
      OfficerActivityTypeLabel[OfficerActivityType.ALL_OTHER]
    }`,
    value: OfficerActivityType.ALL_OTHER,
  },
  {
    label: `${OfficerActivityType.NOT_APPLICABLE} = ${
      OfficerActivityTypeLabel[OfficerActivityType.NOT_APPLICABLE]
    }`,
    value: OfficerActivityType.NOT_APPLICABLE,
  },
];

export const OfficerAssignmentTypeSelectItems = [
  {
    label: `${OfficerAssignmentType.TWO_OFFICER_VEHICLE} = ${
      OfficerAssignmentTypeLabel[OfficerAssignmentType.TWO_OFFICER_VEHICLE]
    }`,
    value: OfficerAssignmentType.TWO_OFFICER_VEHICLE,
  },
  {
    label: `${OfficerAssignmentType.ONE_OFFICER_VEHICLE_ALONE} = ${
      OfficerAssignmentTypeLabel[
        OfficerAssignmentType.ONE_OFFICER_VEHICLE_ALONE
      ]
    }`,
    value: OfficerAssignmentType.ONE_OFFICER_VEHICLE_ALONE,
  },
  {
    label: `${OfficerAssignmentType.ONE_OFFICER_VEHICLE_ASSISTED} = ${
      OfficerAssignmentTypeLabel[
        OfficerAssignmentType.ONE_OFFICER_VEHICLE_ASSISTED
      ]
    }`,
    value: OfficerAssignmentType.ONE_OFFICER_VEHICLE_ASSISTED,
  },
  {
    label: `${OfficerAssignmentType.DETECTIVE_OR_SPECIAL_ASSIGNMENT_ALONE} = ${
      OfficerAssignmentTypeLabel[
        OfficerAssignmentType.DETECTIVE_OR_SPECIAL_ASSIGNMENT_ALONE
      ]
    }`,
    value: OfficerAssignmentType.DETECTIVE_OR_SPECIAL_ASSIGNMENT_ALONE,
  },
  {
    label: `${
      OfficerAssignmentType.DETECTIVE_OR_SPECIAL_ASSIGNMENT_ASSISTED
    } = ${
      OfficerAssignmentTypeLabel[
        OfficerAssignmentType.DETECTIVE_OR_SPECIAL_ASSIGNMENT_ASSISTED
      ]
    }`,
    value: OfficerAssignmentType.DETECTIVE_OR_SPECIAL_ASSIGNMENT_ASSISTED,
  },
  {
    label: `${OfficerAssignmentType.OTHER_ALONE} = ${
      OfficerAssignmentTypeLabel[OfficerAssignmentType.OTHER_ALONE]
    }`,
    value: OfficerAssignmentType.OTHER_ALONE,
  },
  {
    label: `${OfficerAssignmentType.OTHER_ASSISTED} = ${
      OfficerAssignmentTypeLabel[OfficerAssignmentType.OTHER_ASSISTED]
    }`,
    value: OfficerAssignmentType.OTHER_ASSISTED,
  },
  {
    label: `${OfficerAssignmentType.NOT_APPLICABLE} = ${
      OfficerAssignmentTypeLabel[OfficerAssignmentType.NOT_APPLICABLE]
    }`,
    value: OfficerAssignmentType.NOT_APPLICABLE,
  },
];

export const JurisdictionTypeSelectItems = [
  {
    label: `${JurisdictionType.NOT_APPLICABLE} = ${
      JurisdictionTypeLabel[JurisdictionType.NOT_APPLICABLE]
    }`,
    value: JurisdictionType.NOT_APPLICABLE,
  },
];

export const PersonVictimTypes = [
  VictimType.FIRE_FIGHTER,
  VictimType.INDIVIDUAL,
  VictimType.LAW_ENFORCEMENT_OFFICER,
];

export const TabNamesNextPaths = {
  nameList: [
    'MasterAddName',
    'MasterNameSetInvolvement',
    'MasterNameNotes',
    'MasterNameView',
  ],
  MasterAddName: ['MasterNameView'],
  MasterNameView: ['CFSInvolvementHistory', 'KasesInvolvementHistory'],
  driverLicense: ['MasterAddDriverLicense', 'MasterNameDriverLicenseView'],
  MasterAddNameDetails: [
    'MasterLookupName',
    'MasterNameDriverLicense',
    'driverLicense',
  ],
};

export enum TabNamesScreens {
  CFSInvolvementHistory = 'CFSInvolvementHistory',
  KasesInvolvementHistory = 'KasesInvolvementHistory',
  KasesAddUnknownPerson = 'KasesAddUnknownPerson',
  KasesAddUnknownOrganization = 'KasesAddUnknownOrganization',
  MasterAddDriverLicense = 'MasterAddDriverLicense',
  MasterNameKaseOrganizationForm = 'MasterNameKaseOrganizationForm',
  MasterAddName = 'MasterAddName',
  MasterAddNameDetails = 'MasterAddNameDetails',
  MasterLookupName = 'MasterLookupName',
  MasterNameKaseInvolvement = 'MasterNameKaseInvolvement',
  MasterNameDriverLicense = 'MasterNameDriverLicense',
  MasterNameDriverLicenseView = 'MasterNameDriverLicenseView',
  MasterNameNotes = 'MasterNameNotes',
  MasterNameSetInvolvement = 'MasterNameSetInvolvement',
  MasterNameView = 'MasterNameView',
  NamesList = 'NamesList',
  ViewNamesDetails = 'ViewNamesDetails',
  MasterNameOrganizationView = 'MasterNameOrganizationView',
}

export const KaseNamesTableColumns: TableColumn[] = [
  { key: 'type', label: 'Type' },
  { key: 'name', label: 'Name' },
  { key: 'address', label: 'Address' },
  { key: 'involvement', label: 'Involvement' },
  { key: 'isVerified', label: 'Data Source' },
];

export const MasterNamesTableColumns: TableColumn[] = [
  { key: 'name', label: 'Name' },
  { key: 'dateOfBirth', label: 'Date of Birth' },
  { key: 'isVerified', label: 'Data Source' },
  { key: 'phone', label: 'Phone Number' },
  { key: 'involvement', label: 'Involvement' },
  { key: 'race', label: 'Race' },
];

export const MasterLookupTableColumns: TableColumn[] = [
  { key: 'name', label: 'Name' },
  { key: 'dateOfBirth', label: 'Date of Birth' },
  { key: 'race', label: 'Race' },
  { key: 'ssn', label: 'Social security number' },
  { key: 'gender', label: 'Gender' },
];

export const setInvolvementNameFormFields = [
  {
    name: 'behavior',
    type: 'autocomplete',
    label: 'Behavior',
    items: 'getBehaviorItems',
  },
  {
    name: 'involvement',
    type: 'autocomplete',
    multiple: true,
    label: 'Involvement',
    items: 'getInvolvementItems',
  },
];

export const setInvolvementKaseFormFields = [
  {
    name: 'behavior',
    type: 'autocomplete',
    label: 'Behavior',
    items: 'getBehaviorItems',
    disableClearable: true,
    rules: {
      required: 'This field is required.',
    },
  },
  {
    name: 'involvement',
    type: 'autocomplete',
    label: 'Involvement',
    items: 'getInvolvementItems',
    rules: {
      required: 'This field is required.',
    },
  },
];

export const booleanTypesItems = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const ageFields = [
  {
    name: 'ageGroup',
    type: 'select',
    label: 'Age Group',
    items: AgeGroupSelectItems,
    shouldDisplayCallback: 'checkDobNotSet',
  },
  {
    name: 'age',
    type: 'number',
    label: 'Age',
    shouldDisplayCallback: 'checkExactAge',
    max: 98,
    rules: {
      validate: (currentValue: string, formValues: kaseOffenderForm) => {
        if (parseInt(currentValue) < 0) {
          return 'Age cannot be negative. Please enter a valid age.';
        }

        if (parseInt(currentValue) > 98) {
          return 'Age should not be more than 98. Please enter a valid age.';
        }

        return formValues.ageGroup === AgeGroup.EXACT_AGE && !currentValue
          ? 'This field is required.'
          : true;
      },
    },
  },
  {
    name: 'ageRange',
    type: 'range',
    label: 'Age range',
    shouldDisplayCallback: 'checkAgeRange',
    defaultValue: [0, 20],
    max: 98,
    rules: {
      validate: (currentValue: string[], formValues: kaseOffenderForm) => {
        if (formValues.ageGroup === AgeGroup.AGE_RANGE && !currentValue) {
          return 'This field is required.';
        }

        // conider when value is not an array but a number
        const [startAge, endAge] = Array.isArray(currentValue)
          ? currentValue
          : [0, Number(currentValue)];
        if (Number(startAge) >= Number(endAge)) {
          return 'Start age should be less than end age.';
        }

        if (Number(endAge) > 98) {
          return 'Age should not be more than 98.';
        }

        return true;
      },
    },
  },
];

export const KaseOffenderFields: Array<IFieldItem> = [
  {
    name: 'offenderType',
    type: 'autocomplete',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    label: 'Offender Type',
    items: OffenderTypeSelectItems,
  },
  {
    name: 'wasArrested',
    type: 'select',
    label: 'Was Arrested',
    items: booleanTypesItems,
    shouldDisplayCallback: 'checkOffenderType',
  },
  {
    name: 'offenderCondition',
    type: 'autocomplete',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    label: 'Offender Condition',
    items: OffenderConditionSelectItems,
  },
  ...ageFields,
  {
    name: 'residenceStatus',
    type: 'autocomplete',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    label: 'Arrestee Residence Status',
    items: ResidenceStatusSelectItems,
    shouldDisplayCallback: 'checkWasArrested',
  },
];

export const caseVictimFields: Array<IFieldItem> = [
  {
    name: 'victimType',
    type: 'autocomplete',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    label: 'Victim Type',
    items: 'getVictimTypeSelectItems',
  },
  {
    name: 'interviewedBy',
    type: 'autocomplete',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    label: 'Interviewed By',
    items: 'getUsers',
  },
  {
    name: 'levelOfInjury',
    type: 'autocomplete',
    label: 'Level of Injury ',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    items: LevelOfInjurySelectItems,
    rules: {
      validate: (currentValue: string, formValues: kaseVictimForm) => {
        if (formValues.victimType && currentValue) {
          if (
            PersonVictimTypes.includes(formValues.victimType) &&
            currentValue === LevelOfInjury.NOT_APPLICABLE
          ) {
            return 'Level of Injury must be applicable for this victim type';
          } else if (
            !PersonVictimTypes.includes(formValues.victimType) &&
            currentValue !== LevelOfInjury.NOT_APPLICABLE
          ) {
            return 'Level of Injury must be "Not applicable" for this victim type';
          }
        }
        return true;
      },
    },
  },
  {
    name: 'injuryType',
    type: 'autocomplete',
    label: 'Injury Type',
    multiple: true,
    items: InjuryTypeSelectItems,
    rules: {
      validate: (currentValue: string[], formValues: kaseVictimForm) => {
        if (formValues.victimType && currentValue) {
          if (currentValue.length > 5) {
            return 'Please select 5 items at maximum';
          } else if (
            PersonVictimTypes.includes(formValues.victimType) &&
            currentValue.some((v) => v === InjuryType.NOT_APPLICABLE)
          ) {
            return 'Injury Type must be applicable for this victim type';
          } else if (
            !PersonVictimTypes.includes(formValues.victimType) &&
            !currentValue.every((v) => v === InjuryType.NOT_APPLICABLE)
          ) {
            return 'Injury Type must be "Not applicable" for this victim type';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
  },
  {
    name: 'medicalTreatment',
    type: 'autocomplete',
    label: 'Medical Treatment ',
    items: MedicalTreatmentSelectItems,
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    rules: {
      validate: (currentValue: string, formValues: kaseVictimForm) => {
        if (formValues.victimType && currentValue) {
          if (
            PersonVictimTypes.includes(formValues.victimType) &&
            currentValue === MedicalTreatment.NOT_APPLICABLE
          ) {
            return 'Medical Treatment must be applicable for this victim type';
          } else if (
            !PersonVictimTypes.includes(formValues.victimType) &&
            currentValue !== MedicalTreatment.NOT_APPLICABLE
          ) {
            return 'Medical Treatment must be "Not applicable" for this victim type';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
  },

  ...ageFields,
  {
    name: 'residenceStatus',
    type: 'autocomplete',
    label: 'Victim Residence Status',
    items: VictimResidenceStatusSelectItems,
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
  },
  {
    name: 'officerActivityType',
    type: 'autocomplete',
    label: 'Officer Activity/Circumstance Type (LEOKA)',
    shouldDisplayCallback: 'isOfficerVictim',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    items: OfficerActivityTypeSelectItems,
    rules: {
      validate: (currentValue: string, formValues: kaseVictimForm) => {
        if (formValues.victimType && currentValue) {
          if (
            formValues.victimType === VictimType.LAW_ENFORCEMENT_OFFICER &&
            currentValue === OfficerActivityType.NOT_APPLICABLE
          ) {
            return 'It must be applicable for this victim type';
          } else if (
            formValues.victimType !== VictimType.LAW_ENFORCEMENT_OFFICER &&
            currentValue !== OfficerActivityType.NOT_APPLICABLE
          ) {
            return 'It must be "Not applicable" for this victim type';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
  },
  {
    name: 'officerAssignmentType',
    type: 'autocomplete',
    label: 'Officer Assignment Type (LEOKA)',
    shouldDisplayCallback: 'isOfficerVictim',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    items: OfficerAssignmentTypeSelectItems,
    rules: {
      validate: (currentValue: string, formValues: kaseVictimForm) => {
        if (formValues.victimType && currentValue) {
          if (
            formValues.victimType === VictimType.LAW_ENFORCEMENT_OFFICER &&
            currentValue === OfficerAssignmentType.NOT_APPLICABLE
          ) {
            return 'It must be applicable for this victim type';
          } else if (
            formValues.victimType !== VictimType.LAW_ENFORCEMENT_OFFICER &&
            currentValue !== OfficerAssignmentType.NOT_APPLICABLE
          ) {
            return 'It must be "Not applicable" for this victim type';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
  },
  {
    name: 'jurisdiction',
    type: 'autocomplete',
    label: 'Officer ORI/Jurisdiction (LEOKA)',
    shouldDisplayCallback: 'isOfficerVictim',
    items: JurisdictionTypeSelectItems,
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    rules: {
      validate: (currentValue: string, formValues: kaseVictimForm) => {
        if (formValues.victimType && currentValue) {
          if (
            formValues.victimType === VictimType.LAW_ENFORCEMENT_OFFICER &&
            currentValue === JurisdictionType.NOT_APPLICABLE
          ) {
            return 'It must be applicable for this victim type';
          } else if (
            formValues.victimType !== VictimType.LAW_ENFORCEMENT_OFFICER &&
            currentValue !== JurisdictionType.NOT_APPLICABLE
          ) {
            return 'It must be "Not applicable" for this victim type';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
  },
];

export const caseWitnessFields: Array<IFieldItem> = [
  {
    name: 'interviewedBy',
    type: 'autocomplete',
    label: 'Interviewed By',
    items: 'getUsers',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
  },
  ...ageFields,
  {
    name: 'residenceStatus',
    type: 'autocomplete',
    freeSolo: true,
    clearOnBlur: false,
    addNew: false,
    label: 'Witness Residence Status',
    items: VictimResidenceStatusSelectItems,
  },
];
export const kasesInvolvementFields = new Map([
  [KaseMasterNameInvolvement.OFFENDER, KaseOffenderFields],
  [KaseMasterNameInvolvement.VICTIM, caseVictimFields],
  [KaseMasterNameInvolvement.WITNESS, caseWitnessFields],
]);

export const tabNameNotesTableColumns: TableColumn[] = [
  { key: 'description', label: 'Note description' },
  { key: 'createdBy', label: 'Created by' },
  { key: 'createdAt', label: 'Date' },
  { key: 'cfs', label: 'Referenced items' },
];

export const OrganizationInvolvementKaseDisabled = [
  'behavior',
  'involvement',
  'victimType',
];

export const OrganizationInvolvementKaseHidden = [
  'levelOfInjury',
  'injuryType',
  'medicalTreatment',
  'ageGroup',
  'age',
  'residenceStatus',
  'interviewedBy',
  'behavior',
];

export const DriverLicenseFormFields = [
  //TODO : remove this after BE is updated
  // {
  //   key: 'information',
  //   title: 'Identity',
  //   items: [
  //     {
  //       name: 'firstName',
  //       label: 'First Name',
  //       rules: {
  //         maxLength: {
  //           value: 50,
  //           message: 'Name should be less than 50 characters.',
  //         },
  //       },
  //       grid: { md: 3 },
  //     },
  //     {
  //       name: 'middleName',
  //       label: 'Middle Name',
  //       rules: {
  //         maxLength: {
  //           value: 50,
  //           message: 'Middle Name should be less than 50 characters.',
  //         },
  //       },
  //       grid: { md: 3 },
  //     },
  //     {
  //       name: 'lastName',
  //       label: 'Last Name',
  //       rules: {
  //         maxLength: {
  //           value: 50,
  //           message: 'Last Name should be less than 50 characters.',
  //         },
  //       },
  //       grid: { md: 3 },
  //     },
  //     {
  //       ...genderInput,
  //       name: 'gender',
  //       grid: { md: 2 },
  //       rules: {
  //         required: 'This field is required.',
  //       },
  //     },
  //     {
  //       name: 'height',
  //       type: 'number',
  //       label: 'Height',
  //       grid: {
  //         md: 1,
  //       },
  //     },

  //     {
  //       name: 'birthday',
  //       type: 'date',
  //       label: 'Date of birth',
  //     },

  //     eyeColorInput,
  //   ],
  // },
  {
    key: 'licenseInfo',
    title: 'License Info',
    items: [
      {
        name: 'number',
        label: 'ID number',
        type: 'text',
        rules: {
          required: 'This field is required.',
          maxLength: {
            value: 20,
            message: 'ID number should be less than 20 characters.',
          },
        },
      },
      {
        name: 'state',
        label: 'State',
        type: 'autocomplete',
        items: USStates,
        rules: {
          required: 'This field is required.',
          // TODO: this is invalid ?
          min: 2,
          max: 2,
          matches: {
            pattern: /^[A-Z]*$/,
            errorMessage: 'can contain only uppercase letters',
          },
        },
      },
      {
        name: 'issueDate',
        type: 'date',
        label: 'Issue date',
      },
      {
        name: 'expireDate',
        type: 'date',
        label: 'Expiration date',
      },
    ],
  },
  {
    key: 'personalInfo',
    title: 'Personal Info',
    items: [
      {
        name: 'address',
        type: 'address',
        label: 'Address',
        grid: {
          md: 6,
        },
        rules: {
          required: 'This field is required',
        },
      },
    ],
  },
];

export const DriverDetailsViewFields = [
  { label: 'Name', key: 'firstName' },
  { label: 'Middle Name', key: 'middleName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'ID number', key: 'number' },
  { label: 'Date of birth', key: 'birthday' },
  { label: 'State', key: 'state' },
  { label: 'Address', key: 'address.addressLabel' },
  { label: 'City', key: 'address.city' },
  { label: 'State', key: 'address.state' },
  { label: 'Zipcode', key: 'address.zipcode' },
  { label: 'Issue date', key: 'issuedate' },
  { label: 'Expiration date', key: 'expirationDate' },
  { label: 'Gender', key: 'gender' },
  { label: 'Height', key: 'height' },
  { label: 'Eye color', key: 'eyeColor' },
];

export const kasesHistoryTableColumns: TableColumn[] = [
  { key: '_id', label: 'Case ID' },
  { key: 'historyDate', label: 'Date' },
  { key: 'involvement', label: 'Involvement' },
];

export const cfsHistoryTableColumns: TableColumn[] = [
  { key: 'cfs.number', label: 'CFS ID' },
  { key: 'createdAt', label: 'Date created' },
  { key: 'relationship.behavior', label: 'Behavior' },
  { key: 'relationship.involvement', label: 'Involvement' },
];

export type TableRowHistory = {
  _id: string;
  historyDate: string;
  involvement: string;
};

export const kasesHistoryDummyData: TableRowHistory[] = [
  {
    _id: '1',
    historyDate: '10.11.2023',
    involvement: 'Involvement',
  },
  {
    _id: '2',
    historyDate: '10.11.2023',
    involvement: 'Involvement',
  },
  {
    _id: '3',
    historyDate: '10.11.2023',
    involvement: 'Involvement',
  },
  {
    _id: '4',
    historyDate: '10.11.2023',
    involvement: 'Involvement',
  },
];

export const MasterNameViewFields = <Array<IViewCard & { method?: string }>>[
  {
    title: 'Identity',
    key: 'Identity',
    size: { md: 2 },
    items: [
      { label: 'Suffix', value: 'suffix' },
      { label: 'First Name', value: 'firstName' },
      { label: 'Middle Name', value: 'middleName' },
      { label: 'Last Name', value: 'lastName' },
      { label: 'Alias Names', value: 'aliasName' },
      { label: 'Date of Birth', value: 'birthday' },
      { label: 'Gender', value: 'sex' },
      { label: ' Race', value: 'race' },
      { label: 'Social Security Number', value: 'ssn' },
    ],
  },
  {
    title: "Driver's license/ID Card",
    key: 'driverLicense',
    size: { md: 2 },
    items: [
      { label: 'ID Number', value: 'driverLicense.number' },
      { label: 'Address', value: 'driverLicense.address.addressLabel' },
      { label: 'State', value: 'driverLicense.state' },
      { label: 'Issue Date', value: 'driverLicense.issueDate' },
      { label: 'Expiration Date', value: 'driverLicense.expireDate' },
    ],
  },
  {
    title: 'Personal Information',
    key: 'personalInfo',
    size: { md: 2 },
    items: [
      { label: 'Address', value: 'personalInfo.address' },
      {
        label: 'Home Phone Number',
        value: 'personalInfo.phoneNumbers',
      },
    ],
  },
  {
    title: 'Employer Information',
    key: 'employerInfo',
    size: { md: 2 },
    items: [
      { label: 'Employer Name', value: 'employerInfo.name' },
      { label: 'Employer Phone Number', value: 'employerInfo.phoneNumber' },
      { label: 'Employer Address', value: 'employerInfo.address' },
    ],
  },

  {
    title: 'Physical Characteristics',
    key: 'physicalCharacteristics',
    size: { md: 2 },
    items: [
      {
        label: 'Weight',
        value: 'physicalCharacteristics.weight',
      },
      {
        label: 'Weight Unit',
        value: 'physicalCharacteristics.weightUnit',
      },
      {
        label: 'Height',
        value: 'physicalCharacteristics.height',
      },
      {
        label: 'Height Unit',
        value: 'physicalCharacteristics.heightUnit',
      },
      {
        label: 'Eye Color',
        value: 'physicalCharacteristics.eyeColor',
      },
      {
        label: 'Hair Color',
        value: 'physicalCharacteristics.hairColor',
      },
      {
        label: 'Scars, Marks and Tattoos',
        value: 'physicalCharacteristics.bodyMarking',
        size: {
          md: 3,
        },
      },
      {
        label: 'Other Physical Characteristics',
        value: 'physicalCharacteristics.others',
        size: {
          md: 3,
        },
      },
    ],
  },
  // TODO: uncomment when notest are done
  // {
  //   key: 'notes',
  //   items: [{ label: 'Notes', value: 'notes', method: 'viewAllNotes' }],
  // },
  {
    key: 'viewcfsInvolvementHistory',
    items: [{ label: 'CFS Involvement History', method: 'viewCfsHistory' }],
  },
  // TODO: uncomment when Cases masternames are done
  // {
  //   key: 'caseInvolvementHistory',
  //   items: [
  //     {
  //       label: 'Case Involvement History',
  //       method: 'KasesInvolvementHistory',
  //     },
  //   ],
  // },
  {
    key: 'verificationStatus',
    items: [
      {
        label: 'Verification Status',
        value: 'isVerified',
        method: 'checkIsVerified',
      },
    ],
  },
  {
    key: 'photos',
    title: 'Photos',
    size: { md: 2 },
  },
];

export const MasterOrganizationViewFields = <Array<IViewCard>>[
  {
    title: 'Basic information',
    key: 'basicInformation',
    size: { md: 2 },
    items: [
      {
        label: 'Organization Name',
        value: 'name',
        parser: formatOrganisationName,
      },
      {
        label: 'Organization type',
        value: 'organizationType',
        parser: formatOrganisationType,
      },
      { label: 'Address', value: 'address.normalized' },
    ],
  },
  {
    title: 'Social media links',
    key: 'SocialMediaLinks',
    multiple: 'socialMediaLinks',

    items: [{ label: 'Link' }],
  },
  {
    title: 'Phone numbers',
    key: 'phoneNumbers',
    size: { md: 5 },
    multiple: 'phoneNumbers',
    items: [
      {
        label: 'Phone number',
        value: 'phoneNumber',
        parser: formatPhoneNumber,
      },
      { label: 'Type', value: 'type', parser: formatPhoneNumberType },
    ],
  },
  {
    title: 'Contacts',
    key: 'contacts',
    multiple: 'contacts',
    size: { md: 3 },
    items: [
      { label: 'Name', value: 'name' },
      {
        label: 'Phone number',
        value: 'phoneNumber',
        parser: formatPhoneNumber,
      },
      { label: 'Phone number ext.', value: 'phoneNumberExt' },
    ],
  },
  {
    title: 'Urls',
    key: 'urls',
    multiple: 'urls',

    items: [{ label: 'URL' }],
  },
  {
    key: 'photos',
    title: 'Photos',
    size: { md: 2 },
  },
];

const { rules, ...restGender } = genderInput;

export const MasterNamePhoneNumberFormFields = [
  {
    ...phoneTypeInput,
    grid: {
      md: 4,
    },
  },
  {
    ...phoneNumberInput,
    name: 'phoneNumber',
    label: 'Phone number',
    rules: {
      ...phoneNumberInput.rules,
    },
    grid: {
      md: 4,
    },
  },
];

export const MasterNameFormFields = [
  {
    key: 'identity',
    title: 'Identity',
    items: [
      {
        name: 'suffix',
        label: 'Suffix',
      },
      {
        name: 'firstName',
        label: 'First name',
        rules: {
          required: 'This field is required.',
          maxLength: {
            value: 50,
            message: 'Name should be less than 50 characters.',
          },
        },
      },
      {
        name: 'middleName',
        label: 'Middle name',
        rules: {
          maxLength: {
            value: 50,
            message: 'Name should be less than 50 characters.',
          },
        },
      },
      {
        name: 'lastName',
        label: 'Last name',
        rules: {
          required: 'This field is required.',
          maxLength: {
            value: 50,
            message: 'Name should be less than 50 characters.',
          },
        },
      },

      {
        name: 'aliasName',
        label: 'Alias name',
      },
      {
        name: 'birthday',
        label: 'Date of birth',
        type: 'date',
        grid: {
          md: 3,
        },
      },
      {
        ...restGender,
        grid: {
          md: 3,
        },
      },
      raceInput,
      ethnicRaceInput,
      {
        name: 'ssn',
        type: 'ssn',
        label: 'Social security number',
        helperText: 'format: 000-00-0000',
        rules: {
          maxLength: {
            value: 9,
            message: 'Invalid SSN.',
          },
          minLength: {
            value: 9,
            message: 'Invalid SSN.',
          },
        },
      },
      {
        name: 'ssn_prev',
        type: 'hidden',
      },
    ],
    footer: 'renderIdentityFooter',
  },
  {
    key: 'personalInfo',
    title: 'Personal information',
    items: [
      {
        name: 'address',
        type: 'address',
        label: 'Address',
        grid: {
          md: 4,
        },
      },
      {
        name: 'phoneNumbers',
        label: 'Home phone number',
        grid: {
          md: 12,
          sm: 12,
        },
      },
    ],
  },
  {
    title: 'Employment information',
    key: 'employerInfo',
    items: [
      {
        name: 'employerInfo.name',
        type: 'text',
        label: 'Employer name',
        rules: {
          maxLength: {
            value: 50,
            message: 'Name should be less than 50 characters.',
          },
        },
        grid: {
          md: 3,
        },
      },
      {
        ...phoneNumberInput,
        name: 'employerInfo.phoneNumber',
        label: 'Employer phone number',
        grid: {
          md: 3,
        },
      },
      {
        name: 'employerInfo.address',
        type: 'address',
        label: 'Address',
        grid: {
          md: 6,
        },
      },
    ],
  },
  {
    title: 'Physical characteristics',
    key: 'physicalCharacteristics',
    items: [
      {
        name: 'physicalCharacteristics.weight',
        type: 'number',
        label: 'Weight',
        grid: {
          md: 1.5,
        },
        rules: {
          min: {
            value: 0,
            message: 'Weight cannot be negative. Please enter a valid weight.',
          },
        },
      },
      {
        name: 'physicalCharacteristics.weightUnit',
        type: 'autocomplete',
        label: 'Weight Unit',
        grid: {
          md: 1.5,
        },
        items: [
          {
            value: WeightMeasurementType.KILOGRAM,
            label: 'KG',
          },
          {
            value: WeightMeasurementType.POUND,
            label: 'Pound',
          },
          {
            value: WeightMeasurementType.STONE,
            label: 'Stone',
          },
        ],
      },
      {
        name: 'physicalCharacteristics.height',
        type: 'number',
        label: 'Height',
        grid: {
          md: 1.5,
        },
        rules: {
          min: {
            value: 0,
            message: 'Height cannot be negative. Please enter a valid height.',
          },
        },
      },
      {
        name: 'physicalCharacteristics.heightUnit',
        type: 'autocomplete',
        label: 'Height Unit',
        grid: {
          md: 1.5,
        },
        items: [
          {
            value: HeightMeasurementType.FEET,
            label: HeightMeasurementType.FEET,
          },
          {
            value: HeightMeasurementType.INCHES,
            label: HeightMeasurementType.INCHES,
          },
        ],
      },
      {
        ...eyeColorInput,
        name: 'physicalCharacteristics.eyeColor',
        grid: { md: 1.5 },
      },
      {
        name: 'physicalCharacteristics.hairColor',
        type: 'autocomplete',
        label: 'Hair color',
        grid: {
          md: 1.5,
        },
        items: [
          {
            label: 'Brown',
            value: 'BROWN',
          },
          {
            label: 'Black',
            value: 'BLACK',
          },
          {
            label: 'Amber',
            value: 'AMBER',
          },
          {
            label: 'Hazel',
            value: 'HAZEL',
          },
          {
            label: 'Green',
            value: 'GREEN',
          },
          {
            label: 'Blue',
            value: 'BLUE',
          },
          {
            label: 'Gray',
            value: 'GRAY',
          },
        ],
      },
      {
        name: 'physicalCharacteristics.bodyMarking',
        type: 'text',
        label: 'Scars, marks, and tattoos',
        grid: {
          md: 3,
        },
      },
      {
        name: 'physicalCharacteristics.others',
        type: 'autocomplete',
        multiple: true,
        label: 'Other physical characteristics',
        items: [
          { label: 'Mustache', value: OtherPhysicalCharacteristics.MUSTACHE },
          { label: 'Beared', value: OtherPhysicalCharacteristics.BEARED },
          { label: 'Big Eyes', value: OtherPhysicalCharacteristics.BIG_EYES },
          {
            label: 'Short Hair',
            value: OtherPhysicalCharacteristics.SHORT_HAIR,
          },
          { label: 'Long Hair', value: OtherPhysicalCharacteristics.LONG_HAIR },
          {
            label: 'Straight Hair',
            value: OtherPhysicalCharacteristics.STRAIGHT_HAIR,
          },
          { label: 'Curls', value: OtherPhysicalCharacteristics.CURLS },
          { label: 'Freckles', value: OtherPhysicalCharacteristics.FRECKLES },
        ],
        grid: {
          md: 3,
        },
      },
    ],
  },
  {
    key: 'photos',
    title: 'Photos',
    items: [
      {
        name: 'files',
        type: 'file',
        accept: FileAcceptedTypes,
        label: '',
        grid: {
          md: 12,
          lg: 12,
        },
      },
    ],
  },
  {
    key: 'verified',
    title: '',
    items: [
      {
        name: 'isVerified',
        type: 'checkbox',
        label: 'Verified',
        sx: {
          fontWeight: 600,
        },
        grid: {
          md: 6,
        },
      },
    ],
  },
];
export const InformationTypes = {
  URL: 'urls',
  SOCIAL_MEDIA_LINKS: 'socialMediaLinks',
  CONTACTS: 'contacts',
  PHONE_NUMBERS: 'phoneNumbers',
  EMAILS: 'emails',
} as const;

export type InformationType = keyof typeof InformationTypes;
export type InformationValues = (typeof InformationTypes)[InformationType];
export const informationTypesItems = [
  {
    label: 'choose information type',
    value: '0',
    disabled: true,
  },
  {
    label: 'URL',
    value: InformationTypes.URL,
  },

  {
    label: 'Social Media Links',
    value: InformationTypes.SOCIAL_MEDIA_LINKS,
  },
  {
    label: 'Contact',
    value: InformationTypes.CONTACTS,
  },
  {
    label: 'Phone number',
    value: InformationTypes.PHONE_NUMBERS,
  },
  {
    label: 'Email',
    value: InformationTypes.EMAILS,
  },
];

export const MasterNameKaseOrganizationFields = [
  {
    key: 'basicInformation',
    title: 'Basic information',
    items: [
      {
        name: 'name',
        label: 'Organization name',
        grid: {
          md: 4,
        },
        rules: {
          required: 'This field is required.',
          maxLength: {
            value: 50,
            message:
              'Organization name should be less than 50 characters long.',
          },
        },
      },
      {
        name: 'organizationType',
        label: 'Organization type',
        type: 'autocomplete',
        items: Object.entries(OrganizationType).map(([value, label]) => ({
          label,
          value,
        })),
        grid: {
          md: 4,
        },
      },
      {
        name: 'address',
        type: 'address',
        label: 'Address',
        grid: {
          md: 6,
        },
      },
      {
        name: 'informationTypes',
        label: 'Information type',
        type: 'autocomplete',
        items: informationTypesItems,
        defaultValue: '0',
        grid: {
          md: 12,
        },
      },
    ],
  },
  {
    key: 'photos',
    title: 'Photos',
    items: [
      {
        name: 'files',
        type: 'file',
        accept: FileAcceptedTypes,
        label: '',
        grid: {
          md: 12,
          lg: 12,
        },
      },
    ],
  },
  {
    key: 'verified',
    title: '',
    items: [
      {
        name: 'isVerified',
        type: 'checkbox',
        label: 'Verified',
        sx: {
          fontWeight: 600,
        },
        grid: {
          md: 6,
        },
      },
    ],
  },
];
export const phoneColumns = [
  'employerInfo.phoneNumber',
  'personalInfo.homePhoneNumber',
  'personalInfo.cellPhoneNumber',
];

export const infoTypeViewFields = [
  {
    title: 'Social media links',
    items: [{ label: 'link' }],
  },
  {
    title: 'Phone numbers',
    items: [
      { label: 'Phone number', value: 'phoneNumber', parser: parsePhoneNumber },
      { label: 'Type', value: 'type' },
    ],
  },
  {
    title: 'Contacts',
    items: [
      { label: 'Name', value: 'name' },
      { label: 'Phone number', value: 'phoneNumber', parser: parsePhoneNumber },
    ],
  },
  {
    title: 'Urls',
    items: [{ label: 'url' }],
  },
];
export const infoTypeMap = {
  [InformationTypes.SOCIAL_MEDIA_LINKS]: {
    addLabel: 'new social media link',
    InputFields: [
      {
        name: 'social_media_link',
        label: 'Social media link',
        rules: {
          pattern: {
            value:
              /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s]*)?$/i, // value: /[2-9]{1}[0-9]{9}$/im,
            message: 'Invalid social media link.',
          },
        },
      },
    ],
  },
  [InformationTypes.PHONE_NUMBERS]: {
    addLabel: 'new phone number',
    InputFields: [
      { ...phoneTypeInput },
      {
        ...phoneNumberInput,
        name: 'phoneNumber',
        label: 'Phone number',
      },
    ],
  },
  [InformationTypes.CONTACTS]: {
    addLabel: 'new contact',
    InputFields: [
      { name: 'name', label: 'Contact person name' },
      {
        ...phoneNumberInput,
        name: 'phoneNumber',
        label: 'Contact person phone number',
      },
      {
        type: 'pattern',
        name: 'phoneNumberExt',
        label: 'Contact person phone number ext.',
        format: '#####',
      },
    ],
  },
  [InformationTypes.URL]: {
    addLabel: 'new URL',
    InputFields: [
      {
        name: `url`,
        label: 'URL',
        rules: {
          pattern: {
            value:
              /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s]*)?$/i, // value: /[2-9]{1}[0-9]{9}$/im,
            message: 'Invalid url.',
          },
        },
      },
    ],
  },
  [InformationTypes.EMAILS]: {
    addLabel: 'new email',
    InputFields: [
      {
        name: `email`,
        type: 'email',
        label: 'Email address',
        rules: {
          required: 'This field is required.',
          pattern: {
            value:
              /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, // eslint-disable-line
            message: 'Invalid email',
          },
        },
      },
    ],
  },
};
