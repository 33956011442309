import { SidebarItems1 } from '@/layouts/data';
import { AgencyType, IAccount, IUser, Role } from '@/models';

import {
  isTargetRole,
  isAgencyTypeRole,
  hasAnyOfTargetRoles,
} from './service.role';

type IValidatorPayload = {
  user?: IUser;
  account?: IAccount;
};

interface IRouteValidator {
  path: string;
  validator: (payload: IValidatorPayload) => boolean | undefined;
}

const ProtectedRoutes: IRouteValidator[] = [
  {
    path: '/my-cfs',
    validator: ({ user }) => isTargetRole(user as IUser, Role.FIRST_RESPONDER),
  },
  {
    path: '/map-view',
    validator: ({ user }) => isTargetRole(user as IUser, Role.FIRST_RESPONDER),
  },
  {
    path: '/agencies',
    validator: ({ user }) =>
      isTargetRole(user as IUser, Role.ACCOUNT_ADMIN) ||
      isTargetRole(user as IUser, Role.AGENCY_ADMIN),
  },
  {
    path: '/cfs',
    validator: ({ user }) => isTargetRole(user as IUser, Role.DISPATCHER),
  },
  {
    path: '/cfs-active',
    validator: ({ user }) => isTargetRole(user as IUser, Role.DISPATCHER),
  },
  {
    path: '/cases',
    validator: ({ user }) => {
      if (!user) return false;
      return (
        isAgencyTypeRole(user, Role.AGENCY_ADMIN, AgencyType.POLICE) ||
        isTargetRole(user, Role.KASE_APPROVER) ||
        isTargetRole(user, Role.POLICE)
      );
    },
  },
  {
    path: '/master-index',
    validator: ({ user }) =>
      hasAnyOfTargetRoles(user as IUser, [
        Role.DISPATCHER,
        Role.AGENCY_ADMIN,
        Role.POLICE,
        Role.KASE_APPROVER,
      ]),
  },
  {
    path: '/vendors',
    validator: ({ account }) => account?.settings.features?.isEnabledVendors,
  },
  {
    path: '/inventory-locations',
    validator: ({ account }) =>
      account?.settings.features?.isEnabledInventoryLocation,
  },
  {
    path: '/tasks',
    validator: ({ user }) =>
      hasAnyOfTargetRoles(user as IUser, [
        Role.POLICE,
        Role.KASE_APPROVER,
        Role.AGENCY_ADMIN,
        Role.DISPATCHER,
      ]),
  },
  {
    path: '/tools',
    validator: ({ account }) => account?.settings.demoMode,
  },
];

// Check if user has permission to access given route
export const validateRoute = (
  path: string,
  user?: IUser,
  account?: IAccount,
) => {
  const validators = ProtectedRoutes.filter((route) =>
    path.includes(route.path),
  ).map(({ validator }) => validator);
  if (validators.length) {
    return validators.every((validator) => validator({ user, account }));
  } else {
    return true;
  }
};

// Filter sidebar items that user has permission to access
export const getAllowedSidebarItems = (user: IUser, account?: IAccount) => {
  return SidebarItems1.filter(({ link }) => validateRoute(link, user, account));
};
