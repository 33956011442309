import { Ref, forwardRef } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

import { FormHelperText, InputLabel, styled } from '@mui/material';

import { colors } from '@/theme/variables';

import { BaseFieldProps } from '../types';

const Root = styled('div')(({ theme }) => ({
  marginBottom: 8,

  '& input': {
    height: 36,
    width: '100%',
    padding: '4px 10px',
    backgroundColor: 'transparent!important',
    color: colors.grey[80],
    borderRadius: 6,
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'Roboto,sans-serif',
    lineHeight: '1.25rem',
    border: `1px solid ${colors.grey[20]}`,
    ...(theme.palette.mode !== 'light' && {
      color: colors.grey[20],
      border: `1px solid ${colors.grey[60]}`,
    }),

    '&:focus-visible': {
      outline: 'none',
    },
  },
}));

interface NumberInputProps
  extends Omit<PatternFormatProps, 'size'>,
    BaseFieldProps {}

export const PatternInput = forwardRef<Ref<any>, NumberInputProps>(
  (props, ref) => {
    const { label, error, ...rest } = props;

    return (
      <Root>
        <InputLabel>{label}</InputLabel>
        <PatternFormat getInputRef={ref} {...rest} />
        <FormHelperText error>{error}</FormHelperText>
      </Root>
    );
  },
);

PatternInput.displayName = 'Patttern Input';
