import { forwardRef, ReactNode, Ref, useState } from 'react';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { FormHelperText, InputLabel } from '@mui/material';
import {
  DateTimePickerProps,
  LocalizationProvider,
  DateTimePicker as MuiDatetimePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

import { DateTimePickerRoot, componentCustomStyles } from './styles';
import { BaseFieldProps } from '../types';

export interface BaseDateTimePickerProps
  extends DateTimePickerProps<string>,
    BaseFieldProps {
  hideInput?: boolean;
  pickerIcon?: ReactNode | null;
  clearable?: boolean;
  onClear?: () => void;
  onKeyUp?: (
    event: React.KeyboardEvent<HTMLDivElement | HTMLInputElement>,
  ) => void;
}

export const DateTimePicker = forwardRef<Ref<any>, BaseDateTimePickerProps>(
  (props, ref) => {
    const [open, setOpen] = useState(false);

    const {
      label,
      error,
      hideInput,
      pickerIcon,
      clearable,
      onKeyUp,
      onClear,
      ...rest
    } = props;

    // Default icon with toggle behavior
    const defaultIcon = (
      <InsertInvitationIcon
        onClick={() => setOpen(!open)}
        sx={{
          padding: 0.5,
          '&:hover': {
            borderRadius: '100%',
            backgroundColor: '#EDEDED',
            boxShadow: '0 0 0 2px #EDEDED',
          },
        }}
      />
    );

    // Wrap the custom icon in a container to preserve click behavior
    const renderedPickerIcon = pickerIcon ? (
      <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        {pickerIcon}
      </div>
    ) : (
      defaultIcon
    );

    return (
      <DateTimePickerRoot className={hideInput ? 'hide-input' : ''}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          {label && <InputLabel>{label}</InputLabel>}
          <MuiDatetimePicker
            {...rest}
            inputRef={ref as Ref<HTMLInputElement>}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              mt: 0,
              '.clearButton': {
                marginRight: 0.5,
              },
            }}
            closeOnSelect={false}
            slotProps={{
              actionBar: {
                actions: ['cancel', 'accept'],
              },
              field: {
                clearable,
                onClear,
              },
              textField: {
                onKeyUp,
                InputProps: {
                  endAdornment: renderedPickerIcon,
                },
              },
              ...componentCustomStyles,
            }}
          />
          <FormHelperText error>{error}</FormHelperText>
        </LocalizationProvider>
      </DateTimePickerRoot>
    );
  },
);

DateTimePicker.displayName = 'Custom Datetime Picker';
