import { useState } from 'react';

import { Box, Button, Menu, MenuItem } from '@mui/material';

import { IcArrowDown } from '@/assets/images';
import { IUser, Role } from '@/models';
import { isTargetRole } from '@/services';
import { useUserStore } from '@/store';

type DashboardActionsProps = {
  onCreateIncident: () => void;
  onCreateCarStop: () => void;
  onCreateCFS: () => void;
};

const DashboardActions = ({
  onCreateIncident,
  onCreateCarStop,
  onCreateCFS,
}: DashboardActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openActionsMenu = Boolean(anchorEl);
  const { user } = useUserStore();

  const handleCloseActionsMenu = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box paddingLeft={2}>
      <Box>
        <Button
          variant="contained"
          color="error"
          onClick={handleClick}
          endIcon={<IcArrowDown />}
        >
          New
        </Button>
      </Box>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={openActionsMenu}
        onClose={handleCloseActionsMenu}
      >
        <MenuItem onClick={() => onCreateCarStop()}>Car Stop</MenuItem>
        <MenuItem onClick={() => onCreateCFS()}>CFS</MenuItem>
        {isTargetRole(user as IUser, Role.POLICE) && (
          <MenuItem onClick={() => onCreateIncident()}>Incident</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default DashboardActions;
