import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { AuthApi, http, UserApi } from '@/api';
import { LocalStorageItems } from '@/constants';
import { IAgency, IUser, IUserRole } from '@/models';
import { setSentryScopes } from '@/services';
import { useAgencyStore, useToastStore, useUserStore } from '@/store';

interface AuthContextTokens {
  accessToken: string | null;
  refreshToken: string | null;
}

interface AuthContextValue extends AuthContextTokens {
  updateToken: (
    newAccessToken: string | null,
    newRefreshToken: string | null,
  ) => void;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextValue>({
  accessToken: null,
  refreshToken: null,
  updateToken: () => {},
  logout: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [tokens, setTokens] = useState<AuthContextTokens>({
    accessToken: null,
    refreshToken: null,
  });
  const { updateUserStore } = useUserStore();
  const { fetchAgencies } = useAgencyStore();
  const { updateToast } = useToastStore();

  useEffect(() => {
    if (tokens.accessToken) {
      fetchUserData();
      fetchAgencies();
    }
  }, [tokens.accessToken]);

  useLayoutEffect(() => {
    const _accessToken = localStorage.getItem(LocalStorageItems.AccessToken);
    setTokens({
      accessToken: _accessToken,
      refreshToken: localStorage.getItem(LocalStorageItems.RefreshToken),
    });

    http.setHeader(
      'Authorization',
      _accessToken ? `Bearer ${_accessToken}` : undefined,
    );
  }, []);

  useEffect(() => {
    function storageEventHandler(event: StorageEvent) {
      if (event.key === 'access-token' && !event.newValue) {
        setTokens({
          accessToken: null,
          refreshToken: null,
        });
      }
    }
    window.addEventListener('storage', storageEventHandler);
    return () => {
      window.removeEventListener('storage', storageEventHandler);
    };
  }, []);

  const getAgencyIdForLoggedInUser = (user: IUser) => {
    if (!user || !user.roles) return null;
    const agencyRole = user.roles.find((role: IUserRole) => role.agency);
    const agency = agencyRole?.agency as IAgency;
    return agency ? agency._id : null;
  };

  const fetchUserData = async () => {
    try {
      http.setHeader('Authorization', `Bearer ${tokens.accessToken}`);

      const [userRes, accountRes] = await Promise.all([
        UserApi.getCurrentUser(),
        UserApi.getMyAccount(),
      ]);

      const userAgencyId = getAgencyIdForLoggedInUser(userRes.data);

      // Update user roles data temporarily
      updateUserStore({
        user: userRes.data,
        account: accountRes.data,
        userAgencyId,
      });

      setSentryScopes(userRes.data, accountRes.data);
    } catch (error: any) {
      updateToast({ open: true, message: error.message });
    }
  };

  const updateToken = (
    newAccessToken: string | null,
    newRefreshToken: string | null,
  ) => {
    localStorage.setItem(LocalStorageItems.AccessToken, newAccessToken ?? '');
    localStorage.setItem(LocalStorageItems.RefreshToken, newRefreshToken ?? '');
    setTokens({
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    });
  };

  const logout = async () => {
    try {
      if (tokens.refreshToken) {
        await AuthApi.logout({ refreshToken: tokens.refreshToken });
      }
    } catch (error: any) {
      updateToast({ open: true, message: error.message });
    }
    localStorage.setItem(LocalStorageItems.CurrentAgencyId, '');
    localStorage.setItem(LocalStorageItems.AccessToken, '');
    updateUserStore({ user: null, account: null });
    http.setHeader('Authorization', undefined);
    window.dispatchEvent(
      new StorageEvent('storage', { key: 'access-token', newValue: '' }),
    );
  };

  return (
    <AuthContext.Provider
      value={{
        ...tokens,
        updateToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
