import { Divider, Stack, styled, Typography } from '@mui/material';

import { ICFS } from '@/models';
import { CFSAssignmentStatusItem } from '@/pages/CFS/Edit/components/CFSAssignmentStatusItem';
import { getAddressLocation } from '@/services';

export const CFSListItem = ({ cfs }: { cfs: ICFS }) => {
  const StyledStack = styled(Stack)(() => ({
    '& .cfs-assignment-status': {
      marginLeft: '10px',
      height: '43px',
      '& .MuiChip-label': {
        lineHeight: '17px',
        padding: '0px 8px',
      },
    },
  }));

  return (
    <Stack
      flex={1}
      padding={2}
      border={1}
      borderColor="divider"
      borderRadius={2}
      gap={2}
    >
      <StyledStack direction="row" alignItems="center" paddingTop={1}>
        <Typography fontWeight={600} fontSize={21} align="left">
          {cfs.shortDescription || 'No Emergency detail for this CFS'}
        </Typography>
        <CFSAssignmentStatusItem
          assignmentStatus={cfs.assignmentStatus}
          isReadyToDispatch={cfs.isReadyToDispatch}
          className="cfs-assignment-status"
        />
      </StyledStack>
      <Divider />
      <Stack>
        <Typography fontWeight={500} fontSize={18} align="left">
          {getAddressLocation(cfs?.addressInfo?.address) ||
            'No address provided for this CFS'}
        </Typography>
        <Typography fontSize={18} fontWeight={400}>
          {cfs?.addressInfo?.additionalInfo?.beat?.name}
        </Typography>
      </Stack>
    </Stack>
  );
};
