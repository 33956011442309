import { Button, Divider, Stack, Typography } from '@mui/material';

import { IcFlash } from '@/assets/images';
import { colors } from '@/theme/variables';

import { ModalState } from '../shared/modalState';

type BackupRequestedModalContentProps = {
  toggleModal: (modalName: keyof ModalState) => void;
  action: (accepted: boolean) => void;
  cfsId: string;
};

const BackupRequestedModalContent = ({
  toggleModal,
  action,
  cfsId,
}: BackupRequestedModalContentProps) => {
  return (
    <>
      <Stack rowGap={2} flex={1} alignItems="center" fontSize={40} padding={5}>
        <IcFlash width={84} height={84} color={colors.error.main} />
        <Typography component="h3" variant="h6">
          Requested Backup for {cfsId}
        </Typography>
      </Stack>
      <Stack>
        <Divider />
        <Stack direction="row" gap={2} paddingTop={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={() => {
              action(false);
              toggleModal('backupRequested');
            }}
          >
            Reject
          </Button>
          <Button
            color="error"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={() => {
              action(true);
              toggleModal('backupRequested');
            }}
          >
            Accept
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default BackupRequestedModalContent;
