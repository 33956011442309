import * as React from 'react';

import { createRoot } from 'react-dom/client';

import App from './App';
import { initSentry } from './services';

initSentry();

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
