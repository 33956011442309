import { CSSProperties, useEffect, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';

import { IcUpload } from '@/assets/images';
import { FilePickerDefaultOptions } from '@/constants';
import { IFile } from '@/models';
import { colors } from '@/theme/variables';
import { getErrorMessage } from '@/utils';

import { FileList } from './FileList';
import { FilePickerRoot } from './styles';

export type FilePickerDirection = 'vertical' | 'horizontal';

export interface FilePickerProps
  extends Omit<DropzoneOptions, 'onDropAccepted' | 'onDropRejected'> {
  className?: string;
  style?: CSSProperties;
  initialFileItems?: IFile[];
  onChange: (files: IFile[]) => void;
  allowedFileTypes?: string[];
  itemClassName?: string;
  enableEditDescription?: boolean;
  direction?: FilePickerDirection;
  preventUpload?: boolean;
}

export const FilePicker = (props: FilePickerProps) => {
  const {
    className,
    style,
    initialFileItems = [],
    onChange,
    accept,
    itemClassName,
    enableEditDescription,
    direction = 'horizontal',
    preventUpload,
    ...rest
  } = props;

  const [fileItems, setFileItems] = useState<IFile[]>([]);

  useEffect(() => {
    if (initialFileItems && initialFileItems.length > 0) {
      setFileItems(initialFileItems);
    }
  }, [initialFileItems]);

  const onDropAccepted = (newAcceptedFiles: File[]) => {
    const newFileItems = newAcceptedFiles.map((file) => ({
      name: file.name,
      size: file.size,
      mimeType: file.type,
      file,
    }));
    setFileItems((v) => {
      onChange([...newFileItems, ...v]);
      return [...newFileItems, ...v];
    });
  };

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    const rejectedFileItems = rejectedFiles.map(({ file, errors }) => {
      return {
        file,
        name: file.name,
        url: '',
        size: file.size,
        mimeType: file.type,
        error: getErrorMessage(errors[0]?.code, errors[0]?.message),
      };
    });
    setFileItems((v) => {
      onChange([...v, ...rejectedFileItems]);
      return [...v, ...rejectedFileItems];
    });
  };

  const nameLengthValidator = (file: File) => {
    if (file.name.length > 100) {
      return {
        code: 'name-too-large',
        message: 'Name is larger than 100 characters',
      };
    }
    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      ...(accept || { 'image/jpeg': [], 'image/png': [] }),
    },
    ...FilePickerDefaultOptions,
    ...rest,
    onDropAccepted,
    onDropRejected,
    validator: nameLengthValidator,
  });

  const handleRemove = (index: number) => {
    const remainedFileItems = fileItems
      .slice(0, index)
      .concat(fileItems.slice(index + 1));
    setFileItems(remainedFileItems);
    onChange(remainedFileItems);
  };

  return (
    <FilePickerRoot
      justifyContent="center"
      alignItems="center"
      className={clsx(className, {
        'vertical-aligned-file-picker': direction === 'vertical',
      })}
      style={style}
    >
      <Stack
        {...getRootProps({})}
        className={`pickerBox${preventUpload ? ' pickerBox--prohibited' : ''}`}
      >
        <input {...getInputProps()} />

        <IcUpload color="red" />

        <Typography className="title1">
          Drop a file here to upload, or
        </Typography>
        <Typography className="title2" color={colors.error.main}>
          click here to browse
        </Typography>
      </Stack>
      <FileList
        files={fileItems}
        direction={direction}
        itemClassName={itemClassName}
        enableEditDescription={enableEditDescription}
        onRemove={handleRemove}
      />
    </FilePickerRoot>
  );
};
