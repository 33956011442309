import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { TableColumn, TableContainer } from '@/components';

import { IKaseReport, KaseReportsTableColumns } from '../data';

interface KaseReportsTableProps {
  rows: IKaseReport[];
}

const KaseReportsTable = ({ rows }: KaseReportsTableProps) => {
  const renderColumn = (
    row: IKaseReport,
    field: { key: string; label?: string },
  ) => {
    switch (field.key) {
      default: {
        return (
          <Typography variant="body2">
            {row[field.key as keyof typeof row]?.toString()}
          </Typography>
        );
      }
    }
  };

  return (
    <Stack spacing={2} sx={{ flex: 1, height: '100%', overflowY: 'auto' }}>
      <TableContainer dontCalculateHeight>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {KaseReportsTableColumns.map((field) => (
                <TableCell key={field.key}>{field.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{
                  height: '45px!important;',
                }}
              >
                {KaseReportsTableColumns.map((field: TableColumn) => {
                  return (
                    <TableCell key={field.key}>
                      {renderColumn(row, field)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default KaseReportsTable;
