import { TableColumn } from '@/components';
import { IViewCard } from '@/components/Cards';
import { USStates } from '@/constants';
import { IMasterVehicleForm, VehicleStyle, VehicleTypeList } from '@/models';
import { FileAcceptedTypes } from '@/pages/Kases/KaseFiles/components/data';

export enum MasterVehicleScreens {
  MasterVehicleList = 'MasterVehicleList',
  MasterVehicleForm = 'MasterVehicleForm',
  MasterVehicleLookup = 'MasterVehicleLookup',
  MasterVehicleOwnerList = 'MasterVehicleOwnerList',
  MasterVehicleView = 'MasterVehicleView',
  TabNameNotes = 'TabNameNotes',
}

export const MasterVehicleNextPaths = {
  MasterVehicleList: [
    MasterVehicleScreens.MasterVehicleView,
    MasterVehicleScreens.MasterVehicleForm,
  ],
  MasterAddVehicleDetail: [
    MasterVehicleScreens.MasterVehicleLookup,
    MasterVehicleScreens.MasterVehicleOwnerList,
  ],
};

export const MasterVehicleTableColumns: TableColumn[] = [
  { key: 'licensePlate.number', label: 'Plate number' },
  { key: 'color', label: 'Color' },
  { key: 'make', label: 'Make' },
  { key: 'model', label: 'Model' },
  { key: 'isVerified', label: 'Data Source' },
  { key: 'action', label: '' },
];

export const MasterVehiclesearchTableColumns: TableColumn[] = [
  { key: 'licensePlate.number', label: 'Plate number' },
  { key: 'licensePlate.state', label: 'Plate state' },
  { key: 'color', label: 'Color' },
  { key: 'make.name', label: 'Make' },
  { key: 'model.name', label: 'Model' },
  { key: 'vin', label: 'VIN' },
  { key: 'year', label: 'Year' },
  { key: 'useInfo', label: '' },
];

export const MasterVehicleOwnerTableViewColumns: TableColumn[] = [
  { key: 'name', label: 'Name' },
  { key: 'phoneNumber', label: 'Phone number' },
  { key: 'address.normalized', label: 'Address' },
  { key: 'type', label: 'Type' },
];

export const MasterVehicleOwnerTableColumns: TableColumn[] = [
  ...MasterVehicleOwnerTableViewColumns,
  { key: 'action', label: '' },
];

const validateIfVerified = (
  currentValue: string,
  formValues: IMasterVehicleForm,
) => {
  return formValues.isVerified && !currentValue
    ? 'This field is required.'
    : true;
};
const startYear = new Date().getFullYear();
export const vehicleYears = Array.from({ length: 125 }, (_, index) => {
  const year = (startYear - index).toString();
  return { value: year, label: year };
});

export const MasterVehicleFormFields = [
  {
    key: 'information',
    title: 'Vehicle information',
    items: [
      {
        name: 'licensePlate.number',
        label: 'License plate number',
        rules: {
          maxLength: {
            value: 20,
            message: 'License plate number should be less than 20 characters.',
          },
        },
      },
      {
        name: 'licensePlate.state',
        label: 'License plate state',
        type: 'autocomplete',
        multiple: false,
        items: USStates,
      },
      {
        name: 'vin',
        label: 'VIN number',
        rules: {
          maxLength: {
            value: 20,
            message: 'VIN should be less than 20 characters long.',
          },
          validate: validateIfVerified,
        },
      },
    ],
  },
  {
    key: 'description',
    title: 'Vehicle description',
    items: [
      {
        name: 'type',
        label: 'Vehicle type',
        type: 'autocomplete',
        items: Object.entries(VehicleTypeList).map(([value, label]) => ({
          value,
          label,
        })),
        rules: {
          validate: validateIfVerified,
        },
      },
      {
        name: 'uniqueCharacteristics',
        label: 'Unique characteristics',
        rules: {
          maxLength: {
            value: 100,
            message: 'Characteristics should be less than 100 characters',
          },
        },
        grid: {
          md: 9,
          lg: 9,
        },
      },
    ],
  },
  {
    key: 'photos',
    title: 'Photos',
    items: [
      {
        name: 'files',
        type: 'file',
        accept: FileAcceptedTypes,
        label: '',
        grid: {
          md: 12,
          lg: 12,
        },
      },
    ],
  },
  {
    key: 'ownerInformation',
    title: 'Registered owner information',
    items: [],
  },
  {
    key: 'verified',
    title: '',
    items: [
      {
        name: 'isVerified',
        type: 'checkbox',
        label: 'Verified',
        sx: {
          fontWeight: 600,
        },
        grid: {
          md: 6,
        },
      },
    ],
  },
];

export const AutomobileTypeFormFields = [
  {
    name: 'year',
    label: 'Year',
    type: 'autocomplete',
    items: vehicleYears,
    rules: {
      validate: validateIfVerified,
    },
  },
  {
    name: 'make',
    label: 'Make',
    type: 'autocomplete',
    multiple: false,
    rules: {
      validate: validateIfVerified,
    },
  },
  {
    name: 'model',
    label: 'Model',
    type: 'autocomplete',
    rules: {
      validate: validateIfVerified,
    },
    multiple: false,
  },
  {
    name: 'color',
    label: 'Color',
    type: 'autocomplete',
    clearOnBlur: false,
    freeSolo: true,
    // rules: {
    //   validate: validateIfVerified,
    // },
  },
  {
    name: 'style',
    label: 'Style',
    type: 'autocomplete',
    items: [
      {
        label: 'TDR',
        value: VehicleStyle.TDR,
      },
      {
        label: 'FDR',
        value: VehicleStyle.FDR,
      },
    ],
  },
];

export const VehicleTypeFormFields = [
  {
    name: 'year',
    label: 'Year',
    type: 'autocomplete',
    items: vehicleYears,
    rules: {
      validate: validateIfVerified,
    },
  },
  {
    name: 'make',
    label: 'Make',
    rules: {
      validate: validateIfVerified,
    },
  },
  {
    name: 'model',
    label: 'Model',
    rules: {
      validate: validateIfVerified,
    },
  },
  {
    name: 'color',
    label: 'Color',
    rules: {
      validate: validateIfVerified,
    },
  },
];

export const MasterVehicleViewFields: IViewCard[] = [
  {
    title: 'Vehicle information',
    key: 'vehicleInformation',
    size: { md: 2 },
    items: [
      {
        label: 'License plate number',
        value: 'licensePlate.number',
      },
      {
        label: 'License plate state',
        value: 'licensePlate.state',
      },
      {
        label: 'VIN number',
        value: 'vin',
      },
    ],
  },
  {
    title: 'Vehicle description',
    key: 'vehicleDescription',
    size: { md: 2 },
    items: [
      {
        label: 'Make',
        value: 'make',
      },
      {
        label: 'Model',
        value: 'model',
      },
      {
        label: 'Year',
        value: 'year',
      },
      {
        label: 'Color',
        value: 'color',
      },
      {
        label: 'Style',
        value: 'style',
      },
      {
        label: 'Unique characteristics',
        value: 'uniqueCharacteristics',
      },
    ],
  },
  {
    title: 'Registered owner information',
    key: 'ownerinfo',
    size: { md: 2 },
    items: [],
  },
  {
    key: 'verificationStatus',
    items: [
      {
        label: 'Verification Status',
        value: 'isVerified',
      },
    ],
  },
  {
    title: 'Photos',
    key: 'photos',
    items: [
      {
        label: 'Photos',
        value: 'files',
      },
    ],
  },
];

export const MasterVehicleOwnersTempdata = [
  {
    firstName: 'First name',
    lastName: 'Last name',
    homePhoneNumber: 'Home phone number',
    cellPhoneNumber: 'Cell phone number',
    address: 'Address',
  },
];

export const MasterVehicleOwnerSearchFields = [
  {
    name: 'firstName',
    label: 'First name',
    type: 'text',
  },
  {
    name: 'lastName',
    label: 'Last name',
    type: 'text',
  },
  {
    name: 'address',
    label: 'Address',
    type: 'address',
  },
];

export const MasterVehicleSearchFields = [
  {
    name: 'plateNumber',
    label: 'Plate number',
    rules: {
      minLength: {
        value: 4,
        message: 'Plate number search text should be more than 4 characters.',
      },
      maxLength: {
        value: 20,
        message: 'Plate number is less than 20 characters.',
      },
    },
  },
  {
    name: 'vin',
    label: 'VIN',
    rules: {
      maxLength: {
        value: 20,
        message: 'VIN is less than 20 characters.',
      },
    },
  },
];
