import {
  styled,
  Drawer as MuiDrawer,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material';

import { colors, sizes } from '@/theme/variables';

export const Root = styled(Stack)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: sizes.sidebarClosed,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.sideBar.main,
}));

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  maxHeight: '100%',
  paddingBottom: 25,
  boxSizing: 'border-box',
  borderRight: 0,
  zIndex: 0,

  '.MuiDrawer-paper': {
    width: 52,
    paddingBottom: 40,
    marginLeft: 18,
    borderRight: 0,
    backgroundColor: theme.palette.sideBar.main,
  },

  '& .MuiPaper-root': {
    overflow: 'hidden',
  },

  '& .MuiTypography-body1': {
    fontWeight: '600',
  },
  '& .MuiTypography-body2': {
    color: '#EE3E5C',
  },

  '& .list-item': {
    justifyContent: 'flex-start',
    padding: '15px',
    fontSize: '0.9375rem',
    color: colors.white,
    borderRadius: 8,
    '@media (max-width: 1367px)': {
      marginBottom: '20px',
    },
    '&.active': {
      color: colors.error.main,
      '@media (max-width: 1367px)': {
        padding: '13px',
        backgroundColor: colors.error.main,
        color: colors.white,
      },
    },
    '&:hover': {
      backgroundColor: colors.bgActive,
    },
  },
}));

export const SideBarMenu = styled(Menu)(() => ({
  '&.submenuRoot': {
    left: '70px',
    '& .MuiBackdrop-root': {
      display: 'none',
    },

    '& .submenuPaper': {
      marginLeft: '-65px',
      padding: 0,
    },
  },
  '& .MuiPaper-root': {
    padding: '0 8px',
    backgroundColor: colors.bgActive,
    color: 'white',

    '& .MuiMenuItem-root': {
      justifyContent: 'flex-start',
      fontSize: '0.9375rem',
      color: colors.grey[30],

      '&:hover': {
        color: colors.white,
      },
      '&.active': {
        color: colors.white,
      },
    },
    '& .MuiMenuItem-root:first-of-type': {
      color: colors.white,
    },
  },
}));

export const TooltipTitle = styled(MenuItem)(() => ({
  justifyContent: 'flex-start',
  padding: '15px',
  marginLeft: 5,
  fontSize: '0.9375rem',
  color: colors.white,
  backgroundColor: colors.bgActive,
  borderRadius: 6,

  '&:hover': {
    backgroundColor: colors.bgActive,
  },
}));
