import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { MasterNameApi } from '@/api';
import { SearchBar } from '@/components';
import { withLoader, WithLoaderProps } from '@/hocs';
import { NameNotesTable } from '@/layouts/TabLayout/MasterNames/components/tables/NameNotesTable';
import { INote } from '@/models/note';
import { useToastStore } from '@/store';

import MasterIndexPersonBase from './MasterIndexPersonBase';

const MasterIndexPersonNotes = ({
  showLoader,
  hideLoader,
}: WithLoaderProps) => {
  const { updateToast } = useToastStore();
  const [searchText, setSearchText] = useState<string>('');
  const [notesRows, setNotesRows] = useState<INote[]>([]);
  const { id: personId } = useParams();

  const handleSearch = useDebouncedCallback((value: string) => {
    setSearchText(value);
  }, 500);

  const fetchMasteNameNotes = async () => {
    if (!personId) return;
    showLoader();
    try {
      const res = await MasterNameApi.getMasterNameNote(personId, {
        search: searchText,
        sort: JSON.stringify({ createdAt: -1 }),
      });

      if (res) {
        setNotesRows(res.data);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    fetchMasteNameNotes();
  }, [searchText]);

  useEffect(() => {
    if (personId) {
      fetchMasteNameNotes();
    }
  }, [personId]);

  return (
    <MasterIndexPersonBase>
      <Box padding={2}>
        <SearchBar onChange={handleSearch} placeholder="Search..." />
      </Box>
      <NameNotesTable
        personId={personId}
        rows={notesRows}
        deleteNode={() => {}}
        editNote={() => {}}
        isMasterIndex={true}
      />
    </MasterIndexPersonBase>
  );
};

export default withLoader(MasterIndexPersonNotes);
