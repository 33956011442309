import { styled, Menu as MuiMenu, Stack } from '@mui/material';

import { colors, sizes } from '@/theme/variables';

export const UnitShiftListRoot = styled(Stack)(({ theme, className }) => ({
  height:
    className === 'hasCfsId'
      ? `calc(100vh - ${
          sizes.bottomTabBar.height + sizes.bottomTabBar.margin * 2
        }px  )`
      : '',
  width: '100%',
  backgroundColor: theme.palette.background.paper,

  '& .header': {
    padding: '16px 16px 0px',
    backgroundColor: theme.palette.background.paper,
    minHeight: 67,

    '& .MuiTypography-body2': {
      fontWeight: 500,
      color: colors.grey[100],
    },

    '& .sector-chip': {
      padding: '4px 8px',
      backgroundColor: colors.primary.main,
      borderRadius: 6,
      '& .MuiChip-label': {
        lineHeight: '17px',
        padding: 0,
        fontSize: '0.75rem',
        fontWeight: 500,
        color: 'white',
      },
    },
  },
  '& .tab-box': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 15px',
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiTabs-root': {
    '& .MuiTab-root': {
      padding: '20px 30px 14px 30px',
      fontSize: '0.9375rem',
      lineHeight: '20px',
      textTransform: 'capitalize',
      color: colors.grey[60],
    },
    '& .Mui-selected': {
      color: theme.palette.text.secondary,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.error.main,
    },
  },
  '& .MuiButton-outlined': {
    padding: '10px 25px',
    borderColor: colors.grey[20],
  },
}));

export const Menu = styled(MuiMenu)(() => ({
  marginTop: 25,
  '& .MuiPaper-root': {
    minWidth: '300px!important',
    padding: '2px 20px 10px',

    '& .MuiTabs-scroller': {
      borderBottom: `solid 1px ${colors.grey[10]}`,

      '& .MuiTab-root': {
        margin: 0,
        padding: '15px 10px',
      },
    },

    '& .checkbox': {
      width: '100%',
      backgroundColor: colors.itemGrey,
      marginLeft: 0,
      marginTop: 4,
      padding: '0px',
      borderRadius: 6,
      fontSize: '0.875rem',
      fontWeight: 500,
      color: colors.grey[100],
      lineHeight: '1.25rem',
    },

    '& .filter-item': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px 8px',
      backgroundColor: 'red',
      borderRadius: 6,

      '& .txt-filter-name': {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: colors.grey[100],
        lineHeight: '1.25rem',
      },
    },
  },
}));

export const UnitItemRoot = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginTop: 15,
  padding: 15,
  borderRadius: 6,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 1px 3px 0px #00000026',

  '&:first-of-type': {
    marginTop: 0,
  },

  '& .content-section': {
    flexDirection: 'row',
    // alignItems: 'center',
  },
  '& .name-box': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    marginLeft: 10,
    borderRadius: 6,
    backgroundColor: colors.grey[10],
    color: theme.palette.text.secondary,

    '& .MuiTypography-root': {
      lineHeight: '17px',
    },

    '& svg': {
      width: 17,
      height: 17,
      color: colors.grey[80],
      marginRight: 5,
    },

    '&.status-box': {
      backgroundColor: colors.grey[80],
      color: colors.white,
      fontSize: '0.875rem',
      width: 85,
      textTransform: 'capitalize',
      justifyContent: 'center',
    },
  },
  '& .MuiButton-root': {
    padding: '8px 12px',
  },
  '& .Mui-disabled': {
    border: 0,
  },
  '& .MuiIconButton-root': {
    padding: '0px 8px',
  },
  '& .txt-location': {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  '& .txt-direction': {
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },

  '& .MuiAccordion-root': {
    boxShadow: 'none',

    '&:before': {
      height: 0,
    },

    '&.Mui-expanded': {
      marginTop: 0,
    },

    '& .MuiAccordionSummary-root': {
      minHeight: 0,
      padding: 0,
      borderTop: 0,

      '& .content-section': {
        flex: 1,
        // alignItems: 'center',
      },

      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },

    '& .MuiAccordionDetails-root': {
      padding: 0,
      backgroundColor: theme.palette.background.paper,
    },

    '& .resource-item': {
      flexDirection: 'row',
      flex: 1,
      maxWidth: '33%',
      marginLeft: 20,

      '&:first-of-type': {
        marginLeft: 0,
      },
    },

    '& .resource-name': {
      fontSize: '0.875rem',
      lineHeight: '16.5px',
    },
  },
}));
