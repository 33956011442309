import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { CssBaseline, Grid, styled } from '@mui/material';

import { IcLogoWithText } from '@/assets/images';
import BgImage from '@/assets/images/pngs/bg-auth.png';
import { ThemeProvider } from '@/contexts';
import { createCustomTheme } from '@/theme';

const AuthLayoutRoot = styled(Grid)(({ theme }) => ({
  height: '100vh',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },

  '& .logo-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flex: 1,

    '& .bg-image': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 0,
      objectFit: 'cover',
    },
    '& .logo': {
      width: 250,
      color: 'white',
      zIndex: 100,

      [theme.breakpoints.up('md')]: {
        width: 300,
      },

      [theme.breakpoints.up('lg')]: {
        width: 350,
      },

      [theme.breakpoints.up('xl')]: {
        width: 400,
      },
    },
  },

  '& .page-container': {
    flex: 0,
    padding: '40px 0 25px',
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      flex: 1,
    },
  },
}));

export const AuthLayout: FC = () => {
  const mode =
    (localStorage.getItem('themeMode') as 'light' | 'dark') || 'light';
  const theme = createCustomTheme(mode);

  return (
    <ThemeProvider>
      <CssBaseline />
      <AuthLayoutRoot theme={theme} container>
        <Grid className="logo-container" xs={12} sm={6} md={8} item>
          <IcLogoWithText className="logo" />
          <img className="bg-image" src={BgImage} />
        </Grid>
        <Grid
          className="page-container"
          xs={12}
          sm={6}
          md={4}
          sx={{
            position: 'relative',
          }}
          item
        >
          <Outlet />
        </Grid>
      </AuthLayoutRoot>
    </ThemeProvider>
  );
};
