const isSupported = 'getScreens' in window || 'getScreenDetails' in window;

export const getScreenInfo = async () => {
  let cachedScreens = null;
  if (isSupported) {
    cachedScreens =
      'getScreens' in window
        ? await (window as any).getScreens()
        : await (window as any).getScreenDetails();
    return cachedScreens?.screens;
  }
  return [window.screen];
};

export const openNewWindow = async (
  path: string,
  title = '',
  width?: number,
  height?: number,
) => {
  const screens = await getScreenInfo();
  screens.forEach((screen: any) => {
    if (!screen?.isPrimary || screens?.length === 1) {
      const features = [
        `left=${screen.availLeft}`,
        `top=${screen.availTop}`,
        `width=${width || screen.width}`,
        `height=${height || screen.height}`,
        `menubar=no`,
        `toolbar=no`,
        `location=no`,
        `status=no`,
        `resizable=yes`,
        `scrollbars=no`,
      ].join(',');
      window.open(path, title, features);
    }
  });
};
