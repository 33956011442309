import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { OpenInFull } from '@mui/icons-material';
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';
import * as Ably from 'ably';
import { useChannel } from 'ably/react';

import { CfsApi } from '@/api';
import { AIIcon } from '@/assets/images';
import { AdditionalInfoForm, FormCard } from '@/components';
import { useCFSContext } from '@/hooks';
import { IAdditionalInfo } from '@/models';
import { useToastStore, useUserStore } from '@/store';
import { openNewWindow } from '@/utils';

import { FormItemRoot, LiveInformationRoot } from '../styles';

interface LiveInformationProps {
  isNewWindow?: boolean;
  cfsId?: string;
  isFirstResponderView?: boolean;
  isMaxHeight?: boolean;
  onNewMessages?: (newMessages: number) => void;
}

export const LiveInformation = ({
  isNewWindow,
  isFirstResponderView = false,
  isMaxHeight = false,
  ...rest
}: LiveInformationProps) => {
  const urlParams = useParams();
  const cfsId = rest.cfsId || String(urlParams.cfsId);

  const { updateToast } = useToastStore();
  const { user, account } = useUserStore();
  const enabledAiGeneratedSummary =
    account?.settings?.features?.isEnabledAIGeneratedCFSDescription;

  const { cfs, updateCFS } = useCFSContext();
  const [collapse, setCollapse] = useState<boolean>(false);
  const [aiGeneratedSummary, setAiGeneratedSummary] = useState<string>('');

  const additionalInfo = cfs?.additionalInfo || [];

  const { channel } = useChannel(
    `account:${account?._id}:cfs:${cfsId}`,
    'additionalInfo',
    (message: Ably.Types.Message) => {
      if (cfs) {
        const info = [
          ...cfs.additionalInfo,
          {
            ...message.data,
            isNew: !open && message.data?.createdBy?._id !== user?._id,
          },
        ];
        updateCFS({
          ...cfs,
          additionalInfo: info,
        });
      }
    },
  );

  const { channel: deletionChannel } = useChannel(
    `account:${account?._id}:cfs:${cfsId}`,
    'additionalInfo:deleted',
    (message: Ably.Types.Message) => {
      if (cfs && message?.data?.length) {
        updateCFS({
          ...cfs,
          additionalInfo: message?.data,
        });
      }
    },
  );

  const onSubmit = (newDescription: string) => {
    CfsApi.addAdditionalInfo(cfsId, { description: newDescription })
      .then(() => {
        if (user) {
          const newAdditionalInfo = {
            createdBy: {
              _id: user._id,
              firstName: user.firstName,
              lastName: user.lastName,
              fullName: user.fullName,
            },
            description: newDescription,
            createdAt: new Date().toISOString(),
          };

          if (cfs)
            updateCFS({
              ...cfs,
              additionalInfo: [...additionalInfo, newAdditionalInfo],
            });

          channel?.publish('additionalInfo', newAdditionalInfo);
        }
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  const generateAiSummary = async () => {
    if (collapse) {
      setCollapse(false);
      return;
    }

    const apiResult = await CfsApi.generateAISummary(cfsId);
    if (apiResult.status === 200) {
      setAiGeneratedSummary(apiResult?.data?.shortDescription);
    }
    setCollapse(!collapse);
  };

  const updateAdditionalInfos = (additionalInfos: IAdditionalInfo[]) => {
    if (!cfs) return;
    if (!additionalInfo?.length) return;

    updateCFS({
      ...cfs,
      additionalInfo: additionalInfos,
    });

    deletionChannel?.publish('additionalInfo:deleted', additionalInfos);
  };

  return (
    <LiveInformationRoot>
      {isNewWindow && (
        <HelmetProvider>
          <Helmet>
            <title>{`${cfs?.number} - Live information`}</title>
          </Helmet>
        </HelmetProvider>
      )}
      <FormCard
        title={
          !isFirstResponderView
            ? (isNewWindow && cfs?.number ? `${cfs.number} - ` : '') +
              'Live information'
            : undefined
        }
        classes={{
          cardContainer: 'live-info-card-container',
          cardContent: 'live-info-card-content',
        }}
      >
        <FormItemRoot>
          {!isNewWindow && !isFirstResponderView && (
            <>
              {enabledAiGeneratedSummary && (
                <IconButton
                  sx={{ position: 'absolute', right: 40, top: 8 }}
                  onClick={() => {
                    generateAiSummary();
                  }}
                >
                  <AIIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton
                sx={{ position: 'absolute', right: 10, top: 8 }}
                onClick={() => {
                  openNewWindow(
                    `/cfs/${cfsId}/live-info`,
                    `${cfsId} - Live information`,
                    500,
                    500,
                  );
                }}
              >
                <OpenInFull fontSize="small" />
              </IconButton>
            </>
          )}
          <Collapse in={collapse} timeout="auto" unmountOnExit>
            <Box
              sx={{
                position: 'absolute',
                top: 60,
                left: 0,
                right: 0,
                borderRadius: 1,
                boxShadow: 0,
                mb: 2,
                width: '100%',
                backgroundColor: 'white',
                zIndex: 1,
              }}
            >
              <Divider></Divider>
              <Typography
                sx={{
                  marginTop: '0.5rem',
                  marginBottom: '0.5rem',
                  paddingLeft: '0.8rem',
                }}
                color="GrayText"
                variant="body2"
              >
                {aiGeneratedSummary}
              </Typography>
              <Divider></Divider>
            </Box>
          </Collapse>
          <AdditionalInfoForm
            cfsId={cfsId}
            additionalInfoList={additionalInfo}
            classes={
              isNewWindow
                ? { additionalInfoList: 'new-window' }
                : isFirstResponderView
                ? { additionalInfoList: 'first-responder' }
                : undefined
            }
            onSubmit={onSubmit}
            setNewAdditionalInfo={updateAdditionalInfos}
            isFirstResponderView={isFirstResponderView}
          />
        </FormItemRoot>
      </FormCard>
    </LiveInformationRoot>
  );
};
