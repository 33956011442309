import { colors } from '../variables';

export default {
  MuiBadge: {
    styleOverrides: {
      badge: () => ({
        border: `2px solid ${colors.error.main}`,
        backgroundColor: colors.error.main,
        color: 'white',
        padding: 0,
        borderRadius: '50%',
      }),
    },
  },
};
