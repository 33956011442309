import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Divider, Grid, Stack, useTheme } from '@mui/material';

import { MasterIndexApi, MasterVehicleApi, VehicleApi } from '@/api';
import {
  FormRoot,
  MasterNameAutocomplete,
  NoResultItem,
  RenderFormField,
  VehicleMakeAutocomplete,
  ViewCard,
  FormCard,
} from '@/components';
import { withLoader, WithLoaderProps } from '@/hocs';
import withUnsavedChangesWarning, {
  WithUnsavedChangesWarningProps,
} from '@/hocs/withUnsavedChangesWarning';
import { useFilesValidation } from '@/hooks';
import { useMasterVehicleFormOptions } from '@/hooks/useMasterVehicleFormOptions';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import {
  IMasterName,
  IMasterNamePerson,
  IMasterVehicleForm,
  MasterNameType,
  VehicleType,
} from '@/models';
import { uploadFiles } from '@/services';
import { useToastStore } from '@/store';

import { useMasterVehicleContext } from '..';
import {
  AutomobileTypeFormFields,
  MasterVehicleFormFields,
  MasterVehicleNextPaths,
  MasterVehicleOwnerTableColumns,
  MasterVehicleScreens,
  VehicleTypeFormFields,
} from '../data';
import { MasterVehicleOwnerTable } from './tables/MasterVehicleOwnerTable';

export interface MasterVehicleFormData
  extends Omit<IMasterVehicleForm, 'owner'> {
  owner?: IMasterName;
}

export interface MasterVehicleFormProps {
  isMasterIndex?: boolean;
  hideHeader?: boolean;
  goBack?: () => void;
}

const MasterVehicleForm = ({
  showLoader,
  hideLoader,
  setIsDirty,
  onNavigation,
  isMasterIndex,
  hideHeader,
  goBack,
}: WithLoaderProps &
  WithUnsavedChangesWarningProps &
  MasterVehicleFormProps) => {
  const { updateToast } = useToastStore();
  const { currentVehicleId, handleAddLinkedVehicle } =
    useMasterVehicleContext();
  const { updateCurrentScreen } = useTabLayoutContext();

  const { handleSubmit, watch, setValue } =
    useFormContext<MasterVehicleFormData>();
  const owner = watch('owner');
  const makeName = watch('make');
  const modelName = watch('model');
  const year = watch('year');
  const vehicleType = watch('type');

  const uploadedFileItems = watch('files');
  const hasInvalidFiles = useFilesValidation(uploadedFileItems);

  const { makeOptions, modelOptions, yearOptions, colorOptions } =
    useMasterVehicleFormOptions(vehicleType, makeName, modelName, year);

  const getItems = (itemName: string) => {
    switch (itemName) {
      case 'make':
        return makeOptions;
      case 'model':
        return modelOptions;
      case 'year':
        return yearOptions;
      case 'color':
        return colorOptions;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (name === 'model' && type === 'change') {
        setValue('color', '');
      }
      if (name === 'make' && type === 'change') {
        setValue('model', '');
        setValue('color', '');
      }
      if (name === 'type' && type === 'change') {
        setValue('year', undefined);
        setValue('make', '');
        setValue('model', '');
        setValue('color', '');
        setValue('style', undefined);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      updateCurrentScreen(MasterVehicleScreens.MasterVehicleList);
    }
  };

  const theme = useTheme();

  const onSubmit = async ({
    files,
    ...restFormValues
  }: MasterVehicleFormData) => {
    setIsDirty(false);
    try {
      showLoader();
      const payload = {
        ...restFormValues,
        owner: restFormValues.owner?._id,
        files,
        year: restFormValues.year || '',
      };
      if (files && files.length) {
        const uploadedFiles = await uploadFiles(files);
        if (!uploadedFiles) return;
        payload.files = uploadedFiles;
      }

      const api = isMasterIndex ? MasterIndexApi : MasterVehicleApi;

      const submitFunction = restFormValues._id
        ? api.updateMasterVehicle
        : api.createMasterVehicle;

      const res = await submitFunction(payload);
      if (!restFormValues._id && res.data._id) {
        await handleAddLinkedVehicle(res.data._id);
      }
      onGoBack();
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
  };

  const handleChange = (newValue: IMasterName) => {
    if (!newValue._id) return;
    setIsDirty(true);
    setValue('owner', newValue as IMasterNamePerson);
  };

  const handleColorChange = async (name: string, value: string) => {
    if (!value) {
      setValue('color', '');
      return;
    }

    const existingOption = getItems(name)?.find(
      (option) => option.value === value,
    );
    if (makeName && modelName && year && !existingOption) {
      try {
        const filteredMakeOptions = makeOptions.filter(
          (option) => option.label === makeName,
        );
        const makeId =
          filteredMakeOptions.length > 0
            ? filteredMakeOptions[0]._id
            : undefined;

        const filteredModelOptions = modelOptions?.filter(
          (option) => option.label === modelName,
        ) as { label: string; value: string; _id: string }[];

        const modelId =
          filteredModelOptions.length > 0
            ? filteredModelOptions[0]._id
            : undefined;

        if (makeId && modelId) {
          await VehicleApi.createVehicleColor({
            name: value,
            make: makeId || '',
            model: modelId || '',
            year: year || '',
          });
        }
      } catch (err: any) {
        updateToast({ open: true, message: err.message });
      }
    }
    setIsDirty(true);
    setValue('color', value);
  };

  return (
    <TabAnimationWrapper
      nextPaths={MasterVehicleNextPaths.MasterAddVehicleDetail}
    >
      {!hideHeader && (
        <PageBackHeader
          title={
            currentVehicleId ? 'Edit vehicle details' : 'Add vehicle details'
          }
          goBack={() => {
            if (onNavigation(new Event('back'))) {
              onGoBack();
            }
          }}
          style={{
            background: theme.palette.background.paper,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        />
      )}

      <FormRoot
        id={isMasterIndex ? "master-index-form" : undefined}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          pb: 0,
          '.p-kase-edit &': {
            padding: '40px 20px',
            mt: 0,
            background: theme.palette.background.paper,
          },
        }}
      >
        {MasterVehicleFormFields.map(({ key, title, items }) => {
          const CardComponent = isMasterIndex ? FormCard : ViewCard;
          return (
            <CardComponent key={key} title={title} className="grey-title">
              {items.map(({ grid, ...rest }: any) => (
                <>
                <Grid key={rest.name} xs={12} sm={6} md={3} {...grid} item>
                  <RenderFormField
                    items={getItems(rest.name)}
                    {...rest}
                    inputValue={rest.value}
                  />
                </Grid>
                {rest.name === 'type' &&
                  vehicleType &&
                  key === 'description' && (
                    <>
                      <Grid item xs={9} />
                      {(vehicleType === VehicleType.AUTOMOBILE
                        ? AutomobileTypeFormFields
                        : VehicleTypeFormFields
                      ).map(({ grid: innerGrid, ...innerRest }: any) => {
                        if (innerRest.name === 'make') {
                          return (
                            <Grid
                              key={rest.name}
                              container
                              alignItems="flex-start"
                              xs={12}
                              sm={6}
                              md={3}
                              {...grid}
                              item
                            >
                              <VehicleMakeAutocomplete
                                {...rest}
                                value={makeName}
                                label="Make"
                                key={rest.name}
                                onChange={(v) => {
                                  setValue('make', v.name);
                                }}
                              />
                            </Grid>
                          );
                        }

                        return (
                          <Grid
                            key={innerRest.name}
                            xs={12}
                            sm={6}
                            md={3}
                            {...innerGrid}
                            item
                          >
                            <RenderFormField
                              items={getItems(innerRest.name)}
                              {...innerRest}
                              inputValue={innerRest.value}
                              disabled={
                                innerRest.name === 'color' &&
                                (!makeName || !modelName || !year)
                              }
                              handleChange={
                                innerRest.name === 'color'
                                  ? handleColorChange
                                  : undefined
                              }
                            />
                          </Grid>
                        );
                      })}
                    </>
                  )}
              </>
            ))}
            {key === 'ownerInformation' && (
              <div style={{ marginLeft: 24, width: '100%' }}>
                <MasterNameAutocomplete
                  excludedMasterNames={owner ? [owner] : []}
                  filterByType={MasterNameType.PERSON}
                  onChange={handleChange}
                  noResults={<NoResultItem />}
                />
                <MasterVehicleOwnerTable
                  columns={MasterVehicleOwnerTableColumns}
                  owners={owner ? [owner] : []}
                  registeredOwnerId={owner?._id}
                  onSelectOwner={() => setValue('owner', undefined)}
                />
              </div>
            )}
            {key !== 'ownerInformation' && key !== 'verified' && (
              <Divider sx={{ my: 2, width: '100%' }} />
            )}
          </CardComponent>
        );
        })}
      </FormRoot>
      {/* )} */}
      {!isMasterIndex && (
        <Stack
          sx={{
            background: theme.palette.background.paper,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <Divider sx={{ my: 2, width: '100%' }} />
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            sx={{ my: 3, '.p-kase-edit &': { p: 2 } }}
          >
            <Button
              color="inherit"
              variant="outlined"
              sx={{ px: 4, mr: 2 }}
              onClick={onGoBack}
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={hasInvalidFiles}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      )}
    </TabAnimationWrapper>
  );
};

export default withLoader(withUnsavedChangesWarning(MasterVehicleForm, false));