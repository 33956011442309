import { FC, useEffect, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { useChannel } from 'ably/react';

import { CfsApi, UnitApi } from '@/api';
import { ActionButton } from '@/components';
import { withLoader } from '@/hocs';
import { useCFSContext, useUnitListFilter, useUnitShiftContext } from '@/hooks';
import { IUnit, IUnitShift } from '@/models';
import { getUnitShiftAddressLabel } from '@/services';
import { ConfirmTypes, useToastStore, useUserStore } from '@/store';

import { UnitAccordion } from './UnitAccordion';
import { UnitItem } from './UnitItem';
import {
  default as UnitShiftAssignModal,
  UnitShiftAssignModalProps,
} from './UnitShiftAssignModal';
import { CommonUnitsProps } from './UnitShiftList';
import {
  availableHQUnitActions,
  availableUnitActions,
  unitShiftStandbyActionItems,
  unitShiftStandbyHQActionItems,
} from '../data';
import { AssignedUnitShiftItem } from '@/contexts';

interface AvailableUnitsProps extends CommonUnitsProps {
  setCurrentTab?: (tabNumber: number) => void;
  fetchActiveCFSList?: () => void;
  handleAssignUnit?: () => void;
  showMenu?: boolean;
}

const accordions = {
  WAITING: 'waiting',
  ON_DUTY: 'on-duty',
  STANDBY: 'standby',
  SCHEDULED: 'scheduled',
};

type ExpandedAccordion = {
  scheduled: string | null;
  waiting: string | null;
  'on-duty': string | null;
  standby: string | null;
};

const isUnitShift = (item: IUnit | IUnitShift | AssignedUnitShiftItem): item is IUnitShift => {
  return (item as IUnitShift).startAt !== undefined;
}

const AvailableUnits: FC<AvailableUnitsProps> = ({
  cfsId,
  filterValues,
  searchText,
  showLoader,
  hideLoader,
  setCurrentTab,
  fetchActiveCFSList,
  handleAssignUnit,
  showMenu,
}) => {
  const { updateToast } = useToastStore();
  const { cfs, unitShiftDistances, fetchCFS } = useCFSContext();
  const {
    onDutyUnitShifts,
    waitingUnitShifts,
    scheduledUnitShifts,
    unitShiftAddresses,
    fetchOnDutyUnitShifts,
    fetchScheduledUnitShifts,
    fetchWaitingUnitShifts,
  } = useUnitShiftContext();
  const { account } = useUserStore();
  const [expandedPanel, setExpandedPanel] = useState<ExpandedAccordion>({
    waiting: null,
    scheduled: null,
    'on-duty': null,
    standby: null,
  });
  const [standByUnits, setStandByUnits] = useState<IUnit[]>([]);
  const [assignUnitShiftModal, setAssignUnitShiftModal] = useState<
    Omit<UnitShiftAssignModalProps, 'onClose' | 'showLoader' | 'hideLoader'>
  >({
    open: false,
  });

  useChannel(`account:${account?._id}:unit`, () => {
    fetchStandByUnits();
  });

  const activeUnitShiftData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: onDutyUnitShifts,
    searchText,
    searchResources: true,
  }) as IUnitShift[];

  const scheduledUnitsData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: scheduledUnitShifts,
    searchText,
  }) as IUnitShift[];

  const standByUnitsData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: standByUnits,
    searchText,
  }) as IUnit[];
  const waitingUnitShiftData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: waitingUnitShifts,
    searchText,
  }) as IUnitShift[];

  useEffect(() => {
    Promise.all([
      fetchStandByUnits(),
      fetchOnDutyUnitShifts(),
      fetchWaitingUnitShifts(),
      fetchScheduledUnitShifts(),
    ]).then(([standByUnitsRes, onDutyUnitShiftsRes, waitingUnitShiftsRes]) => {
      // Expand the first panel that has data
      if (waitingUnitShiftsRes.length) {
        setExpandedPanel((prev) => ({ ...prev, waiting: accordions.WAITING }));
      } else if (onDutyUnitShiftsRes.length) {
        setExpandedPanel((prev) => ({
          ...prev,
          'on-duty': accordions.ON_DUTY,
        }));
      } else if (standByUnitsRes.length) {
        setExpandedPanel((prev) => ({ ...prev, standby: accordions.STANDBY }));
      }
    });
  }, []);

  // Expand the panels that have data when search text is entered
  useEffect(() => {
    if (searchText !== '') {
      setExpandedPanel({
        waiting: waitingUnitShiftData.length > 0 ? accordions.WAITING : null,
        'on-duty': activeUnitShiftData.length > 0 ? accordions.ON_DUTY : null,
        standby: standByUnitsData.length > 0 ? accordions.STANDBY : null,
        scheduled: scheduledUnitsData.length > 0 ? accordions.SCHEDULED : null,
      });
    } else {
      // Reset the expanded panel when search text is empty
      // Expand the first panel that has data
      if (waitingUnitShiftData.length > 0) {
        setExpandedPanel({
          waiting: accordions.WAITING,
          'on-duty': null,
          standby: null,
          scheduled: null,
        });
      } else if (activeUnitShiftData.length > 0) {
        setExpandedPanel({
          waiting: null,
          'on-duty': accordions.ON_DUTY,
          standby: null,
          scheduled: null,
        });
      } else if (standByUnitsData.length > 0) {
        setExpandedPanel({
          waiting: null,
          'on-duty': null,
          standby: accordions.STANDBY,
          scheduled: null,
        });
      } else if (scheduledUnitsData.length > 0) {
        setExpandedPanel({
          waiting: null,
          'on-duty': null,
          standby: null,
          scheduled: accordions.SCHEDULED,
        });
      }
    }
  }, [searchText, waitingUnitShiftData, activeUnitShiftData, standByUnitsData]);

  const fetchStandByUnits = async () => {
    try {
      const filter = JSON.stringify({
        status: 'OFF_DUTY',
        isStandByWhenOffDuty: true,
      });
      const res = await UnitApi.getUnits({ filter, limit: 1000 });
      setStandByUnits(res.data.results);
      return res.data.results;
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return [];
    }
  };

  const handleExpand = (panel: string, accordion: keyof ExpandedAccordion) => {
    if (expandedPanel[accordion] !== null) {
      setExpandedPanel((prev) => ({ ...prev, [panel]: null }));
    } else setExpandedPanel((prev) => ({ ...prev, [panel]: panel }));
  };

  const updateToOffDuty = async (unitId: string) => {
    try {
      await UnitApi.setUnitOffDuty(unitId);
      updateToast({
        type: ConfirmTypes.SUCCESS,
        open: true,
        message: 'Unit shift is now off-duty',
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleSelectAction = (
    action: string | number,
    unitShiftId?: string,
    unitId?: string,
  ) => {
    if (action === 'assign') {
      setAssignUnitShiftModal({
        open: true,
        unitShiftId,
      });
    } else if (action === 'assignToHQ') {
      setAssignUnitShiftModal({
        open: true,
        unitShiftId,
        assignToHQ: true,
      });
    } else {
      updateToOffDuty(unitId || '');
    }
  };

  const handleSelectStandbyAction = (action: string | number, unit: IUnit) => {
    switch (action) {
      case 'assign':
        onOpenModal(unit);
        break;
      case 'assignToHQ':
        onOpenModal(unit, true);
        break;
      case 'setOnDuty':
        onOpenModal(unit, false, true);
        break;
    }
  };

  const onCloseModal = () => {
    setAssignUnitShiftModal({
      open: false,
    });
  };

  const onOpenModal = (
    unit?: IUnit,
    assignToHQ?: boolean,
    setOnDuty?: boolean,
  ) => {
    setAssignUnitShiftModal({
      open: true,
      unitShiftId: undefined,
      unit,
      assignToHQ,
      setOnDuty,
    });
  };

  const assignUnitShift = async (
    unitShiftId = '',
    isDispatchedToHQ?: boolean,
  ) => {
    showLoader();
    try {
      if (cfsId) {
        await CfsApi.assignUnitShift(cfsId, unitShiftId, isDispatchedToHQ);
        const response = await fetchCFS();

        if (response?.assignmentStatus === 'ASSIGNED' && setCurrentTab) {
          setCurrentTab(1);
        }

        if (fetchActiveCFSList) {
          fetchActiveCFSList();
        }

        updateToast({
          type: ConfirmTypes.SUCCESS,
          open: true,
          message: 'Unit shift successfully assigned',
        });
        handleAssignUnit?.();
      }
    } catch (err: any) {
      updateToast({
        open: true,
        message: err.message,
        type: ConfirmTypes.WARNING,
      });
    } finally {
      hideLoader();
    }
  };

  const checkAndUpdateShifts = async () => {
    const currentTime = new Date();
    const filterPassed = scheduledUnitsData.filter((unitData) => {
      if (isUnitShift(unitData)) {
        return (
          unitData.status === 'SCHEDULED' &&
          new Date(unitData.startAt as string).getTime() <= currentTime.getTime()
        );
      }
      return false;
    });

    if (filterPassed.length) {
      await fetchScheduledUnitShifts();
      await fetchOnDutyUnitShifts();
    }

    return;
  }

  useEffect(() => {
    checkAndUpdateShifts();

    // Every minute, check for scheduled unit shifts
    const interval = setInterval(checkAndUpdateShifts, 10000);
    return () => clearInterval(interval);
  }, [scheduledUnitsData])

  return (
    <Stack flex={1}>
      {!!waitingUnitShiftData.length && (
        <UnitAccordion
          title="Waiting"
          value="waiting"
          badge={waitingUnitShiftData.length}
          currentValue={expandedPanel.waiting}
          handleExpand={(p) => handleExpand(p, 'waiting')}
        >
          {waitingUnitShiftData.map((unitShift, index) => (
            <UnitItem
              key={index}
              {...unitShift.unit}
              addressLabel={getUnitShiftAddressLabel(
                unitShift,
                unitShiftAddresses,
              )}
              distance={unitShiftDistances[unitShift?._id as string]}
              resources={{
                polygons: unitShift.polygons,
              }}
            >
              {cfsId ? (
                <>
                  {unitShift.unit.type.isDispatchToHQEnabled && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      onClick={() => assignUnitShift(unitShift._id, true)}
                      disabled={cfs?.isClosed}
                      sx={{ mr: 2, padding: '8px 20px' }}
                    >
                      Assign to HQ
                    </Button>
                  )}
                  <Button
                    color="error"
                    variant="contained"
                    size="large"
                    onClick={() => assignUnitShift(unitShift._id)}
                    disabled={cfs?.isClosed}
                  >
                    Assign
                  </Button>
                </>
              ) : (
                <ActionButton
                  color="error"
                  variant="contained"
                  size="large"
                  items={
                    unitShift.unit.type.isDispatchToHQEnabled
                      ? availableHQUnitActions
                      : availableUnitActions
                  }
                  onSelectItem={(action) =>
                    handleSelectAction(
                      action,
                      unitShift._id,
                      unitShift.unit._id,
                    )
                  }
                >
                  More actions
                </ActionButton>
              )}
            </UnitItem>
          ))}
        </UnitAccordion>
      )}
      {!!activeUnitShiftData.length && (
        <UnitAccordion
          title="On duty"
          value="on-duty"
          badge={activeUnitShiftData.length}
          currentValue={expandedPanel['on-duty']}
          handleExpand={(p) => handleExpand(p, 'on-duty')}
        >
          {activeUnitShiftData.map((unitShift, index) => (
            <UnitItem
              key={index}
              {...unitShift.unit}
              addressLabel={getUnitShiftAddressLabel(
                unitShift,
                unitShiftAddresses,
              )}
              distance={unitShiftDistances[unitShift?._id as string]}
              resources={{
                polygons: unitShift.polygons,
                users: unitShift.users,
                equipments: unitShift.equipments,
                vehicles: unitShift.vehicles,
              }}
              itemType="on-duty"
              showResources
            >
              {cfsId ? (
                <>
                  {unitShift.unit.type.isDispatchToHQEnabled && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      onClick={() => assignUnitShift(unitShift._id, true)}
                      disabled={cfs?.isClosed}
                      sx={{ mr: 2, padding: '8px 20px' }}
                    >
                      Assign to HQ
                    </Button>
                  )}
                  <Button
                    color="error"
                    variant="contained"
                    size="large"
                    onClick={() => assignUnitShift(unitShift._id)}
                    disabled={cfs?.isClosed}
                  >
                    Assign
                  </Button>
                </>
              ) : (
                <ActionButton
                  color="error"
                  variant="contained"
                  size="large"
                  items={
                    unitShift.unit.type.isDispatchToHQEnabled
                      ? availableHQUnitActions
                      : availableUnitActions
                  }
                  onSelectItem={(action) =>
                    handleSelectAction(
                      action,
                      unitShift._id,
                      unitShift.unit._id,
                    )
                  }
                >
                  More actions
                </ActionButton>
              )}
            </UnitItem>
          ))}
        </UnitAccordion>
      )}
      {!!standByUnitsData.length && (
        <UnitAccordion
          title="Standby"
          value="standby"
          badge={standByUnitsData.length}
          currentValue={expandedPanel.standby}
          handleExpand={(p) => handleExpand(p, 'standby')}
        >
          {standByUnitsData.map((unit, index) => (
            <UnitItem key={index} {...unit}>
              {showMenu ? (
                <ActionButton
                  color="error"
                  variant="contained"
                  items={
                    unit.type.isDispatchToHQEnabled
                      ? unitShiftStandbyHQActionItems
                      : unitShiftStandbyActionItems
                  }
                  onSelectItem={(v) => handleSelectStandbyAction(v, unit)}
                >
                  More actions
                </ActionButton>
              ) : (
                <>
                  {unit.type.isDispatchToHQEnabled && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      onClick={() => onOpenModal(unit, true)}
                      sx={{ mr: 2, padding: '8px 20px' }}
                    >
                      Assign to HQ
                    </Button>
                  )}
                  <Button
                    color="error"
                    variant="contained"
                    size="large"
                    onClick={() => onOpenModal(unit)}
                  >
                    Assign
                  </Button>
                </>
              )}
            </UnitItem>
          ))}
        </UnitAccordion>
      )}
      {!!scheduledUnitsData.length && (
        <UnitAccordion
          title="Scheduled"
          value="scheduled"
          badge={scheduledUnitsData.length}
          currentValue={expandedPanel.scheduled}
          handleExpand={(p) => handleExpand(p, 'scheduled')}
        >
          {scheduledUnitsData.map((unitShift, index) => (
            <UnitItem
              key={index}
              startAt={unitShift.startAt}
              {...unitShift.unit}
              resources={{
                polygons: unitShift.polygons,
                users: unitShift.users,
                equipments: unitShift.equipments,
                vehicles: unitShift.vehicles,
              }}
              itemType="scheduled"
              showResources
            />
          ))}
        </UnitAccordion>
      )}
      <UnitShiftAssignModal
        onClose={onCloseModal}
        cfsId={cfsId}
        {...assignUnitShiftModal}
      />
    </Stack>
  );
};

export default withLoader(AvailableUnits);
