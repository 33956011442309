import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ButtonTabs, ButtonTabsProps, ButtonTabValue } from '@/components';

export interface FormTabFieldProps
  extends Omit<ButtonTabsProps, 'onChange' | 'value'> {
  name: string;
  rules?: object;
  handleChange?: (name: string, value: ButtonTabValue) => void;
}

const FormTabField: FC<FormTabFieldProps> = (props) => {
  const { name, rules, handleChange, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <ButtonTabs
          onChange={(d) => {
            onChange(d);
            if (handleChange) handleChange(name, d);
          }}
          value={value}
          {...rest}
        />
      )}
      name={name}
      rules={rules}
    />
  );
};

export { FormTabField };
