import { forwardRef, Ref } from 'react';

import {
  InputBase,
  InputLabel,
  InputBaseProps,
  FormHelperText,
  styled,
} from '@mui/material';

import { BaseFieldProps } from '../types';

const InputRoot = styled('div')(() => ({
  flex: 1,
  width: '100%',
  maxWidth: '100%',
  marginBottom: 8,

  '& .MuiInputBase-root': {
    width: '100%',

    '& input': {
      boxSizing: 'content-box!important',
    },
  },
}));

export interface InputProps
  extends Omit<InputBaseProps, 'value' | 'error'>,
    BaseFieldProps {
  value?: string;
  showLabel?: boolean;
  helperText?: string;
}

export const Input = forwardRef<Ref<any>, InputProps>((props, ref) => {
  const {
    label,
    error,
    showLabel = true,
    onChange,
    helperText,
    style,
    sx,
    ...rest
  } = props;
  return (
    <InputRoot style={style} sx={sx}>
      {label && (
        <InputLabel sx={{ visibility: showLabel ? 'visible' : 'hidden' }}>
          {label}
        </InputLabel>
      )}
      <InputBase {...rest} inputRef={ref} onChange={onChange} />
      {error && <FormHelperText error>{error}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </InputRoot>
  );
});

Input.displayName = 'Input';
