import { useEffect, useState } from 'react';

import { Button, Divider, Stack, Typography } from '@mui/material';

import { CfsApi } from '@/api';
import { IcWarning } from '@/assets/images';
import { SEVERITY_STATUS } from '@/constants';
import { withLoader, WithLoaderProps } from '@/hocs';
import { ICFS, TSeverity } from '@/models';
import { getAddressLocation } from '@/services';
import { colors } from '@/theme/variables';

import { ModalState } from '../shared/modalState';

type NewCfsAssignedModalContentProps = {
  cfsId: string;
  toggleModal: (modalName: keyof ModalState) => void;
  action: () => void;
};

const NewCfsAssignedModalContent = ({
  cfsId,
  toggleModal,
  action,
  showLoader,
  hideLoader,
}: NewCfsAssignedModalContentProps & WithLoaderProps) => {
  const [cfs, setCfs] = useState<ICFS | null>(null);
  useEffect(() => {
    if (cfsId) {
      showLoader();
      CfsApi.getOne(cfsId).then((res) => {
        hideLoader();
        setCfs(res.data);
      });
    }
  }, [cfsId]);

  const getColor = () => {
    switch (cfs?.severity) {
      case TSeverity.HIGH:
        return SEVERITY_STATUS.HIGH;
      case TSeverity.MEDIUM:
        return SEVERITY_STATUS.MEDIUM;
      case TSeverity.LOW:
        return SEVERITY_STATUS.LOW;
      default:
        return colors.error.main;
    }
  };

  if (!cfs) return null;

  return (
    <>
      <Stack rowGap={2} flex={1} alignItems="center" fontSize={40} padding={5}>
        <IcWarning width={84} height={84} color={getColor()} />
        <Typography component="h3" variant="h6">
          {cfs?.shortDescription || 'No Description'}
        </Typography>
        <Typography component="p">
          {getAddressLocation(cfs?.addressInfo?.address)}
        </Typography>
      </Stack>
      <Stack>
        <Divider />
        <Button
          color="error"
          variant="contained"
          size="large"
          sx={{ padding: 2 }}
          fullWidth
          onClick={() => {
            action();
            toggleModal('newCfsAssigned');
          }}
        >
          Preview CFS
        </Button>
      </Stack>
    </>
  );
};

export default withLoader(NewCfsAssignedModalContent);
