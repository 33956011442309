import { IAuditModel } from './base';
import { IBaseLog } from './log';

export enum KaseEvent {
  CREATED = 'CREATED',
  CREATED_FROM_CFS = 'CREATED_FROM_CFS',
  UPDATED_BASIC_INFO = 'UPDATED_BASIC_INFO',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  REOPENED = 'REOPENED',
  UPDATED_PRIMARY_OFFICER = 'UPDATED_PRIMARY_OFFICER',
  UPDATED_ASSISTING_OFFICERS = 'UPDATED_ASSISTING_OFFICERS',
  UPDATED_PRIMARY_INVESTIGATOR = 'UPDATED_PRIMARY_INVESTIGATOR',
  UPDATED_TYPE = 'UPDATED_TYPE',
  // event for updating biasMotivations, involveCargoTheft
  UPDATED_INCIDENT_INFO = 'UPDATED_INCIDENT_INFO',
  // events for toggle priority
  MARKED_PRIORITY = 'MARKED_PRIORITY',
  UNMARKED_PRIORITY = 'UNMARKED_PRIORITY',
  // event for assigning Kase to KASE_APPROVER
  ASSIGNED_TO_KASE_APPROVER = 'ASSIGNED_TO_KASE_APPROVER',
  UNASSIGNED_FROM_KASE_APPROVER = 'UNASSIGNED_FROM_KASE_APPROVER',
  ADDED_DISPOSITION = 'ADDED_DISPOSITION',
  UPDATED_FILES = 'UPDATED_FILES',
  REPORTED_TO_STATE_GOVERNMENT = 'REPORTED_TO_STATE_GOVERNMENT',
  REPORTED_TO_NIBRS = 'REPORTED_TO_NIBRS',
  PRINTED = 'PRINTED',
  DOWNLOADED = 'DOWNLOADED',
  EMAILED = 'EMAILED',
  DELETED = 'DELETED',

  // Kase CFS events
  LINKED_CFS = 'LINKED_CFS',
  UNLINKED_CFS = 'UNLINKED_CFS',

  // Kase link events
  LINKED_KASE = 'LINKED_KASE',
  UNLINKED_KASE = 'UNLINKED_KASE',

  // Kase MasterName events
  LINKED_PERSON = 'LINKED_PERSON',
  LINKED_ORGANIZATION = 'LINKED_ORGANIZATION',
  LINKED_UNKNOWN_PERSON = 'LINKED_UNKNOWN_PERSON',
  LINKED_UNKNOWN_ORGANIZATION = 'LINKED_UNKNOWN_ORGANIZATION',
  UPDATED_PERSON_RELATIONSHIP = 'UPDATED_PERSON_RELATIONSHIP',
  UPDATED_ORGANIZATION_RELATIONSHIP = 'UPDATED_ORGANIZATION_RELATIONSHIP',
  UPDATED_UNKNOWN_PERSON_RELATIONSHIP = 'UPDATED_UNKNOWN_PERSON_RELATIONSHIP',
  UPDATED_UNKNOWN_ORGANIZATION_RELATIONSHIP = 'UPDATED_UNKNOWN_ORGANIZATION_RELATIONSHIP',
  UNLINKED_PERSON = 'UNLINKED_PERSON',
  UNLINKED_ORGANIZATION = 'UNLINKED_ORGANIZATION',
  UNLINKED_UNKNOWN_PERSON = 'UNLINKED_UNKNOWN_PERSON',
  UNLINKED_UNKNOWN_ORGANIZATION = 'UNLINKED_UNKNOWN_ORGANIZATION',

  // Kase MasterVehicle events
  LINKED_MASTER_VEHICLE = 'LINKED_MASTER_VEHICLE',
  UNLINKED_MASTER_VEHICLE = 'UNLINKED_MASTER_VEHICLE',

  // Kase Narrative events
  ADDED_PRIMARY_NARRATIVE = 'ADDED_PRIMARY_NARRATIVE',
  ADDED_PERSONAL_NARRATIVE = 'ADDED_PERSONAL_NARRATIVE',
  UPDATED_PRIMARY_NARRATIVE = 'UPDATED_PRIMARY_NARRATIVE',
  UPDATED_PERSONAL_NARRATIVE = 'UPDATED_PERSONAL_NARRATIVE',
  DELETED_PRIMARY_NARRATIVE = 'DELETED_PRIMARY_NARRATIVE',
  DELETED_PERSONAL_NARRATIVE = 'DELETED_PERSONAL_NARRATIVE',

  // Offense events
  CREATED_OFFENSE = 'CREATED_OFFENSE',
  UPDATED_OFFENSE = 'UPDATED_OFFENSE',
  DELETED_OFFENSE = 'DELETED_OFFENSE',

  // Arrestee events
  ADDED_ARRESTEE = 'ADDED_ARRESTEE',
  UPDATED_ARRESTEE = 'UPDATED_ARRESTEE',
  DELETED_ARRESTEE = 'DELETED_ARRESTEE',

  // Property events
  ADDED_PROPERTY = 'ADDED_PROPERTY',
  UPDATED_PROPERTY = 'UPDATED_PROPERTY',
  REMOVED_PROPERTY = 'REMOVED_PROPERTY',
  LOGGED_PROPERTY = 'LOGGED_PROPERTY',
  RELEASED_PROPERTY_TO_LAB = 'RELEASED_PROPERTY_TO_LAB',
  RELEASED_PROPERTY_TO_COURT = 'RELEASED_PROPERTY_TO_COURT',
  RELEASED_PROPERTY_TO_PERSON = 'RELEASED_PROPERTY_TO_PERSON',
  RELEASED_PROPERTY_TO_OWNER = 'RELEASED_PROPERTY_TO_OWNER',
  SEIZED_PROPERTY_IN_POLICE_CUSTODY = 'SEIZED_PROPERTY_IN_POLICE_CUSTODY',
  DISPOSED_PROPERTY = 'DISPOSED_PROPERTY',
  DESTROYED_PROPERTY = 'DESTROYED_PROPERTY',
  AUCTIONED_PROPERTY = 'AUCTIONED_PROPERTY',
  TRANSFERRED_PROPERTY = 'TRANSFERRED_PROPERTY',
  UPDATED_PROPERTY_LOCATION = 'UPDATED_PROPERTY_LOCATION',
}

export interface IKaseLinkedItems {
  cfs?: string; // MongoDB ObjectId string representation of CFS
  masterName?: string; // MongoDB ObjectId string representation of MasterName
  masterVehicle?: string; // MongoDB ObjectId string representation of MasterVehicle
  property?: string; // MongoDB ObjectId string representation of Property
  narrative?: string; // MongoDB ObjectId string representation of Narrative
  offense?: string; // MongoDB ObjectId string representation of Offense
  kase?: string; // MongoDB ObjectId string representation of Kase
}

export interface IKaseLog extends Omit<IAuditModel, 'createdAt'>, IBaseLog {
  kase: string;
  event: KaseEvent;
  linkedItems?: IKaseLinkedItems;
}
