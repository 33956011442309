import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Stack, Box, useTheme } from '@mui/material';
import moment from 'moment';

import { MasterIndexApi } from '@/api';
import { IViewItem, ViewCard, ViewItem } from '@/components';
import { DATE_FORMAT } from '@/config';
import { IMasterAddress, IMasterPoint } from '@/models/master.address';
import { useToastStore } from '@/store';

import { MasterAddressViewFields } from '../data';

interface MasterIndexAddressViewContentProps {
  hideHeader?: boolean;
}

export const MasterIndexAddressViewContent: FC<
  MasterIndexAddressViewContentProps
> = () => {
  const { addressId } = useParams();
  const { updateToast } = useToastStore();
  const [currentAddress, setCurrentAddress] = useState<IMasterAddress>();
  const theme = useTheme();
  useEffect(() => {
    if (addressId) fetchAddressData();
  }, [addressId]);

  const fetchAddressData = async () => {
    try {
      const res = await MasterIndexApi.getMasterAddress(addressId || '');
      setCurrentAddress(res.data);
    } catch (err: any) {
      updateToast({
        open: true,
        message: err.message || 'Failed to fetch address details',
      });
    }
  };

  const getValue = (item: IViewItem): string => {
    if (!currentAddress) return '';
    const itemValue = currentAddress[item.value as keyof IMasterAddress];

    if (
      ['createdAt', 'updatedAt'].includes(item.value as string) &&
      itemValue
    ) {
      return moment(itemValue as string).format(DATE_FORMAT);
    }

    if (item.value === 'point' && itemValue) {
      const point = itemValue as IMasterPoint;
      return `${point.coordinates[1]}, ${point.coordinates[0]}`;
    }

    if (item.value === 'intersectionStreets') {
      return Array.isArray(itemValue) ? (itemValue as string[]).join(', ') : '';
    }

    if (itemValue === null) {
      return 'N/A';
    }

    if (typeof itemValue === 'object' && itemValue !== null) {
      return JSON.stringify(itemValue);
    }

    return String(itemValue);
  };

  const renderViewCardContent = ({
    items,
    size,
  }: (typeof MasterAddressViewFields)[0]) => {
    return items.map((item) => (
      <ViewItem
        key={item.label}
        label={item.label}
        value={getValue(item)}
        md={4}
        {...size}
      />
    ));
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          p: 2.5,
          background: theme.palette.background.paper,
          borderRadius: 2,
        }}
      >
        <Stack spacing={2}>
          {currentAddress &&
            MasterAddressViewFields.map((data, index) => (
              <ViewCard
                key={data.key}
                title={data.title}
                showDivider={index !== MasterAddressViewFields.length - 1}
              >
                {renderViewCardContent(data)}
              </ViewCard>
            ))}
        </Stack>
      </Box>
    </Box>
  );
};
