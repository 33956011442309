import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import { IMasterVehicle, IMasterVehicleForm } from '@/models';

import { http } from './http';
import { IGetParams, IListResponse } from './types';

export const MasterVehicleApi = {
  getMasterVehicles: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IMasterVehicle>>> => {
    return http.get(`api/${ApiVersion.V1}/vehicles/master-vehicles`, {
      params,
    });
  },
  getMasterVehicle: (id: string): Promise<AxiosResponse<IMasterVehicle>> => {
    return http.get(`api/${ApiVersion.V1}/vehicles/master-vehicles/${id}`);
  },
  createMasterVehicle: (
    data: IMasterVehicleForm,
  ): Promise<AxiosResponse<IMasterVehicle>> => {
    return http.post(`api/${ApiVersion.V1}/vehicles/master-vehicles`, data);
  },
  updateMasterVehicle: ({
    _id,
    ...data
  }: IMasterVehicleForm): Promise<AxiosResponse<IMasterVehicle>> => {
    return http.put(
      `api/${ApiVersion.V1}/vehicles/master-vehicles/${_id}`,
      data,
    );
  },
  deleteMasterVehicle: (id: string): Promise<AxiosResponse<IMasterVehicle>> => {
    return http.delete(`api/${ApiVersion.V1}/vehicles/master-vehicles/${id}`);
  },
};
