import { useEffect, useState } from 'react';

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { MasterNameApi } from '@/api';
import { DATE_FORMAT } from '@/config';
import { withLoader, WithLoaderProps } from '@/hocs';
import { cfsHistoryTableColumns } from '@/layouts/TabLayout/MasterNames/data';
import { ICFSMasterName, IMasterName } from '@/models';
import { useToastStore } from '@/store';

const RelationsList = ({
  type,
  currentMastername,
  showLoader,
  hideLoader,
}: {
  type: string;
  currentMastername: IMasterName | null;
} & WithLoaderProps) => {
  const [historyRows, setHistoryRows] = useState<ICFSMasterName[]>();
  const { updateToast } = useToastStore();

  useEffect(() => {
    if (type === 'CFS') {
      fetchCfsHistory();
    }
  }, [currentMastername?._id]);

  const fetchCfsHistory = async () => {
    if (!currentMastername?._id) return;
    try {
      showLoader();
      const res = await MasterNameApi.getNameCfsHistory(currentMastername?._id);
      setHistoryRows(res.data);
      hideLoader();
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      hideLoader();
    }
  };

  const renderCell = (row: Record<string, any>, key: string) => {
    try {
      const value = key.split('.').reduce((acc, part) => {
        return acc?.[part];
      }, row);

      if (key === 'createdAt') {
        return moment(value).format(DATE_FORMAT);
      }

      if (key === 'relationship.involvement') {
        return Array.isArray(value) ? value.join(', ') : '';
      }

      return value || '-';
    } catch (err) {
      return '-';
    }
  };

  return (
    <Stack width="100%">
      <Table>
        <TableHead>
          <TableRow>
            {cfsHistoryTableColumns.map((field) => (
              <TableCell
                key={field.key}
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: 'background.paper',
                }}
              >
                {field.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {historyRows?.map((row) => (
            <TableRow
              key={row._id}
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              {cfsHistoryTableColumns.map((field) => (
                <TableCell
                  key={field.key}
                  sx={{
                    minWidth: field.key === 'cfs.number' ? 120 : 'auto',
                    padding: '10px',
                  }}
                >
                  {String(renderCell(row, field.key))}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

export default withLoader(RelationsList);
