import { MasterIndexEvent } from '@/models';
import { colors } from '@/theme/variables';

import { TableColumn } from '../CFS/Records/types/types';

export const MasterIndexTabs = [
  'persons',
  'organizations',
  'vehicles',
  'properties',
  'address',
  'logs',
];

export const tabRelatedRecordsTableColumns: TableColumn[] = [
  { key: 'name', label: 'Record number' },
  { key: 'location', label: 'Location' },
  { key: 'dateAndTime', label: 'Date and time' },
  { key: 'involvement', label: 'Involvement' },
  { key: 'actions', label: '' },
];

export const masterIndexLogsEventStatus = {
  [MasterIndexEvent.CREATED]: {
    label: 'Created',
    color: 'white',
    bgColor: colors.secondary.main,
  },
  [MasterIndexEvent.UPDATED]: {
    label: 'Updated',
    color: 'white',
    bgColor: colors.primary.main,
  },
  [MasterIndexEvent.DELETED]: {
    label: 'Deleted',
    color: 'white',
    bgColor: colors.error.main,
  },
};
