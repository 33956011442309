import { useFormContext } from 'react-hook-form';

import { Grid, Stack } from '@mui/material';

import { FormCard, RenderFormField } from '@/components';
import { IAddress } from '@/models';
import { getAddressLocation } from '@/services';

import { AssociatedItem } from './AssociatedItem';

interface AssociatedFormProps {
  addresses: IAddress[];
}

export const AssociatedAddressForm = ({ addresses }: AssociatedFormProps) => {
  const { setValue, watch } = useFormContext();
  const currentAddress = watch('address');

  const handleUpdateAddress = (address?: IAddress) => {
    if (currentAddress?._id === address?._id) {
      setValue('address', undefined);
    } else {
      setValue('address', address);
    }
  };

  return (
    <FormCard
      title="Address information"
      style={{ marginBottom: '16px', marginTop: '1px' }}
    >
      <Grid sm={12} item>
        <RenderFormField name="address" type="address" label="Address" />
        <FormCard
          title="Associated addresses"
          style={{ boxShadow: 'none', padding: 0 }}
        >
          <Stack className="item-wrapper">
            {addresses.map((address) => (
              <AssociatedItem
                key={address._id}
                itemType="address"
                label={getAddressLocation(address)}
                isUsed={address?._id === currentAddress?._id}
                onClick={() => handleUpdateAddress(address)}
              />
            ))}
          </Stack>
        </FormCard>
      </Grid>
    </FormCard>
  );
};
