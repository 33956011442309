import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import MasterVehicleForm from '@/layouts/TabLayout/MasterVehicles/components/MasterVehicleForm';
import { IMasterName, IMasterVehicleForm } from '@/models';
import MasterIndexVehicleBase from '@/pages/MasterIndex/Vehicles/components/MasterIndexVehicleBase';

export interface MasterVehicleFormData
  extends Omit<IMasterVehicleForm, 'owner'> {
  owner?: IMasterName;
}

const MasterIndexVehicleForm: FC = () => {
  const navigate = useNavigate();

  return (
    <MasterIndexVehicleBase>
      <MasterVehicleForm
        goBack={() => navigate('/master-index/vehicles')}
        hideHeader={true}
        isMasterIndex={true}
      />
    </MasterIndexVehicleBase>
  );
};

export default MasterIndexVehicleForm;
