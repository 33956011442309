// https://docs.google.com/spreadsheets/d/1GCrdedP2Kn1quBntCrX0AYnAhZWds4M2RYYCRT3bf4g/edit#gid=272291441
// This doesn't include personal narrative permission logic since it was implemented in the component.
import { KaseRoleBasedAction } from '@/constants';
import { KaseStatus, IKase, IUser, Role } from '@/models';

import { isTargetAgencyRole } from './service.role';

export const isReadOnlyKaseRole = (
  action: KaseRoleBasedAction,
  user: IUser | null,
  kase: IKase | null,
) => {
  if (!user || !kase) {
    return true;
  }
  switch (kase.status) {
    case KaseStatus.OPEN:
      if (action === KaseRoleBasedAction.NARRATIVE_MAIN) {
        return kase.primaryOfficer?._id !== user._id;
      } else {
        return false;
      }
    case KaseStatus.WAITING_FOR_SUPERVISOR:
    case KaseStatus.REJECTED:
      if (isTargetAgencyRole(user, Role.KASE_APPROVER)) {
        return action === KaseRoleBasedAction.NARRATIVE_MAIN;
      } else {
        return true;
      }
    default:
      return true;
  }
};
