import { FC } from 'react';

import { RefreshOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { MapControl, ControlPosition } from '@vis.gl/react-google-maps';

interface ResetMapControlProps {
  onReset: () => void;
  position?: ControlPosition;
}

export const ResetMapControl: FC<ResetMapControlProps> = ({
  onReset,
  position = ControlPosition.RIGHT_BOTTOM,
}) => {
  return (
    <MapControl position={position}>
      <IconButton
        onClick={onReset}
        sx={{
          cursor: 'pointer',
          py: 0,
          backgroundColor: 'white',
          boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
          border: '1px solid #ccc',
          borderRadius: '2px',
          mr: 1.5,
          mb: 1,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          },
          width: '40px',
          height: '40px',
        }}
      >
        <RefreshOutlined sx={{ fontSize: 20 }} />
      </IconButton>
    </MapControl>
  );
};
