import badge from './badge';
import button from './button';
import buttongroup from './buttongroup';
import datagridpanel from './datagridpanel';
import datetimepicker from './datetimepicker';
import form from './form';
import menu from './menu';
import select from './select';
import tab from './tab';
import table from './table';
import tooltip from './tooltip';

export default {
  ...badge,
  ...button,
  ...buttongroup,
  ...form,
  ...menu,
  ...table,
  ...datetimepicker,
  ...tab,
  ...tooltip,
  ...datagridpanel,
  ...select,
};
