import moment from 'moment';

import { DataGridColSettings } from '@/components/DataGrid/utils';
import { DATE_TIME_FORMAT_WITH_SECONDS } from '@/config';
import { phoneNumberInput, phoneTypeInput } from '@/constants/form_inputs';
import { IKaseNewForm } from '@/models';

export const kaseDataGridColumns: DataGridColSettings[] = [
  {
    field: 'priority',
    headerName: 'Priority',
    width: 70,
    visible: true,
  },
  {
    field: 'number',
    headerName: 'Case ID',
    width: 150,
    visible: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 140,
    visible: true,
  },
  {
    field: 'approvedInfo',
    headerName: 'Date approved',
    width: 140,
    visible: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last edited',
    width: 140,
    visible: true,
  },
  {
    field: 'masterNameCount',
    headerName: 'Names(count)',
    width: 130,
    visible: true,
  },
  {
    field: 'address',
    headerName: 'Location',
    visible: true,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    visible: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 160,
    visible: true,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 80,
    visible: true,
  },
];

export const filterMenuFormFields = [
  {
    name: 'kasesId',
    type: 'autocomplete',
    defaultValue: '0',
    items: [
      {
        label: 'Case ID ',
        value: '0',
      },
      {
        label: 'Case ID 1 ',
        value: 'Case ID1 first options',
      },
      {
        label: 'Case ID 2',
        value: 'Case ID 2',
      },
      {
        label: 'Case ID 3 ',
        value: 'Case ID 3',
      },
    ],
  },
  {
    name: 'contains',
    type: 'autocomplete',
    defaultValue: '0',
    items: [
      {
        label: 'Contains',
        value: '0',
      },
      {
        label: 'item1',
        value: 'item1',
      },
      {
        label: 'item2',
        value: 'item2',
      },
      {
        label: 'item3',
        value: 'item3',
      },
    ],
  },
  {
    name: 'custom',
    type: 'text',
    label: 'Enter value',
    placeholder: 'Enter Value',
  },
];

export const setInvolvementFormFields = [
  {
    name: 'behavior',
    type: 'autocomplete',
    label: 'Behavior',
    items: [
      {
        label: 'behavior1',
        value: 'Behavoir Option 1',
      },
      {
        label: 'behavior2',
        value: 'Behavoir Option 2',
      },
      {
        label: 'behavior3',
        value: 'Behavoir Option 3',
      },
    ],
  },
  {
    name: 'involvement',
    type: 'autocomplete',
    label: 'Involvement',
    items: [
      {
        label: 'Involvement1',
        value: 'Involvement Option 1',
      },
      {
        label: 'Involvement2',
        value: 'Involvement Option 2',
      },
      {
        label: 'Involvement3',
        value: 'Involvement Option 3',
      },
    ],
  },
];

export const KaseFormFields = [
  {
    name: 'type',
    label: 'Case type',
    type: 'autocomplete',
    rules: {
      required: 'This field is required.',
    },
    grid: { md: 12 },
  },
  {
    name: 'primaryOfficer',
    label: 'Primary officer',
    type: 'autocomplete',
    rules: {
      required: 'This field is required.',
    },
    grid: { md: 6 },
  },
  {
    name: 'primaryInvestigator',
    label: 'Primary investigator',
    type: 'autocomplete',
    grid: { md: 6 },
  },
  {
    name: 'address',
    label: 'Address',
    type: 'address',
    rules: {
      required: 'This field is required.',
    },
    grid: { md: 12 },
  },
  {
    name: 'locationCode',
    label: 'CTV Location Code',
    type: 'autocomplete',
    freeSolo: true,
    // multiple: false,
    items: 'ctvCodes',
    grid: { md: 6 },
  },
  {
    name: 'incidentStartedAt',
    label: 'Incident date and time',
    type: 'datetime',
    maxDateTime: moment().minute(moment().minute()),
    grid: { md: 6 },
    rules: {
      required: 'This field is required.',
    },
    clearable: true,
  },
  {
    name: 'incidentReportedAt',
    label: 'Report date and time',
    type: 'datetime',
    maxDateTime: moment().minute(moment().minute()),
    grid: { md: 6 },
    rules: {
      validate: (value: string, { incidentStartedAt }: IKaseNewForm) => {
        if (!value || !incidentStartedAt) return true;
        return new Date(value) >= new Date(incidentStartedAt)
          ? true
          : 'Report datetime must be the same or later than incident datetime.';
      },
    },
    clearable: true,
    views: ['year', 'month', 'day', 'hours', 'minutes', 'seconds'],
    timeSteps: { hours: 1, minutes: 1, seconds: 1 },
    format: DATE_TIME_FORMAT_WITH_SECONDS,
  },
  {
    name: 'sdpIdentifier',
    label: 'Station/Division/Precinct Identifier',
    grid: { md: 6 },
    rules: {
      pattern: {
        value: /^[a-zA-Z0-9]+$/,
        message: 'Only alphanumeric values allowed.',
      },
      maxLength: {
        value: 6,
        message:
          'Station/Division/Precinct Identifier should be less than 6 characters long.',
      },
    },
    clearable: true,
  },
];

export const ExternalResourcesPhoneNumberFields = [
  {
    ...phoneTypeInput,
    grid: {
      md: 4,
    },
  },
  {
    ...phoneNumberInput,
    name: 'phoneNumber',
    label: 'Phone number',
    rules: {
      ...phoneNumberInput.rules,
      required: 'This field is required.',
    },
    grid: {
      md: 4,
    },
  },
];
