import { Menu, Grid, Stack, styled } from '@mui/material';

import { AppModal } from '@/components';
import { colors } from '@/theme/variables';

export const IncomingCallRoot = styled(Stack)(() => ({
  position: 'fixed',
  bottom: 60,
  right: 20,

  '& .btn-circle': {
    width: '46px',
    height: '46px',
    borderRadius: '23px',
    transition: 'all .3s',
  },
  '& svg': {
    width: '24px',
    height: '24px',
  },
  '& svg path': {
    fill: colors.white,
    stroke: colors.white,
    strokeWidth: '0',
  },
  '& [aria-expanded="true"]': {
    borderRadius: '0px 23px 23px 23px',

    '& svg': {
      animation: 'bounceUp .3s linear 2',
    },
  },
  '@keyframes bounceUp': {
    '0%': { transform: 'translateY(-10px)' },
    '40%': { transform: 'translateY(-30px)' },
    '60%': { transform: 'translateY(-15px)' },
  },
}));

export const CallCardRoot = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: '227px', // To match design
  borderRadius: '6px',
  border: `1px solid ${colors.grey[10]}`,
  padding: '10px',

  '&.is-new-call': {
    backgroundColor: 'rgb(238, 62, 92, 0.05)',
    '& .svg-phone path': {
      stroke: colors.error.main,
    },
    '&:hover': {
      backgroundColor: 'rgb(238, 62, 92, 0.4)',
    },
  },
  '& .svg-phone': {
    marginRight: '13px',
  },
  '&:hover': {
    backgroundColor: colors.grey[10],
  },

  '& .call-number': {
    lineHeight: 'normal',
  },

  '& .call-info': {
    fontSize: '12px',
    lineHeight: 'normal',
  },
}));

export const IncomingCallModalRoot = styled(AppModal)(() => ({
  '& .modal-content': {
    width: '100%',
    maxHeight: '90%',
    overflowY: 'scroll',
    position: 'relative',
  },
  '& .recent-cfs': {
    width: '100%',
    maxHeight: 660,
    overflow: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  '& .btn-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '15px',
    paddingTop: '16px',
    marginTop: '12px',
    borderTop: `1px solid ${colors.grey[10]}`,
  },
  '& .item-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    gap: '10px',
    width: '100%',
    maxHeight: 140,
    overflow: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  '& .badge-counter': {
    position: 'absolute',
    top: 35,
    marginLeft: 165,
    '.MuiBadge-badge': {
      width: 25,
      height: 25,
    },
  },
}));

export const IncomingCallMenuRoot = styled(Menu)(() => ({
  height: 375,

  '& .MuiPaper-root': {
    transform: 'translateY(-50px)!important',
  },
}));

export const RecentCFSCardRoot = styled(Stack)(() => ({
  padding: '12px 16px',
  margin: '5px 15px',
  backgroundColor: colors.white,
  borderRadius: 8,
  boxShadow: `0px 0px 0px 1px ${colors.grey[20]}`,

  '& .call-chips': {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '10px',
  },
  '& .call-info-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .cfs-description': {
    fontSize: '14px',
    wordBreak: 'break-word',
    marginRight: '40px',
    width: 357,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .call-action': {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
}));

export const AssociatedItemRoot = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10px 15px',
  borderRadius: 6,
  margin: '0 auto',
  width: '100%',
  border: `1px solid ${colors.grey[20]}`,

  '& .name': {
    textTransform: 'capitalize',
  },
}));
