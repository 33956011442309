import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Divider, Grid, Stack } from '@mui/material';
import moment from 'moment';

import { DriverLicenseApi } from '@/api/api.driver.license';
import { FormRoot, RenderFormField, ViewCard } from '@/components';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { IMasterNamePerson } from '@/models';
import { IDriverLicense } from '@/models/driver.license';
import { ConfirmTypes, useToastStore } from '@/store/store.toast';
import colors from '@/theme/variables/colors';

import { useMasterNamesContext } from '..';
import {
  DriverLicenseFormFields,
  TabNamesNextPaths,
  TabNamesScreens,
} from '../data';
import { UserAvatar } from '../styles';

export const MasterAddDriverLicense = () => {
  const { updateCurrentScreen } = useTabLayoutContext();
  const { updateToast } = useToastStore();
  const { currentDriverLicenseId, handleUpdateMasterName, currentMastername } =
    useMasterNamesContext();
  const methods = useForm<IDriverLicense>({
    mode: 'all',
  });
  const { handleSubmit, getValues, register } = methods;
  useEffect(() => {
    if (currentDriverLicenseId) fetchCurrentDriverLicense();
  }, [currentDriverLicenseId]);

  const fetchCurrentDriverLicense = async () => {
    try {
      const res = await DriverLicenseApi.getDriverLicense(
        String(currentDriverLicenseId),
      );
      methods.reset({
        ...res.data,
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const onSubmit = async (values: IDriverLicense) => {
    try {
      const {
        firstName,
        lastName,
        middleName = '',
        birthday,
        sex: gender,
      } = currentMastername?.masterName as IMasterNamePerson;

      const { eyeColor, height } = (
        currentMastername?.masterName as IMasterNamePerson
      ).physicalCharacteristics || { eyeColor: undefined, height: undefined };

      const payload = {
        ...values,
        firstName,
        lastName,
        middleName,
        gender,
        birthday: birthday ? moment(birthday).format('YYYY-MM-DD') : undefined,
        eyeColor,
        height,

        address: values.address?._id || '',
      };

      const savedLicense = await DriverLicenseApi.saveDriverLicense(payload);

      if (savedLicense?.data?._id && currentMastername?.masterName._id) {
        if (!values._id) {
          await handleUpdateMasterName(currentMastername.masterName._id, {
            firstName,
            lastName,
            driverLicense: savedLicense.data._id,
          });
        }

        updateToast({
          open: true,
          message: 'License saved',
          type: ConfirmTypes.SUCCESS,
        });
        updateCurrentScreen(TabNamesScreens.MasterAddNameDetails);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
    return false;
  };

  const onGoBack = () => {
    updateCurrentScreen(TabNamesScreens.MasterAddNameDetails);
  };

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.driverLicense}>
      <PageBackHeader
        title="Driver's license / ID card information"
        goBack={onGoBack}
      />
      <Stack flexDirection="row" justifyContent="left" alignItems="center">
        <UserAvatar sx={{ color: colors.error.dark, fontSize: 28 }}>
          {getValues().firstName?.charAt(0)}
          {getValues().lastName?.charAt(0)}
        </UserAvatar>
      </Stack>
      <Divider sx={{ mx: 3, mb: 2 }} />
      <FormProvider {...methods}>
        <FormRoot sx={{ py: 2, flex: 1 }} onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={2} mx={2} container>
            {DriverLicenseFormFields.map(({ key, items, title }) => (
              <ViewCard key={key} title={title}>
                {items.map(({ grid, ...rest }: any) => {
                  return rest.type === 'hidden' ? (
                    <input type="hidden" {...register(rest.name)} />
                  ) : (
                    <Grid
                      key={rest.name}
                      xs={12}
                      sm={6}
                      md={3}
                      {...grid}
                      item
                      mb={2}
                    >
                      <RenderFormField {...rest} />
                    </Grid>
                  );
                })}
              </ViewCard>
            ))}
          </Grid>
        </FormRoot>
        <Divider sx={{ my: 2, width: '100%' }} />
        <Stack
          columnGap={2}
          flexDirection="row"
          justifyContent="end"
          m={3}
          flex={1}
          flexGrow={0}
        >
          <Button
            variant="contained"
            color="inherit"
            size="medium"
            onClick={onGoBack}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            size="medium"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Stack>
      </FormProvider>
    </TabAnimationWrapper>
  );
};
