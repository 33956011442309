export const cautionFormFields = [
  {
    name: 'type',
    type: 'autocomplete',
    label: 'Type',
    rules: {
      required: 'This field is required.',
    },
    styles: {
      xs: 4,
    },
  },
  {
    name: 'customCautionType',
    type: 'text',
    label: 'Custom caution type',
    rules: {
      required: 'This field is required.',
    },
    styles: {
      xs: 8,
    },
  },
  {
    name: 'comment',
    type: 'text',
    label: 'Comment',
    rules: {
      required: 'This field is required.',
      minLength: {
        value: 3,
        message: 'Comment should be more than 2 characters.',
      },
    },
    styles: {
      xs: 8,
    },
  },
];

export const CautionTableColumns = [
  'Type',
  'Comment',
  'Date and time',
  'Added by',
  '',
];
