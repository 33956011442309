import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';

import { IUser, Role } from '@/models';
import { isTargetRole } from '@/services';
import { useUserStore } from '@/store';
interface CFSCreateRmsMenuItemProps {
  isClosed?: boolean;
  cfsId?: string;
}
export const CFSCreateRmsMenuItem = ({
  isClosed,
  cfsId,
}: CFSCreateRmsMenuItemProps) => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  return (
    isTargetRole(user as IUser, Role.POLICE) &&
    isClosed &&
    cfsId && (
      <MenuItem onClick={() => navigate(`/cases?from=${cfsId}`)}>
        Create RMS Case
      </MenuItem>
    )
  );
};
