import { useCallback } from 'react';

import { Card } from '@mui/material';

import { useTabLayoutContext } from '@/layouts/TabLayout';

import KaseReportsList from './components/KaseReportsList';

const KaseReportListScreen = () => {
  const { currentScreen } = useTabLayoutContext();

  const renderScreen = useCallback(() => {
    switch (currentScreen) {
      default:
        return <KaseReportsList />;
    }
  }, [currentScreen]);

  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        mt: 2,
        borderRadius: 2,
        height: 'calc(100% - 40px)',
      }}
    >
      {renderScreen()}
    </Card>
  );
};

export default KaseReportListScreen;
