import { colors } from '../variables';

export default {
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }: any) => ({
        minWidth: '150px!important',
        padding: 8,
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
        borderRadius: 6,

        ...(theme.palette.mode !== 'light' && {
          backgroundColor: colors.grey[100],
        }),
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }: any) => ({
        justifyContent: 'center',
        color: colors.grey[100],
        ':not([data-value])': {
          fontWeight: 500,
        },

        ...(theme.palette.mode !== 'light' && {
          color: colors.grey[20],
        }),

        '&.error': {
          color: colors.error.main,
        },
      }),
    },
  },
};
