export enum AuthRoutes {
  Login = '/auth/login',
  ForgotPassword = '/auth/forgot-password',
  ResetPassword = '/auth/reset-password',
  ConfirmEmail = '/auth/confirm-email',
  CodeVerification = '/auth/code-verification',
}

export enum MainRoutes {
  Home = '/',
  AgencyList = '/agencies',
  EmployeeList = ':agencyId/employees',
  VehicleList = ':agencyId/vehicles',
  EquipmentList = ':agencyId/equipment',
  InventoryLocations = ':agencyId/inventory-locations',
  UnitList = ':agencyId/units',
  CFSList = '/cfs',
  ActiveCFS = '/cfs-active',
  KaseList = '/cases',
  TaskList = '/tasks',
  CamerasList = 'tools/cameras',
  VendorList = ':agencyId/vendors',
}

export enum MasterIndexRoutes {
  Persons = 'persons',
  Organizations = 'organizations',
  Vehicles = 'vehicles',
  Properties = 'properties',
  Address = 'address',
  Logs = 'logs',
}

export enum MasterIndexTabRoutes {
  Information = 'information',
  Related = 'related',
  Notes = 'notes',
  Cautions = 'cautions',
}
