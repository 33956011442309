import { FC, PropsWithChildren } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import {
  IconButton,
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material';

import { IcArrowLeft } from '@/assets/images';
import { colors } from '@/theme/variables';

interface PageBackHeaderProps extends PropsWithChildren, StackProps {
  pageTitle?: string;
  title?: string;
  goBack?: () => void;
}

const Root = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 0px',
  borderBottom: `1px solid ${colors.grey[10]}`,
}));

export const PageBackHeader: FC<PageBackHeaderProps> = ({
  pageTitle,
  title,
  goBack,
  children,
  style,
}) => {
  return (
    <Root style={style}>
      {!!pageTitle && (
        <HelmetProvider>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
        </HelmetProvider>
      )}
      {!!goBack && (
        <IconButton onClick={goBack} sx={{ mr: 1 }}>
          <IcArrowLeft />
        </IconButton>
      )}
      <Typography variant="inherit">{title}</Typography>
      {children}
    </Root>
  );
};
