import { FC, useState } from 'react';

import {
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { ConfirmationDialog, TableContainer } from '@/components';
import { DATE_TIME_FORMAT } from '@/config';
import { ICaution } from '@/models';

import { CautionTableColumns } from '../data';

interface CautionTableProps {
  cautions: ICaution[];
  handleDelete: (id?: string) => void;
}

export const CautionTable: FC<CautionTableProps> = ({
  cautions,
  handleDelete,
}) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');

  function renderRow(row: ICaution) {
    const { _id, type, comment, createdBy, createdAt } = row;

    return (
      <TableRow key={_id}>
        <TableCell>{type.name}</TableCell>
        <TableCell style={{ maxWidth: 160 }}>{comment}</TableCell>
        <TableCell>{moment(createdAt).format(DATE_TIME_FORMAT)}</TableCell>
        <TableCell>{`${createdBy.firstName} ${createdBy.lastName}`}</TableCell>
        <TableCell
          className="action-cell"
          style={{ maxWidth: 'unset' }}
          align="right"
        >
          <Button
            color="error"
            onClick={() => {
              setSelectedId(_id ?? '');
              setShowConfirm(true);
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack divider={<Divider />} sx={{ height: 350, width: '100%' }}>
      <TableContainer
        sx={{
          padding: 0,
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Table aria-label="custom pagination table" stickyHeader>
          <TableHead>
            <TableRow>
              {CautionTableColumns.map((title) => (
                <TableCell key={title} width={100}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{cautions?.map((row) => renderRow(row))}</TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        title="Delete Caution?"
        description="Warning! You are about to delete this caution, are you sure?"
        confirmText="Delete"
        open={showConfirm}
        onCancel={() => {
          setShowConfirm(false);
          setSelectedId('');
        }}
        onConfirm={() => {
          if (selectedId) handleDelete(selectedId);
          setShowConfirm(false);
        }}
      />
    </Stack>
  );
};
