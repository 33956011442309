import { FC, useEffect, useState } from 'react';

import { Button, ButtonProps, Menu, MenuItem } from '@mui/material';

import { IcArrowDown } from '@/assets/images';

export interface ActionButtonItem {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface ActionButtonProps extends ButtonProps {
  defaultValue?: string | number;
  onSelectItem: (value: string | number) => void;
  items: ActionButtonItem[];
}

export const ActionButton: FC<ActionButtonProps> = (props) => {
  const { items, onSelectItem, defaultValue, children, ...rest } = props;
  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<
    ActionButtonItem | undefined
  >();

  const openMenu = Boolean(menuEl);

  useEffect(() => {
    if (defaultValue) {
      setCurrentItem(defaultValue);
    }
  }, [defaultValue]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const setCurrentItem = (value: string | number) => {
    const defaultItem = items.find((item) => item.value === value);
    setSelectedItem(defaultItem);
  };

  const handleSelect = (value: string | number) => {
    setCurrentItem(value);
    onSelectItem(value);
    handleMenuClose();
  };

  return (
    <>
      <Button
        color="inherit"
        variant="outlined"
        onClick={(e) => handleMenuOpen(e)}
        sx={{ ml: 2 }}
        {...rest}
      >
        {children || selectedItem?.label}
        <IcArrowDown style={{ marginLeft: 5 }} />
      </Button>
      <Menu
        id="unit-filter-menu"
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'unit-filter-menu',
        }}
        style={{ minWidth: '300px!important' }}
      >
        {items.map((item) => {
          if (!children && selectedItem?.value === item.value) return null;
          return (
            <MenuItem
              key={`${item.label}-${item.value}`}
              value={item.value}
              onClick={() => handleSelect(item.value)}
              sx={{ fontSize: '0.9375rem' }}
              disabled={item.disabled}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
