import {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { PaletteMode, ThemeProvider as MuiThemeProvider } from '@mui/material';

import { createCustomTheme } from '@/theme';

interface ThemeModeProviderProps {
  children: ReactNode;
}

interface ThemeModeContextValue {
  mode: PaletteMode;
  toggleThemeMode: (newMode: PaletteMode) => void;
}

export const ThemeModeContext = createContext<ThemeModeContextValue>({
  mode: 'light',
  toggleThemeMode: () => {},
});

export const ThemeProvider: FC<ThemeModeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode>('light');

  useEffect(() => {
    const prevMode = localStorage.getItem('themeMode');
    setMode((prevMode as PaletteMode) || 'light');
  }, []);

  const toggleThemeMode = (newMode: PaletteMode) => {
    if (newMode === mode) return;
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const theme = useMemo(() => createCustomTheme(mode), [mode]);

  return (
    <ThemeModeContext.Provider value={{ mode, toggleThemeMode }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeModeContext.Provider>
  );
};
