import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import { MasterIndexApi, MasterVehicleApi } from '@/api';
import {
  FormRoot,
  ViewCard,
  ViewItem,
  IViewCard,
  FileGrid,
} from '@/components';
import { USStates } from '@/constants';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { IMasterName, IMasterVehicle } from '@/models';
import { useToastStore } from '@/store';
import { deflatten, flattenObject } from '@/utils';

import { useMasterVehicleContext } from '..';
import {
  MasterVehicleOwnerTableViewColumns,
  MasterVehicleScreens,
  MasterVehicleViewFields,
} from '../data';
import { MasterVehicleOwnerTable } from './tables/MasterVehicleOwnerTable';

export const renderViewCardContent = (
  { key, items }: IViewCard,
  flattenData: Record<string, any>,
) => {
  if (key === 'photos') {
    return (
      <ViewItem sx={{ mt: -4 }} md={8} key={key}>
        <FileGrid files={flattenData.files} />
      </ViewItem>
    );
  }
  if (key === 'ownerinfo') {
    const owner = flattenData['owner._id']
      ? (deflatten(flattenData, 'owner') as IMasterName)
      : null;
    return (
      <ViewItem>
        <MasterVehicleOwnerTable
          columns={MasterVehicleOwnerTableViewColumns}
          owners={owner ? [owner] : []}
        />
      </ViewItem>
    );
  }

  if (key === 'verificationStatus') {
    return (
      <ViewItem label={items[0].label}>
        <Typography variant="inherit" sx={{ fontSize: '0.875rem' }}>
          {flattenData?.isVerified ? 'Verified' : 'Not Verified'}
        </Typography>
      </ViewItem>
    );
  }
  return items.map((item) => {
    let value = flattenData[item.value as string];
    if (item.value === 'licensePlate.state') {
      value = USStates.find(
        ({ value: newValue }) => newValue === flattenData[item.value as string],
      )?.label;
    }
    return <ViewItem key={item.label} {...item} value={value} md={2} />;
  });
};

interface MasterVehicleViewProps {
  isMasterIndex?: boolean;
  hideHeader?: boolean;
}

export const MasterVehicleView = ({
  isMasterIndex,
  hideHeader,
}: MasterVehicleViewProps) => {
  const { updateToast } = useToastStore();
  const { updateCurrentScreen } = useTabLayoutContext();
  const { currentVehicleId } = useMasterVehicleContext();
  const [currentVehicle, setCurrentVehicle] = useState<IMasterVehicle>();
  const flattenVehicleObj = flattenObject(currentVehicle);

  const { vehicleId } = useParams();

  useEffect(() => {
    if (currentVehicleId || vehicleId) fetchCurrentVehicle();
  }, [currentVehicleId, vehicleId]);

  const fetchCurrentVehicle = async () => {
    try {
      const api = isMasterIndex ? MasterIndexApi : MasterVehicleApi;
      const res = await api.getMasterVehicle(
        isMasterIndex ? (vehicleId as string) : String(currentVehicleId),
      );
      setCurrentVehicle(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  return (
    <TabAnimationWrapper>
      {!hideHeader && (
        <PageBackHeader
          goBack={() =>
            updateCurrentScreen(MasterVehicleScreens.MasterVehicleList)
          }
          title="View details"
        />
      )}
      <FormRoot
        sx={{
          mt: 1,
          '.p-kase-edit &': { p: 2.5, borderRadius: 2 },
        }}
      >
        {MasterVehicleViewFields.map((data, index) => (
          <ViewCard
            title={data.title}
            key={data.key}
            showDivider={index !== MasterVehicleViewFields.length - 1}
          >
            {renderViewCardContent(data, flattenVehicleObj)}
          </ViewCard>
        ))}
      </FormRoot>
    </TabAnimationWrapper>
  );
};
