import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Button, Stack } from '@mui/material';

import { CfsApi } from '@/api';
import { Page, PageHeader } from '@/components';
import { useCFSContext } from '@/hooks';
import { ICFS, ICFSForm } from '@/models';
import { CFSForm } from '@/pages/CFS/Edit/components/CFSForm';
import { CFSReporterTimeItem } from '@/pages/CFS/Edit/components/CFSReporterTimeItem';
import { LiveInformation } from '@/pages/CFS/Edit/components/LiveInformation';
import { cfsDefaultValues } from '@/pages/CFS/Edit/data';
import { ConfirmTypes, useToastStore, useUserStore } from '@/store';
import { parsePhoneNumber } from '@/utils';

import { findUnitShiftByUserId } from '../shared/findUnitShiftByUserId';

type IncidentCfsTabProps = {
  handleBack: () => void;
  handleSlefAssignCfs: (cfsToAssign: ICFS, isFromCreate?: boolean) => void;
};

const IncidentCfsTab = ({
  handleBack,
  handleSlefAssignCfs,
}: IncidentCfsTabProps) => {
  const { cfs, updateCFS, ablyCfsChannel } = useCFSContext();
  const { user } = useUserStore();
  const { updateToast } = useToastStore();

  const formMethods = useForm<ICFSForm>({
    defaultValues: cfsDefaultValues,
    mode: 'onChange',
  });

  const handleChangeReporterTime = async (v: string) => {
    try {
      ablyCfsChannel?.publish('cfsForm', { reportTime: v });
      if (cfs)
        updateCFS({ ...cfs, reporter: { ...cfs.reporter, reportTime: v } });
      await CfsApi.update(String(cfs?._id), {
        reporter: { reportTime: v },
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  useEffect(() => {
    if (cfs?._id) {
      const data: ICFSForm = {
        number: cfs.number,
        severity: cfs.severity,
        shortDescription: cfs.shortDescription,
        incidentCodes: cfs.incidentCodes.map((code) => String(code._id)),
        addressInfo: {
          ...cfsDefaultValues.addressInfo,
          ...cfs.addressInfo,
          instructions: cfs.addressInfo?.instructions || '',
        },
        reporter: {
          ...cfs.reporter,
          contactPhoneNumber: parsePhoneNumber(cfs.reporter.contactPhoneNumber),
        },
      };
      formMethods.reset(data);
    }
  }, [cfs?._id]);

  return (
    <Stack>
      <FormProvider {...formMethods}>
        <Page title="Create CFS">
          <PageHeader
            title="Create CFS"
            onBack={handleBack}
            sx={{
              paddingLeft: 2,
            }}
          >
            <Stack direction="row" spacing={2} padding={2}>
              <CFSReporterTimeItem
                reporterTime={cfs?.reporter.reportTime}
                onAccept={handleChangeReporterTime}
              />
              <Button
                variant="contained"
                color="error"
                disabled={cfs?.addressInfo?.address ? false : true}
                onClick={() => {
                  if (!cfs) return;
                  handleSlefAssignCfs(cfs, true);
                  handleBack();
                }}
              >
                Self Assign CFS
              </Button>
              <Button
                variant="contained"
                color="inherit"
                size="large"
                onClick={() => {
                  if (!cfs) return;
                  const assignedUnitShift = findUnitShiftByUserId(
                    cfs.assignedUnitShifts,
                    user?._id as string,
                  );

                  const badgeNumber =
                    user?.profile?.employmentInformation?.badgeNumber || 'N/A';
                  updateToast({
                    type: ConfirmTypes.SUCCESS,
                    open: true,
                    message: assignedUnitShift
                      ? `${cfs.number} was successfully created by ${assignedUnitShift?.unitShift.unit.name} (${badgeNumber})`
                      : `${cfs.number} was successfully created by ${badgeNumber}`,
                  });
                  handleBack();
                }}
              >
                Done
              </Button>
            </Stack>
          </PageHeader>
          <Stack spacing={2} flex={1} direction="row" padding={3}>
            <Box display="flex" flex={1}>
              <CFSForm cfsId={cfs?._id} hideReporterInfo={true} />
            </Box>
            <Box display="flex" flex={1} flexDirection="column">
              <LiveInformation
                cfsId={cfs?._id}
                isNewWindow={false}
                isMaxHeight={true}
              />
            </Box>
          </Stack>
        </Page>
      </FormProvider>
    </Stack>
  );
};

export default IncidentCfsTab;
