import { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';

import { CautionApi } from '@/api';
import { ICaution } from '@/models';
import { useToastStore } from '@/store';
type CautionChipsProps = {
  addressId: string;
};

export const CautionChips = ({ addressId }: CautionChipsProps) => {
  const { updateToast } = useToastStore();
  const [cautions, setCautions] = useState<ICaution[] | null>(null);
  useEffect(() => {
    if (addressId !== '') {
      fetchCautions();
    }
  }, [addressId]);

  const fetchCautions = async () => {
    try {
      const params = {
        sort: JSON.stringify({ createdAt: -1 }),
        filter: JSON.stringify({ address: addressId }),
      };
      const res = await CautionApi.getCautions(params);

      setCautions(res.data.results);
    } catch (err: any) {
      updateToast({ open: err, message: err.message });
    }
  };
  return (
    <Stack gap={1}>
      {addressId === '' && <Typography>No address selected</Typography>}
      {addressId !== '' && cautions === null && (
        <Typography>Loading cautions...</Typography>
      )}
      {addressId !== '' && cautions?.length === 0 && (
        <Typography>No registered cautions for this address</Typography>
      )}
      {cautions?.map((caution, index) => (
        <Stack key={index} direction="row" gap={1} alignItems="center">
          <Typography fontWeight={500} fontSize={18} color="error">
            {caution.type.name.toUpperCase()}:
          </Typography>
          <Typography fontWeight={500} fontSize={18} color="text.secondary">
            {caution.comment}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
