import { ICFSForm, TSeverity } from '@/models';

export const severityItems = [
  {
    label: 'High',
    value: TSeverity.HIGH,
  },
  {
    label: 'Medium',
    value: TSeverity.MEDIUM,
  },
  {
    label: 'Low',
    value: TSeverity.LOW,
  },
];

export const OnBlurFormFields = [
  'shortDescription',
  'addressInfo.instructions',
];

export const CFSFormFields = [
  {
    key: 'address',
    title: 'Address information',
    name: 'addressInfo',
    items: [
      {
        name: 'address',
        label: 'Address',
        type: 'address',
        styles: {
          display: 'flex',
          xs: 12,
        },
      },
      {
        name: 'instructions',
        type: 'text',
        label: 'Instructions',
        styles: {
          xs: 12,
        },
        autoComplete: 'off',
      },
    ],
  },
  {
    key: 'emergency',
    title: 'Emergency detail',
    name: '',
    items: [
      {
        name: 'incidentCodes',
        type: 'autocomplete',
        label: 'Incident Code',
        multiple: true,
        limitTags: 3,
        items: [],
        styles: {
          xs: 6,
        },
      },
      {
        name: 'severity',
        type: 'tab',
        label: 'Severity',
        buttons: severityItems,
        styles: {
          xs: 6,
        },
      },
      {
        name: 'shortDescription',
        type: 'text',
        label: 'Short description',
        styles: {
          xs: 12,
        },
        autoComplete: 'off',
      },
    ],
  },
];

export const cfsDefaultValues: ICFSForm = {
  addressInfo: {
    instructions: '',
  },
  shortDescription: '',
  incidentCodes: [],
  severity: TSeverity.MEDIUM,
  reporter: {
    isCallingForSelf: false,
    isKnowSubject: false,
    notOnLocation: false,
    notWantToBeSeen: false,
  },
};

export const cfsAblyUpdateFields = [
  'requiredUnits',
  'primaryReporter',
  'addressInfo',
  'names',
  'vehicles',
  'hasUnsetInvolvementName',
  'reporter',
];
