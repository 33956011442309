import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material';

export const Link = styled(RouterLink)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontSize: '0.9375rem',
  fontWeight: 400,
  color: theme.palette.error.main,
  textDecoration: 'none',
}));
