import { ReactNode } from 'react';

import { Box } from '@mui/material';

import { TabLayoutContextProvider } from '@/layouts/TabLayout';
import { MasterVehicleContextProvider } from '@/layouts/TabLayout/MasterVehicles';

interface MasterIndexVehicleBaseProps {
  children: ReactNode;
}

const MasterIndexVehicleBase = ({ children }: MasterIndexVehicleBaseProps) => {
  return (
    <TabLayoutContextProvider>
      <MasterVehicleContextProvider nextRoutes={[]}>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ overflowY: 'auto' }}
        >
          {children}
        </Box>
      </MasterVehicleContextProvider>
    </TabLayoutContextProvider>
  );
};

export default MasterIndexVehicleBase;
