import { useState, useEffect } from 'react';

import { IFile } from '@/models';

export const useFilesValidation = (uploadedFileItems?: IFile[]) => {
  const [hasInvalidFiles, setHasInvalidFiles] = useState<boolean>(false);

  useEffect(() => {
    const hasFiles = (uploadedFileItems?.length || 0) > 0;
    setHasInvalidFiles(
      hasFiles &&
        !!uploadedFileItems?.some((file) => file.error || !file.mimeType),
    );
  }, [uploadedFileItems]);

  return hasInvalidFiles;
};
