import { useContext, useState } from 'react';

import {
  PaletteMode,
  styled,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { IcMoon, IcSun } from '@/assets/images';
import { ThemeModeContext } from '@/contexts';

export const ThemeSwitch = () => {
  const { mode, toggleThemeMode } = useContext(ThemeModeContext);
  const [theme, setTheme] = useState(mode);

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newTheme: PaletteMode,
  ) => {
    if (newTheme === null) return;
    setTheme(newTheme);
    toggleThemeMode(newTheme);
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme: t }) => ({
    '& .MuiToggleButton-root.Mui-selected': {
      background: theme === 'light' ? '#0D2C3E' : t.palette.background.default,
    },
  }));

  return (
    <StyledToggleButtonGroup
      orientation="vertical"
      value={theme}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="light" aria-label="light">
        <IcSun />
      </ToggleButton>
      <ToggleButton value="dark" aria-label="dark" sx={{ color: 'white' }}>
        <IcMoon />
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};
