import { ChangeEvent, FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { IconButton } from '@mui/material';

import { IcMinus, IcPlus } from '@/assets/images';
import { colors } from '@/theme/variables';

import { Input, InputProps } from '../Input';
import { FormElementProps } from '../types';

export interface FormIntegerFieldProps
  extends Omit<InputProps, 'name' | 'onChange'>,
    FormElementProps {
  disableInput?: boolean;
  handleChange?: (name: string, value: string | number) => void;
}

export const FormIntegerField: FC<FormIntegerFieldProps> = (props) => {
  const { name, rules, defaultValue, handleChange, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  const onChange = (value: string | number) => {
    if (handleChange != null) handleChange(name, value);
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ''}
      name={name}
      render={({ field }: any) => (
        <Input
          {...rest}
          {...field}
          type="text"
          error={error?.message}
          name={name}
          startAdornment={
            <IconButton
              onClick={() => {
                const newValue = Math.max(field.value - 1, 0);
                field.onChange(newValue);
                onChange(newValue);
              }}
              disabled={props.disabled}
            >
              <IcMinus />
            </IconButton>
          }
          endAdornment={
            <IconButton
              onClick={() => {
                const newValue = Number(field.value) + 1;
                field.onChange(newValue);
                onChange(newValue);
              }}
              disabled={props.disabled}
            >
              <IcPlus color={colors.error.main} />
            </IconButton>
          }
          onChange={(
            event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => {
            if (
              props.disableInput ||
              isNaN(Number(event.target.value)) ||
              event.target.value.includes('.')
            )
              return;
            field.onChange(event);
            onChange(event.target.value);
          }}
          inputProps={{ style: { textAlign: 'center' } }}
        />
      )}
      rules={rules}
    />
  );
};
