import { FC } from 'react';

import { Avatar as MuiAvatar, AvatarProps, Tooltip } from '@mui/material';

import colors from '@/theme/variables/colors';

interface IAvatarProps extends AvatarProps {
  name?: string;
  showTooltip?: boolean;
}

export const Avatar: FC<IAvatarProps> = ({
  src = '',
  name = '',
  showTooltip,
  ...rest
}) => {
  const stringAvatar = () => {
    return {
      sx: {
        bgcolor: colors.error.main,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  if (!showTooltip) {
    return <MuiAvatar {...stringAvatar()} src={src} alt={name} {...rest} />;
  }

  return (
    <Tooltip title={name}>
      <MuiAvatar {...stringAvatar()} src={src} alt={name} {...rest} />
    </Tooltip>
  );
};
