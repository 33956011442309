import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
  useMemo,
} from 'react';

import { useChannel } from 'ably/react';

import { CfsApi, UnitApi } from '@/api';
import { IcMarkerCfs } from '@/assets/images';
import { IMarker, UnitShiftMarkerInfoItem } from '@/components/GoogleMap';
import {
  ASSIGNMENT_STATUS,
  LocalStorageItems,
  UnitShiftMarkerColors,
  UnitShiftMarkerIcons,
} from '@/constants';
import {
  AssignedUnitShiftStatus,
  IAddress,
  IAssignedUnitShift,
  ICFS,
  IUnitShift,
  IUnitShiftLocation,
  UnitShiftStatus,
  FilterValues,
  IUnit,
  AssignmentStatus,
} from '@/models';
import { getAddressLocation } from '@/services';
import { useToastStore, useUserStore } from '@/store';
import { openNewWindow } from '@/utils';

export interface AssignedUnitShiftItem extends IAssignedUnitShift {
  cfsId?: string;
  cfsNumber?: string;
  cfsDescription?: string;
  searchText?: string;
}

interface CFSFilterState {
  severity: string;
  assignmentStatus: string;
  readBy: boolean;
  searchText: string;
}

interface UnitShiftContextValue {
  onDutyUnitShifts: IUnitShift[];
  waitingUnitShifts: IUnitShift[];
  scheduledUnitShifts: IUnitShift[];
  assignedUnitShifts: AssignedUnitShiftItem[];
  unitShiftMarkers: IMarker[];
  filteredUnitShiftMarkers: IMarker[];
  activeCfsMarkers: IMarker[];
  unitShiftAddresses: UnitShiftAddresses;
  filterValues: FilterValues;
  searchText: string;
  setFilterValues: (values: FilterValues) => void;
  setSearchText: (text: string) => void;
  fetchScheduledUnitShifts: () => Promise<IUnitShift[]>;
  fetchOnDutyUnitShifts: () => Promise<IUnitShift[]>;
  fetchWaitingUnitShifts: () => Promise<IUnitShift[]>;
  fetchAssignedUnitShifts: () => Promise<void>;
  cfsSearchText: string;
  setCfsSearchText: (text: string) => void;
  cfsFilterState: CFSFilterState;
  setCfsFilterState: (filter: Partial<CFSFilterState>) => void;
  filteredCfsMarkers: IMarker[];
}

interface UnitShiftProviderProps {
  children: ReactNode;
}

interface IUnitShiftAddress {
  unitShift: string;
  address: IAddress;
}

type UnitShiftAddresses = Record<string, string>;

const initFilterValues: FilterValues = {
  agencies: null,
  beat: null,
  agencyType: null,
  unitTypes: null,
};

export const UnitShiftContext = createContext<UnitShiftContextValue>({
  onDutyUnitShifts: [],
  scheduledUnitShifts: [],
  waitingUnitShifts: [],
  assignedUnitShifts: [],
  unitShiftMarkers: [],
  filteredUnitShiftMarkers: [],
  activeCfsMarkers: [],
  unitShiftAddresses: {},
  filterValues: initFilterValues,
  searchText: '',
  setSearchText: () => {},
  setFilterValues: () => {},
  fetchScheduledUnitShifts: async () => [],
  fetchOnDutyUnitShifts: async () => [],
  fetchWaitingUnitShifts: async () => [],
  fetchAssignedUnitShifts: async () => {},
  cfsSearchText: '',
  setCfsSearchText: () => {},
  cfsFilterState: {
    severity: '',
    assignmentStatus: '',
    readBy: false,
    searchText: '',
  },
  setCfsFilterState: () => {},
  filteredCfsMarkers: [],
});

export const UnitShiftProvider: FC<UnitShiftProviderProps> = (props) => {
  const { children } = props;
  const { updateToast } = useToastStore();
  const { account } = useUserStore();
  const [assignedUnitShifts, setAssignedUnitShifts] = useState<
    AssignedUnitShiftItem[]
  >([]);
  const [scheduledUnitShifts, setScheduledUnitShifts] = useState<IUnitShift[]>(
    [],
  );
  const [onDutyUnitShifts, setOnDutyUnitShifts] = useState<IUnitShift[]>([]);
  const [waitingUnitShifts, setWaitingUnitShifts] = useState<IUnitShift[]>([]);
  const [unitShiftMarkers, setUnitShiftMarkers] = useState<IMarker[]>([]);
  const [activeCfsMarkers, setActiveCfsMarkers] = useState<IMarker[]>([]);
  const [unitShiftAddresses, setUnitShiftAddresses] =
    useState<UnitShiftAddresses>({});
  const [filterValues, setFilterValues] =
    useState<FilterValues>(initFilterValues);
  const [searchText, setSearchText] = useState('');
  const [cfsSearchText, setCfsSearchText] = useState('');
  const [cfsFilterState, setCfsFilterState] = useState<CFSFilterState>({
    severity: '',
    assignmentStatus: '',
    readBy: false,
    searchText: '',
  });
  const [cfsList, setCfsList] = useState<ICFS[]>([]);

  const isUnitMatchingFilters = (unit: IUnit) => {
    // Search filter
    if (
      searchText &&
      !unit.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return false;
    }

    // Agency filter
    if (
      filterValues.agencies?.length &&
      !filterValues.agencies.includes(unit.agency)
    ) {
      return false;
    }

    // Agency type filter
    if (
      filterValues.agencyType &&
      unit.type.agencyType !== filterValues.agencyType
    ) {
      return false;
    }

    // Unit type filter
    if (
      filterValues.unitTypes?.length &&
      !filterValues.unitTypes.includes(unit.type._id as string)
    ) {
      return false;
    }

    // Beat filter
    if (filterValues.beat && !unit.polygons?.includes(filterValues.beat)) {
      return false;
    }

    return true;
  };

  const filteredUnitShiftMarkers = useMemo(() => {
    return unitShiftMarkers.filter((marker) => {
      const unitShift = [
        ...onDutyUnitShifts,
        ...assignedUnitShifts.map((aus) => aus.unitShift),
      ].find((us) => String(us._id) === marker.key);

      if (!unitShift) return true;

      return isUnitMatchingFilters(unitShift.unit);
    });
  }, [
    unitShiftMarkers,
    filterValues,
    searchText,
    onDutyUnitShifts,
    assignedUnitShifts,
  ]);

  useChannel(`account:${account?._id}:unit-shift`, ({ data, extras }) => {
    const unitShiftId = extras?.headers?.unitShift;
    if (data.location && unitShiftId) {
      updateUnitShiftLocation(unitShiftId, data.location);
    }
    if (data.status) {
      if (data.status === UnitShiftStatus.ACTIVE) {
        fetchOnDutyUnitShifts();
      } else if (data.status === UnitShiftStatus.WAITING) {
        fetchWaitingUnitShifts();
      } else if (data.status === UnitShiftStatus.SCHEDULED) {
        fetchScheduledUnitShifts();
      } else {
        const newOnDutyUnitShifts = onDutyUnitShifts.filter(
          ({ _id }) => _id !== unitShiftId,
        );
        if (newOnDutyUnitShifts.length !== onDutyUnitShifts.length) {
          setOnDutyUnitShifts(newOnDutyUnitShifts);
        } else {
          const newWaitingUnitShifts = waitingUnitShifts.filter(
            ({ _id }) => _id !== unitShiftId,
          );
          setWaitingUnitShifts(newWaitingUnitShifts);
        }
      }
    }
  });

  useChannel(
    `account:${account?._id}:cfs:assigned-unit-shift`,
    async (message) => {
      const { data, extras } = message;
      if (data.assignedUnitShifts) {
        let currentAssignedUnitShifts = assignedUnitShifts.slice();
        const indexOfExist = currentAssignedUnitShifts.findIndex(
          ({ _id }) => _id === data.assignedUnitShifts._id,
        );
        if (indexOfExist > -1) {
          currentAssignedUnitShifts[indexOfExist] = {
            ...currentAssignedUnitShifts[indexOfExist],
            ...data.assignedUnitShifts,
          };
        } else {
          const sameCFSUnitShift = currentAssignedUnitShifts.find(
            ({ cfsId }) => cfsId === extras.headers.cfs,
          );
          if (sameCFSUnitShift) {
            currentAssignedUnitShifts.push({
              ...sameCFSUnitShift,
              ...data.assignedUnitShifts,
            });
          } else {
            const newCFS = await fetchCFS(extras.headers.cfs);
            if (newCFS) {
              const { _assignedUnitShifts, _cfsMarkers } =
                extractAssignedUnitShifts(newCFS);
              currentAssignedUnitShifts =
                currentAssignedUnitShifts.concat(_assignedUnitShifts);
              setActiveCfsMarkers((v) => [...v, ..._cfsMarkers]);
            }
          }
        }
        setAssignedUnitShifts(currentAssignedUnitShifts);
      }
    },
  );

  useChannel(`account:${account?._id}:unit-shift:address`, async (message) => {
    const { data } = message;
    if (data.unitShiftAddresses) {
      const addressObj = (
        data.unitShiftAddresses as IUnitShiftAddress[]
      ).reduce((obj: UnitShiftAddresses, current) => {
        obj[current.unitShift] = getAddressLocation(current.address);
        return obj;
      }, {});
      setUnitShiftAddresses(addressObj);
    }
  });

  const extractAssignedUnitShifts = (_cfs: ICFS) => {
    const _assignedUnitShifts: AssignedUnitShiftItem[] = [];
    const _cfsMarkers: IMarker[] = [];
    _cfs.assignedUnitShifts.forEach((assignedUnitShift) => {
      _assignedUnitShifts.push({
        ...assignedUnitShift,
        cfsId: _cfs._id,
        cfsNumber: _cfs.number,
        cfsDescription: _cfs.shortDescription,
      });
    });
    const coordinates = _cfs?.addressInfo?.address?.point?.coordinates;
    if (coordinates?.length) {
      _cfsMarkers.push({
        key: String(_cfs._id),
        title: _cfs.number,
        position: {
          lng: coordinates[0],
          lat: coordinates[1],
        },
        icon: (
          <IcMarkerCfs color={ASSIGNMENT_STATUS[_cfs.assignmentStatus].color} />
        ),
        onClick: () => {
          openNewWindow(`/cfs/${_cfs._id}`, _cfs._id);
        },
      });
    }
    return { _assignedUnitShifts, _cfsMarkers };
  };

  const fetchCFS = async (cfsId: string) => {
    try {
      const res = await CfsApi.getOne(cfsId);
      return res.data;
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return null;
    }
  };

  const updateUnitShiftLocation = (
    unitShiftId: string,
    location: IUnitShiftLocation,
  ) => {
    let isUpdated = false;
    assignedUnitShifts.forEach((assignedUnitShift, index) => {
      if (assignedUnitShift.unitShift._id === unitShiftId) {
        const tempAssignedUnitShifts = assignedUnitShifts.slice();
        tempAssignedUnitShifts[index] = {
          ...assignedUnitShift,
          unitShift: {
            ...assignedUnitShift.unitShift,
            location,
          },
        };
        setAssignedUnitShifts(tempAssignedUnitShifts);
        isUpdated = true;
        return;
      }
    });

    if (!isUpdated) {
      onDutyUnitShifts.forEach((unitShift, index) => {
        if (unitShift._id === unitShiftId) {
          const tempUnitShifts = onDutyUnitShifts.slice();
          tempUnitShifts[index] = {
            ...unitShift,
            location,
          };
          setOnDutyUnitShifts(tempUnitShifts);
          isUpdated = true;
          return;
        }
      });
    }
  };

  const fetchScheduledUnitShifts = async () => {
    try {
      const filter = JSON.stringify({
        status: UnitShiftStatus.SCHEDULED,
      });
      const res = await UnitApi.getUnitShifts({ filter, limit: 1000 });
      const agencyId = localStorage.getItem(LocalStorageItems.CurrentAgencyId);
      if (agencyId) {
        const filteredScheduledUnitShifts = res.data.results.filter(
          (unitShift) => unitShift.unit.agency === agencyId,
        );
        setScheduledUnitShifts(filteredScheduledUnitShifts);
        return filteredScheduledUnitShifts;
      } else {
        setScheduledUnitShifts(res.data.results);
        return res.data.results;
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return [];
    }
  };

  const fetchOnDutyUnitShifts = async () => {
    try {
      const filter = JSON.stringify({
        status: 'ACTIVE',
      });
      const res = await UnitApi.getUnitShifts({ filter, limit: 1000 });
      const agencyId = localStorage.getItem(LocalStorageItems.CurrentAgencyId);
      if (agencyId) {
        const filteredOnDutyUnitShifts = res.data.results.filter(
          (unitShift) => unitShift.unit.agency === agencyId,
        );
        setOnDutyUnitShifts(filteredOnDutyUnitShifts);
        return filteredOnDutyUnitShifts;
      } else {
        setOnDutyUnitShifts(res.data.results);
        return res.data.results;
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return [];
    }
  };

  const fetchWaitingUnitShifts = async () => {
    try {
      const filter = JSON.stringify({
        status: 'WAITING',
      });
      const res = await UnitApi.getUnitShifts({ filter, limit: 1000 });
      const agencyId = localStorage.getItem(LocalStorageItems.CurrentAgencyId);
      if (agencyId) {
        const filteredWaitingUnitShifts = res.data.results.filter(
          (unitShift) => unitShift.unit.agency === agencyId,
        );
        setWaitingUnitShifts(filteredWaitingUnitShifts);
        return filteredWaitingUnitShifts;
      } else {
        setWaitingUnitShifts(res.data.results);
        return res.data.results;
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return [];
    }
  };

  const fetchAssignedUnitShifts = async () => {
    try {
      let _assignedUnitShifts: AssignedUnitShiftItem[] = [];
      let _cfsMarkers: IMarker[] = [];
      const res = await CfsApi.activeList({
        sort: JSON.stringify({ createdAt: -1 }),
      });
      setCfsList(res.data);
      res.data.forEach((cfs) => {
        const {
          _assignedUnitShifts: newAssignedUnitShifts,
          _cfsMarkers: newCfsMarkers,
        } = extractAssignedUnitShifts(cfs);
        _assignedUnitShifts = _assignedUnitShifts.concat(newAssignedUnitShifts);
        _cfsMarkers = _cfsMarkers.concat(newCfsMarkers);
      });
      setAssignedUnitShifts(_assignedUnitShifts);
      setActiveCfsMarkers(_cfsMarkers);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const getUnitShiftMarker = (
    unitShift: IUnitShift,
    assignedStatus?: AssignedUnitShiftStatus,
    cfsNumber?: string,
    cfsDescription?: string,
  ) => {
    if (unitShift.location && unitShift.status !== UnitShiftStatus.CLOSED) {
      const IconSvg = UnitShiftMarkerIcons[unitShift.unit.type.agencyType];
      const iconColor =
        assignedStatus !== AssignedUnitShiftStatus.ASSIGNED &&
        assignedStatus !== AssignedUnitShiftStatus.EN_ROUTE
          ? UnitShiftMarkerColors[unitShift.status]
          : UnitShiftMarkerColors[assignedStatus];
      return {
        key: String(unitShift._id),
        title: unitShift.unit.name,
        position: {
          lng: unitShift.location?.point?.coordinates[0],
          lat: unitShift.location?.point?.coordinates[1],
        },
        info: (
          <UnitShiftMarkerInfoItem
            unitName={unitShift.unit.name}
            unitEmployees={unitShift.users}
            cfsNumber={cfsNumber}
            cfsDescription={cfsDescription}
          />
        ),
        icon: <IconSvg {...iconColor} />,
      };
    } else return null;
  };

  const getUnitShiftMarkers = () => {
    const _mapMarkers: IMarker[] = [];

    // Filter assigned units
    const filteredAssignedUnits = assignedUnitShifts.filter(
      (assignedUnitShift) => {
        if (
          assignedUnitShift.status === AssignedUnitShiftStatus.COMPLETED ||
          assignedUnitShift.status === AssignedUnitShiftStatus.CANCELED
        ) {
          return false;
        }
        return isUnitMatchingFilters(assignedUnitShift.unitShift.unit);
      },
    );

    // Filter on-duty units
    const filteredOnDutyUnits = onDutyUnitShifts.filter((unitShift) =>
      isUnitMatchingFilters(unitShift.unit),
    );

    // Create markers for filtered units
    filteredAssignedUnits.forEach(
      ({ unitShift, status, cfsDescription, cfsNumber }) => {
        const marker = getUnitShiftMarker(
          unitShift,
          status,
          cfsNumber,
          cfsDescription,
        );
        if (marker) _mapMarkers.push(marker);
      },
    );

    filteredOnDutyUnits.forEach((unitShift) => {
      const marker = getUnitShiftMarker(unitShift);
      if (marker) _mapMarkers.push(marker);
    });

    setUnitShiftMarkers(_mapMarkers);
  };

  useEffect(() => {
    getUnitShiftMarkers();
  }, [onDutyUnitShifts, assignedUnitShifts, filterValues, searchText]);

  const filteredCfsMarkers = useMemo(() => {
    return activeCfsMarkers.filter((marker) => {
      const matchingCfs = cfsList.find((cfsItem) => cfsItem._id === marker.key);
      if (!matchingCfs) return true;

      // Search text filter
      if (cfsFilterState.searchText) {
        const currentSearchText = cfsFilterState.searchText.toLowerCase();
        if (!marker.title?.toLowerCase().includes(currentSearchText)) {
          return false;
        }
      }

      // Severity filter
      if (
        cfsFilterState.severity &&
        matchingCfs.severity !== cfsFilterState.severity
      ) {
        return false;
      }

      // Assignment status filter
      if (cfsFilterState.assignmentStatus) {
        if (cfsFilterState.assignmentStatus === 'isReadyToDispatch') {
          if (
            matchingCfs.assignmentStatus !== AssignmentStatus.NEW ||
            !matchingCfs.isReadyToDispatch
          ) {
            return false;
          }
        } else if (
          matchingCfs.assignmentStatus !== cfsFilterState.assignmentStatus
        ) {
          return false;
        }
      }

      // Read by filter
      if (cfsFilterState.readBy) {
        return false;
      }

      return true;
    });
  }, [activeCfsMarkers, cfsList, cfsFilterState]);

  const handleFilterChange = (newFilter: Partial<CFSFilterState>) => {
    setCfsFilterState((prev) => ({
      ...prev,
      ...newFilter,
    }));
  };

  return (
    <UnitShiftContext.Provider
      value={{
        assignedUnitShifts,
        onDutyUnitShifts,
        waitingUnitShifts,
        scheduledUnitShifts,
        unitShiftMarkers,
        filteredUnitShiftMarkers,
        activeCfsMarkers,
        unitShiftAddresses,
        filterValues,
        searchText,
        setSearchText,
        setFilterValues,
        fetchAssignedUnitShifts,
        fetchOnDutyUnitShifts,
        fetchScheduledUnitShifts,
        fetchWaitingUnitShifts,
        cfsSearchText,
        setCfsSearchText,
        cfsFilterState,
        setCfsFilterState: handleFilterChange,
        filteredCfsMarkers,
      }}
    >
      {children}
    </UnitShiftContext.Provider>
  );
};
