import { cloneElement, ReactElement } from 'react';

import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';

import { IcNavigationPointer } from '@/assets/images';
import { SEVERITY_STATUS } from '@/constants';
import { TSeverity } from '@/models';
import { colors } from '@/theme/variables';

export const HeaderSection = ({
  title,
  subtitle,
  icon,
  severity,
  phoneNumber,
  primaryReporter,
  handleGetDirections,
  isDirectionsDisabled,
  hideNavigationButton = false,
}: {
  title: string;
  subtitle: string;
  icon?: ReactElement;
  severity?: TSeverity;
  phoneNumber?: string;
  primaryReporter?: string;
  handleGetDirections?: () => void;
  isDirectionsDisabled?: boolean;
  hideNavigationButton?: boolean;
}) => {
  const theme = useTheme();
  const getColor = () => {
    switch (severity) {
      case TSeverity.HIGH:
        return SEVERITY_STATUS.HIGH;
      case TSeverity.MEDIUM:
        return SEVERITY_STATUS.MEDIUM;
      case TSeverity.LOW:
        return SEVERITY_STATUS.LOW;
      default:
        return colors.error.main;
    }
  };
  return (
    <>
      <Stack direction="row" gap={2} alignItems="center">
        <Typography variant="h4" fontWeight={600}>
          {title}
        </Typography>
        {icon &&
          cloneElement(icon, {
            height: 40,
            width: 40,
            style: {
              minWidth: 40,
            },
            color: getColor(),
          })}
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h6"
          fontWeight={500}
          fontSize={21}
          marginY={2}
          color="#2974E2"
        >
          {subtitle}
        </Typography>
        {hideNavigationButton ? null : (
          <ButtonBase
            disabled={isDirectionsDisabled}
            onClick={handleGetDirections}
            style={{
              borderRadius: '50%',
              backgroundColor: '#2974E2',
              padding: 10,
            }}
          >
            <IcNavigationPointer fill="white" width={18} height={18} />
          </ButtonBase>
        )}
      </Stack>
      <Stack gap={2} display="flex" direction="row" marginY={2}>
        <Box flex={1} display="flex" gap={1}>
          <Typography fontWeight={600} fontSize={16}>
            {primaryReporter}
          </Typography>
          {primaryReporter !== 'No reporter' ? (
            <Typography color={theme.palette.text.secondary}>
              (reporter)
            </Typography>
          ) : null}
        </Box>
        <Box flex={1}>
          <Typography fontWeight={600} fontSize={16}>
            {phoneNumber || 'No phone number'}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};
