import * as React from 'react';

import { Button, Stack, styled } from '@mui/material';
import usePagination, { UsePaginationProps } from '@mui/material/usePagination';

import { IcNarrowLeft, IcNarrowRight } from '@/assets/images';
import { colors } from '@/theme/variables';

const List = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  marginTop: '0px!important',

  '& li': {
    padding: 6,
    marginTop: -1,
    borderTop: '2px solid transparent',
    '&.selected': {
      borderTop: `2px solid ${colors.error.main}`,

      '& button': {
        color: theme.palette.error.main,
      },
    },
    '& button': {
      minWidth: 0,
      backgroundColor: 'transparent',
      border: 0,
      cursor: 'pointer',
      fontFamily: 'Roboto',
      fontSize: 15,
      fontWeight: 500,
      color: theme.palette.mode !== 'light' ? colors.grey[30] : colors.grey[80],
    },
  },
  '&>button': {
    height: 0,
    padding: '22px 0px',
    cursor: 'pointer',
    color: theme.palette.mode !== 'light' ? '#FFFFFF' : colors.grey[100],
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    textTransform: 'capitalize',

    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      opacity: 0.6,
      color: theme.palette.mode !== 'light' ? '#FFFFFF' : colors.grey[100],
    },
  },
}));

export const TablePagination: React.FC<UsePaginationProps> = (props) => {
  const itemsRef = usePagination(props);
  const items = itemsRef?.items ?? [];

  const btnPrevious = items.find((item) => item.type === 'previous');
  const btnNext = items.find((item) => item.type === 'next');

  return (
    <List>
      <Button {...btnPrevious} type="button" startIcon={<IcNarrowLeft />}>
        {btnPrevious?.type}
      </Button>

      <Stack direction="row" justifyContent="center" sx={{ flex: 1 }}>
        {items.map(({ page, type, selected, ...item }, index) => {
          if (type === 'previous' || type === 'next') return null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis')
            return <li key={index}>...</li>;
          return (
            <li key={index} className={selected ? 'selected' : ''}>
              <Button {...item}>{page}</Button>
            </li>
          );
        })}
      </Stack>

      <Button {...btnNext} type="button" endIcon={<IcNarrowRight />}>
        {btnNext?.type}
      </Button>
    </List>
  );
};
