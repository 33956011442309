import { Grid, IconButton, Typography, styled } from '@mui/material';

import { IcClose } from '@/assets/images';
import { colors } from '@/theme/variables';

export const LinkedTableItemRoot = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 12px',
  marginBottom: 10,
  border: `1px solid ${colors.grey[10]}`,
  borderRadius: theme.spacing(),
  '& .MuiButton-text': {
    textDecoration: 'underline',
  },
  '& .MuiButtonBase-root': {
    color: colors.error.main,
  },
}));

interface LinkedTableItemProps {
  label?: string;
  onRemove: () => void;
}

export const LinkedTableItem = ({ label, onRemove }: LinkedTableItemProps) => {
  return (
    <LinkedTableItemRoot xs={12} sm={5} item>
      <Typography variant="inherit">{label}</Typography>
      <IconButton onClick={onRemove}>
        <IcClose />
      </IconButton>
    </LinkedTableItemRoot>
  );
};
