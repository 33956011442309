import { SyntheticEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';

import { CfsApi, MasterNameApi, MasterVehicleApi } from '@/api';
import { IcClock, IcMarkerPin, IcPencil } from '@/assets/images';
import {
  MasterNameAutocomplete,
  NoResultItem,
  Page,
  PageHeader,
  RenderFormField,
} from '@/components';
import { DATE_TIME_FORMAT } from '@/config';
import { useCFSContext } from '@/hooks';
import { useMasterNamesContext } from '@/layouts/TabLayout/MasterNames';
import { useMasterVehicleContext } from '@/layouts/TabLayout/MasterVehicles';
import {
  ICFS,
  ICFSForm,
  ICFSInvolvement,
  IMasterName,
  IMasterNamePerson,
  IMasterVehicle,
  MasterNameType,
} from '@/models';
import { cfsDefaultValues } from '@/pages/CFS/Edit/data';
import { getAddressCommonString } from '@/services';
import { ConfirmTypes, useToastStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';
import { parsePhoneNumber } from '@/utils';

import CarDetailsCard from '../components/CarDetailsCard';
import { default as PersonDetailsCard } from '../components/PersonDetailsCard';
import RelationsList from '../components/RelationsList';
import { findUnitShiftByUserId } from '../shared/findUnitShiftByUserId';

type CarStopCfsTabProps = {
  handleBack: () => void;
  handleSlefAssignCfs: (cfsToAssign: ICFS, isFromCreate?: boolean) => void;
};

export type TabType = 'details' | 'relations-cfs' | 'relations-cases';

const CarStopCfsTab = ({
  handleBack,
  handleSlefAssignCfs,
}: CarStopCfsTabProps) => {
  const { cfs, updateCFS, ablyCfsChannel, mapBounds } = useCFSContext();
  const { user } = useUserStore();
  const { updateToast } = useToastStore();
  const [selectedTab, setSelectedTab] = useState<TabType>('details');
  const [linkedPerson, setLinkedPerson] = useState<IMasterName | null>(null);
  const [linkedVehicle, setLinkedVehhicle] = useState<IMasterVehicle | null>(
    null,
  );

  const [searchText, setSearchText] = useState('');
  const [searchedVehicles, setSearchedVehicles] = useState<IMasterVehicle[]>(
    [],
  );
  const [isEditing, setIsEditing] = useState(false);

  const formMethods = useForm<ICFSForm>({
    defaultValues: cfsDefaultValues,
    mode: 'onChange',
  });

  const reporterTime = formMethods.watch('reporter.reportTime');

  const { handleAddLinkedName, handleDeleteLinkedName } =
    useMasterNamesContext();
  const { handleAddLinkedVehicle, handleDeleteLinkedVehicle } =
    useMasterVehicleContext();

  const handleChangeReporterTime = async () => {
    const newDateTime = formMethods.getValues('reporter.reportTime');
    try {
      if (cfs)
        await CfsApi.update(String(cfs?._id), {
          reporter: { reportTime: newDateTime },
        }).then((res) => {
          updateCFS({
            ...cfs,
            reporter: { ...res.data.reporter },
          });
          ablyCfsChannel?.publish('cfsForm', {
            reportTime: res.data.reporter.reportTime,
          });
          updateToast({
            type: ConfirmTypes.SUCCESS,
            open: true,
            message: 'Reporter time updated',
          });
        });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const onUpdateAddressInfo = () => {
    const newAddress = formMethods.getValues('addressInfo.address');
    CfsApi.updateAddressInfo(cfs?._id as string, newAddress?._id)
      .then((res) => {
        ablyCfsChannel?.publish('cfsForm', {
          addressInfo: {
            ...cfsDefaultValues.addressInfo,
            ...res.data.addressInfo,
          },
        });
        if (cfs)
          updateCFS({
            ...cfs,
            addressInfo: res.data.addressInfo,
          });
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  const handlePersonChange = async (newValue: IMasterName) => {
    if (!newValue._id) return;
    if (!cfs?._id) return;
    if (linkedPerson && handleDeleteLinkedName) {
      await handleDeleteLinkedName(linkedPerson?._id as string);
    }

    const data = {
      relationship: {
        behavior: 'N/A',
        involvement: [],
      } as ICFSInvolvement,
    };

    handleAddLinkedName(newValue._id, data);
    const mastername = await MasterNameApi.getMasterName(newValue._id);
    setLinkedPerson(mastername.data);
  };

  const updateAddedVehicles = async (vehicleId: string) => {
    try {
      await handleAddLinkedVehicle(vehicleId);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleVehicleChange = async (newValue: IMasterVehicle) => {
    if (!newValue._id) return;
    if (linkedVehicle && handleDeleteLinkedVehicle) {
      await handleDeleteLinkedVehicle(linkedVehicle?._id as string);
    }
    updateAddedVehicles(newValue._id);
    const masterVehicle = await MasterVehicleApi.getMasterVehicle(newValue._id);

    setLinkedVehhicle(masterVehicle.data);
  };

  useEffect(() => {
    if (cfs?._id) {
      const data: ICFSForm = {
        number: cfs.number,
        severity: cfs.severity,
        shortDescription: cfs.shortDescription,
        incidentCodes: cfs.incidentCodes.map((code) => String(code._id)),
        addressInfo: {
          ...cfsDefaultValues.addressInfo,
          ...cfs.addressInfo,
          instructions: cfs.addressInfo?.instructions || '',
        },
        reporter: {
          ...cfs.reporter,
          contactPhoneNumber: parsePhoneNumber(cfs.reporter.contactPhoneNumber),
        },
      };
      formMethods.reset(data);
    }
  }, [cfs?._id]);

  useEffect(() => {
    fetchMasterVehicles();
  }, [searchText]);

  const fetchMasterVehicles = async () => {
    try {
      const params = {
        search: searchText,
        limit: 50,
      };
      const res = await MasterVehicleApi.getMasterVehicles(params);
      setSearchedVehicles(res.data.results);
    } catch (err: any) {
      updateToast({ open: true, message: err });
    }
  };

  const handleInputChange = useDebouncedCallback(
    (_: SyntheticEvent, v: string) => {
      setSearchText(v);
    },
    500,
  );

  const renderHeader = (tab: TabType) => {
    switch (tab) {
      case 'details': {
        return (
          <Stack direction="row" spacing={2} padding={2} alignItems="center">
            <ButtonBase
              onClick={() => setIsEditing(!isEditing)}
              sx={{ padding: 1 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                columnGap={1}
                marginRight={2}
              >
                {cfs?.addressInfo?.address && (
                  <>
                    <IcMarkerPin color={colors.grey[80]} height={20} />
                    <Typography color={colors.grey[80]} variant="body2">
                      {getAddressCommonString(cfs?.addressInfo?.address)}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" columnGap={1}>
                <IcClock color={colors.grey[80]} height={20} />
                <Typography color={colors.grey[80]} variant="body2">
                  {moment(reporterTime).format(DATE_TIME_FORMAT)}
                </Typography>
                <IcPencil />
              </Stack>
            </ButtonBase>
            <Button
              variant="contained"
              color="error"
              disabled={cfs?.addressInfo?.address ? false : true}
              onClick={() => {
                if (!cfs) return;
                handleSlefAssignCfs(cfs, true);
                handleBack();
              }}
            >
              Self Assign CFS
            </Button>
            <Button
              variant="contained"
              color="inherit"
              size="large"
              onClick={() => {
                if (!cfs) return;
                const assignedUnitShift = findUnitShiftByUserId(
                  cfs.assignedUnitShifts,
                  user?._id as string,
                );

                const badgeNumber =
                  user?.profile?.employmentInformation?.badgeNumber || 'N/A';
                updateToast({
                  type: ConfirmTypes.SUCCESS,
                  open: true,
                  message: assignedUnitShift
                    ? `${cfs.number} was successfully created by ${assignedUnitShift?.unitShift.unit.name} (${badgeNumber})`
                    : `${cfs.number} was successfully created by ${badgeNumber}`,
                });
                handleBack();
              }}
            >
              Done
            </Button>
          </Stack>
        );
      }
      case 'relations-cases': {
        return <></>;
      }
      case 'relations-cfs': {
        return <></>;
      }
    }
  };

  const renderContent = (tab: TabType) => {
    switch (tab) {
      case 'details': {
        return (
          <>
            <Box display="flex" flex={1} flexDirection="column" gap={2}>
              <Stack>
                <MasterNameAutocomplete
                  excludedMasterNames={linkedPerson ? [linkedPerson] : []}
                  onChange={handlePersonChange}
                  filterByType={MasterNameType.PERSON}
                  noResults={
                    <NoResultItem
                      flexDirection="row"
                      alignItems="center"
                      sx={{ ml: -1 }}
                    ></NoResultItem>
                  }
                />
              </Stack>
              {linkedPerson && (
                <PersonDetailsCard
                  data={linkedPerson as IMasterNamePerson}
                  setSelectedTab={setSelectedTab}
                />
              )}
            </Box>
            <Box display="flex" flex={1} flexDirection="column">
              <Stack>
                <Autocomplete
                  fullWidth
                  onInputChange={handleInputChange}
                  options={searchedVehicles}
                  noOptionsText={<NoResultItem />}
                  onChange={(_, v) => {
                    if (v) handleVehicleChange(v);
                  }}
                  getOptionLabel={() => ''}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      flexDirection="column"
                      flexWrap="wrap"
                      sx={{
                        flexDirection: 'row',
                        borderBottom: `1px solid ${colors.grey[10]}`,
                        flexWrap: 'wrap',
                        m: 1,
                        height: '60px',
                      }}
                    >
                      <p style={{ width: '100%', margin: 0 }}>
                        <strong style={{ margin: 0 }}>
                          {option.year} {option.make} {option.model}
                        </strong>
                      </p>
                      <p style={{ margin: 0 }}>{option.licensePlate?.number}</p>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={searchText}
                      placeholder="Search vehicles..."
                      fullWidth
                      sx={{ marginBottom: 2 }}
                    />
                  )}
                  clearOnBlur={false}
                />
              </Stack>
              {linkedVehicle && (
                <CarDetailsCard
                  data={linkedVehicle as IMasterVehicle}
                  setSelectedTab={setSelectedTab}
                />
              )}
            </Box>
          </>
        );
      }
      case 'relations-cfs': {
        return <RelationsList type="CFS" currentMastername={linkedPerson} />;
      }
      case 'relations-cases': {
        return <RelationsList type="Cases" currentMastername={linkedPerson} />;
      }
    }
  };

  const onBack = () => {
    if (selectedTab === 'details') {
      handleBack();
    } else {
      setSelectedTab('details');
    }
  };

  const getTitle = () => {
    switch (selectedTab) {
      case 'details':
        return 'Create CFS - Car Stop';
      case 'relations-cfs':
        return 'Relations CFS';
      case 'relations-cases':
        return 'Relations Cases';
    }
  };

  return (
    <Stack>
      <FormProvider {...formMethods}>
        <Page title={getTitle()}>
          <PageHeader
            title={getTitle()}
            onBack={onBack}
            sx={{
              paddingLeft: 2,
            }}
          >
            {renderHeader(selectedTab)}
          </PageHeader>
          {isEditing && cfs && (
            <>
              <Stack
                display="flex"
                flex={1}
                padding={1}
                direction="row"
                spacing={2}
              >
                <Box flex={1} paddingLeft={2}>
                  <RenderFormField
                    label="Address"
                    name="addressInfo.address"
                    type="address"
                    locationRestriction={mapBounds}
                    handleChange={onUpdateAddressInfo}
                  />
                </Box>
                <Box flex={1} paddingRight={2} paddingLeft={1}>
                  <RenderFormField
                    label="Reporter time"
                    name="reporter.reportTime"
                    type="datetime"
                    handleChange={handleChangeReporterTime}
                  />
                </Box>
              </Stack>
              <Divider />
            </>
          )}
          <Stack spacing={2} flex={1} direction="row" padding={3}>
            {renderContent(selectedTab)}
          </Stack>
        </Page>
      </FormProvider>
    </Stack>
  );
};

export default CarStopCfsTab;
