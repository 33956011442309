export default {
  MuiSelect: {
    styleOverrides: {
      select: {
        '&:focus': {
          backgroundColor: 'white', // Your desired override
        },
      },
    },
  },
};
