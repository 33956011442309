import { FC, useLayoutEffect, useRef, useState } from 'react';

import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';

import { DATE_TIME_FORMAT, TIME_FORMAT } from '@/config';
import { IAdditionalInfo } from '@/models';
import { IcDeleteTrash } from '@/assets/images';
import { useUserStore } from '@/store';

interface IAdditionalInfoItemProps {
  cfsId?: string;
  additionalInfo: IAdditionalInfo;
  updateAdditionalInfos?: (data: IAdditionalInfo) => void;
}

export const AdditionalInfoItem: FC<IAdditionalInfoItemProps> = ({
  additionalInfo,
  updateAdditionalInfos
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [truncate, setTruncate] = useState(true);
  const [showLink, setShowLink] = useState(false);
  const { user } = useUserStore();

  useLayoutEffect(() => {
    if (
      ref &&
      ref.current &&
      ref.current.clientWidth < ref.current.scrollWidth
    ) {
      setShowLink(true);
    }
  }, [ref]);
  
  const removeMessage = async () => {
    additionalInfo.isDeleted = true;
    if (updateAdditionalInfos)
      updateAdditionalInfos(additionalInfo);
  }

  return (
    <Box className="item">
      <Stack direction="row" alignItems="center" columnGap={1}>
        <Tooltip
          title={
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {additionalInfo.createdBy?.firstName || ''}{' '}
              {additionalInfo.createdBy?.lastName || ''}
            </Typography>
          }
        >
          <Box className="circle">
            {additionalInfo.createdBy?.firstName?.charAt(0) || ''}
            {additionalInfo.createdBy?.lastName?.charAt(0) || ''}
          </Box>
        </Tooltip>
        {additionalInfo.isNew && (
          <Chip label="New" color="error" className="chip" />
        )}

        <Box className="description-container">
          <Typography
            ref={ref}
            className={`
              description-content
              ${showLink && truncate && ' truncate'}
              `}
            sx={{
              maxWidth: 100,
              whiteSpace: truncate ? 'nowrap' : 'normal',
              overflow: truncate ? 'hidden' : '',
              textOverflow: 'ellipsis',
            }}
          >
            <span style={{ textDecoration: additionalInfo?.isDeleted ? 'line-through' : 'none' }}>
              {additionalInfo.description}
            </span>
            {additionalInfo.isTruncateToggleable && truncate === false && (
              <Typography color="error" sx={{ cursor: 'pointer' }}>
                <div
                  onClick={() => {
                    setTruncate(true);
                  }}
                >
                  See less
                </div>
              </Typography>
            )}
          </Typography>

          {additionalInfo.isTruncateToggleable && showLink && truncate && (
            <Typography sx={{ cursor: 'pointer' }} color="error">
              <div
                onClick={() => {
                  setTruncate(false);
                }}
              >
                See more
              </div>
            </Typography>
          )}
          {
            !additionalInfo?.isDeleted && user?._id === additionalInfo?.createdBy?._id && (
              <Tooltip style={{ cursor: 'pointer' }} title="Delete">
                <IcDeleteTrash onClick={() => removeMessage()} />
              </Tooltip>
            )
          }
          <Tooltip
            title={
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {moment(additionalInfo.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            }
          >
            <Typography className="description-date">
              {moment(additionalInfo.createdAt).format(TIME_FORMAT)}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
    </Box>
  );
};
