import { useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { MasterNameApi } from '@/api';
import { DATE_FORMAT } from '@/config';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { ICFSMasterName } from '@/models/cfs';
import { useToastStore } from '@/store';

import {
  TabNamesNextPaths,
  TabNamesScreens,
  cfsHistoryTableColumns,
} from '../../data';
import { useMasterNamesContext } from '../../MasterNamesContext';

export const CFSInvolvementHistory = () => {
  const { updateToast } = useToastStore();
  const { updateCurrentScreen } = useTabLayoutContext();
  const { currentMastername } = useMasterNamesContext();
  const [historyRows, setHistoryRows] = useState<ICFSMasterName[]>();

  useEffect(() => {
    fetchCfsHistory();
  }, [currentMastername?.masterName._id]);

  const fetchCfsHistory = async () => {
    if (!currentMastername?.masterName._id) return;
    try {
      const res = await MasterNameApi.getNameCfsHistory(
        currentMastername?.masterName._id,
      );
      setHistoryRows(res.data);
    } catch (err: any) {
      console.error('Error fetching history:', err);
      updateToast({ open: true, message: err.message });
    }
  };

  const renderCell = (row: Record<string, any>, key: string) => {
    try {
      const value = key.split('.').reduce((acc, part) => {
        return acc?.[part];
      }, row);

      if (key === 'createdAt') {
        return moment(value).format(DATE_FORMAT);
      }

      if (key === 'relationship.involvement') {
        return Array.isArray(value) ? value.join(', ') : '';
      }

      return value || '-';
    } catch (err) {
      console.error('Error rendering cell:', err);
      return '-';
    }
  };

  const onGoBack = () => {
    updateCurrentScreen(TabNamesScreens.MasterNameView);
  };

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.MasterNameView}>
      <PageBackHeader title="CFS involvement history" goBack={onGoBack} />

      <div style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {cfsHistoryTableColumns.map((field) => (
                <TableCell
                  key={field.key}
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: 'background.paper',
                  }}
                >
                  {field.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {historyRows?.map((row) => (
              <TableRow
                key={row._id}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                {cfsHistoryTableColumns.map((field) => (
                  <TableCell
                    key={field.key}
                    sx={{
                      minWidth: field.key === 'cfs.number' ? 120 : 'auto',
                    }}
                  >
                    {String(renderCell(row, field.key))}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </TabAnimationWrapper>
  );
};
