import { colors } from '../variables';

export default {
  MuiMultiSectionDigitalClockSection: {
    styleOverrides: {
      root: () => ({
        '.MuiButtonBase-root.Mui-selected, .MuiButtonBase-root.Mui-selected:hover, .MuiButtonBase-root.focus.Mui-selected':
          {
            backgroundColor: colors.error.main,
          },
      }),
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: () => ({
        '&.MuiButtonBase-root.Mui-selected, &.MuiButtonBase-root.Mui-selected:hover, &.MuiButtonBase-root.focus.Mui-selected':
          {
            backgroundColor: colors.error.main,
          },
      }),
    },
  },
};
