import { IAddress } from '@/models';

export const getAddressLocation = (
  address?: IAddress,
  ignoreSubAddress?: boolean,
): string => {
  if (!address) {
    return '';
  }
  const {
    addressLabel,
    subAddress,
    intersectionStreets,
    zipName,
    state,
    zipcode,
  } = address;

  const parts: string[] = [];

  let addressLine = addressLabel ?? '';
  if (intersectionStreets && intersectionStreets.length) {
    addressLine = intersectionStreets.join(' & ');
  }
  if (!ignoreSubAddress && subAddress) {
    addressLine = `${addressLine} ${subAddress}`;
  }
  if (addressLine) parts.push(addressLine);

  if (zipName) parts.push(zipName);

  if (state || zipcode) {
    parts.push(`${state || ''} ${zipcode || ''}`.trim());
  }

  return parts.filter(Boolean).join(', ');
};

// String of same fields of Main address item and sub-address item.
export const getAddressCommonString = (address?: IAddress) => {
  if (!address) return '';
  return `${address.addressNumber}, ${address.completeStreetName}, ${address.zipcode}`;
};

// Object with lat and lng of address point.
export const getAddressCoordinates = (
  address?: IAddress,
): { lat: number; lng: number } | null => {
  if (!address || !address.point || !address.point.coordinates) return null;
  return {
    lat: address.point.coordinates[1],
    lng: address.point.coordinates[0],
  };
};
