import { FocusEvent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { Box, Grid, Tooltip } from '@mui/material';
import equal from 'fast-deep-equal';

import { CfsApi } from '@/api';
import { IcTriangleWarning } from '@/assets/images';
import {
  CautionModal,
  CFSAddressJurisdictionModal,
  FormCard,
  FormRoot,
  FormValueType,
  RenderFormField,
  ReporterForm,
} from '@/components';
import { useCFSContext } from '@/hooks';
import { IAddress, ICFS, ICFSForm } from '@/models';
import { getAddressCommonString } from '@/services';
import { useDataStore, useToastStore, useUserStore } from '@/store';
import { compositeObject, isInsideMultiPolygon } from '@/utils';

import { OnBlurFormFields, CFSFormFields, cfsDefaultValues } from '../data';

export type OptionType = {
  label: string;
  value: string;
};

export const CFSForm = (props: {
  cfsId?: string;
  hideReporterInfo?: boolean;
}) => {
  const { getValues, setValue, trigger, watch } = useFormContext<ICFSForm>();
  const { cfs, mapBounds, ablyCfsChannel, updateCFS } = useCFSContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParams = useParams();
  const checkAddress = searchParams.get('checkAddress');
  const cfsId = props?.cfsId || String(urlParams.cfsId);
  const { updateToast } = useToastStore();
  const { account } = useUserStore();
  const { incidentCodes } = useDataStore();
  const [openAddressBoundaryModal, setOpenAddressBoundaryModal] =
    useState(false);

  const formAddressValue = watch('addressInfo.address');
  const addressId = formAddressValue?._id;

  const isNotJurisdictionAddress =
    account?.settings.areas &&
    cfs?.addressInfo?.address &&
    !isInsideMultiPolygon(
      cfs?.addressInfo?.address?.point,
      account?.settings.areas,
    );

  useEffect(() => {
    if (cfs?._id && checkAddress && isNotJurisdictionAddress) {
      return setOpenAddressBoundaryModal(true);
    }
  }, [checkAddress, cfs?._id]);

  // Update CFS form values when CFS data is updated by ably message.
  useEffect(() => {
    if (cfs) {
      const formValues = getValues();
      const _cfs = {
        ...cfs,
        incidentCodes: cfs.incidentCodes.map((code) => String(code._id)),
        reporter: {
          ...cfs.reporter,
          // Form phone number field doesn't country code +1.
          contactPhoneNumber: cfs.reporter?.contactPhoneNumber?.replace(
            '+1',
            '',
          ),
        },
      };
      Object.entries(formValues).forEach(([key, value]) => {
        if (!equal(_cfs[key as keyof ICFS], value)) {
          if (key === 'addressInfo') {
            setValue('addressInfo.address', _cfs.addressInfo?.address);
            setValue(
              'addressInfo.instructions',
              _cfs.addressInfo?.instructions || '',
            );
          }
          setValue(key as keyof ICFSForm, _cfs[key as keyof ICFS] as any);
        }
      });
    }
  }, [JSON.stringify(cfs)]);

  // Should checkout this part again

  // useEffect(() => {
  //   const dirtyFieldStr = JSON.stringify(dirtyFields);
  //   const existDirtyFields =
  //     dirtyFieldStr.includes('addressInfo') &&
  //     !dirtyFieldStr.includes('address.');

  //   const beforeunload = (e: BeforeUnloadEvent) => {
  //     if (existDirtyFields) e.returnValue = 'Are you sure you want to leave?';
  //   };
  //   window.addEventListener('beforeunload', beforeunload);
  //   return () => window.removeEventListener('beforeunload', beforeunload);
  // }, [window, JSON.stringify(dirtyFields)]);

  const incidentCodeOptions = useMemo(() => {
    return incidentCodes.map(({ _id, code }) => ({
      label: code,
      value: _id ?? '',
    }));
  }, [incidentCodes]);

  const onUpdateAddressInfo = (closeReason?: string) => {
    const newAddress = getValues('addressInfo.address');
    CfsApi.updateAddressInfo(cfsId, newAddress?._id)
      .then((res) => {
        ablyCfsChannel?.publish('cfsForm', {
          addressInfo: {
            ...cfsDefaultValues.addressInfo,
            ...res.data.addressInfo,
          },
        });
        if (cfs)
          updateCFS({
            ...cfs,
            addressInfo: res.data.addressInfo,
            ...(!!closeReason && { closeReason, isClosed: true }),
          });
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
    handleCloseAddressBoundaryModal();
  };

  const onUpdateIncidentCodes = (newIncidentCodes: string[]) => {
    const validCodes = newIncidentCodes.reduce((acc, item) => {
      const valid = incidentCodes.find(
        (code) => code._id === item || code.code === item,
      );
      if (valid?._id) {
        acc.push(valid._id);
      }
      return acc;
    }, [] as string[]);

    CfsApi.updateIncidentCodes(cfsId, validCodes)
      .then((res) => {
        ablyCfsChannel?.publish('cfsForm', {
          incidentCodes: res.data.incidentCodes,
          requiredUnits: res.data.requiredUnits,
        });
        if (cfs)
          updateCFS({
            ...cfs,
            incidentCodes: res.data.incidentCodes,
            requiredUnits: res.data.requiredUnits,
          });
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  const onUpdateCFS = (data: Partial<ICFSForm>) => {
    CfsApi.update(cfsId, data)
      .then((res) => {
        updateCFS({ ...cfs, ...res.data });
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  const handleChange = async (fieldName: string, newValue: FormValueType) => {
    const data = compositeObject(fieldName, newValue);
  
    if (fieldName === 'addressInfo.address') {
      const newAddress = getValues('addressInfo.address');
      if (
        account?.settings.areas &&
        newAddress?.point &&
        getAddressCommonString(newAddress as IAddress) !==
          getAddressCommonString(cfs?.addressInfo?.address) &&
        !isInsideMultiPolygon(newAddress.point, account?.settings.areas)
      ) {
        return setOpenAddressBoundaryModal(true);
      }
      return onUpdateAddressInfo();
    } else if (fieldName === 'incidentCodes') {
      return onUpdateIncidentCodes(newValue as string[]);
    } else if (fieldName === 'reporter.contactPhoneNumber') {
      const isValidPhoneNumber = await trigger(fieldName as keyof ICFSForm);
      if (!isValidPhoneNumber) {
        return;
      }
      const phoneData = !newValue 
        ? { reporter: { contactPhoneNumber: undefined } }
        : compositeObject(fieldName, `+1${newValue}`);
      onUpdateCFS(phoneData);
      if (cfs) {
        updateCFS({
          ...cfs,
          reporter: {
            ...cfs.reporter,
            contactPhoneNumber: newValue ? `+1${newValue}` : undefined,
          },
        });
      }
      return;
    } else if (!OnBlurFormFields.includes(fieldName)) {
      onUpdateCFS(data);
    } else if (fieldName === 'addressInfo.instructions') {
      data.addressInfo.address = getValues('addressInfo.address');
    }
    ablyCfsChannel?.publish('cfsForm', data);
  };

  // Submit CFS form at onBlur event
  const onBlur = async (event: FocusEvent<HTMLFormElement>) => {
    if (!OnBlurFormFields.includes(event.target.name)) return;
    const fieldName = event.target.name;
    const value = event.target.value;

    const isValid = await trigger(fieldName as keyof ICFSForm);
    if (!isValid) return;
    const data = compositeObject(fieldName, value);
    onUpdateCFS(data);
  };

  const handleCloseAddressBoundaryModal = () => {
    if (checkAddress) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('checkAddress');
      setSearchParams(newSearchParams);
    }
    setOpenAddressBoundaryModal(false);
  };

  const handleClearAddress = () => {
    setValue('addressInfo.address', undefined);
    onUpdateAddressInfo();
    handleCloseAddressBoundaryModal();
  };

  return (
    <>
      <FormRoot onBlur={onBlur} sx={{ mt: '2px', pb: 0 }}>
        {CFSFormFields.map(({ title, name, items }) => (
          <FormCard key={title} title={title}>
            {name === 'addressInfo' && !!addressId && (
              <CautionModal addressId={addressId} />
            )}
            {items.map(({ styles, ...rest }) => (
              <Grid
                key={`${name}-${rest.name}`}
                {...{
                  ...styles,
                  marginRight:
                    rest.name === 'address' && isNotJurisdictionAddress ? 5 : 0,
                }}
                item
              >
                <RenderFormField
                  {...rest}
                  name={name ? `${name}.${rest.name}` : rest.name}
                  items={
                    rest.name === 'incidentCodes'
                      ? incidentCodeOptions
                      : undefined
                  }
                  locationRestriction={
                    rest.name === 'address' ? mapBounds : undefined
                  }
                  handleChange={handleChange}
                />
                {rest.name === 'address' && isNotJurisdictionAddress && (
                  <Tooltip
                    title="The selected address falls outside our jurisdiction"
                    arrow
                    followCursor
                  >
                    <Box
                      sx={{
                        width: 8,
                        pt: 3.6,
                        pl: 2,
                        cursor: 'pointer',
                      }}
                    >
                      <IcTriangleWarning />
                    </Box>
                  </Tooltip>
                )}
              </Grid>
            ))}
          </FormCard>
        ))}
        {!props.hideReporterInfo && (
          <ReporterForm cfsId={cfsId} onChange={handleChange} />
        )}
      </FormRoot>
      <CFSAddressJurisdictionModal
        open={openAddressBoundaryModal}
        onClose={onUpdateAddressInfo}
        onClearAddress={handleClearAddress}
        onKeepAddress={() => onUpdateAddressInfo()}
      />
    </>
  );
};
