import { useState, useEffect, RefObject } from 'react';

export const useDimensions = (targetRef?: RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    getDimensions();
  }, [targetRef]);

  function getDimensions() {
    let { innerWidth: width, innerHeight: height } = window;
    if (targetRef?.current) {
      width = targetRef.current.clientWidth;
      height = targetRef.current.clientHeight;
    }
    setDimensions({ height, width });
  }

  return dimensions;
};
