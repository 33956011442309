import { IconButton, Stack, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const ModalContent = styled(Stack)<{
  width?: number | string;
}>(({ width, theme }) => ({
  height: '100%',
  width: width || 650,
  position: 'relative',
  float: 'right',
  backgroundColor: theme.palette.background.paper,
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  width: 34,
  height: 34,
  position: 'absolute',
  background: theme.palette.mode === 'light' ? colors.white : colors.grey[80],
  left: -18,
  top: 40,
  zIndex: 1,
  boxShadow: '0px 0px 25px #26465826',
  color: colors.error.main,

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },

  '&:hover': {
    background: theme.palette.mode === 'light' ? colors.white : colors.grey[80],
  },
}));
