import { forwardRef, Ref } from 'react';

import { Stack, useTheme } from '@mui/material';
import {
  useGridApiContext,
  GridToolbarProps,
  GridPreferencePanelsValue,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';

import { IcFilter, IcSettings } from '@/assets/images';
import { NarrowButton } from '@/components';
import { toolbarStyles } from '@/components/DataGrid/styles';
import { colors } from '@/theme/variables';

export interface DataGridToolbarProps extends GridToolbarProps {
  cta?: React.ReactNode;
  hideFilter?: boolean;
}

export const DataGridToolbar = forwardRef<HTMLDivElement, DataGridToolbarProps>(
  function CustomDataGridToolbar({ cta, hideFilter, ...rest }, ref) {
    const apiRef = useGridApiContext();
    const theme = useTheme();

    const handleColumnsPanel = () => {
      if (apiRef?.current) {
        apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
      }
    };

    const handleFiltersPanel = () => {
      if (apiRef?.current) {
        apiRef.current.showPreferences(GridPreferencePanelsValue.filters);
      }
    };

    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        padding="12px"
        sx={toolbarStyles(theme).GridFilterPanel}
        ref={ref as Ref<HTMLDivElement>}
        {...rest}
      >
        <Stack spacing="4px" direction="row">
          <Stack position="relative">
            {!hideFilter ? (
              <NarrowButton
                variant="contained"
                color="inherit"
                onClick={handleFiltersPanel}
                sx={{ width: '36px', height: '36px' }}
              >
                <IcFilter />
              </NarrowButton>
            ) : null}
          </Stack>
          <NarrowButton
            variant="contained"
            color="inherit"
            onClick={handleColumnsPanel}
            sx={{ width: '36px', height: '36px' }}
          >
            <IcSettings color={colors.grey[90]} />
          </NarrowButton>
        </Stack>
        <Stack
          spacing="12px"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridToolbarQuickFilter
            sx={toolbarStyles(theme).GridToolbarQuickFilter}
            variant="outlined"
            debounceMs={800}
          />
          {cta}
        </Stack>
      </Stack>
    );
  },
);
