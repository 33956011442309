import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useBlocker } from 'react-router-dom';

export interface WithUnsavedChangesWarningProps {
  onNavigation: (event: Event) => boolean;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}

const MESSAGE =
  'You have unsaved changes. Are you sure you want to navigate away?';

const confirmUnsavedChanges = () => {
  return window.confirm(MESSAGE);
};

const withUnsavedChangesWarning = <P extends object>(
  WrappedComponent: React.ComponentType<P & WithUnsavedChangesWarningProps>,
  useBlock = true,
) => {
  const HOC = (props: P) => {
    const [isDirty, setIsDirty] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    let previousLocation = location;

    useEffect(() => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (isDirty) {
          event.returnValue = MESSAGE; // Standard for most browsers
          return MESSAGE; // For some older browsers
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [isDirty]);

    useEffect(() => {
      const handleFormChange = () => {
        setIsDirty(true);
      };

      // Attach event listener to form elements
      const formElements = document.querySelectorAll('input, textarea, select');
      formElements.forEach((element) => {
        element.addEventListener('change', handleFormChange);
      });

      // Cleanup: Remove event listeners
      return () => {
        formElements.forEach((element) => {
          element.removeEventListener('change', handleFormChange);
        });
      };
    }, []);

    useBlocker(() => {
      if (!useBlock) return false;
      if (isDirty) {
        const confirmed = confirmUnsavedChanges();
        return !confirmed; // Block navigation if user chooses to stay
      }
      return false; // Allow navigation if no unsaved changes
    });

    useEffect(() => {
      if (isDirty && location.pathname !== previousLocation.pathname) {
        const confirmed = confirmUnsavedChanges();
        if (!confirmed) {
          // Prevent navigation by resetting location
          navigate(previousLocation.pathname, { replace: true });
        }
      }

      // Update previous location
      previousLocation = location;
    }, [location, isDirty, navigate]);

    const handleNavigation = (event: Event) => {
      if (isDirty) {
        const confirmed = confirmUnsavedChanges();
        if (!confirmed) {
          event.preventDefault();
          return false;
        }
        return true;
      }
      return true;
    };

    return (
      <WrappedComponent
        {...props}
        onNavigation={handleNavigation}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
      />
    );
  };

  HOC.displayName = `withUnsavedChangesWarning(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return HOC;
};

export default withUnsavedChangesWarning;
