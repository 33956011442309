import { IAuditModel, ObjectId } from './base';
import { IPoint, IPolygon } from './geo';

export enum AgencyType {
  EMS = 'EMS',
  FIRE = 'FIRE',
  POLICE = 'POLICE',
}

export interface IAgencyForm extends ObjectId {
  name: string;
  phoneNumber?: string;
  type: AgencyType;
  area: string;
  oriNumber: string;
  account?: {
    _id: string;
    number: string;
  };
}

export interface IAgency extends IAuditModel, Omit<IAgencyForm, 'area'> {
  area: IPolygon;
  center: IPoint;
}
