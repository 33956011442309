import { styled, Stack } from '@mui/material';

import { colors } from '@/theme/variables';

export const CFSEditRoot = styled(Stack)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  padding: '0 0 0 20px',
  backgroundColor: theme.palette.background.default,
  '& .active-cfs-content': {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 194px)',
    overflow: 'auto',
    '& .active-cfs-widgets': {
      paddingLeft: '15px',
    },
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  '& .cfs-action-button': {
    padding: '9.5px 12px',
  },
  '& .cfs-assignment-status': {
    marginLeft: '-10px',
    '& .MuiChip-label': {
      lineHeight: '17px',
      padding: '0px 8px',
    },
  },
  '& .mapbox-unit-list': {
    height: 'calc(100vh - 105px)',
    zIndex: 1,
    paddingLeft: '15px !important',
    '& .unit-list': {
      height: '100%',
      '.MuiStack-root .content': {
        height: '100%',
      },
    },
    '& .MuiTab-root': {
      paddingBottom: '14px!important;',
      lineHeight: '20px',
    },
    '& .unit-accordion-title': {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    '& .unit-divider': {
      margin: '0 15px',
    },
    '& .name-box': {
      marginLeft: '10px',
      '& .MuiTypography-root': {
        color: colors.grey[80],
      },
    },
    '& .txt-location': {
      color: theme.palette.text.secondary,
    },
    '& .txt-sector': {
      color: colors.grey[80],
    },
  },
  '& .map-card-content': {
    marginTop: 0,
    '& .map-details-wrapper': {
      marginTop: '15px',
    },
  },
  '& .map-item-value': {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 'normal',
  },
  '& .cfs-detail-avatar': {
    fontSize: '1rem',
    lineHeight: '24px',
    width: 36,
    height: 36,
  },
  '& .master-names-screen, .master-vehicle-screen': {
    border: 0,
    padding: 0,
    margin: 0,
  },
}));

export const FormItemRoot = styled(Stack)(() => ({
  flex: 1,
  paddingLeft: '16px',
}));

export const LiveInformationRoot = styled(Stack)(() => ({
  flex: 1,
  '& .live-info-card-container': {
    height: '100%',
  },
  '& .live-info-card-content': {
    flex: 1,
    overflow: 'auto',
    marginTop: 0,
  },
}));
