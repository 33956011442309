import { FC, useState } from 'react';

import { Box, styled, Tab, Tabs } from '@mui/material';

interface FirstResponderTabsProps {
  onTabChange?: (selectedTab: string) => void;
  page: 'my-cfs' | 'map-view';
}

export const MY_CFS_TABS = [
  { label: 'Details', path: 'details' },
  { label: 'Units', path: 'units' },
  { label: 'Times', path: 'times' },
  { label: 'Media', path: 'media' },
];

export const DASHBOARD_TABS = [
  { label: "CFS's list", path: 'cfs-list' },
  { label: 'Units', path: 'all-units' },
];

const TABS_BY_PAGE = {
  'my-cfs': MY_CFS_TABS,
  'map-view': DASHBOARD_TABS,
};

const FirstResponderTabs: FC<FirstResponderTabsProps> = ({
  onTabChange,
  page,
}) => {
  const [selectedTab, setSelectedTab] = useState(TABS_BY_PAGE[page][0].path);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setSelectedTab(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: '1.1rem',
    color: `${theme.palette.text.secondary} !important`,
    height: '75px',
    '&.Mui-selected': {
      color: `${theme.palette.text.primary} !important`,
    },
    '& .MuiButtonBase-root': {
      padding: '20px',
    },
  }));

  return (
    <Box flex={1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={selectedTab}
        variant="fullWidth"
        onChange={handleTabChange}
        aria-label="basic tabs example"
        sx={{ height: '75px' }}
      >
        {TABS_BY_PAGE[page].map((tab, index) => (
          <StyledTab key={index} label={tab.label} value={tab.path} />
        ))}
      </Tabs>
    </Box>
  );
};

export default FirstResponderTabs;
