import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Grid, Stack } from '@mui/material';

import { CfsApi } from '@/api';
import { FormCard, FormRoot } from '@/components';
import { IAddress, ICFS, IMasterName, IMasterNamePerson } from '@/models';
import { useToastStore } from '@/store';
import { openNewWindow } from '@/utils';

import { AssociatedAddressForm } from './AssociatedAddressForm';
import { AssociatedReporterForm } from './AssociatedReporterForm';
import { RecentCFSCard } from './RecentCFSCard';
import { IncomingCallDefaultValues } from '../data';

type IncomingCallCFSFormValues = {
  address?: IAddress;
  primaryReporter?: IMasterName;
  reporter: {
    callerPhoneNumber: string;
  };
};

interface IncomingCallCFSFormProps {
  phoneNumber: string;
  masterNames: IMasterNamePerson[];
  activeCfses: ICFS[];
  onClose: () => void;
}

export const IncomingCallCFSForm = ({
  phoneNumber,
  masterNames,
  activeCfses,
  onClose,
}: IncomingCallCFSFormProps) => {
  const { updateToast } = useToastStore();
  const methods = useForm<IncomingCallCFSFormValues>({
    defaultValues: IncomingCallDefaultValues,
    mode: 'all',
  });
  const { handleSubmit, reset } = methods;
  const addresses = masterNames
    .filter(({ address }) => !!address)
    .map(({ address }) => address) as IAddress[];

  useEffect(() => {
    reset({
      reporter: {
        callerPhoneNumber: phoneNumber,
      },
    });
  }, [phoneNumber]);

  const onSubmit = async (values: IncomingCallCFSFormValues) => {
    const { address, primaryReporter, reporter } = values;
    try {
      const response = await CfsApi.createFromIncomingCall({
        address: address?._id,
        primaryReporter: primaryReporter?._id,
        reporter,
      });
      const cfsId = response.data._id;

      await openNewWindow(`/cfs/${cfsId}/?checkAddress=${true}`, cfsId);

      onClose();
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  return (
    <FormProvider {...methods}>
      <FormRoot onSubmit={handleSubmit(onSubmit)} sx={{ padding: '1px' }}>
        <Grid container spacing={2.5}>
          <Grid item sm={12} xl={6}>
            <AssociatedAddressForm addresses={addresses} />
            <AssociatedReporterForm masterNames={masterNames} />
          </Grid>
          <Grid item sm={12} xl={6}>
            <FormCard
              key="Active CFSs and address"
              title="Active CFSs and address"
              sx={{ py: 3 }}
            >
              <Stack className="recent-cfs">
                {activeCfses.map((item) => (
                  <RecentCFSCard key={item._id} {...item} onClose={onClose} />
                ))}
              </Stack>
            </FormCard>
          </Grid>
        </Grid>
        <Stack className="btn-wrapper">
          <Button variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" type="submit">
            Create CFS
          </Button>
        </Stack>
      </FormRoot>
    </FormProvider>
  );
};
