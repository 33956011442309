import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import { IDriverLicense, IDriverLicenseForm } from '@/models/driver.license';

import { http } from './http';

export const DriverLicenseApi = {
  getDriverLicense: async (
    id: string,
  ): Promise<AxiosResponse<IDriverLicense>> => {
    return http.get(`api/${ApiVersion.V1}/driver-licenses/${id}`);
  },
  deleteDriverLicense: (id: string): Promise<AxiosResponse<IDriverLicense>> => {
    return http.delete(`api/${ApiVersion.V1}/driver-licenses/${id}`);
  },
  saveDriverLicense: (
    data: IDriverLicenseForm,
  ): Promise<AxiosResponse<IDriverLicense>> => {
    const method = data._id ? 'put' : 'post';
    return http[method](
      `api/${ApiVersion.V1}/driver-licenses${data._id ? '/' + data._id : ''}`,
      data,
    );
  },
};
