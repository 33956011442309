import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  MapCameraChangedEvent,
  MapCameraProps,
} from '@vis.gl/react-google-maps';

import { IcMarkerCfs } from '@/assets/images';
import { FormCard } from '@/components';
import { ExpandControl, FireHydrantMap, IMarker } from '@/components/GoogleMap';
import { defaultMapCenter } from '@/config';
import { ASSIGNMENT_STATUS } from '@/constants';
import { useUnitShiftContext } from '@/hooks';
import { useCFSContext } from '@/hooks/useCFSContext';
import { useUserStore } from '@/store';
import { colors } from '@/theme/variables';
import { formatDistance, openNewWindow } from '@/utils';

import { FormItemRoot } from '../styles';

export const CFSMap = (props: { cfsId?: string }) => {
  const { watch } = useFormContext();
  const { cfs, cfsMapMarkers = [], setMapBounds } = useCFSContext();
  const { filteredUnitShiftMarkers } = useUnitShiftContext();
  const { account } = useUserStore();
  const [mapCameraValues, setMapCameraValues] =
    useState<Partial<MapCameraProps>>();
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [mapTypeId, setMapTypeId] = useState<string>();
  const addressInfo = watch('addressInfo');
  // const nearestIntersection = addressInfo?.address?.nearestIntersection;
  const urlParams = useParams();
  const cfsId = props?.cfsId || String(urlParams.cfsId);
  const coordinates = addressInfo?.address?.point?.coordinates;

  useEffect(() => {
    if (coordinates?.length && cfs) {
      setMarkers([
        {
          key: 'emergency',
          title: cfs.number,
          position: { lng: coordinates[0], lat: coordinates[1] },
          icon: (
            <IcMarkerCfs
              color={ASSIGNMENT_STATUS[cfs.assignmentStatus].color}
            />
          ),
        },
      ]);
      setMapCameraValues({
        center: { lng: coordinates[0], lat: coordinates[1] },
        zoom: 15,
      });
    } else {
      setMarkers([]);
      const accountCenterCoordinates = account?.settings.center?.coordinates;
      setMapCameraValues({
        center: accountCenterCoordinates?.length
          ? {
              lng: accountCenterCoordinates[0],
              lat: accountCenterCoordinates[1],
            }
          : defaultMapCenter,
        zoom: 11,
      });
    }
  }, [addressInfo?.address, cfs?.assignmentStatus]);

  const crossStreetsLabel = useMemo(() => {
    const { crossStreet1, crossStreet2, distance1, distance2 } =
      addressInfo?.address || {};
    if (crossStreet1 && crossStreet2) {
      return `${crossStreet1}(${formatDistance(
        distance1,
      )}) and ${crossStreet2}(${formatDistance(distance2)})`;
    }
    return '-';
  }, [addressInfo?.address]);

  const handleExpandMap = () => {
    let newWindowUrl = `/unit-shifts?cfsId=${cfsId}`;
    if (mapTypeId) {
      newWindowUrl += `&mapTypeId=${mapTypeId}`;
    }
    openNewWindow(newWindowUrl);
  };

  const handleMapBoundsChanged = (e: MapCameraChangedEvent) => {
    setMapBounds(e.detail.bounds);
  };

  return (
    <FormCard classes={{ cardContent: 'map-card-content' }}>
      <FormItemRoot>
        <Stack spacing={2}>
          {!!mapCameraValues && (
            <FireHydrantMap
              {...mapCameraValues}
              mapTypeId={mapTypeId}
              markers={markers
                .concat(filteredUnitShiftMarkers || [])
                .concat(cfsMapMarkers)}
              showContextMenu={false}
              showStreetViewControl={true}
              onBoundsChanged={handleMapBoundsChanged}
              onHandleMapTypeIdChange={setMapTypeId}
              style={{ height: 342, borderRadius: 8 }}
            >
              <ExpandControl onExpand={handleExpandMap} />
            </FireHydrantMap>
          )}
          <Box className="map-details-wrapper">
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{ fontSize: '0.75rem', color: colors.grey[80] }}
                >
                  Cross streets
                </Typography>
                <Typography className="map-item-value">
                  {crossStreetsLabel}
                </Typography>
              </Grid>
              {/* <Grid item xs={6}>
                <Typography
                  sx={{ fontSize: '0.75rem', color: colors.grey[80] }}
                >
                  Nearest intersection
                </Typography>
                <Typography className="map-item-value">
                  {nearestIntersection && nearestIntersection.length > 0
                    ? nearestIntersection.join(' and ')
                    : '-'}
                </Typography>
              </Grid> */}
            </Grid>
          </Box>
        </Stack>
      </FormItemRoot>
    </FormCard>
  );
};
