import { DataGridColSettings } from '@/components/DataGrid/utils';

export const masterAddressDataGridCols: DataGridColSettings[] = [
  {
    field: 'addressLabel',
    headerName: 'Address',
    visible: true,
  },
  {
    field: 'subAddress',
    headerName: 'SubAddress',
    width: 200,
    visible: true,
  },
  {
    field: 'cautionsCount',
    headerName: 'Cautions',
    width: 120,
    visible: true,
  },
  {
    field: 'actions',
    headerName: '',
    width: 180,
    visible: true,
  },
];

export const MasterAddressViewFields = [
  {
    title: 'Address Information',
    key: 'basic',
    size: { xs: 12, md: 6 },
    items: [
      { label: 'Address Label', value: 'addressLabel' },
      { label: 'Normalized', value: 'normalized' },
      { label: 'Complete Street Name', value: 'completeStreetName' },
      { label: 'Address Number', value: 'addressNumber' },
      { label: 'Place Name', value: 'placeName' },
      { label: 'Zip Name', value: 'zipName' },
      { label: 'Zipcode', value: 'zipcode' },
      { label: 'State', value: 'state' },
      { label: 'Country', value: 'country' },
    ],
  },
  {
    title: 'Additional Information',
    key: 'additional',
    size: { xs: 12, md: 6 },
    items: [
      { label: 'Status', value: 'status' },
      { label: 'Point Type', value: 'pointType' },
      { label: 'Cautions Count', value: 'cautionsCount' },
      { label: 'Created At', value: 'createdAt' },
      { label: 'Updated At', value: 'updatedAt' },
    ],
  },
  {
    title: 'Location Details',
    key: 'location',
    size: { xs: 12 },
    items: [
      { label: 'Coordinates', value: 'point' },
      { label: 'Intersection Streets', value: 'intersectionStreets' },
    ],
  },
];

export const MasterAddressFormFields = [
  {
    title: 'Address Information',
    name: '',
    items: [
      {
        name: 'address',
        label: 'Address',
        type: 'text',
        required: true,
        grid: { xs: 12, md: 12 },
      },
      {
        name: 'subAddress',
        label: 'SubAddress',
        type: 'text',
        grid: { xs: 12, md: 12 },
      },
    ],
  },
  {
    title: 'Cautions',
    name: 'cautions',
    items: [
      {
        name: 'type',
        label: 'Type',
        type: 'select',
        required: true,
        options: [
          { label: 'Warning', value: 'warning' },
          { label: 'Notice', value: 'notice' },
          { label: 'Danger', value: 'danger' },
        ],
        grid: { xs: 12, md: 3 },
      },
      {
        name: 'comment',
        label: 'Comment',
        type: 'text',
        required: true,
        grid: { xs: 12, md: 6 },
      },
      {
        name: 'related',
        label: 'Related to',
        type: 'text',
        grid: { xs: 12, md: 3 },
      },
    ],
  },
];
