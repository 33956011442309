import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { IcPopUp } from '@/assets/images';
import { TableContainer } from '@/components';
import { DATE_FORMAT } from '@/config';
import { KaseStatusChip } from '@/layouts/TabLayout/MasterNames/styles';
import { IMasterIndexRelatedItems } from '@/models';
import { TableColumn } from '@/pages/CFS/Records/types/types';
import { getAddressLocation } from '@/services';
import { openNewWindow } from '@/utils';

export interface RelatedRecordsTableProps {
  rows: IMasterIndexRelatedItems[];
  columns: TableColumn[];
}
export interface MenuState {
  menuEl: HTMLElement | null;
  selectedId?: string;
}

export const RelatedRecordsTable = ({
  rows,
  columns,
}: RelatedRecordsTableProps) => {
  const navigate = useNavigate();

  const handleAction = (e: React.MouseEvent, row: IMasterIndexRelatedItems) => {
    e.stopPropagation();
    const type = row.name.includes('CFS') ? 'cfs' : 'kase';
    if (type === 'cfs') {
      openNewWindow(`/cfs/${row._id}`, row._id);
    } else {
      navigate('/cases/' + row._id + '/overview');
    }
  };

  const formatInvolvement = (relationship: any) => {
    if (!relationship?.involvement) return '/';
    const involvement = Array.isArray(relationship.involvement)
      ? relationship.involvement[0]
      : relationship.involvement;
    if (!involvement) return '/';
    return involvement.charAt(0) + involvement.slice(1);
  };

  const renderColumn = (
    row: IMasterIndexRelatedItems,
    field: { key: string; label: string },
  ) => {
    switch (field.key) {
      case 'actions': {
        return (
          <div style={{ textAlign: 'right' }}>
            <IconButton onClick={(e) => handleAction(e, row)}>
              <IcPopUp />
            </IconButton>
          </div>
        );
      }
      case 'involvement':
        return <KaseStatusChip label={formatInvolvement(row.relationship)} />;
      case 'location':
        return getAddressLocation(row.address) || '/';
      case 'dateAndTime':
        return row.createdAt ? moment(row.createdAt).format(DATE_FORMAT) : '/';
      case 'name':
        return row.name || '/';
      default:
        return '/';
    }
  };

  const renderRows = useMemo(() => {
    if (!rows?.length) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length + 1} sx={{ textAlign: 'center' }}>
            No related records found
          </TableCell>
        </TableRow>
      );
    }
    return rows.map((row) => (
      <TableRow key={row._id}>
        {columns.map((field) => (
          <TableCell key={field.key}>{renderColumn(row, field)}</TableCell>
        ))}
      </TableRow>
    ));
  }, [rows]);

  return (
    <TableContainer sx={{ px: 0 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((field) => (
              <TableCell key={field.key}>{field.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{renderRows}</TableBody>
      </Table>
    </TableContainer>
  );
};
