import { ReactElement, useMemo } from 'react';

import { Box, Chip, Divider, Stack, Typography, useTheme } from '@mui/material';

import { IcFire, IcMedicalCross, IcPolice } from '@/assets/images';
import { AgencyType, IUnitType } from '@/models';

type UnitItemProps = {
  name: string;
  type: IUnitType;
  action: ReactElement;
  description?: ReactElement;
};

export const UnitItem = ({
  name,
  type,
  action,
  description,
}: UnitItemProps) => {
  const theme = useTheme();
  const getIcon = useMemo(() => {
    if (type?.agencyType === AgencyType.POLICE)
      return <IcPolice color={theme.palette.text.primary} />;
    if (type?.agencyType === AgencyType.EMS)
      return <IcMedicalCross color={theme.palette.text.primary} />;
    return <IcFire color={theme.palette.text.primary} />;
  }, [type]);

  return (
    <Stack
      padding={2}
      border="solid 1px"
      borderColor={theme.palette.divider}
      borderRadius={1}
      flex={1}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={2}>
          <Typography fontSize={21} fontWeight={600}>
            {name}
          </Typography>
          <Chip
            sx={{ borderRadius: 1 }}
            icon={getIcon}
            label={`${type.name}`}
            color="default"
          />
        </Box>
        {action}
      </Stack>
      {description && <Divider sx={{ marginTop: 2 }} />}
      {description}
    </Stack>
  );
};
