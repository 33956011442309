import { createContext, ReactNode, useContext } from 'react';

import { ICFS } from '@/models';

interface ProviderProps<T> {
  value?: T;
  children?: ReactNode | undefined;
  assignedUserCfs: ICFS[] | null;
  queuedUserCfs: ICFS[];
  fetchUserCfs: () => void;
}

const UserCfsContext = createContext<{
  assignedUserCfs: ICFS[] | null;
  queuedUserCfs: ICFS[];
  fetchUserCfs: () => void;
}>({ assignedUserCfs: null, queuedUserCfs: [], fetchUserCfs: () => {} });

export const UserCfsProvider = ({
  assignedUserCfs,
  queuedUserCfs,
  fetchUserCfs,
  children,
}: ProviderProps<null>) => {
  return (
    <UserCfsContext.Provider
      value={{ assignedUserCfs, queuedUserCfs, fetchUserCfs }}
    >
      {children}
    </UserCfsContext.Provider>
  );
};

export const useUserCfs = () => useContext(UserCfsContext);
