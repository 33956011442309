import { useFieldArray, useFormContext } from 'react-hook-form';

import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';

import { IcClose } from '@/assets/images';
import { RenderFormField } from '@/components';
import { OptionType } from '@/pages/CFS/Edit/components/CFSForm';
import { colors } from '@/theme/variables';

import AdditionalInfoRelated from './AdditionalInfoRelated';
import { informationTypesItems } from '../data';

export const AdditionanInformationForm = () => {
  const { control, watch, getValues } = useFormContext();
  const { remove, append, fields } = useFieldArray({
    control,
    name: 'informationType',
  });

  const currentInformationType = watch('informationType');
  const addAditionalInformationType = () => {
    append({});
  };

  return (
    <>
      {fields.map((item, index) => (
        <Stack key={item.id} my={1}>
          <Grid container>
            {currentInformationType && currentInformationType[index] && (
              <Grid item>
                <Tooltip title="delete this section">
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{
                      color: colors.error.main,
                      mt: 3,
                      alignSelf: 'flex-start',
                    }}
                  >
                    <IcClose />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item md={3}>
              <RenderFormField
                name={`informationType.${index}.name`}
                label="Information type"
                type="select"
                items={informationTypesItems.map((additionalItem) => {
                  return !currentInformationType?.find(
                    (infoType: { name: string }) =>
                      infoType?.name === additionalItem.value,
                  )
                    ? additionalItem
                    : { ...additionalItem, disabled: true };
                })}
                defaultValue={
                  '0' as (string | number | readonly string[]) & OptionType
                }
                disabled={
                  getValues(currentInformationType[index].name)?.length &&
                  currentInformationType[index].name !== '0'
                }
              />
            </Grid>
            <Grid item flex="1">
              {currentInformationType &&
                currentInformationType[index] &&
                currentInformationType[index].name !== '0' && (
                  <AdditionalInfoRelated
                    currentSelection={currentInformationType[index].name}
                    control={control}
                    index={index}
                  />
                )}
            </Grid>
          </Grid>

          <Divider />
        </Stack>
      ))}
      <Button
        variant="text"
        color="error"
        sx={{ mb: 2 }}
        onClick={addAditionalInformationType}
      >
        + Add new
      </Button>
    </>
  );
};
