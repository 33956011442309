import { styled, TextField as MuiTextField } from '@mui/material';

export const AutoCompleteRoot = styled('div')(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
  '& .MuiAutocomplete-root': {
    width: '100%',
    padding: 0,

    '& .MuiAutocomplete-input': {
      boxSizing: 'content-box!important',
    },
  },
  '& .MuiAutocomplete-root .MuiTextField-root': {
    height: 'initial',
  },
}));

export const TextField = styled(MuiTextField)(() => ({
  height: 42,
  '& .MuiInputBase-root': {
    height: 'initial',
    gap: 2,
    padding: '2px 39px 2px 5px',
  },

  '& .MuiOutlinedInput-root .MuiChip-root': {
    height: 30,
    marginTop: 0,
  },
}));
