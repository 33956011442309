import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Button, Grid, IconButton, Stack } from '@mui/material';

import { IcDeleteTrash } from '@/assets/images';
import { RenderFormField } from '@/components';
import { PhoneNumberType } from '@/models';
import { colors } from '@/theme/variables';

import { MasterNamePhoneNumberFormFields } from '../data';

export const MasterNamePhoneNumberForm = () => {
  const { remove, append, fields } = useFieldArray({
    name: 'phoneNumbers',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append(
        {
          type: PhoneNumberType.OTHER,
          phoneNumber: '',
        },
        { shouldFocus: false },
      );
    }
  }, [fields]);

  useEffect(() => {
    disableSelectCallerType(); // Caller type shouldn't be able to be added. It's used only to associate the MasterName with an Incoming Call number.
  }, [MasterNamePhoneNumberFormFields]);

  const disableSelectCallerType = () => {
    const selectElement: any = MasterNamePhoneNumberFormFields.find(
      (e) => e.type.toLowerCase() === 'select',
    );
    selectElement?.items.forEach((item: any) => {
      if (item.label.toLowerCase() === 'caller') {
        item.disabled = true;
      }
    });
  };

  const handleAddPhoneNumber = () => {
    append({
      type: PhoneNumberType.OTHER,
      phoneNumber: '',
    });
  };

  return (
    <Stack my={2}>
      {fields.map((phoneField, index: number) => (
        <Grid container columnSpacing={2} key={`row-${phoneField.id}`}>
          {MasterNamePhoneNumberFormFields.map((field) => (
            <Grid key={`${phoneField.id}`} xs={3} item>
              <RenderFormField
                {...field}
                name={`phoneNumbers.${index}.${field.name}`}
                disabled={'type' in phoneField && phoneField.type === 'CALLER'}
              />
            </Grid>
          ))}
          <Grid style={{ display: 'flex' }} xs={3} item>
            <IconButton
              onClick={() => remove(index)}
              sx={{ color: colors.error.main, mt: 2, alignSelf: 'center' }}
            >
              <IcDeleteTrash />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        color="error"
        variant="text"
        onClick={handleAddPhoneNumber}
        sx={{ width: 'fit-content', padding: 0 }}
      >
        + Add phone number
      </Button>
    </Stack>
  );
};
