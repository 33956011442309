import { useEffect, useState } from 'react';

import { LocalStorageItems } from '@/constants';
import { CFSProvider, UnitShiftProvider } from '@/contexts';
import { useUserStore } from '@/store';

import FirstResponderDashboard from './FirstResponderDashboard';

const FirstResponderDashboardPage = () => {
  const [activeCfsId, setActiveCfsId] = useState<string | undefined>(undefined);

  const { userAgencyId } = useUserStore();

  useEffect(() => {
    if (userAgencyId) {
      localStorage.setItem(LocalStorageItems.CurrentAgencyId, userAgencyId);
    }
  }, [userAgencyId]);
  return (
    <CFSProvider cfsId={activeCfsId}>
      <UnitShiftProvider>
        <FirstResponderDashboard setActiveCfsId={setActiveCfsId} />
      </UnitShiftProvider>
    </CFSProvider>
  );
};

export default FirstResponderDashboardPage;
