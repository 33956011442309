import { FC, useEffect, useRef } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { CfsApi } from '@/api';
import { IAdditionalInfo } from '@/models';

import { AdditionalInfoInput } from './AdditionalInfoInput';
import { AdditionalInfoItem } from './AdditionalInfoItem';
import { AdditionalInfoList } from './styles';

interface IAdditionalInfoProps {
  cfsId?: string;
  additionalInfoList?: IAdditionalInfo[];
  onSubmit: (value: string) => void;
  classes?: { additionalInfoList: string };
  setNewAdditionalInfo: (data: IAdditionalInfo[]) => void;
  isFirstResponderView?: boolean;
}

export const AdditionalInfoForm: FC<IAdditionalInfoProps> = ({
  cfsId,
  additionalInfoList = [],
  onSubmit,
  classes,
  setNewAdditionalInfo,
  isFirstResponderView,
}) => {
  const infoListBottomRef = useRef<HTMLDivElement>(null);

  // Display the last message into the view port as default
  useEffect(() => {
    infoListBottomRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [additionalInfoList.length]);

  const updateAdditionalInfo = () => {
    CfsApi.update(cfsId as string, { additionalInfo: additionalInfoList }).then(
      (res) => {
        if (res.status === 200) {
          setNewAdditionalInfo(res?.data?.additionalInfo);
        }
      },
    );
  };

  return (
    <Stack rowGap={2} flex={1} overflow="auto">
      <AdditionalInfoList
        className={classes?.additionalInfoList}
        sx={isFirstResponderView ? { maxHeight: 'unset' } : {}}
      >
        {additionalInfoList.length ? (
          <Box className="content">
            {additionalInfoList.map((item, index) => (
              <AdditionalInfoItem
                cfsId={cfsId}
                key={`${item.createdBy?._id}-${index}`}
                additionalInfo={item}
                updateAdditionalInfos={updateAdditionalInfo}
              />
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Typography variant="body2">No message to display</Typography>
          </Box>
        )}
        <Box ref={infoListBottomRef} />
      </AdditionalInfoList>

      {!isFirstResponderView && (
        <>
          <Divider />
          <Box sx={{ flex: 'none' }}>
            <AdditionalInfoInput onSubmit={onSubmit} />
          </Box>
        </>
      )}
    </Stack>
  );
};
