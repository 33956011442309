import { useState } from 'react';

import { Loader } from '@/components';

export interface WithLoaderProps {
  showLoader: () => void;
  hideLoader: () => void;
}

export const withLoader = <P extends WithLoaderProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  function loaderHoc(props: Omit<P, keyof WithLoaderProps>) {
    const [loading, setLoading] = useState<boolean>(false);

    const showLoader = () => {
      setLoading(true);
    };
    const hideLoader = () => {
      setLoading(false);
    };

    return (
      <>
        <Loader open={loading} />

        <WrappedComponent
          {...(props as P)}
          showLoader={showLoader}
          hideLoader={hideLoader}
        ></WrappedComponent>
      </>
    );
  }

  return loaderHoc;
};
