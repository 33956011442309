import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { styled, useTheme } from '@mui/material';
import {
  Map,
  MapProps,
  MapCameraProps,
  MapCameraChangedEvent,
  ControlPosition,
} from '@vis.gl/react-google-maps';

import { defaultGoogleMap, googleMapId } from '@/config';
import { NEW_CFS_MARKER_ID } from '@/pages/Units/UnitShifts';

import { IMarker, MapMarker } from './MapMarker';
import { StreetViewControl } from './StreetViewControl';
import { ZoomControl } from './ZoomControl';

const MapRoot = styled('div')(() => ({
  '& .gm-style-iw-chr': {
    display: 'none',
  },
}));

export interface MapBounds {
  north: number;
  south: number;
  east: number;
  west: number;
}

export interface GoogleMapProps
  extends Omit<MapProps, 'center'>,
    PropsWithChildren {
  center?: google.maps.LatLngLiteral;
  markers?: IMarker[];
  showStreetViewControl?: boolean;
  showZoomControl?: boolean;
  showContextMenu?: boolean;
  handleBoundMapChange?: (bounds: MapBounds, zoom: number) => void;
  onCloseMarkerInfo?: (key: string) => void;
}

export const GoogleMap: FC<GoogleMapProps> = (props) => {
  const {
    markers,
    center,
    zoom,
    children,
    showStreetViewControl,
    showZoomControl = true,
    showContextMenu,
    handleBoundMapChange,
    onCloseMarkerInfo,
    ...rest
  } = props;
  const [cameraProps, setCameraProps] = useState<
    MapCameraProps & { bounds?: MapBounds }
  >(defaultGoogleMap);
  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);
  const theme = useTheme();
  useEffect(() => {
    const newZoom = zoom || defaultGoogleMap.zoom;
    const newCenter = center || defaultGoogleMap.center;
    setCameraProps((v) => ({ ...v, center: newCenter, zoom: newZoom }));
  }, [center, zoom]);

  useEffect(() => {
    if (cameraProps.bounds) {
      handleBoundMapChange?.(cameraProps.bounds, cameraProps.zoom);
    }
  }, [cameraProps.bounds, cameraProps.zoom]);

  const handleCameraChange = useCallback((e: MapCameraChangedEvent) => {
    setCameraProps(e.detail);
  }, []);

  const toggleMarkerInfo = (key: string) => {
    if (onCloseMarkerInfo) {
      onCloseMarkerInfo(key);
    }

    if (selectedMarker !== key) setSelectedMarker(key);
    else setSelectedMarker(null);
  };

  return (
    <MapRoot>
      <Map
        colorScheme={theme.palette.mode === 'dark' ? 'DARK' : 'LIGHT'}
        gestureHandling="greedy"
        mapId={googleMapId}
        onCameraChanged={handleCameraChange}
        disableDefaultUI
        mapTypeControl
        mapTypeControlOptions={{
          position: ControlPosition.RIGHT_TOP,
        }}
        {...rest}
        {...cameraProps}
      >
        {markers?.map(({ key, ...marker }) => (
          <MapMarker
            key={key}
            showInfo={selectedMarker === key || key === NEW_CFS_MARKER_ID}
            toggleInfo={() => toggleMarkerInfo(key)}
            showContextMenu={showContextMenu}
            {...marker}
          />
        ))}
        {showZoomControl && <ZoomControl />}
        {showStreetViewControl && markers && (
          <StreetViewControl
            marker={markers.find((m) => m.key === 'emergency')}
          />
        )}
        {children}
      </Map>
    </MapRoot>
  );
};
