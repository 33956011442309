import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { CfsApi, KaseApi, UserApi } from '@/api';
import { IcClose } from '@/assets/images';
import {
  FormRoot,
  RenderFormField,
  SelectItem,
  TableContainer,
} from '@/components';
import { WithLoaderProps, withLoader } from '@/hocs';
import withUnsavedChangesWarning, {
  WithUnsavedChangesWarningProps,
} from '@/hocs/withUnsavedChangesWarning';
import { useKaseContext } from '@/hooks/useKaseContext';
import { IKaseNewForm, KaseTypes } from '@/models';
import { ConfirmTypes, useToastStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';

import { KaseFormFields } from '../KaseList/data';
import LocationCodeSearch from '../LocationCodeSearch/LocationCodeSearch';

interface IKasesFormProps extends WithLoaderProps {
  cfsId?: string;
  onClose: () => void;
}

const KasesForm = ({
  showLoader,
  hideLoader,
  cfsId,
  onClose,
  setIsDirty,
  onNavigation,
}: IKasesFormProps & WithUnsavedChangesWarningProps) => {
  const { kase, updateKase } = useKaseContext();
  const { user } = useUserStore();

  const navigate = useNavigate();
  const { updateToast } = useToastStore();
  const methods = useForm<IKaseNewForm>({
    mode: 'all',
  });
  const [officerOptions, setOfficerOptions] = useState<SelectItem[]>([]);

  const { handleSubmit, reset, setValue, formState, watch, getValues } =
    methods;

  const kaseTypeOptions = useMemo(() => {
    return Object.entries(KaseTypes).map(([value, label]) => ({
      label,
      value,
    }));
  }, []);

  const currentAssignedOfficers = watch('assistingOfficers');
  const currentPrimaryOfficer = watch('primaryOfficer');

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchCfs();
  }, [cfsId]);

  useEffect(() => {
    resetKaseFormValues();
  }, [kase, onClose]);

  useEffect(() => {
    if (kase || !user?._id) return;
    setValue('primaryOfficer', user?._id);
  }, [!kase, setValue]);

  const fetchCfs = async () => {
    if (!cfsId) return;
    showLoader();
    try {
      const { data } = await CfsApi.getOne(cfsId);

      reset({
        address: data.addressInfo?.address,
        type: 'INCIDENT_REPORT',
        incidentStartedAt: data.createdAt,
        primaryOfficer: getValues('primaryOfficer'),
        // incidentReportedAt: data.incidentReportedAt,
        incidentCodes: data.incidentCodes.map(({ _id }) => _id),
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return null;
    } finally {
      hideLoader();
    }
  };

  const resetKaseFormValues = () => {
    if (!kase) return;

    reset({
      _id: kase._id,
      primaryInvestigator: kase.primaryInvestigator?._id,
      assistingOfficers: kase.assistingOfficers?.map((item) => item._id),
      primaryOfficer: kase.primaryOfficer?._id,
      address: kase.address,
      type: kase.type,
      incidentCodes: kase.incidentCodes,
      locationCode: kase.locationCode?._id,
      sdpIdentifier: kase.sdpIdentifier,
      ...(kase.incidentStartedAt && {
        incidentStartedAt: new Date(kase.incidentStartedAt?.toString()),
      }),
      ...(kase.incidentReportedAt && {
        incidentReportedAt: new Date(kase.incidentReportedAt?.toString()),
      }),
      requireNarative: kase?.requireNarative?.reduce(
        (acc, item) => ({ ...acc, [item]: true }),
        {},
      ),
    });
  };

  const fetchEmployees = async () => {
    showLoader();
    const filterParams: any = {
      'roles.role': 'POLICE',
    };
    try {
      const { data } = await UserApi.get({
        limit: 1000,
        filter: JSON.stringify(filterParams),
      });

      const officers = data.results.map((officer) => ({
        value: officer._id,
        label: officer.fullName,
      }));

      setOfficerOptions(officers);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
  };

  const getListItems = (fieldName: string) => {
    return fieldName === 'type' ? kaseTypeOptions : officerOptions;
  };

  const onSubmit = async (values: IKaseNewForm) => {
    setIsDirty(false);
    showLoader();
    try {
      const data = {
        ...values,
        primaryOfficer: values.primaryOfficer ?? '',
        primaryInvestigator: values.primaryInvestigator ?? '',
        address: values.address?._id ?? '',
        incidentStartedAt: values.incidentStartedAt
          ? new Date(values.incidentStartedAt).toISOString()
          : '',
        incidentReportedAt: values.incidentReportedAt
          ? new Date(values.incidentReportedAt).toISOString()
          : '',
        requireNarative: values.requireNarative
          ? Object.entries(values.requireNarative).reduce(
              (acc, [key, value]) => (value ? [...acc, key] : acc),
              [] as string[],
            )
          : [],
      };

      const res = cfsId
        ? await KaseApi.createFromCsf(data, cfsId)
        : await KaseApi.save(data);

      if (res.data._id) {
        updateToast({
          open: true,
          message: kase ? 'Changes saved successfully!' : 'New case saved',
          type: ConfirmTypes.SUCCESS,
        });
        if (values._id) {
          updateKase(res.data);
        } else {
          const navRules =
            values.type !== 'INCIDENT_REPORT' ? '/narrative' : '/overview';
          navigate(`/cases/${res.data._id}${navRules}`);
        }
      } else {
        updateToast({
          open: true,
          message: 'Error: cannot save case',
          type: ConfirmTypes.ERROR,
        });
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
    hideLoader();
    reset();
    onClose();
    return false;
  };

  return (
    <FormProvider {...methods}>
      <FormRoot>
        <Grid
          container
          flexDirection="row"
          columnSpacing={2}
          justifyContent="flex-end"
          mb={1}
        >
          {KaseFormFields.map(
            ({ grid, type, name, handleChange, ...rest }: any) => (
              <Grid key={name} {...grid} item mb={2} sx={{ maxWidth: 300 }}>
                {name === 'locationCode' ? (
                  <>
                    <InputLabel>CTV Location Code</InputLabel>
                    <LocationCodeSearch
                      onSelect={(value) => {
                        setIsDirty(true);
                        setValue('locationCode', value);
                      }}
                      value={kase?.locationCode}
                    />
                  </>
                ) : // Primary officer should be pre-populated with current user (if applicable) on Creating Case
                name === 'primaryOfficer' && !kase ? (
                  <RenderFormField
                    type="autocomplete"
                    name="primaryOfficer"
                    label="Primary officer"
                    items={getListItems('primaryOfficer')}
                    handleChange={() => {
                      setIsDirty(true);
                      if (handleChange) {
                        handleChange('primaryOfficer');
                      }
                    }}
                    {...rest}
                  />
                ) : (
                  <RenderFormField
                    {...rest}
                    name={name}
                    type={type}
                    {...((type === 'select' || type === 'autocomplete') && {
                      items:
                        name !== 'primaryOfficer'
                          ? getListItems(name)
                          : getListItems(name).filter(
                              (officer) =>
                                !currentAssignedOfficers?.includes(
                                  officer.value as string,
                                ),
                            ),
                      handleChange: () => {
                        setIsDirty(true);
                        if (handleChange) {
                          handleChange(name);
                        }
                      },
                    })}
                    {...(type === 'datetime' && {
                      onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => {
                        e.stopPropagation();
                        if (e.key === 'Enter') {
                          setValue(name, new Date());
                        }
                      },
                    })}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 250, padding: 0 } },
                    }}
                  />
                )}
              </Grid>
            ),
          )}
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <RenderFormField
              label="Assisting officers"
              name="assistingOfficers"
              type="autocomplete"
              items={officerOptions.filter(
                (officer) => currentPrimaryOfficer !== officer.value,
              )}
              multiple
            />
          </Grid>
          {!!currentAssignedOfficers?.length && (
            <Grid item md={12}>
              <TableContainer
                dontCalculateHeight
                sx={{
                  padding: 0,
                  boxShadow: 'none',
                  borderRadius: 0,
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell key="Assisting officer" colSpan={2}>
                        Assisting officer
                      </TableCell>
                      <TableCell
                        key="Require narrative"
                        sx={{ textAlign: 'right' }}
                      >
                        Require narrative
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentAssignedOfficers?.map((userID) => {
                      const officerDetails = officerOptions.find(
                        (officer) => officer.value === userID,
                      );

                      return (
                        officerDetails && (
                          <TableRow key={userID}>
                            <TableCell sx={{ width: '40px' }}>
                              <IconButton
                                onClick={() =>
                                  setValue(
                                    'assistingOfficers',
                                    currentAssignedOfficers?.filter(
                                      (officer) => officer !== userID,
                                    ),
                                  )
                                }
                                sx={{
                                  color: colors.error.main,
                                  alignSelf: 'center',
                                }}
                              >
                                <IcClose />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <Typography>{officerDetails.label}</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                              <RenderFormField
                                name={`requireNarative.${userID}`}
                                type="switch"
                                checked={
                                  !!kase?.requireNarative?.includes(userID)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </FormRoot>
      <Stack
        justifyContent="flex-end"
        flexDirection="row"
        sx={{ position: 'sticky', bottom: 0, background: '#fff', pt: 2 }}
      >
        <Button
          variant="contained"
          color="inherit"
          size="medium"
          onClick={() => {
            if (onNavigation(new Event('close'))) {
              onClose();
            }
          }}
          sx={{ mx: 2 }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          size="medium"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={formState.isSubmitting}
        >
          {kase ? 'Save' : 'Create'}
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default withLoader(withUnsavedChangesWarning(KasesForm));
