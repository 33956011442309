import { useFormContext } from 'react-hook-form';

import { Grid, InputLabel, Stack } from '@mui/material';

import { FormCard, PrimaryReporterForm } from '@/components';
import { IMasterName, IMasterNamePerson } from '@/models';

import { AssociatedItem } from './AssociatedItem';

interface AssociatedFormProps {
  masterNames: IMasterNamePerson[];
}

export const AssociatedReporterForm = ({
  masterNames,
}: AssociatedFormProps) => {
  const { setValue, watch } = useFormContext();
  const primaryReporter = watch('primaryReporter');

  const handleChange = async (newValue?: IMasterName) => {
    setValue('primaryReporter', newValue);
  };

  const handleClickItem = (item: IMasterName) => {
    if (item?._id === primaryReporter?._id) {
      handleChange();
    } else {
      handleChange(item);
    }
  };

  return (
    <FormCard title="Reporter information" style={{ marginTop: '1px' }}>
      <Grid sm={12} item>
        <InputLabel>Reporter name</InputLabel>
        <PrimaryReporterForm
          masterName={primaryReporter}
          onChange={handleChange}
        />

        <FormCard
          title="Associated names"
          style={{ boxShadow: 'none', padding: 0 }}
        >
          <Stack className="item-wrapper">
            {masterNames.map((masterName) => (
              <AssociatedItem
                key={masterName._id}
                itemType="reporter"
                label={masterName?.name}
                isUsed={masterName._id === primaryReporter?._id}
                tooltip={masterName.isVerified ? 'Verified' : ''}
                onClick={() => handleClickItem(masterName)}
              />
            ))}
          </Stack>
        </FormCard>
      </Grid>
    </FormCard>
  );
};
