import { Tabs as MuiTabs, styled } from '@mui/material';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  marginBottom: -1,

  '& .MuiTab-root': {
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    margin: '15px 10px',
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    lineHeight: '1.25rem',

    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.error.main,
  },
}));
