import { ChangeEvent, KeyboardEvent, useState } from 'react';

import { IconButton } from '@mui/material';

import { IcArrowCircleRight } from '@/assets/images';

import { Input } from '../../FormElements/Input';

interface AdditionalInfoInputProps {
  label?: string;
  placeholder?: string;
  onSubmit: (value: string) => void;
}

export const AdditionalInfoInput = (props: AdditionalInfoInputProps) => {
  const { onSubmit, ...rest } = props;
  const [value, setValue] = useState('');

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (value) onSubmit(value);
    setValue('');
  };

  return (
    <Input
      name="additionalInfo"
      value={value}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      endAdornment={
        <IconButton onClick={handleSubmit} disabled={!value}>
          <IcArrowCircleRight />
        </IconButton>
      }
      {...rest}
      sx={{ width: '100%', paddingRight: 0.25, lineHeight: '24px' }}
      fullWidth
    />
  );
};
