export enum LocalStorageItems {
  AccessToken = 'access-token',
  RefreshToken = 'refresh-token',
  CurrentAgencyId = 'current-agency-id',
  ExpandedUnitItems = 'expanded-unit-items',
  UnitShiftListCurrentTab = 'unit-shift-list-current-tab',
  UnitShiftListOpen = 'unit-shift-list-open',
  UnitShiftsOpenStyle = 'unit-shifts-open-style',
  ManageUnitsOpenStyle = 'manage-units-open-style',
}

export enum LocalStoragePrefix {
  TableSetting = 'table-setting',
  MasterIndexTableSetting = 'master-index-table-setting',
  UnitFilters = 'unit-filters',
}
