import { styled, TextField as MuiTextField, Paper } from '@mui/material';

import { colors } from '@/theme/variables';

export const AddressFieldRoot = styled('div')(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
  display: 'flex',
  gap: '16px',
  alignItems: 'flex-start',

  '& > *:first-of-type': {
    flex: 1,
    minWidth: 0,
  },

  '& > *:last-child': {
    flex: 1,
    minWidth: 0,
  },

  '& .MuiAutocomplete-root': {
    width: '100%',
    padding: 0,

    '& .MuiAutocomplete-input': {
      height: 36,
      padding: '0px 5px!important',
      boxSizing: 'border-box!important',
    },
  },

  '& .MuiInputLabel-root.Mui-disabled': {
    marginTop: 0,
    color: colors.grey[30],
  },

  '& .MuiInputLabel-root': {
    marginTop: 0,
  },

  '& .txt-busines-name': {
    flex: 1,
    marginTop: 5,
    marginLeft: 10,
    fontSize: '0.875rem',
    color: colors.grey[30],
  },

  '& .MuiStack-root': {
    width: '100%',
    minWidth: 0,
  },
}));

export const TextField = styled(MuiTextField)(({ theme }) => ({
  height: 36,
  width: '100%',
  '& .MuiInputBase-root': {
    padding: '0px 5px',
    height: '36px',
  },

  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },

  '& .MuiOutlinedInput-root .MuiChip-root': {
    height: 33,
    marginTop: 0,
  },

  '& .MuiAutocomplete-clearIndicator': {
    color: colors.error.main,
  },
  '& .MuiAutocomplete-popupIndicator': {
    display: 'none',
  },
}));

export const OptionListPaper = styled(Paper)(() => ({
  marginTop: 10,
  borderRadius: 6,

  '& .MuiAutocomplete-noOptions': {
    padding: '14px 8px',
  },
}));

export const AddressOptionItemRoot = styled('li')(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: 0,
  borderBottom: `1px solid ${colors.grey[10]}`,
  color: theme.palette.text.primary,

  '& .ic-location': {
    width: 20,
    height: 20,
    marginRight: 5,
    marginLeft: -8,
  },
}));
