import {
  PropsWithChildren,
  ReactNode,
  isValidElement,
  CSSProperties,
} from 'react';

import { GridProps, Tooltip, Typography } from '@mui/material';

import { ViewItemRoot } from './styles';

interface ViewItemProps extends PropsWithChildren, GridProps {
  label?: string;
  value?: string | number | boolean | Date | null | ReactNode;
  labelStyle?: CSSProperties;
  valueStyle?: CSSProperties;
  children?: ReactNode;
  valueAsTooltip?: boolean;
}

export const ViewItem = ({
  label,
  value,
  direction = 'row',
  labelStyle,
  valueStyle,
  children,
  valueAsTooltip,
  ...rest
}: ViewItemProps) => {
  return (
    <ViewItemRoot
      xs={12}
      sx={{ display: 'flex', flexDirection: 'column' }}
      direction={direction}
      {...rest}
      container
      item
    >
      {label && (
        <Typography className="label" style={labelStyle}>
          {label}
        </Typography>
      )}
      {value !== null &&
        value !== undefined &&
        (valueAsTooltip ? (
          <Tooltip title={value.toString()}>
            <Typography
              className={`value${
                label?.toLowerCase() === 'email address' ? ' is--email' : ''
              }`}
              style={{
                ...valueStyle,
                ...{
                  fontSize: '14px',
                  wordBreak: 'break-word',
                  width: 125,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            >
              {isValidElement(value) ? value : value.toString()}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            className={`value${
              label?.toLowerCase() === 'email address' ? ' is--email' : ''
            }`}
            style={valueStyle}
          >
            {isValidElement(value) ? value : value.toString()}
          </Typography>
        ))}
      {children}
    </ViewItemRoot>
  );
};
