import { lazy } from 'react';
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { MasterIndexApiPath } from '@/api';
import { UnitShiftListModal } from '@/components';
import { UnitShiftListSideModal } from '@/components/Modals/UnitShiftListSideModal/UnitShiftListSideModal';
import { AuthLayout } from '@/layouts/AuthLayout';
import { MainLayout } from '@/layouts/MainLayout';
import { CFSInvolvementHistory } from '@/layouts/TabLayout/MasterNames/components/tables/CFSInvolvementHistory';
import { Role } from '@/models';
import FirstResponderDashboardPage from '@/pages/FirstResponder/pages/FirstResponderDashboard/FirstResponderDashboardPage';
import KaseReportListScreen from '@/pages/Kases/KaseReports/KaseLogsListScreen';
import MasterAddressCautionsView from '@/pages/MasterIndex/Address/components/MasterAddressCautionsView';
import { MasterIndexAddressViewContent } from '@/pages/MasterIndex/Address/components/MasterIndexAddressViewContent';
import { MasterIndexDetailsPage } from '@/pages/MasterIndex/components/MasterIndexDetailsPage';
import MasterIndexRelatedRecords from '@/pages/MasterIndex/components/MasterIndexRelatedRecords/MasterIndexRelatedRecords';
import { MasterIndex } from '@/pages/MasterIndex/MasterIndex';
import MasterIndexOrganizationForm from '@/pages/MasterIndex/Organizations/Form';
import MasterIndexOrganizationNotes from '@/pages/MasterIndex/Organizations/Notes';
import MasterIndexOrganizationView from '@/pages/MasterIndex/Organizations/View';
import MasterIndexPersonForm from '@/pages/MasterIndex/Persons/Form';
import MasterIndexPersonNotes from '@/pages/MasterIndex/Persons/Notes';
import MasterIndexPersonView from '@/pages/MasterIndex/Persons/View';
import MasterVehicleEdit from '@/pages/MasterIndex/Vehicles/components/MasterVehicleEdit';
import MasterIndexVehicleView from '@/pages/MasterIndex/Vehicles/components/MasterVehicleView';

import { AuthGuard, DemoGuard, GuestGuard } from './RouteGuards';
import {
  MainRoutes,
  MasterIndexRoutes,
  MasterIndexTabRoutes,
} from './RoutePaths';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// Auth screens
const Login = lazy(() => import('@/pages/Auth/Login'));
const ForgotPassword = lazy(() => import('@/pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('@/pages/Auth/ResetPassword'));
const EmailConfirm = lazy(() => import('@/pages/Auth/EmailConfirm'));
const CodeVerification = lazy(() => import('@/pages/Auth/CodeVerification'));

// Dashboard screens
const AgencyList = lazy(() => import('@/pages/AgencyList'));
const AgencyDetail = lazy(() => import('@/pages/AgencyDetail'));
const EmployeeList = lazy(() => import('@/pages/Employees/List'));
const EmployeeEdit = lazy(() => import('@/pages/Employees/Edit'));
const EmployeeView = lazy(() => import('@/pages/Employees/View'));
const VehicleList = lazy(() => import('@/pages/Vehicles/List'));
const VehicleEdit = lazy(() => import('@/pages/Vehicles/Edit'));
const VehicleView = lazy(() => import('@/pages/Vehicles/View'));
const EquipmentList = lazy(() => import('@/pages/Equipments/List'));
const EquipmentEdit = lazy(() => import('@/pages/Equipments/Edit'));
const EquipmentView = lazy(() => import('@/pages/Equipments/View'));
const UnitList = lazy(() => import('@/pages/Units/List'));
const UnitEdit = lazy(() => import('@/pages/Units/Edit'));
const UnitView = lazy(() => import('@/pages/Units/View'));
const UnitShiftList = lazy(() => import('@/pages/Units/UnitShifts'));
const MasterUnitShiftList = lazy(() => import('@/pages/UnitShifts/List'));
const VendorList = lazy(() => import('@/pages/Vendors/VendorList'));
const VendorView = lazy(() => import('@/pages/Vendors/VendorView'));
const VendorEdit = lazy(() => import('@/pages/Vendors/VendorEdit'));
const VendorHistory = lazy(() => import('@/pages/Vendors/VendorHistory'));
const InventoryLocationsList = lazy(
  () => import('@/pages/InventoryLocations/InventoryLocationsList'),
);
const InventoryLocationsEdit = lazy(
  () => import('@/pages/InventoryLocations/InventoryLocationsEdit'),
);
const InventoryLocationsView = lazy(
  () => import('@/pages/InventoryLocations/InventoryLocationsView'),
);

// CFS screens
const CFSList = lazy(() => import('@/pages/CFS/List'));
const ActiveCFSList = lazy(() => import('@/pages/CFS/ActiveCFSList'));
const CFSEdit = lazy(() => import('@/pages/CFS/Edit'));
const CFSHistory = lazy(() => import('@/pages/CFS/CFSHistory'));
const CFSNames = lazy(() => import('@/pages/CFS/CFSNames'));
const CFSVehicles = lazy(() => import('@/pages/CFS/CFSVehicles'));
const CFS911Info = lazy(() => import('@/pages/CFS/Information911'));
const CFSProcedure = lazy(
  () => import('@/pages/CFS/CFSRecommendedProcedure/Procedure'),
);
const NewWindowLiveInfo = lazy(
  () => import('@/pages/CFS/Edit/components/NewWindowLiveInfo'),
);
const CFSFirstRespondersPage = lazy(
  () => import('@/pages/FirstResponder/pages/MyCfs/MyCfsPage'),
);

// Kases screens
const KaseList = lazy(() => import('@/pages/Kases/KaseList/KaseList'));
const RecordsList = lazy(() => import('@/pages/CFS/Records/RecordsList'));
const KaseEdit = lazy(() => import('@/pages/Kases/KaseEdit/KaseEdit'));
const KaseNames = lazy(() => import('@/pages/Kases/KaseNames/KaseNames'));
const KaseVehicles = lazy(
  () => import('@/pages/Kases/KaseVehicles/KaseVehicles'),
);
const KaseProperty = lazy(
  () => import('@/pages/Kases/KaseProperty/KaseProperty'),
);
const KaseNaratives = lazy(
  () => import('@/pages/Kases/KaseNaratives/KaseNaratives'),
);
const KaseFiles = lazy(() => import('@/pages/Kases/KaseFiles/KaseFiles'));
const KaseOverview = lazy(
  () => import('@/pages/Kases/KaseOverview/KaseOverview'),
);
const KaseCrimes = lazy(() => import('@/pages/Kases/KaseCrimes/KaseCrimes'));
const KaseArrests = lazy(() => import('@/pages/Kases/KaseArrests/KaseArrests'));
const KaseLogListScreen = lazy(
  () => import('@/pages/Kases/KaseLogs/KaseLogsListScreen'),
);

// Tasks screens
const TaskList = lazy(() => import('@/pages/Tasks/TaskList/TaskList'));
const TaskEdit = lazy(() => import('@/pages/Tasks/TaskEdit/TaskEdit'));
const TaskView = lazy(() => import('@/pages/Tasks/TaskView/TaskView'));

// Tools screens
const ComplaintsList = lazy(
  () => import('@/pages/Tools/Complaints/ComplaintsList'),
);
const SexOffendersList = lazy(
  () => import('@/pages/Tools/SexOffenders/SexOffendersList'),
);
const BOLOsList = lazy(() => import('@/pages/Tools/BOLOs/BOLOsList'));
const CameraList = lazy(() => import('@/pages/Tools/Cameras/CameraList'));
const CameraEdit = lazy(() => import('@/pages/Tools/Cameras/CameraEdit'));
const CameraView = lazy(() => import('@/pages/Tools/Cameras/CameraView'));
const PermitList = lazy(
  () => import('@/pages/Tools/Permits/PermitList/PermitList'),
);

// MasterIndex
const MasterIndexPersonsList = lazy(
  () => import('@/pages/MasterIndex/Persons/components/MasterPersonsDataGrid'),
);
const MasterIndexOrganizationsList = lazy(
  () => import('@/pages/MasterIndex/Organizations/List'),
);
const MasterIndexVehiclesList = lazy(
  () => import('@/pages/MasterIndex/Vehicles/List'),
);
const MasterIndexPropertiesList = lazy(
  () => import('@/pages/MasterIndex/Properties/List'),
);
const MasterIndexPropertiesView = lazy(
  () => import('@/pages/MasterIndex/Properties/View'),
);
const MasterIndexAddressList = lazy(
  () => import('@/pages/MasterIndex/Address/List'),
);
const MasterIndexAddressEdit = lazy(
  () => import('@/pages/MasterIndex/Address/Edit'),
);

const MasterIndexLogsList = lazy(() => import('@/pages/MasterIndex/Logs/List'));

// Error pages
const NotFoundScreen = lazy(() => import('@/pages/NotFound'));

export const routes: RouteObject[] = [
  {
    path: '/auth',
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/auth/login" replace />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'confirm-email',
        element: <EmailConfirm />,
      },
      {
        path: 'code-verification',
        element: <CodeVerification />,
      },
      {
        path: 'login',
        element: <Login />,
      },
    ],
  },
  {
    path: 'cfs/:cfsId',
    element: (
      <AuthGuard>
        <CFSEdit />
      </AuthGuard>
    ),
    children: [
      {
        path: 'names',
        element: <CFSNames />,
      },
      {
        path: 'vehicles',
        element: <CFSVehicles />,
      },
      {
        path: '911-information',
        element: <CFS911Info />,
      },
      {
        path: 'procedure',
        element: <CFSProcedure />,
      },
      {
        path: 'history',
        element: <CFSHistory />,
      },
    ],
  },
  {
    path: 'cfs/:cfsId/live-info',
    element: (
      <AuthGuard>
        <NewWindowLiveInfo />
      </AuthGuard>
    ),
  },
  {
    path: 'unit-shifts',
    element: (
      <AuthGuard>
        <UnitShiftList />
      </AuthGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/cfs-active" replace />,
      },
      {
        path: 'agencies',
        handle: {
          allowedRoles: [Role.ACCOUNT_ADMIN, Role.AGENCY_ADMIN],
        },
        children: [
          {
            index: true,
            element: <AgencyList />,
          },
          {
            path: ':agencyId',
            element: <AgencyDetail />,
            children: [
              {
                index: true,
                element: <Navigate to="employees" replace />,
              },
              {
                path: 'employees',
                element: <EmployeeList />,
              },
              {
                path: 'vehicles',
                element: <VehicleList />,
              },
              {
                path: 'equipment',
                element: <EquipmentList />,
              },
              {
                path: 'units',
                element: <UnitList />,
                children: [
                  {
                    path: 'unit-shifts',
                    element: <UnitShiftListSideModal open={true} />,
                  },
                ],
              },
              {
                path: 'vendors',
                element: <VendorList />,
              },
              {
                path: 'inventory-locations',
                element: <InventoryLocationsList />,
                handle: {
                  roles: ['admin'],
                },
              },
            ],
          },
          {
            path: `${MainRoutes.EmployeeList}/:employeeId/view`,
            element: <EmployeeView />,
          },
          {
            path: `${MainRoutes.EmployeeList}/:employeeId`,
            element: <EmployeeEdit />,
          },
          {
            path: `${MainRoutes.VehicleList}/:vehicleId/view`,
            element: <VehicleView />,
          },
          {
            path: `${MainRoutes.VehicleList}/:vehicleId`,
            element: <VehicleEdit />,
          },
          {
            path: `${MainRoutes.EquipmentList}/:equipmentId/view`,
            element: <EquipmentView />,
          },
          {
            path: `${MainRoutes.EquipmentList}/:equipmentId`,
            element: <EquipmentEdit />,
          },
          {
            path: `${MainRoutes.UnitList}/:unitId/view`,
            element: <UnitView />,
          },
          {
            path: `${MainRoutes.UnitList}/:unitId`,
            element: <UnitEdit />,
          },
          {
            path: `${MainRoutes.VendorList}/:vendorId/view`,
            element: <VendorView />,
          },
          {
            path: `${MainRoutes.VendorList}/:vendorId`,
            element: <VendorEdit />,
          },
          {
            path: `${MainRoutes.VendorList}/:vendorId/history`,
            element: <VendorHistory />,
          },
          {
            path: `${MainRoutes.InventoryLocations}/:locationId/view`,
            element: <InventoryLocationsView />,
          },
          {
            path: `${MainRoutes.InventoryLocations}/:locationId`,
            element: <InventoryLocationsEdit />,
          },
        ],
      },
      {
        path: 'cfs',
        element: <CFSList />,
      },
      {
        path: 'cfs-active',
        element: <ActiveCFSList />,
        children: [
          {
            path: 'unit-shifts',
            element: (
              <UnitShiftListSideModal backRoute="/cfs-active" open={true} />
            ),
          },
          {
            path: ':cfsId',
            element: (
              <UnitShiftListModal
                fetchActiveCFSList={() => {
                  /* fetch active CFS list */
                }}
                open={true}
              />
            ),
            children: [
              {
                path: 'names',
                element: <CFSNames />,
              },
              {
                path: 'vehicles',
                element: <CFSVehicles />,
              },
              {
                path: '911-information',
                element: <CFS911Info />,
              },
              {
                path: 'procedure',
                element: <CFSProcedure />,
              },
              {
                path: 'history',
                element: <CFSHistory />,
              },
            ],
          },
        ],
      },
      {
        path: 'cases',
        children: [
          {
            index: true,
            element: <KaseList />,
          },
          {
            path: ':kaseId',
            element: <KaseEdit />,
            children: [
              {
                index: true,
                element: <KaseEdit />,
              },
              {
                path: 'overview',
                element: <KaseOverview />,
              },
              {
                path: 'names',
                element: <KaseNames />,
              },
              {
                path: 'vehicles',
                element: <KaseVehicles />,
              },
              {
                path: 'properties',
                element: <KaseProperty />,
              },
              {
                path: 'narrative',
                element: <KaseNaratives />,
              },
              {
                path: 'files',
                element: <KaseFiles />,
              },
              {
                path: 'crimes',
                element: <KaseCrimes />,
              },
              {
                path: 'arrests',
                element: <KaseArrests />,
              },
              {
                path: 'logs',
                element: <KaseLogListScreen />,
              },
              {
                path: 'reports',
                element: (
                  <DemoGuard>
                    <KaseReportListScreen />
                  </DemoGuard>
                ),
              },
            ],
          },
          {
            path: 'records',
            element: <RecordsList />,
          },
          {
            path: 'cfs',
            element: <KaseList />,
            children: [
              {
                path: ':cfsId',
                element: (
                  <UnitShiftListModal
                    fetchActiveCFSList={() => {
                      /* fetch active CFS list */
                    }}
                    open={true}
                    backRoute="/cases"
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'master-index',
        element: <MasterIndex />,
        children: [
          {
            index: true,
            element: <Navigate to={MasterIndexRoutes.Persons} replace />,
          },
          {
            path: MasterIndexRoutes.Persons,
            children: [
              {
                index: true,
                element: <MasterIndexPersonsList />,
              },
              {
                path: 'new',
                element: (
                  <MasterIndexDetailsPage
                    action="new"
                    masterIndexType={MasterIndexRoutes.Persons}
                  />
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate to={MasterIndexTabRoutes.Information} replace />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexPersonForm />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        type={MasterIndexApiPath.People}
                      />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Notes,
                    element: <MasterIndexPersonNotes />,
                  },
                ],
              },
              {
                path: ':id/view',
                element: (
                  <MasterIndexDetailsPage
                    action="view"
                    masterIndexType="persons"
                  />
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="information" replace />,
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexPersonView />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        type={MasterIndexApiPath.People}
                      />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Notes,
                    element: <MasterIndexPersonNotes />,
                  },
                  {
                    path: 'cfs-history',
                    element: <CFSInvolvementHistory />,
                  },
                ],
              },
              {
                path: ':id/edit',
                element: (
                  <MasterIndexDetailsPage
                    action="edit"
                    masterIndexType="persons"
                  />
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="information" replace />,
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexPersonForm />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        type={MasterIndexApiPath.People}
                      />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Notes,
                    element: <MasterIndexPersonNotes />,
                  },
                ],
              },
            ],
          },
          {
            path: MasterIndexRoutes.Organizations,
            children: [
              {
                index: true,
                element: <MasterIndexOrganizationsList />,
              },
              {
                path: 'new',
                element: (
                  <MasterIndexDetailsPage
                    action="new"
                    masterIndexType={MasterIndexRoutes.Organizations}
                  />
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate to={MasterIndexTabRoutes.Information} replace />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexOrganizationForm />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: <></>,
                  },
                  {
                    path: MasterIndexTabRoutes.Notes,
                    element: <></>,
                  },
                ],
              },
              {
                path: ':id/view',
                element: (
                  <MasterIndexDetailsPage
                    action="view"
                    masterIndexType="organizations"
                  />
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="information" replace />,
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexOrganizationView />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        type={MasterIndexApiPath.Organizations}
                      />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Notes,
                    element: <MasterIndexOrganizationNotes />,
                  },
                ],
              },
              {
                path: ':id/edit',
                element: (
                  <MasterIndexDetailsPage
                    action="edit"
                    masterIndexType="organizations"
                  />
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="information" replace />,
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexOrganizationForm />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        type={MasterIndexApiPath.Organizations}
                      />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Notes,
                    element: <MasterIndexOrganizationNotes />,
                  },
                ],
              },
            ],
          },
          {
            path: MasterIndexRoutes.Vehicles,
            children: [
              {
                index: true,
                element: <MasterIndexVehiclesList />,
              },
              {
                path: 'new',
                element: (
                  <MasterIndexDetailsPage
                    action="new"
                    masterIndexType={MasterIndexRoutes.Vehicles}
                  />
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate to={MasterIndexTabRoutes.Information} replace />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterVehicleEdit />,
                  },
                ],
              },
              {
                path: ':vehicleId',
                element: (
                  <MasterIndexDetailsPage
                    action="edit"
                    masterIndexType={MasterIndexRoutes.Vehicles}
                  />
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate to={MasterIndexTabRoutes.Information} replace />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexVehicleView />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        idKey="vehicleId"
                        type={MasterIndexApiPath.Vehicles}
                      />
                    ),
                  },
                ],
              },
              {
                path: ':vehicleId/edit',
                element: (
                  <MasterIndexDetailsPage
                    action="edit"
                    masterIndexType={MasterIndexRoutes.Vehicles}
                  />
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate to={MasterIndexTabRoutes.Information} replace />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterVehicleEdit />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        idKey="vehicleId"
                        type={MasterIndexApiPath.Vehicles}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: MasterIndexRoutes.Properties,
            children: [
              {
                index: true,
                element: <MasterIndexPropertiesList />,
              },
              {
                path: ':propertyId',
                element: <MasterIndexPropertiesView />,
              },
            ],
          },
          {
            path: MasterIndexRoutes.Address,
            children: [
              {
                index: true,
                element: <MasterIndexAddressList />,
              },
              {
                path: ':addressId/view',
                element: (
                  <MasterIndexDetailsPage
                    action="view"
                    masterIndexType="address"
                  />
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="information" replace />,
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexAddressViewContent />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        idKey="addressId"
                        type={MasterIndexApiPath.Addresses}
                      />
                    ),
                  },
                  {
                    path: MasterIndexTabRoutes.Cautions,
                    element: <MasterAddressCautionsView />,
                  },
                ],
              },
              {
                path: ':addressId/edit',
                element: (
                  <MasterIndexDetailsPage
                    action="edit"
                    masterIndexType="address"
                  />
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="information" replace />,
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexAddressEdit />,
                  },
                  {
                    path: MasterIndexTabRoutes.Related,
                    element: (
                      <MasterIndexRelatedRecords
                        idKey="addressId"
                        type={MasterIndexApiPath.Addresses}
                      />
                    ),
                  },
                ],
              },
              {
                path: 'new',
                element: (
                  <MasterIndexDetailsPage
                    action="new"
                    masterIndexType="address"
                  />
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="information" replace />,
                  },
                  {
                    path: MasterIndexTabRoutes.Information,
                    element: <MasterIndexAddressEdit />,
                  },
                ],
              },
            ],
          },
          {
            path: MasterIndexRoutes.Logs,
            children: [
              {
                index: true,
                element: <MasterIndexLogsList />,
              },
            ],
          },
        ],
      },
      {
        path: 'master-unit-shifts',
        element: <MasterUnitShiftList />,
      },
      {
        path: 'tasks',
        children: [
          {
            index: true,
            element: <TaskList />,
          },

          {
            path: `${MainRoutes.TaskList}/:taskId/view`,
            element: <TaskView />,
          },
          {
            path: `${MainRoutes.TaskList}/:taskId`,
            element: <TaskEdit />,
          },
        ],
      },
      {
        path: 'tools/permit',
        children: [
          {
            index: true,
            element: (
              <DemoGuard>
                <PermitList />
              </DemoGuard>
            ),
          },
        ],
      },
      {
        path: 'tools/complaints',
        element: (
          <DemoGuard>
            <ComplaintsList />
          </DemoGuard>
        ),
      },
      {
        path: 'tools/sex-offenders',
        element: (
          <DemoGuard>
            <SexOffendersList />
          </DemoGuard>
        ),
      },
      {
        path: 'tools/bolo',
        element: (
          <DemoGuard>
            <BOLOsList />
          </DemoGuard>
        ),
      },
      {
        path: 'tools/cameras',
        children: [
          {
            index: true,
            element: (
              <DemoGuard>
                <CameraList />
              </DemoGuard>
            ),
          },

          {
            path: `/${MainRoutes.CamerasList}/:cameraId/view`,
            element: (
              <DemoGuard>
                <CameraView />
              </DemoGuard>
            ),
          },
          {
            path: `/${MainRoutes.CamerasList}/add`,
            element: (
              <DemoGuard>
                <CameraEdit />
              </DemoGuard>
            ),
          },
          {
            path: `/${MainRoutes.CamerasList}/:cameraId`,
            element: (
              <DemoGuard>
                <CameraEdit />
              </DemoGuard>
            ),
          },
        ],
      },
    ],
  },
  // Add fullscreen pages (without padding) for mainLayout here
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout isFullScreen={true} />
      </AuthGuard>
    ),
    children: [
      {
        path: 'my-cfs',
        element: <CFSFirstRespondersPage />,
      },
      {
        path: 'map-view',
        element: <FirstResponderDashboardPage />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundScreen />,
  },
];

export const AppRoutes = () => {
  return <RouterProvider router={sentryCreateBrowserRouter(routes)} />;
};
