import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { MasterIndexApi, MasterIndexApiPath } from '@/api';
import { SearchBar } from '@/components';
import { withLoader, WithLoaderProps } from '@/hocs';
import { IMasterIndexRelatedItems, IMasterAddress } from '@/models';
import { tabRelatedRecordsTableColumns } from '@/pages/MasterIndex/data';
import { useToastStore } from '@/store';
import { RelatedRecordsTable } from '../RelatedRecordsTable';

interface RelatedRecordWithAddress extends IMasterIndexRelatedItems {
  addressDetails?: IMasterAddress;
}

const addressColumns = [
  { key: 'name', label: 'Record Number' },
  { key: 'location', label: 'Location' },
  { key: 'dateAndTime', label: 'Date and Time' },
  { key: 'actions', label: '' }
];

const MasterIndexRelatedRecords = ({
  showLoader,
  hideLoader,
  type,
  idKey = 'id',
}: WithLoaderProps & { type: MasterIndexApiPath; idKey?: string }) => {
  const { updateToast } = useToastStore();
  const [searchText, setSearchText] = useState<string>('');
  const [relatedRecordsRows, setRelatedRecordsRows] = useState<RelatedRecordWithAddress[]>([]);
  const { [idKey]: id } = useParams();

  const handleSearch = useDebouncedCallback((value: string) => {
    setSearchText(value);
  }, 500);

  const fetchMasterNameRelatedRecords = async () => {
    if (!id) return;
    showLoader();
    try {
      const res = await MasterIndexApi.getMasterRelatedRecords(type, id, {
        search: searchText,
        sort: JSON.stringify({ createdAt: -1 }),
      });
      if (res) {
        setRelatedRecordsRows(res.data);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
};

  const getColumns = () => {
    if (type === MasterIndexApiPath.Addresses) {
      return addressColumns;
    }
    return type === MasterIndexApiPath.Vehicles
      ? tabRelatedRecordsTableColumns.filter((c) => c.key !== 'involvment')
      : tabRelatedRecordsTableColumns;
  };

  useEffect(() => {
    fetchMasterNameRelatedRecords();
  }, [searchText, id]);

  return (
    <>
      <Box padding={2}>
        <SearchBar onChange={handleSearch} placeholder="Search..." />
      </Box>
      <RelatedRecordsTable 
        columns={getColumns()} 
        rows={relatedRecordsRows} 
      />
    </>
  );
};

export default withLoader(MasterIndexRelatedRecords);