import { Suspense } from 'react';

import { logger } from '@azure/storage-blob';
import { CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { APIProvider as GoogleMapApiProvider } from '@vis.gl/react-google-maps';

import { googleMapsApiKey, muiLicenseKey } from '@/config';

import AblyWrapper from './AblyWrapper';
import { Toast } from './components/Toast';
import { AuthProvider, ThemeProvider } from './contexts';
LicenseInfo.setLicenseKey(muiLicenseKey);

window.addEventListener('vite:preloadError', (_) => {
  logger.info('vite:preloadError', _);
  window.location.reload();
});

function App() {
  return (
    <Sentry.ErrorBoundary>
      <Suspense>
        <ThemeProvider>
          <CssBaseline />
          <Toast />
          <AuthProvider>
            <GoogleMapApiProvider apiKey={googleMapsApiKey}>
              <AblyWrapper />
            </GoogleMapApiProvider>
          </AuthProvider>
        </ThemeProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
