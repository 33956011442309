import { lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const UnitShiftList = lazy(() => import('@/pages/Units/UnitShifts'));

import { ModalProps } from '@mui/material';

import { UnitShiftProvider } from '@/contexts';
import { useUnitShiftContext } from '@/hooks';

import { SideModal } from '../SideModal';

interface UnitShiftListSideModalProps
  extends Omit<ModalProps, 'children' | 'onClose'> {
  width?: string;
  backRoute?: string;
  onClose?: () => void;
}

export const UnitShiftListSideModalComp = ({
  onClose,
  width,
  backRoute,
  ...rest
}: UnitShiftListSideModalProps) => {
  const { fetchAssignedUnitShifts } = useUnitShiftContext();
  const navigate = useNavigate();
  useEffect(() => {
    fetchAssignedUnitShifts();
  }, []);

  if (!onClose) {
    onClose = () => {
      if (backRoute) {
        navigate(backRoute);
      } else {
        navigate('..');
      }
    };
  }

  return (
    <SideModal
      {...rest}
      onClose={onClose}
      width={width ?? '90%'}
      closingType="hide"
    >
      <UnitShiftList />
    </SideModal>
  );
};

export const UnitShiftListSideModal = (props: UnitShiftListSideModalProps) => {
  return (
    <UnitShiftProvider>
      <UnitShiftListSideModalComp {...props} />
    </UnitShiftProvider>
  );
};
