import { Stack, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const AdditionalInfoList = styled(Stack)(({ theme }) => ({
  flex: 1,
  maxHeight: 'calc(100vh - 194px - 598px)',
  minHeight: 156,
  paddingLeft: 5,
  overflowY: 'auto',
  [theme.breakpoints.up('sm')]: {
    minWidth: 280,
  },

  '& .item': {
    paddingBottom: '10px',
    '&:first-of-type': {
      marginTop: '5px',
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },

  '& .circle': {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    border: '1px solid #CFD6DA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontWeight: 500,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  '& .title': {
    marginBottom: '5px',
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  '& .description-container': {
    padding: '5px 10px',
    width: '100%',
    borderRadius: 8,
    fontSize: '0.875rem',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#E7EAEC66',
    color: theme.palette.text.primary,

    '& .description-content': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      color: theme.palette.text.primary,
      minWidth: '85%',
    },

    '& .truncate': {
      minWidth: '75%',
    },

    '& .description-date': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      color: theme.palette.text.primary,

      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  '& .chip': {
    borderRadius: 6,
    fontWeight: 500,
    fontSize: '0.75rem',
    height: 24,
  },
  '& .btnLoadMore': {
    borderColor: colors.grey['10'],
  },
  '&.new-window': {
    maxHeight: 'calc(100vh - 138px)',
    minHeight: 'calc(100vh - 138px)',
  },
  '&.max-height': {
    maxHeight: '100%',
  },
}));
