import { ComponentPropsWithoutRef, FC } from 'react';

import { Box, styled } from '@mui/material';

interface TabPanelProps extends ComponentPropsWithoutRef<'div'> {
  index: number;
  value: number;
}

const TabPanelRoot = styled('div')(() => ({
  display: 'flex',
  minHeight: 0,

  '& .content': {
    flex: 1,
    display: 'flex',
  },

  '&.hidden': {
    display: 'none',
  },
}));

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelRoot
      role="tabpanel"
      className={value !== index ? 'hidden' : ''}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="content">{children}</Box>}
    </TabPanelRoot>
  );
};
