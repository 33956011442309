import {
  IcCarEms,
  IcCarFire,
  IcFirstResponderCarPolice,
} from '@/assets/images';
import { AgencyType } from '@/models';
import { colors } from '@/theme/variables';

export const UnitShiftMarkerIcons = {
  [AgencyType.EMS]: IcCarEms,
  [AgencyType.FIRE]: IcCarFire,
  [AgencyType.POLICE]: IcFirstResponderCarPolice,
};

export const UnitShiftMarkerColors: Record<string, any> = {
  ON_CALL: { color: colors.error.main, color1: colors.error.light },
  ACTIVE: { color: colors.secondary.main, color1: colors.secondary.light },
  EN_ROUTE: { color: colors.warning.main, color1: colors.warning.light },
  ASSIGNED: { color: colors.warning.main, color1: colors.warning.light },
};
