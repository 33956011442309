import { HTMLAttributes, SyntheticEvent, useEffect, useState } from 'react';

import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { KaseApi } from '@/api';
import { NoResultItem } from '@/components';
import { ICTVCode } from '@/models';
import { useToastStore } from '@/store';
import { colors } from '@/theme/variables';

interface LocationCodeSearchProps {
  existingCtvCodes?: ICTVCode[];
  onSelect: (id?: string) => void;
  value?: ICTVCode;
}

const LocationCodeSearch: React.FC<LocationCodeSearchProps> = ({
  existingCtvCodes,
  onSelect,
  value,
}: LocationCodeSearchProps) => {
  const [searchText, setSearchText] = useState('');
  const [searchedLinks, setSearchedLinks] = useState<ICTVCode[]>([]);
  const { updateToast } = useToastStore();

  const handleChange = async (newValue?: ICTVCode | null) => {
    setSearchText('');
    onSelect(newValue?._id || '');
  };

  useEffect(() => {
    fetchCtvCodes('');
  }, [searchText]);

  const handleInputChange = useDebouncedCallback(
    (e: SyntheticEvent, v: string) => {
      setSearchText(v);
      if (e?.type === 'click') return;
      fetchCtvCodes(v);
    },
    500,
  );

  const fetchCtvCodes = async (v?: string) => {
    try {
      const filter = JSON.stringify({
        $or: [
          { locationLiteral: { $regex: v, $options: 'i' } },
          { code: { $regex: v, $options: 'i' } },
        ],
      });
      const params = {
        sort: JSON.stringify({ createdAt: -1 }),
        limit: 50,
        filter,
      };

      const { data } = await KaseApi.listCtvCodes(params);
      setSearchedLinks(data.results);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const renderOptions = (
    props: HTMLAttributes<HTMLLIElement>,
    ctvCode: ICTVCode,
  ) => {
    const { code, locationLiteral, geographicType } = ctvCode;

    return (
      <Box
        {...props}
        component="li"
        flexDirection="column"
        flexWrap="wrap"
        sx={{
          flexDirection: 'row',
          borderBottom: `1px solid ${colors.grey[10]}`,
          flexWrap: 'wrap',
          '&:hover': {
            button: { display: 'block' },
          },
        }}
      >
        <Stack
          flexDirection="row"
          sx={{
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="inherit" sx={{ width: '100%' }}>
            {code} - {locationLiteral} - {geographicType}
          </Typography>
        </Stack>
      </Box>
    );
  };
  return (
    <Autocomplete
      fullWidth
      autoHighlight
      onInputChange={handleInputChange}
      options={searchedLinks}
      noOptionsText={<NoResultItem />}
      filterOptions={(filteredCtvCodes) => {
        return filteredCtvCodes.reduce((acc, item) => {
          const found = existingCtvCodes?.find((linked) => {
            return linked?._id === item._id;
          });

          return found ? acc : [...acc, item];
        }, [] as ICTVCode[]);
      }}
      onChange={(_, v) => {
        handleChange(v);
      }}
      // value={value || null}
      defaultValue={value}
      getOptionLabel={({ code, locationLiteral, geographicType }) =>
        `${code} - ${locationLiteral} - ${geographicType}`
      }
      renderOption={renderOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          value={searchText}
          placeholder="Search..."
          fullWidth
        />
      )}
      clearOnBlur={false}
    />
  );
};

export default LocationCodeSearch;
