/**
 * RequiredUnitForm Component
 *
 * Props:
 * - unitType(string, optional): This props is for single agency account. In single agency account, we edit only one required unit type at once.
 * - agencyType(string, optional): This props is for multiple agency account or add/edit multi-unit types in single agency account.
 * - onUpdateRequiredUnits(function, required): Callback function to handle cfs's required units updating.
 *
 */

import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { Button, Divider, IconButton, Stack } from '@mui/material';

import { CfsApi } from '@/api';
import { IcClose, IcPlus } from '@/assets/images';
import { RenderFormField } from '@/components';
import { WithLoaderProps, withLoader } from '@/hocs';
import withUnsavedChangesWarning from '@/hocs/withUnsavedChangesWarning';
import { useCFSContext } from '@/hooks';
import { AgencyType, IRequiredUnit, IRequiredUnitForm } from '@/models';
import { useDataStore, useToastStore, useUserStore } from '@/store';

import { requiredUnitFormFields } from './data';
import { RequiredUnitFormRoot } from './styles';

interface RequiredUnitsFormFields {
  requiredUnits: IRequiredUnitForm[];
}

interface RequiredUnitsFormProps extends WithLoaderProps {
  unitType?: string;
  agencyType?: AgencyType;
  onUpdateRequiredUnits: (requiredUnits: IRequiredUnit[]) => void;
}

const RequiredUnitForm = ({
  unitType,
  agencyType,
  onUpdateRequiredUnits,
  showLoader,
  hideLoader,
}: RequiredUnitsFormProps) => {
  const { cfs } = useCFSContext();
  const cfsId = cfs?._id;
  const { updateToast } = useToastStore();
  const { unitTypes } = useDataStore();
  const { account } = useUserStore();

  const [otherRequiredUnits, setOtherRequiredUnits] = useState<
    IRequiredUnitForm[]
  >([]);
  const methods = useForm<RequiredUnitsFormFields>({
    mode: 'all',
    defaultValues: { requiredUnits: [] },
  });
  const { reset, control, trigger, getValues, handleSubmit } = methods;
  const { append, remove } = useFieldArray({
    control,
    name: 'requiredUnits',
  });

  const { requiredUnits: formRequiredUnits } = getValues();
  const isSingleAgencyAccount =
    account?.settings.groupCFSRequiredUnits === false;

  const unitTypeItems = useMemo(() => {
    if (unitType) return null;
    return unitTypes
      .filter(
        (type) =>
          formRequiredUnits.findIndex((field) => field.unitType === type._id) <
            0 && type.agencyType === agencyType,
      )
      .map((type) => ({ label: type.name, value: String(type._id) }));
  }, [unitType, unitTypes, formRequiredUnits]);

  const disableAdd =
    !unitTypeItems?.length ||
    (unitTypeItems.length === 1 &&
      !!formRequiredUnits.find((_requiredUnit) => !_requiredUnit.unitType));

  useEffect(() => {
    const initRequiredUnits = cfs?.requiredUnits || [];
    let defaultValues: IRequiredUnitForm[] = [
      { unitType: unitType ?? '', number: 0 },
    ];
    const otherRequiredUnitList: IRequiredUnitForm[] = [];

    if (initRequiredUnits.length) {
      const defaultRequiredUnits = initRequiredUnits.reduce(
        (arr: IRequiredUnitForm[], current) => {
          const currentFormItem = {
            unitType: String(current.unitType._id),
            number: current.number,
          };

          // Single agency required unit form values
          if (unitType && current.unitType._id === unitType) {
            arr.push(currentFormItem);
          } else if (
            !unitType &&
            (current.unitType.agencyType === agencyType ||
              isSingleAgencyAccount)
          ) {
            // Multi agency required unit form values
            arr.push(currentFormItem);
          } else {
            otherRequiredUnitList.push(currentFormItem);
          }
          return arr;
        },
        [],
      );
      setOtherRequiredUnits(otherRequiredUnitList);
      if (defaultRequiredUnits.length) {
        defaultValues = defaultRequiredUnits;
      }
    }
    reset({ requiredUnits: defaultValues });
  }, [cfs?.requiredUnits]);

  const onAppend = async () => {
    const isValid = await trigger();
    if (isValid) {
      append({
        unitType:
          unitTypeItems?.length === 1 ? String(unitTypeItems[0].value) : '',
        number: 0,
      });
    }
  };

  const onSubmit = async (values: RequiredUnitsFormFields) => {
    showLoader();
    try {
      if (cfsId) {
        const res = await CfsApi.updateRequiredUnits(
          cfsId,
          values.requiredUnits.concat(otherRequiredUnits),
        );
        onUpdateRequiredUnits(res.data.requiredUnits);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
  };

  const renderRow = (field: IRequiredUnitForm, index: number) => {
    const currentUnitType = unitTypes.find(
      (_unitType) => _unitType._id === field.unitType,
    );
    const items = unitTypeItems?.slice();
    if (currentUnitType && items) {
      items.push({
        label: String(currentUnitType?.name),
        value: String(currentUnitType?._id),
      });
    }

    if (unitType) {
      return (
        <Stack flexDirection="row" columnGap={2} key={field.unitType}>
          <RenderFormField
            {...requiredUnitFormFields[1]}
            name={`requiredUnits.${index}.number`}
          />
        </Stack>
      );
    }

    return (
      <Stack flexDirection="row" columnGap={2} key={field.unitType}>
        {requiredUnitFormFields.map((item) => (
          <Stack flex={1} key={item.name}>
            <RenderFormField
              {...item}
              name={`requiredUnits.${index}.${item.name}`}
              items={items}
            />
          </Stack>
        ))}
        <IconButton
          onClick={() => remove(index)}
          sx={{ height: '40px', mt: 2.5 }}
        >
          <IcClose className="ic-close" />
        </IconButton>
      </Stack>
    );
  };

  return (
    <RequiredUnitFormRoot style={{ width: unitType ? 200 : 550 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Divider sx={{ my: 2 }} />
          {formRequiredUnits.map((field, index) => renderRow(field, index))}
          {!unitType && (
            <Button
              onClick={onAppend}
              color="inherit"
              variant="text"
              style={{ width: 'fit-content' }}
              disabled={disableAdd}
            >
              <IcPlus className="ic-plus" /> Add unit type
            </Button>
          )}
          <Divider sx={{ my: 2 }} />
          <Button color="error" variant="contained" type="submit" fullWidth>
            Update
          </Button>
        </form>
      </FormProvider>
    </RequiredUnitFormRoot>
  );
};

export default withLoader(withUnsavedChangesWarning(RequiredUnitForm));
