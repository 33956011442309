import { CSSProperties, ReactNode, Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { ExpandMore } from '@mui/icons-material';
import { Backdrop, Stack } from '@mui/material';
import { AnimatePresence } from 'framer-motion';

import { TabLayoutRoot } from './styles';
import {
  TabItem,
  TabLayoutContextProvider,
  useTabLayoutContext,
} from './TabLayoutContext';

interface TabLayoutProps {
  tabLinks: TabItem[];
  basePath: string;
  renderOnPage?: boolean;
  style?: CSSProperties;
  children?: ReactNode;
}

export const TabLayoutContent = ({
  basePath,
  tabLinks,
  renderOnPage,
  style,
  children,
}: TabLayoutProps) => {
  const navigate = useNavigate();
  const { updateTabLayoutContextValues } = useTabLayoutContext();
  const currentNavTab = tabLinks.find(
    (pathName) => `${basePath}/${pathName.path}` === location.pathname,
  );

  useEffect(() => {
    updateTabLayoutContextValues({ basePath, tabLinks });
  }, [basePath, tabLinks]);

  useEffect(() => {
    updateTabLayoutContextValues({ currentNavTab });
  }, [currentNavTab]);

  return (
    <TabLayoutRoot className={currentNavTab ? 'active' : ''} style={style}>
      <Stack className="tablayout-container">
        {!!currentNavTab && (
          <>
            {!renderOnPage && (
              <Stack className="header" onClick={() => navigate(basePath)}>
                <ExpandMore color="error" />
              </Stack>
            )}
            <Stack
              className={`tablayout-content ${renderOnPage && 'renderOnPage'}`}
            >
              <AnimatePresence mode="wait">
                <Suspense fallback={<p>Loading package location...</p>}>
                  <Outlet />
                </Suspense>
              </AnimatePresence>
            </Stack>

            {!renderOnPage && (
              <Backdrop
                open={true}
                onClick={() => navigate(basePath)}
                className="overlay"
              />
            )}
          </>
        )}
      </Stack>
      {children}
    </TabLayoutRoot>
  );
};

export const TabLayout = (props: TabLayoutProps) => (
  <TabLayoutContextProvider>
    <TabLayoutContent {...props} />
  </TabLayoutContextProvider>
);
