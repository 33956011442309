import { IAssignedUnitShift } from '@/models';

export const findUnitShiftByUserId = (
  data: IAssignedUnitShift[],
  userId: string,
) => {
  for (const unitShiftData of data) {
    const {
      unitShift: { users },
    } = unitShiftData;
    if (!users) continue;
    for (const u of users) {
      if (u._id === userId) {
        return unitShiftData;
      }
    }
  }
  return null;
};
